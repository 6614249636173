import React, { useEffect, useState }  from 'react';
import Swal from "sweetalert2";

export default function ModalContent(props) {

    const [modalparcel, setModalParcel] = useState('');
    const [userInfo, setUserInfo]       = useState('');
    const [hubInfo, setHubInfo]         = useState('');
    const [delUserInfo, setDelUserInfo] = useState('');
    const [batchInfo, setBatchInfo]     = useState('');
    
    const [isRemarkAnonymous, setisRemarkAnonymous]     = useState(false);
    const [clientRemark, setClientRemark]               = useState('');
    
     const [isRemarkActive, setIsRemarkActive] = useState(false);
     const [rformErrors, setrformErrors]       = useState({});

    const wayNewbillId = props.wayNbillId;
    const logModUser     = sessionStorage.getItem("UserID");
    
    useEffect(() => {
        async function parcelremarkRequest() {
          try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelRemark`, {
              method: 'POST', 
              body: JSON.stringify({
                  clientID: logModUser,
                  waybillId: wayNewbillId,
                 }),         
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            } )
              .then((respose) => {
                if (respose.ok) {
                  return respose.json()
                }
                throw new Error('error')
              })
              .then((data) => {
                console.log(data.Clstatus);
                setClientRemark(data.Clstatus);
               
              })
          } catch (error) {
            console.log(error.message)
          }
        }
        parcelremarkRequest();
    }, []);
    
    useEffect(() => {
        async function parcelModalRequest() {
          try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/getParcel`, {
              method: 'POST', 
              body: JSON.stringify({
                  waybill: wayNewbillId,
                  clientID: logModUser,
                 }),         
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            } )
              .then((respose) => {
                if (respose.ok) {
                  return respose.json()
                }
                throw new Error('error')
              })
              .then((data) => {
                console.log(data.Clstatus);
                setModalParcel(data.Clstatus);
               
              })
          } catch (error) {
            console.log(error.message)
          }
        }
        parcelModalRequest();
    }, []);
    
    async function userInfoRequest(NewUserid) {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/admin`, {
          method: 'POST', 
          body: JSON.stringify({
            Userid: NewUserid,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            // console.log(data.Clstatus);
            setUserInfo(data.Clstatus);
            // return (data.Clstatus);
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    
    async function deliverInfoRequest(DeUserid) {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/delUser`, {
          method: 'POST', 
          body: JSON.stringify({
            DUserid: DeUserid,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            // console.log(data.Clstatus);
            setDelUserInfo(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
      
    async function hubInfoRequest(logHub) {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getHub`, {
          method: 'POST', 
          body: JSON.stringify({
            hubID: logHub,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            // console.log(data.Clstatus);
            setHubInfo(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    
    async function hubBatchRequest(batchNumberN) {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/batchHistry`, {
          method: 'POST', 
          body: JSON.stringify({
            batchNumber: batchNumberN,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {          
            setBatchInfo(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }

    useEffect(() => {
      if(modalparcel.pickupBy){
        const NUserid = modalparcel.pickupBy;
        userInfoRequest(NUserid);      
      }
    },[modalparcel.pickupBy])

    useEffect(() => {
      if(modalparcel.deliveryBy){
        const NEUserid = modalparcel.deliveryBy;         
        deliverInfoRequest(NEUserid);      
      }
    },[modalparcel.deliveryBy])
    
    useEffect(() => {
      if(modalparcel.hub_id){
        const HubNid = modalparcel.hub_id;         
        hubInfoRequest(HubNid);      
      }
    },[modalparcel.hub_id])
    
    useEffect(() => {
      if(modalparcel.payment_batch_num){
        const BatchID = modalparcel.payment_batch_num;         
        hubBatchRequest(BatchID);      
      }
    },[modalparcel.payment_batch_num])
    
    
    
    const clientRemarkHandler = (event) => {
        setClientRemark(event.target.value);
        setIsRemarkActive(false)
        // console.log(event.target.value);
    }
    
    
     const validate = (clientRemark) => {
      const errors = {};

      if(!clientRemark){
        errors.clientRemark = "This field is required!";  
        setIsRemarkActive(true)
         
      }else{
        setIsRemarkActive(false)
         
       }

      return errors;
    }



    const submitRemarkHandler = (event) => {
      event.preventDefault()
      // setIsSubmit(true); 
            
      setrformErrors(validate(clientRemark));        

      var errorLength =  (Object.keys(validate(clientRemark)).length)
                    
         if(errorLength === 0 ){
          
          async function remarkAddRequest() {
            try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/addclientRemark`, {
                method: 'POST',
                body: JSON.stringify({
                  remark: clientRemark,
                  logModUser:logModUser,
                  waybillId: wayNewbillId,
                }),
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                  console.log(data.Clstatus);               
  
                  const statusRemark =  data.Clstatus;
                  
  
                  if(statusRemark === "success"){
                    setisRemarkAnonymous(false);
                    
                    Swal.fire({
                      title: "Good job!",
                      text: "Remark Successfully Added!",
                      icon: "success",
                      confirmButtonText: "OK",
                      timer: 1500,
                    });
                    
                    async function newparcelremarkRequest() {
                    try {
                        await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelRemark`, {
                        method: 'POST', 
                        body: JSON.stringify({
                            clientID: logModUser,
                            waybillId: wayNewbillId,
                           }),         
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                        },
                      } )
                        .then((respose) => {
                          if (respose.ok) {
                            return respose.json()
                          }
                          throw new Error('error')
                        })
                        .then((data) => {
                          console.log(data.Clstatus);
                          setClientRemark(data.Clstatus);
                         
                        })
                    } catch (error) {
                      console.log(error.message)
                    }
                  }
                  
                  newparcelremarkRequest();
                   
                  }else{
  
                    setisRemarkAnonymous(false);
                    
                    Swal.fire({
                      title: "Warning",
                      text: "Remark Add Unsuccessful!",
                      icon: "error",
                      confirmButtonText: "OK",
                      // timer: 1500,
                    });
  
                  }
                                 
                })
            } catch (error) {
              console.log(error.message)
            }
          }
      
          setisRemarkAnonymous(true);
          remarkAddRequest();
         
        }
      }

  return (
    <>
    {modalparcel !== "NoResults" ? ( 
      <div className="card" id="loadDivUn">
        <div className="card-body" style={{height: 'auto'}}>
          <div className="col-md-12">
            <div className="row" style={{marginBottom: '18px'}}>
              <h5 style={{margin:'auto'}}>Parcel Info</h5>
            </div> 
            <div className="row" style={{padding: '12px', fontWeight: '500', border: '1px solid #ece5e5', borderRadius: '15px', fontSize: '14px', boxShadow: '1px 1px 14px 3px #d8cece'}}>
              <div className="col-md-12" style={{textAlign: 'center'}}>
                
              {
                          (() => {
                              if(modalparcel.deliveryStatus === 'Waiting') {
                                      return (
                                        <h4>
                                          <span className="badge badge-default"><span style={{color:'green'}}>{modalparcel.deliveryStatus}</span></span>
                                        </h4>
                                      )
                                  } else if (modalparcel.deliveryStatus === 'Pickup') {
                                      return (
                                        <h4>
                                          <span className="badge badge-warning">{modalparcel.deliveryStatus}</span>
                                        </h4>
                                      )
                                  } else if (modalparcel.deliveryStatus === 'Pending'){
                                      return (
                                        <h4>
                                          <span className="badge badge-info">{modalparcel.deliveryStatus}</span>
                                        </h4>
                                      )
                                  }else if (modalparcel.deliveryStatus === 'Complete') {
                                      return (
                                        <h4>
                                          <span className="badge badge-success">{modalparcel.deliveryStatus}</span>
                                        </h4>
                                      )
                                  }else if (modalparcel.deliveryStatus === 'Reschedule') {
                                      return (
                                        <h4>
                                          <span className="badge badge-primary">{modalparcel.deliveryStatus}</span>
                                        </h4>
                                      )
                                  }else if (modalparcel.deliveryStatus === 'Cancel') {
                                      return (
                                        <h4>
                                          <span className="badge badge-danger">{modalparcel.deliveryStatus}</span>
                                        </h4>
                                      )
                                  }
                          })()  
                      }  
              </div> 
              
              <div className="col-md-12" style={{textAlign: 'center', marginTop: '5px'}}>
              Parcel ID : {wayNewbillId} 
              </div>

              <div className="col-md-12"  style={{textAlign: 'center', marginTop: '5px'}}>  
              Delivery Fee : {modalparcel.deliveryFee} LKR
              </div>
                         
              <div className="col-md-12"  style={{textAlign: 'center', marginTop: '5px'}}>  
                  Payment Method : 
                  {modalparcel.paymentType === 'COD' ? (
                    <span> COD ({modalparcel.CODAmount} LKR)</span>
                  ) : (
                    <span> AlreadyPaid</span>
                  )
                  }

              </div>
              
              <div className="col-md-12"  style={{textAlign: 'center', marginTop: '5px'}}>  
              Hub : {hubInfo.name}
              </div>
                {
                          (() => {
                                  if(modalparcel.deliveryStatus === 'Waiting') {
                                      return (
                                          <></>
                                      )
                                  }else if (modalparcel.deliveryStatus === 'Pickup') {
                                      return (
                                        <div className="col-md-12" style={{textAlign: 'center', marginTop: '5px'}}>  
                                       
                                        Pickup By : 
                                        {userInfo.fname ? (
                                          <span> {userInfo.fname}</span>
                                        ) : (
                                          <></>
                                        )
                                        }

                                        {userInfo.lname ? (
                                          <span> {userInfo.lname} </span>
                                        ) : (
                                          <></>
                                        )
                                        }
                                        
                                         ({modalparcel.pickupBy})
                                        </div>
                                      )
                                  }else if (modalparcel.deliveryStatus === 'Pending'){
                                      return (
                                        <></>
                                      )
                                  }else if (modalparcel.deliveryStatus === 'Complete') {
                                      return (
                                        <div className="col-md-12" style={{textAlign: 'center', marginTop: '5px'}}>  
                                        Delivered By :  
                                        
                                        {delUserInfo.fname ? (
                                          <span> {delUserInfo.fname}</span>
                                        ) : (
                                          <></>
                                        )
                                        }

                                        {delUserInfo.lname ? (
                                          <span> {delUserInfo.lname} </span>
                                        ) : (
                                          <></>
                                        )
                                        }
                                        
                                        </div>
                                      )
                                  }else if (modalparcel.deliveryStatus === 'Reschedule') {
                                      return (
                                        <></>
                                      )
                                  }else if (modalparcel.deliveryStatus === 'Cancel') {
                                      return (
                                        <></>
                                      )
                                  }
                          })()  
                }       

              <div className="row">
                <div className="col-md-12"  style={{textAlign: 'center', marginTop: '5px'}}>  
                        Responsible Person : 
                        <span> {modalparcel.pickupFrom}</span>             

                        {modalparcel.pickupMobile !== '' ? (
                          <span>, {modalparcel.pickupMobile}</span>
                        ) : (
                          <></>
                        )
                        }

                        {modalparcel.pickupAddress !== '' ? (
                          <span>, {modalparcel.pickupAddress}</span>
                        ) : (
                          <></>
                        )
                        }
            
                </div>
                <div className="col-md-12"  style={{textAlign: 'center', marginTop: '5px'}}>  
                        Recipient Info : 
                        <span> {modalparcel.recipientName}</span>   

                        {modalparcel.recipientMobile ? (
                          <span>, {modalparcel.recipientMobile}</span>
                        ) : (
                          <></>
                        )
                        }

                        {modalparcel.recipientAddress ? (
                          <span>, {modalparcel.recipientAddress}</span>
                        ) : (
                          <></>
                        )
                        }                               
                </div>
              </div>
              
            </div>
  
  
            <div className="row mt-3" style={{marginTop: '-21px', marginBottom: '18px'}} >
              <h5 style={{margin: 'auto'}}>Payment Info</h5>
            </div> 
  
            <div className="row" style={{fontWeight: '500', border: '1px solid #ece5e5', borderRadius: '15px', boxShadow: '1px 1px 14px 3px #d8cece', fontSize: '14px'}} >
              <div className="col-md-12">
                <div className="card">
                <div className="card-body1" style={{paddingBottom: '0px', marginBottom: '0px'}}> 
                                                               
                Batch ID : <h5>{modalparcel.payment_batch_num}</h5><br/>
        
                {
                          (() => {
                                  if(batchInfo.archiveStatus === 'complete' && batchInfo.payStatus === 'paid') {
                                      return (
                                          <>
                                          Invoice Status :<h5 style={{color: 'green'}}>Payment Completed and Archived</h5>
                                          </>
                                      )
                                  }else if (batchInfo.archiveStatus === 'pending' && batchInfo.payStatus === 'paid') {
                                      return (
                                        <>  
                                        Invoice Status :<h5 style={{color: 'green'}}>Payment Completed</h5>
                                        </>
                                      )
                                  }else if (batchInfo.archiveStatus === 'pending' && batchInfo.payStatus !== 'paid'){
                                      return (
                                        <>Invoice Status :<h5 style={{color: 'red'}}>Pending Payment</h5></>
                                      )
                                  }else{
                                      return (
                                        <h5 style={{color: 'red'}}>Payment not yet created</h5>
                                      )
                                  }
                          })()  
                }                               
                </div>
                </div>
              </div>
            </div>
            
            <div className="row mt-3" style={{marginTop: '-21px', marginBottom: '18px'}}>
            <h5 style={{margin:'auto'}}>Remark</h5>
            </div>
            
           
              <div className="row" style={{fontWeight: '500', border: '1px solid #ece5e5', borderRadius: '15px', boxShadow: '1px 1px 14px 3px #d8cece', fontSize: '14px'}}>
                <div className="col-md-12">
                <div className="card" style={{marginBottom:'0px'}}>
                   <div className="card-body1" style={{marginBottom:'0px', padding:'1.25rem'}}>  
                     <div className="form-group" style={{marginBottom:'0px', paddingBottom:'0px'}}>
                          <input type="hidden" id="parcel_id" name="parcel_id" value="13475611"/>
                           
                          <textarea className="form-control" id="remark" name="remark" rows="7" cols="30" value={clientRemark} onChange={clientRemarkHandler}></textarea>
                           <p style={{display: isRemarkActive ? 'block' : 'none', marginBottom: '0px', color: 'red', textAlign: 'left'}} >{rformErrors.clientRemark}</p>
                          <div className="form-group mt-2" style={{marginBottom:'0px', paddingBottom:'0px'}}>
                            <button className="btn btn-primary text-center" disabled={isRemarkAnonymous ? true : false} type="button" onClick={submitRemarkHandler} id="btnRemarkSave"  style={{color:'white'}}>&nbsp;Save&nbsp;</button>
                          </div> 
                     </div> 
                    </div> 
                   </div> 
                </div>
               </div>
          
  
</div>
</div>
      </div>  
    ) : (
      <div className="card bg-danger" style={{marginTop: '36px'}}>
      <h5 className="text-center text-light" style={{padding: '30px'}}>No Results</h5>
      </div>
    ) } 
    </>
  )
}
