import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";

function ModalEdit({EWaybill, modalEditIsClose, cityNames, zoneNames, pageIsChoose, dataIsChoose}) {
  // {id: EWaybill.pType, pName: id: EWaybill.pType'1kg', pSize: id: EWaybill.pType'1kg'}       
      const [parcelTypeB, setparcelTypeB]               = useState([{id: EWaybill.pType}]);
      const [hiddenParcelTypeB, sethiddenParcelTypeB]   = useState(EWaybill.pType);
      const [parceldesB, setparceldesB]                 = useState(EWaybill.pDescription);
      const [payMethodB, setpayMethodB]                 = useState(EWaybill.paymentType);
      const [CODamountB, setCODamountB]                 = useState(EWaybill.CODAmount);
      const [pickupFromB, setpickupFromB]               = useState(EWaybill.pickupFrom);
      const [pickupContactB, setpickupContactB]         = useState(EWaybill.pickupMobile);
      const [pickupAddressB, setpickupAddressB]         = useState(EWaybill.pickupAddress);
      const [clientNoteB, setclientNoteB]               = useState(EWaybill.clientNote);
      const [fnameB, setfnameB]                         = useState(EWaybill.recipientName);
      const [contactnoB, setcontactnoB]                 = useState(EWaybill.recipientMobile);
      const [addressB, setaddressB]                     = useState(EWaybill.recipientAddress);
      const [cityB, setcityB]                           = useState({...cityNames.find(element => element.cid == EWaybill.zoneCity)}.cname);
      const [pickupCityB, setpickupCityB]               = useState(EWaybill.pickupCity);
      const [recipientLocation, setRecipientLocation]   = useState(EWaybill.recipientLocation);
      const [pickupLocation, setpickupLocation]         = useState(EWaybill.pickupLocation);
      
      const [isAnonymous, setisAnonymous]     = useState(false);
      
      const [isPTypeActive, setIsPTypeActive]                   = useState(false);
      const [isPdesActive, setIsPdesActive]                     = useState(false);
      const [isRnameActive, setIsRnameActive]                   = useState(false);
      const [isRcontactActive, setIsRcontactActive]             = useState(false);
      const [isRaddressactActive, setIsRaddressactActive]       = useState(false);
      const [isRcityactActive, setIsRcityactActive]             = useState(false);
      const [ispayMethodActive, setIspayMethodActive]           = useState(false);
      const [iscodActive, setIscodActive]                       = useState(false);
      const [ispickupFromActive, setIspickupFromActive]         = useState(false);
      const [isspickupContactActive, setIspickupContactActive]  = useState(false);
      const [ispickupAddressActive, setIspickupAddressActive]   = useState(false);
 
      const [editformErrors, setEditformErrors]                 = useState({});
    
      const ClientD = sessionStorage.getItem('UserID'); 
      
      async function getData() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/waiting_parcel`, {
            method: 'POST', 
            body: JSON.stringify({
                clientID: ClientD, 
                waybill_id: '',                  
                search: '',
                paymentType: '',
                parcelType: '',
                order_id: '',
                fdate: '',
                tdate: '',
                //status: val.status                                
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             console.log(data.Clstatus);
             dataIsChoose(data.Clstatus);
             pageIsChoose(1);
            
            })
        } catch (error) {
        console.log(error.message)
        }
    }
      
    //   useEffect(() => {
    //     async function getWDetailsRequest() {
    //         try {
    //             await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelDt`, {
    //             method: 'POST', 
    //             body: JSON.stringify({             
    //                 waybillId: EWaybill,               
    //               }),         
    //             headers: {
    //               'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //           } )
    //             .then((respose) => {
    //               if (respose.ok) {
    //                 return respose.json()
    //               }
    //               throw new Error('error')
    //             })
    //             .then((data) => {
    //                 setWaybillDetails(data.Clstatus);
                 
    //             })
    //         } catch (error) {
    //           console.log(error.message)
    //         }
    //       }
    //       getWDetailsRequest();       
    //   }, [])   
      
      async function parcelTypeBRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelType`, {
            method: 'POST',          
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
              
              setparcelTypeB(data.Clstatus);

            })
        } catch (error) {
          console.log(error.message)
        }
      }
        
     useEffect(() => {
            parcelTypeBRequest()            
     }, []);
    
     const handleTypeChange = (e) => {
        
        e.preventDefault();
        sethiddenParcelTypeB(e.target.value);          
        setIsPTypeActive(false)         
    };
    
    const parceldesHandlerB = (event) => {
        setparceldesB(event.target.value);
        setIsPdesActive(false) 
    }
    
    const handleChangeB = (event) => {
        setpayMethodB(event.target.value);
        setIspayMethodActive(false) 
    }
    
    const CODamountHandlerB = (event) => {
        setCODamountB(event.target.value);
        setIscodActive(false) 
    }
    
    const pickupFromHandlerB = (event) => {
        setpickupFromB(event.target.value);
        setIspickupFromActive(false) 
    }
    
    const pickupContactHandlerB = (event) => {
        setpickupContactB(event.target.value);
        setIspickupContactActive(false) 
    }
    
    const pickupAddressHandlerB = (event) => {
        setpickupAddressB(event.target.value);
        setIspickupAddressActive(false) 
    }
    
    const clientNoteHandlerB = (event) => {
        setclientNoteB(event.target.value);
    }
    
    const fnameHandlerB = (event) => {
        setfnameB(event.target.value);
        setIsRnameActive(false) 
    }
    
    const contactnoHandlerB = (event) => {
        setcontactnoB(event.target.value);
        setIsRcontactActive(false) 
    }
    
    const addressHandlerB = (event) => {
        setaddressB(event.target.value);
        setIsRaddressactActive(false)
    }
    
    const cityHandlerB = (event) => {
        setcityB(event.target.value);
        setIsRcityactActive(false)
    }

    const pickupCityHandlerB = (event) => {
      setpickupCityB(event.target.value);
    }

    const recipientLocationHandlerB = (event) => {
      setRecipientLocation(event.target.value);
    }

    const pickupLocationHandlerB = (event) => {
      setpickupLocation(event.target.value);
    }


    
    const validate = (hiddenParcelTypeB,parceldesB,payMethodB,CODamountB,pickupFromB,pickupContactB,pickupAddressB,fnameB,contactnoB,addressB,cityB) => {
      const errors = {};

      if(!parceldesB){
        errors.parceldesB = "This field is required!";  
        setIsPdesActive(true)    
      }else{
        setIsPdesActive(false)
      }

      if(!hiddenParcelTypeB){
        errors.hiddenParcelTypeB = "This field is required!";      
        setIsPTypeActive(true)
      }else{
        setIsPTypeActive(false)
      }

      if(!fnameB){
        errors.fnameB = "This field is required!";
        setIsRnameActive(true)
      }else{
        setIsRnameActive(false)
      }

      if(!contactnoB){
        errors.contactnoB = "This field is required!";      
        setIsRcontactActive(true)
      }else{
        setIsRcontactActive(false)
      }

      if(!addressB){
        errors.addressB = "This field is required!";
        setIsRaddressactActive(true)
      }else{
        setIsRaddressactActive(false)
      }

      if(!cityB){
        errors.cityB = "This field is required!";      
        setIsRcityactActive(true)
      }else{
        setIsRcityactActive(false)
      }

      if(!payMethodB){
        errors.payMethodB = "This field is required!";      
        setIspayMethodActive(true)
      }else{
        setIspayMethodActive(false)
      }

      if(payMethodB === "COD"){
        if(!CODamountB){
          errors.CODamountB = "This field is required!";
          setIscodActive(true)
        }else{
          setIscodActive(false)
        }
      }else{
        setIscodActive(false)
      }
      
      if(!pickupFromB){
        errors.pickupFromB = "This field is required!";      
        setIspickupFromActive(true)
      }else{
        setIspickupFromActive(false)
      }
      
     if(!pickupContactB){
        errors.pickupContactB = "This field is required!";      
        setIspickupContactActive(true)
      }else{
        setIspickupContactActive(false)
      }
      
      if(!pickupAddressB){
        errors.pickupAddressB = "This field is required!";      
        setIspickupAddressActive(true)
      }else{
        setIspickupAddressActive(false)
      }

      return errors;
    }
    
    const submitEditParcelHandler = (event) => {
      event.preventDefault()
    //   setIsSubmit(true); 

      var logUser = sessionStorage.getItem("UserID");
            
      setEditformErrors(validate(hiddenParcelTypeB,parceldesB,payMethodB,CODamountB,pickupFromB,pickupContactB,pickupAddressB,fnameB,contactnoB,addressB,cityB));        


      var errorLength =  (Object.keys(validate(hiddenParcelTypeB,parceldesB,payMethodB,CODamountB,pickupFromB,pickupContactB,pickupAddressB,fnameB,contactnoB,addressB,cityB)).length)
                    
         if(errorLength === 0 ){
          setisAnonymous(true);

          async function parcelEditMRequest() {
            try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/EditMParcel`, {
                method: 'POST',
                body: JSON.stringify({
                  hiddenParcelType: hiddenParcelTypeB,
                  parceldes: parceldesB,
                  payMethod: payMethodB,
                  CODamount: CODamountB,
                  pickupFromB: pickupFromB,
                  pickupContactB: pickupContactB,
                  pickupAddressB: pickupAddressB,
                  clientNoteB: clientNoteB,
                  RecName: fnameB,
                  RecContact: contactnoB,
                  RecAddress: addressB,
                  city: cityB,                  
                  logUser:logUser,
                  pickupCityB: pickupCityB,
                  waybillId: EWaybill.id,
                  recipientLocation:recipientLocation,
                  pickupLocation: pickupLocation,
                }),
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                
                  const errStatus =  data.Clstatus;
                
                  if(errStatus === "Edit_successfully"){
                    setisAnonymous(false);
                   
                    Swal.fire({
                      title: "Good job!",
                      text: "Parcel successfully Edited!",
                      icon: "success",
                      confirmButtonText: "OK",
                      timer: 1500,
                    });
                    
                    modalEditIsClose(false);
                    getData();
                    
                  }else if(errStatus === "Invalid_City"){
  
                    setisAnonymous(false);
                   
  
                    Swal.fire({
                      title: "Warning",
                      text: "Invalid City",
                      icon: "error",
                      confirmButtonText: "OK",
                      
                    });
                    
                  }else if(errStatus === "Invalid_Waybill"){
  
                    setisAnonymous(false);
                   
  
                    Swal.fire({
                      title: "Warning",
                      text: "Invalid Waybill",
                      icon: "error",
                      confirmButtonText: "OK",
                      
                    });
                    
                  }else{
  
                    setisAnonymous(false);
                    
                    Swal.fire({
                      title: "Warning",
                      text: "Can't Update",
                      icon: "error",
                      confirmButtonText: "OK",
                     
                    });
  
                  }
                                 
                })
            } catch (error) {
              console.log(error.message)
            }
          }
              
          parcelEditMRequest();
          
        }
      }

    return (
        <div className="modal" id="modalBTNLoad" style={{display: 'block',overflow: 'auto'}}>
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content"  id="modalDivLoad"> 
                    <form id="ParcelEditForm" name="ParcelEditForm" onSubmit={submitEditParcelHandler} method="post">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel" style={{marginLeft: 'auto'}}> 
                        Parcel&nbsp;
                        {
                            (() => {
                                if(EWaybill.deliveryStatus === 'Waiting') {
                                        return (
                                                <>
                                                    {EWaybill.deliveryStatus}
                                                </>
                                                )
                                }else if(EWaybill.deliveryStatus === 'Pickup'){
                                        return (
                                                <>
                                                    {EWaybill.deliveryStatus}
                                                </>
                                                )
                                }else if(EWaybill.deliveryStatus === 'Pending'){
                                    return (
                                            <>
                                                Processing
                                            </>
                                            )
                                }else if(EWaybill.deliveryStatus === 'Complete'){
                                    return (
                                            <>
                                                Delivered
                                            </>
                                            )
                                }else if(EWaybill.deliveryStatus === 'Reschedule'){
                                    return (
                                            <>
                                                {EWaybill.deliveryStatus}
                                            </>
                                            )
                                }else if(EWaybill.deliveryStatus === 'Cancel'){
                                    return (
                                            <>
                                                Return
                                            </>
                                            )
                                }else if(EWaybill.deliveryStatus === 'Dispatched'){
                                    return (
                                            <>
                                                {EWaybill.deliveryStatus}
                                            </>
                                            )
                                }else if(EWaybill.deliveryStatus === 'Delete'){
                                    return (
                                            <>
                                                Remove
                                            </>
                                            )
                                }
                            })()  
                        } 
                        &nbsp; | Parcel ID:&nbsp;{EWaybill.id}
                                                                                       
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> modalEditIsClose(false)}>
                            <span aria-hidden="true" style={{color: '#ffffff00'}}>&times;</span>
                        </button>
                    </div>

                    <div className="modal-body"> 
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-12 col-md-12" style={{padding: '0'}}>                                  
                                        <div className="row mb-4">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <input type="hidden" id="hiddenParcelTypeB" name="hiddenParcelTypeB" value={hiddenParcelTypeB}  />
                                                <div className="form-group">
                                                    <label htmlFor="formname">Parcel Type :</label>
                                                    <select class="form-control" name="parcelTypeB" id="parcelTypeB" value={hiddenParcelTypeB} onChange={handleTypeChange} >
                                                        <option value="">Select Parcel Type</option>
                                                        {
                                                            parcelTypeB.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.pName}-{option.pSize}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                    <p style={{display: isPTypeActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.hiddenParcelTypeB}</p>
                                                </div>
                                            </div>
            
                                            <div className="col-lg-4">                         
                                                <div className="form-group">
                                                    <label htmlFor="formname">Parcel Description :</label>
                                                    <input type="text" name="parceldescB" className="form-control" id="parceldescB" value={parceldesB} onChange={parceldesHandlerB}/>
                                                    <p style={{display: isPdesActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{editformErrors.parceldesB}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label htmlFor="formname">Payement Type :</label>
                                                    <select className="form-control" name="paymenthodB" id="paymenthodB" value={payMethodB} onChange={handleChangeB}>
                                                        <option value="">Select Payment Type</option>
                                                        <option value="COD">COD</option>
                                                        <option value="AlreadyPaid">Already Paid</option> 
                                                    </select>
                                                    <p style={{display: ispayMethodActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.payMethodB}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-4" id="showcodamount">
                                            {payMethodB === "COD" && 
                                                  
                                                    <div className="form-group" id="showAmountCOD" >
                                                        <label htmlFor="formname">COD Amount :</label>
                                                        <input type="number" id="codAmountB" name="codAmountB" value={CODamountB} onChange={CODamountHandlerB} className="form-control" />
                                                        <p style={{display: iscodActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.CODamountB}</p>
                                                    </div>
                                                  
                                            }
                                               
                                            </div> 
                                        </div>
                                </div>
                            </div>
                            
                            <div className="row">
                               <input type="hidden" id="cityID" name="cityID" value=""/>
                                    <div className="col-lg-6">
                                        <h4>Pickup Details</h4><hr/>  
                                        <div className="form-group">
                                            <label htmlFor="pickupFrom">Pickup From :</label>
                                            <input type="text" name="pickupFrom" className="form-control" id="pickupFrom" value={pickupFromB} onChange={pickupFromHandlerB} />
                                            <p style={{display: ispickupFromActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.pickupFromB}</p>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="pickupContact">Pickup Contact No :</label>
                                            <input type="text" name="pickupContact" className="form-control" id="pickupContact" value={pickupContactB} onChange={pickupContactHandlerB} />
                                            <p style={{display: isspickupContactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.pickupContactB}</p>
                                        </div> 
                                        <div className="form-group">
                                            <label htmlFor="formname">Pickup Address :</label>
                                            <input type="text" name="pickupAddress" className="form-control" id="pickupAddress" value={pickupAddressB} onChange={pickupAddressHandlerB} />
                                            <p style={{display: ispickupAddressActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.pickupAddressB}</p>
                                        </div>
                                      
                                        <div className="form-group">
                                            <label htmlFor="formname">Pickup City :</label>
                                            <select id="pickupCity" name="pickupCity"  className="form-control" value={pickupCityB} style={{width:'100%'}} onChange={pickupCityHandlerB} >
                                            {
                                                zoneNames.map((itemN, index) => {
                                                    return(
                                                        <option key={index} value={itemN.cid}>{`${itemN.cname}`}</option>
                                                    )
                                                })
                                            }
                                            </select>
                                        </div> 
                                    
                                        <div className="form-group">
                                          <label htmlFor="pickupLocation">Pickup Location :</label>
                                          <input type="text" name="pickupLocation" className="form-control" id="pickupLocation" value={pickupLocation} onChange={pickupLocationHandlerB}/>
                                        </div>
                                        
                                        <hr/>   
                                        <div className="form-group">
                                            <label htmlFor="formname">Client Note :</label>
                                            <textarea className="form-control"  name="clientNote" id="clientNote" cols="30" rows="2" value={clientNoteB} onChange={clientNoteHandlerB}></textarea> 
                                        </div> 
                                    
                                    </div>

                                    <div className="col-lg-6">
                                        <h4>Recipient Details</h4><hr/> 
                                        <div className="form-group">
                                          <label htmlFor="formname">Recipient First Name :</label>
                                          <input type="text" name="fname" className="form-control" id="fname" value={fnameB} onChange={fnameHandlerB}  />
                                          <p style={{display: isRnameActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.fnameB}</p>
                                        </div>
                                    
                                        <div className="form-group">
                                          <label htmlFor="formname">Recipient Contact No :</label>
                                          <input type="text" name="contactno" className="form-control" id="contactno" value={contactnoB} onChange={contactnoHandlerB} />
                                          <p style={{display: isRcontactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.contactnoB}</p>
                                        </div>

                                        <div className="form-group">
                                          <label htmlFor="formemail">Recipient Address :</label>
                                          <input type="text" name="address" className="form-control" id="address" value={addressB} onChange={addressHandlerB} />
                                          <p style={{display: isRaddressactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.addressB}</p>
                                        </div>
                                   
                                        <div className="form-group" id="cityResDiv">
                                          <label htmlFor="recipientLocation">Recipient City :</label>
                                          <input type="text" name="city" className="form-control" id="city" value={cityB} onChange={cityHandlerB} />
                                          <p style={{display: isRcityactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{editformErrors.cityB}</p>
                                        </div> 

                                        <div className="form-group">
                                          <label htmlFor="recipientLocation">Recipient Location :</label>
                                          <input type="text" name="recipientLocation" className="form-control" id="recipientLocation" value={recipientLocation} onChange={recipientLocationHandlerB}/>
                                        </div>
                                        
                                    </div> 
                            </div>
                            
                            <div className="inner-repeater mb-4" style={{float: 'right'}}>
                              <button id="assignParcelBotton" type="submit" disabled={isAnonymous ? true : false} className="btn btn-success inner">Submit</button>
                            </div>
                        </div>     
                    </div>

                    
                </form>
                </div>
            </div>
        </div>  
    )
}

export default ModalEdit;


