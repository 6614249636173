import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Pagination from "./Pagination";

export default function WaybillGenerator() {
  document.title = "Logistic Client Portal | Waybill Generator";

  const [externalCl, setExternalCl] = useState('');
  const [settingN, setSettingN]     = useState('');
  const [inputdate, setInputdate]   = useState('');
//   const [searchkey, setSearchkey]   = useState('');
  const [wData, setWData]           = useState('');
  
  const [isWaybillLoadData, setIsWaybillLoadData]   = useState(true);

  const ClientIdN = sessionStorage.getItem('UserID'); 
   
  async function generateDetailsRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getWGenerate`, {
        method: 'POST', 
        body: JSON.stringify({             
            clientID: ClientIdN,
            SearchVal: '',
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
        //   console.log(data.Clstatus);
          setWData(data.Clstatus);
          setIsWaybillLoadData(false);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

useEffect(() => {
    generateDetailsRequest();
    
}, [])

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = wData.slice(firstIndex, lastIndex); 
  
  useEffect(() => {
    async function clientDetailsRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: ClientIdN,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            // console.log(data.Clstatus);
            setExternalCl(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
      clientDetailsRequest();
  }, []);
  
  async function settingRequestN() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
        method: 'POST',          
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {          
          setSettingN(data.Clstatus);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    settingRequestN()
  }, []);

  const handleDateChange = (e) => {
    setInputdate(e.target.value);
  };

  const searchHandler = (e) => {

        e.preventDefault();

        // var SearchVal = inputdate;
        // setSearchkey(SearchVal);
        
        async function generateDetailsRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getWGenerate`, {
            method: 'POST', 
            body: JSON.stringify({             
                clientID: ClientIdN,
                SearchVal: inputdate,
               }),         
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
            //   console.log(data.Clstatus);
              setWData(data.Clstatus);
              setCurrentPage(1);
            })
        } catch (error) {
          console.log(error.message)
        }
      }

      generateDetailsRequest();

  };

  return (
    <div>
        <div id="layout-wrapper">
            <TopBar/>
            <SideBar/>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid" style={{background: '#ebebf3',  margin: '10px'}}>
                        <div className="row mt-3">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="page-title-box">
                                    <h4 className="font-size-18" >Waybill Generator</h4>
                                </div>
                            </div>
                        </div>
                        {
                            (() => {
                                if(externalCl.hub_id === '') {
                                        return (
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body" style={{margin: 'auto', textAlign: 'center'}}>
                                                            <h3 style={{color: '#0078d4'}}>You can not generate waybill</h3>
                                                            <h4 style={{color: 'red'}}>Please Contact Your Admin</h4>
                                                            <p>Contact : {settingN.tel}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }else{
                                        return (
                                        <>
                                            <div className="row justify-content-md-center">
                                                <div className="col-lg-5 ">
                                                    <div className="card">
                                                        <div className="card-body" style={{margin: 'auto', textAlign: 'center'}}>
                                                            <h3 style={{color: '#0078d4'}}> You can not generate waybills</h3>
                                                            <h4 style={{color: 'red'}}>Please contact below numbers for generate waybills.</h4>
                                                            <h5>0760980161 / 0760980037 / 0760980045</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                    <h4 className="font-size-18">Waybill Generate History</h4>
                                                </div>
                                            </div> 

                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="card  mt-3">
                                                        <div className="card-body">                                                       
                                                            <div className="row">                                                             
                                                                <div className="form-group col-md-4"></div> 
                                                                <div className="form-group col-md-4"></div>                                                             
                                                            
                                                                <div className="form-group col-3 col-md-2 col-lg-2">  
                                                                    <label htmlFor="key_date">Generate Date</label>
                                                                    <input className="form-control" id="key_date" name="key_date" type="date" value={inputdate} onChange={handleDateChange} placeholder="From Date" aria-label="From Date" />
                                                                </div>
                                                                                                                                                                                                                                   
                                                                <div className="form-group col-3 col-md-2 col-lg-2">
                                                                    <label className="text-white">Search</label>
                                                                    <button className="btn btn-primary btn-block" id="searchBtn" onClick={searchHandler}> Search</button>
                                                                </div>
                                                            </div>
                                                        
                                                        
                                                            <div id="client_list_load"> 
                                                                <p><b className="" style={{}}>Count - {wData.length}</b></p>
                                                                    <div id="target-content">
                                                                        {
                                                                            (wData.length !== 0) ? (
                                                                                <div className="table-responsive">
                                                                                    <table  className="table">
                                                                                        <thead  className="thead-dark">
                                                                                        <tr>
                                                                                            <th>Id</th>
                                                                                            <th>Generate Date</th>   
                                                                                            <th className="text-center">Requested Count</th>   
                                                                                            <th className="text-center">Success</th>     
                                                                                            <th>Action</th> 
                                                                                        </tr>
                                                                                        </thead>
                                                                        
                                                                                        <tbody>
                                                                                        {
                                                                                          
                                                                                            records.map(item=>{
                                                                                            return(                                                                                              
                                                                                                <tr key={item.id}>    
                                                                                                    <td className="text-left">{item.id}</td>
                                                                                                    <td className="text-left">
                                                                                                    {
                                                                                                        (() => {
                                                                                                                if(item.croneStatus === 'pending') {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                        <i className="fas fa-question text-warning"></i>&nbsp;
                                                                                                                        </>
                                                                                                                        
                                                                                                                    )
                                                                                                                }else{
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                        <i className="fas fa-check text-primary"></i>&nbsp;
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }
                                                                                                        })()  
                                                                                                    }  
                                                                                                            
                                                                                                     {item.date}
                                                                                                    </td>
                                                                                                    <td className="text-center pointer"> 
                                                                                                        {item.down}
                                                                                                            
                                                                                                    </td>
                                                                                                    <td className="text-center pointer">

                                                                                                        {
                                                                                                            (() => {
                                                                                                                if(item.croneStatus === 'pending') {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                            <i className="fas fa-question text-warning"></i>&nbsp;
                                                                                                                            </>
                                                                                                                            
                                                                                                                        )
                                                                                                                    }else{
                                                                                                                        return (
                                                                                                                            <span className="badge badge-success"> {item.successcount}</span>
                                                                                                                        )
                                                                                                                    }
                                                                                                            })()  
                                                                                                        }     
                                                                                                            
                                                                                                    </td> 
                                                                                                    <td>

                                                                                                        {
                                                                                                            (() => {
                                                                                                                if(item.croneStatus === 'before') {
                                                                                                                        return (
                                                                                                                            // <a className="btn btn-primary btn-sm" href={`auth/model-re-add-waybill.php?id=${item.id}&from=${item.from}&to=${item.to}`}>Download</a>                                                                                                          
                                                                                                                           <></> 
                                                                                                                        )
                                                                                                                    }else if(item.croneStatus === 'done'){
                                                                                                                        return (
                                                                                                                            <a className="btn btn-primary btn-sm" href={`${process.env.REACT_APP_UPLOAD_URL}/${item.filename}`}>Download</a>
                                                                                                                        )
                                                                                                                    }else if(item.croneStatus === 'pending'){
                                                                                                                        return (
                                                                                                                            <i className="fas fa-question text-warning"></i>
                                                                                                                        )
                                                                                                                    }
                                                                                                            })()  
                                                                                                        }        
                                                                                                                
                                                                                                
                                                                                                        
                                                                                                    </td>
                                                                                                </tr>                                                                                            
                                                                                            )

                                                                                            })




                                                                                        }                                                                                   
                                                                            
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <nav>                                                                       
                                                                                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={wData} itemPerPage={recordsPerPage}/>
                                                                                    </nav>
                                                                                </div>
                                                                            ): (
                                                                            isWaybillLoadData ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                                                                                <div className="card bg-danger">
                                                                                    <div className="card-body">
                                                                                        <h5 className="text-center text-white"> No Records</h5> 
                                                                                    </div> 
                                                                                </div>
                                                                            )
                                                                        }
                                                                    

                                                                    </div>
                                                            {/* <WaybillList searchkey={searchkey} /> */}
                                                            {/* {openWList === true && (<WaybillList searchkey={searchkey}  closeModal={setOpenModal} />) }          */}
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </>
                                        )
                                    }
                            })()  
                        }  

                        

                    </div>
                </div>

                <footer className="footer">
                    <Footer/>
                </footer>
            </div>
        </div>
    </div>
  )
}
