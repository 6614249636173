import { useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Bike from '../images/bike.png';
import ModalBike from "./modalBike";
import ModalTuk from "./modalTuk";
import ModalLorry from "./modalLorry";


export default function PickupOption() {
  document.title = "Logistic Client Portal | Pickup Option";
  
  const cardStyle = {
  	textAlign: 'center',
    margin: 'auto',
    width: '200px',
                  
  };
  
  const [modalBikeIsOpen, setModalBikeIsOpen]       = useState(false);
  const [modalTukIsOpen, setModalTukIsOpen]       	= useState(false);
  const [modalLorryIsOpen, setModalLorryIsOpenn]    = useState(false);
 
    
  return (
     <>
    	<div id="layout-wrapper">
    	<TopBar/>
      <SideBar/>
      
	      <div className="main-content">
	      
	      	<div className="page-content">
	      		<div className="container-fluid">
	      			<div className="row"  style={{padding: '20px',  paddingLeft: '0px'}}>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">                              
                  <h4 className="font-size-18">Select Pickup Option</h4>                               
                </div>
              </div>
              
              <div className="row" style={{padding: '20px', border: '1px solid #dadad5'}}>
              	<div className="col-md-4" data-toggle="modal" data-target="#bikediv"  onClick={()=> { setModalBikeIsOpen(true); }}>
							    <div className="card" style={cardStyle} >
							      	<img style={{width: '200px', marginBottom: '24px'}}  src={Bike} alt=""/>
							        <h3 style={{margin: 'auto',textAlign: 'center', marginTop: '-45px'}}>Bike</h3>    
							    </div>
								</div>
								{modalBikeIsOpen === true && (<ModalBike modalBikeIsClose={setModalBikeIsOpen}  />) } 
								
								<div className="col-md-4" data-toggle="modal" data-target="#Threewheeldiv" onClick={()=> { setModalTukIsOpen(true); }}>
                  <div className="card" style={cardStyle}>
                    <img style={{width: '200px', marginBottom: '24px'}}  src="https://icat.in/storage/app/public/uploads/reckoners_form_1602250091.png" alt=""/>
                    <h3 style={{margin: 'auto',textAlign: 'center', marginTop: '-45px'}}>Tuk</h3>         
                  </div>
                </div>
                {modalTukIsOpen === true && (<ModalTuk modalTukIsClose={setModalTukIsOpen}  />) } 
                            
                <div className="col-md-4" data-toggle="modal" data-target="#Lorrydiv" onClick={()=> { setModalLorryIsOpenn(true); }}>
                  <div className="card" style={cardStyle}>
                    <img style={{width: '200px', marginBottom: '24px'}}  src="https://thumbs.dreamstime.com/b/orange-truck-loaded-gravel-part-roadworks-construction-site-series-vector-illustrations-flat-cartoon-drawings-89437114.jpg" alt=""/>
                    <h3 style={{margin: 'auto',textAlign: 'center', marginTop: '-45px'}}>Lorry</h3>     
                  </div>
                </div>
                {modalLorryIsOpen === true && (<ModalLorry modalLorryIsClose={setModalLorryIsOpenn}  />) }
                
	      			</div>
	      	
	      		</div>
	      	</div>
	      
	      	<footer className="footer">
            <Footer/>
          </footer>
          
	      </div>
    	</div>
    	
    	  <div className={(modalBikeIsOpen) ? "modal-backdrop show" : ""}></div>
    	  <div className={(modalTukIsOpen) ? "modal-backdrop show" : ""}></div>
    	  <div className={(modalLorryIsOpen) ? "modal-backdrop show" : ""}></div>
     </>
  )
}
