import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Pagination from "./Pagination";
// import {Link} from "react-router-dom";

export default function UnusedOrder({parcelTypeA,zoneNames}) {
  document.title = "Logistic Client Portal | Unused Order List";

  const [unusedWaybill, setUnusedWaybill]   = useState('');
  const [isunusedLoad, setIsunusedLoad]     = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 30;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = unusedWaybill.slice(firstIndex, lastIndex); 

  const [typeWaybill, setTypeWaybill]   = useState('');

  // const [cityNamen, setCityNamen]   = useState('');

  const ClientIdHistry = sessionStorage.getItem('UserID');

  var DefltKey = '';
  
  async function unusedWRequest(NDefltKey) {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getUnusedW`, {               
        method: 'POST', 
        body: JSON.stringify({             
            ClientId: ClientIdHistry,
            searchKey: NDefltKey,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
            setUnusedWaybill(data.Clstatus);
            setCurrentPage(1);
            setIsunusedLoad(false);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {   
    unusedWRequest(DefltKey);
    
  }, [])

  const handleWaybillChange = (e) => {
    setTypeWaybill(e.target.value);
  };

  const searchWaHandler = (e) => {

    e.preventDefault();

    unusedWRequest(typeWaybill);

};

// async function getCityNamen() {
//   try {
//       await fetch(`${process.env.REACT_APP_URL}/test.php/geCityName`, {
//       method: 'POST', 
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     } )
//       .then((respose) => {
//         if (respose.ok) {
//           return respose.json()
//         }
//         throw new Error('error')
//       })
//       .then((data) => {
//         console.log(data.Clstatus);
//         setCityNamen(data.Clstatus);
        
       
//       })
//   } catch (error) {
//     console.log(error.message)
//   }
// }

// useEffect(() => {   
//   getCityNamen();
// }, [])

  return (
    <>
    <div id="layout-wrapper">
      <TopBar/>
      <SideBar/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="page-title-box">
                    <h4 className="font-size-18">Unused Order List</h4>
                    </div>
                </div>
 
                <div className="col-8 col-sm-8 col-md-5 col-lg-5" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    <div className="form-group" style={{marginBottom: '0'}}> 
                        <input type="text" id="sch_key" name="sch_key" className="form-control" placeholder="Waybill ID" value={typeWaybill} onChange={handleWaybillChange} />
                    </div>
                </div>
                    
                 <div className="col-4 col-sm-4 col-md-1 col-lg-1" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    <div className=""> 
                        <button className="btn btn-primary" id="searchBtn" style={{float: 'right'}} onClick={searchWaHandler}> Search</button>
                    </div>
                  </div>               
              </div>
                                  
              <div className="row">
                <div className="col-xl-12">
                  <div className="card  mt-3">
                    <div className="card-body">
                      
                      {/* <div className="row">                      
                          <div className="form-group col-md-4"></div> 
                          <div className="form-group col-md-4"></div> 
                                            
                          <div className="form-group col-3 col-md-2 col-lg-2">  
                            <label htmlFor="key_date">Upload Date</label>
                            <input className="form-control" id="key_date" name="key_date" type="date" value={uploadDate} onChange={handleUDateChange} placeholder="From Date" aria-label="From Date" />
                          </div>
                                                                                
                          <div className="form-group col-3 col-md-2 col-lg-2">
                              <label className="text-white">Search</label>
                              <button className="btn btn-primary btn-block" id="searchBtn" onClick={searchUHandler}> Search</button>
                          </div>
                      </div>
                                         */}
                      <div id=""> 
                        {
                          (unusedWaybill.length !== 0) ? (
                          <>
                            <p><b>Count - {unusedWaybill.length}</b></p>

                            <div id="target-content">
                              <div className="table-responsive">
                                <table  className="table">
                                  <thead  className="thead-dark">
                                    <tr>
                                        <th style={{width: '125px'}}>Order Info</th>
                                        <th style={{width: '150px'}}>Parcel Info</th>
                                        <th style={{width: '150px'}}>Recipient Info</th>
                                        <th style={{width: '150px'}}>Pickup Info</th>
                                        <th style={{width: '150px'}}>Pay Method</th>
                                        <th style={{width: '150px'}}>Client Note</th>
                                        <th style={{width: '155px'}}>Admin Note</th>                                      
                                        </tr>
                                  </thead> 
                                                                         
                                  <tbody>
                                    {                                                                                         
                                    records.map(item=>{
                                    return(                                                                                              
                                        <tr key={item.id}>    
                                        <td className="text-left">{item.id}<br/>{item.orderDate}</td>
                                        <td className="text-left">
                                       
                                        {{...parcelTypeA.find(element => element.id == item.pType)}.pName}<br/>{item.pDescription}</td>
                                        <td>
                                            {item.recipientName}
                                            <br/>{item.recipientMobile}
                                            <br/>{item.recipientAddress}
                                            <br/>{{...zoneNames.find(element => element.cid == item.recipientCity)}.cname}
                                        </td>
                                        <td>
                                            {item.pickupFrom}
                                            <br/>{item.pickupAddress}
                                            <br/>{{...zoneNames.find(element => element.cid == item.pickupCity)}.cname}
                                        </td>
                                        <td>{item.paymentType}</td>
                                        <td>
                                            {
                                                (() => {
                                                    if(item.clientNote){
                                                        return (
                                                            <>{item.clientNote}</>                                                                                                                
                                                        )
                                                                                                           
                                                    }else{
                                                        return (
                                                            <> - </>
                                                        )  
                                                    }
                                                })()  
                                            }  
                                        </td>  
                                        <td>
                                            {
                                                (() => {
                                                    if(item.adminNote){
                                                        return (
                                                            <>{item.adminNote}</>                                                                                                                
                                                        )
                                                                                                           
                                                    }else{
                                                        return (
                                                            <> - </>
                                                        )  
                                                    }
                                                })()  
                                            }  
                                        </td>                                          
                                        </tr>                                                                                              
                                    )
                                    })
                                    }                                                                                                                                                              
                                  </tbody>                                                                                                  
                                </table>
          
                                <nav>                                                                       
                                  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={unusedWaybill} itemPerPage={recordsPerPage}/>
                                </nav>
                              </div>
                            </div>
                           
                          </>
                          ): (
                          isunusedLoad ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                              <div className="card bg-danger">
                                  <div className="card-body">
                                      <h5 className="text-center text-white"> No Parcel</h5> 
                                  </div> 
                              </div>
                          )
                        } 
                      </div>
                      
                    </div>
                  </div>
                </div> 
              </div>

            </div>
          </div>
          <footer className="footer">
            <Footer/>
          </footer>
        </div>
    </div>
    </>
  )
}
