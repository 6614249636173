import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Dropdown() {

    const sub_menu_icon = <i className='ti-arrow-right'></i>;
//   const [click, setClick] = useState(false);

//   const handleClick = () => setClick(!click);

//   return (
//     <>
//       <ul
//         onClick={handleClick}
//         className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
//       >
//         {MenuItems.map((item, index) => {
//           return (
//             <li key={index}>
//               <Link
//                 className={item.cName}
//                 to={item.path}
//                 onClick={() => setClick(false)}
//               >
//                 {item.title}
//               </Link>
//             </li>
//           );
//         })}
//       </ul>
//     </>
//   );
// }

const loggedUser = sessionStorage.getItem('UserID');
const [webhook, setwebhook] = useState([]);

  //  ${loggedUser}
  async function getWebhook() {
        // axios.get(`${process.env.REACT_APP_URL}/index.php/getWebHook`)
            await fetch(`${process.env.REACT_APP_URL}/index.php/getWebHook`, {
            method: 'POST',
            body: JSON.stringify({
                loggedUser: loggedUser,
            }),
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
        // .then(function(data) {
        //     console.log(data);
        //     setParcels(data);
        // });
        .then((respose) => {
          
          if (respose.ok) {
            return respose.json()
          }
                    throw new Error('error')
        })
        .then((data) => {
                    console.log(data.Clstatus.webhook_api_status);
                    setwebhook(data.Clstatus.webhook_api_status)

        })

        
    }

    useEffect(() => {
        getWebhook();
  }, []);


return(
<>
<ul className="sub-menu" aria-expanded="false">                                                                                                                     
                                            <li>
                                                <Link to="/client_api">{sub_menu_icon} Pickup Request</Link>
                                            </li>
                                          
                                            {webhook === "enable" &&
                                            <li>
                                            <Link to="/client_two_way_api">{sub_menu_icon} Reverse API</Link>
                                            </li>
                                            }
                                            
                                            
                                            
                                        </ul>
                                        </>
)
};
export default Dropdown