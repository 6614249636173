import React from 'react'
import { useState, useEffect } from 'react' // { useEffect } 
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import clientsiteLogo from "../images/logo-light.png";
import profileLogo from "../images/1617605084.jpg";
// import '../App.css';
// import  ReactDOM  from 'react-dom/client';
import Modal from '../view/modal';

export default function TopBar() {

  const navigate = useNavigate();

  const [input, setInput] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [waybillId, setwaybillId] = useState('')
  const [openlogout, setLogout] = useState(false)
  const [LUsername, setLUsername] = useState('')
  const [topbarSetting, setTopbarSetting] = useState('');

  var logUser = sessionStorage.getItem("UserID");

   async function lgUserTRequest() {
    try {
      await fetch(`${process.env.REACT_APP_URL}/index.php/user`, {
        method: 'POST',
        body: JSON.stringify({        
          logUser:logUser,
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
        //   console.log(data.Clstatus);               
          setLUsername(data.Clstatus)                       
        })
    } catch (error) {
      console.log(error.message)
    }
  }
  
  useEffect(() => {
    lgUserTRequest()   
}, []);


  async function settingTopRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
        method: 'POST',          
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          // console.log(data.Clstatus);
          setTopbarSetting(data.Clstatus);
         
        })
    } catch (error) {
      console.log(error.message)
    }
  }
   
  useEffect(() => {
    settingTopRequest()
  }, []);


  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const checkKeyPress = (e) => {
    const { keyCode } = e;
    // console.log(key, keyCode);
    if (keyCode === 13) {
    //   console.log(input);

      var ID = input;
      setOpenModal(true);
      setLogout(false);
      setwaybillId(ID);
{/* <Modal closeModal={setOpenModal} /> */}
    //   var modal = document.getElementById('testnewid01');
    //   modal.style.display = "block";

    //   const newroot = ReactDOM.createRoot(document.getElementById('NEWloadDiv'));        
    //   newroot.render(
    //     <BrowserRouter>
    //   <Modal />
    // </BrowserRouter>
    //   );

    //   var html = '<h1>Hello, world!</h1>'

    //   var MyComponent = createReactClass({
    //     render: function() {
    //       return React.createElement("h1", {dangerouslySetInnerHTML: {__html: html}})
    //     }
    //   })
      
    //   ReactDOM.render(React.createElement(MyComponent), document.getElementById('NEWloadDiv'))

    // ReactDOM.render(<p>Hello</p>, document.getElementById('NEWloadDiv'));
    //   module.exports=createReactClass({
    //     render:function(){
    //         return(
    //             <div>
    //                 <h1> the list  </h1>
    //             </div>   
    //         )
    //     }
    // });

        // $("#NEWloadDiv").html('<div class="col-md-12 text-center" style="padding: 128px 0px;"><i class="fas fa-circle-notch fa-spin" style="font-size:50px"></i></div>');
        // $("#NEwviewDiv").html('<div class="col-md-12 text-center" style="padding: 128px 0px;"><i class="fas fa-circle-notch fa-spin" style="font-size:50px"></i></div>');
           
                          
    //              $("#NEWloadDiv").load("Newall_search_load.php?id="+ID);
    //              $("#NEwviewDiv").load('Newall_search_parcel_load.php?orderid='+ID);  
                              
                              
                            //    $("#newwaybillsearch").val("");

      setInput("");
    }
  };

  const handleDropdown = (e) => {
    if(openlogout === false){
        setLogout(true);
    }else{
        setLogout(false);
    }
    
  };

  useEffect(() => {
    if(topbarSetting.maintenance_mode_client === '1'){
      if(LUsername.id !== '1000'){
        navigate("/site_maintenane_client");
        // window.location.replace('site_maintenane_client.php');
      }
    }
  }, [topbarSetting.maintenance_mode_client,LUsername.id]);

  
  
//   if((sessionStorage.getItem('UserID')) !== null){
    return (
    
        <header id="page-topbar">
              <div className="navbar-header">
                        <div className="d-flex">                      
                            <div className="navbar-brand-box">
                            {/*  */}
                            {/* <Link className="logo logo-dark"  to="/index">
                                    <span className="logo-sm">
                                        <img src={clientsiteLogo} alt="" height="75" style={{filter: 'brightness(0) invert(1)'}} />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={clientsiteLogo} alt="" height="75" style={{filter: 'brightness(0) invert(1)'}} />
                                    </span>
                            </Link> */}
    
                            {/* <a className="logo logo-light"  to="/index">
                                    <img src={clientsiteLogo} alt="img" style={{height: '50px'}}/> 
                            </a> */}
    
                            <Link to="/index" className="logo logo-light" style={{display: 'block'}}>
                                <img src={clientsiteLogo} style={{height: '50px'}}></img>
                            </Link> 
    
                            </div>
    
                            <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                                <i className="mdi mdi-menu" style={{color: '#ffffff'}}></i>
                            </button> 
                         <div className="px-3 my-4 text-light float-left"> Client Portal </div>   
                          
                          
                          
                          
                          <div className="position-relative">
    
                                    <input type="text" className="form-control" placeholder="Search..." name="newwaybillsearch" id="newwaybillsearch" value={input} onChange={handleInputChange} onKeyDown={checkKeyPress} style={{marginTop: '1.1rem',borderRadius: '2rem', marginLeft: '1.2rem'}} />                               
                          
                          </div>
                      
                            {/* <script>
                            $("#newwaybillsearch").keypress(function(event){
                            if (event.key === "Enter") {
                   
                            var ID = $("#newwaybillsearch").val();
                 var modal = document.getElementById('testnewid01');
    
                              // $('#exampleModal').modal('show');
                              modal.style.display = "block";
    
      $("#NEWloadDiv").html('<div class="col-md-12 text-center" style="padding: 128px 0px;"><i class="fas fa-circle-notch fa-spin" style="font-size:50px"></i></div>');
                 $("#NEwviewDiv").html('<div class="col-md-12 text-center" style="padding: 128px 0px;"><i class="fas fa-circle-notch fa-spin" style="font-size:50px"></i></div>');
           
                          
                 $("#NEWloadDiv").load("Newall_search_load.php?id="+ID);
                 $("#NEwviewDiv").load('Newall_search_parcel_load.php?orderid='+ID);  
                              
                              
                               $("#newwaybillsearch").val("");
                            }
                             });
                          </script> */}
                          
                          
                        </div>
                        
                      
                       
                      {topbarSetting.popup_alert_vendor !== '' &&
                        
                           <marquee direction = "leff"><h3 style={{color: 'red'}}><b>{topbarSetting.popup_alert_vendor}</b></h3></marquee>
                     
                      }
                      
                   
    
                        <div className="d-flex">
                              
                              <form className="app-search d-none d-lg-block">
    
                            </form>
                          
                           <div className="px-3 my-4 text-light">
                            {LUsername.companyName} ({LUsername.id})
    
                            </div>
    
                            <div className="dropdown d-inline-block d-lg-none ml-2">
                                <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="mdi mdi-magnify"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                                    aria-labelledby="page-header-search-dropdown">
                        
                                    <form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username"/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
    
                      <div className="dropdown d-none d-lg-inline-block">
                                <button type="button" className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                                    <i className="mdi mdi-fullscreen"></i>
                                </button>
                            </div>
    
                            <div className="dropdown d-inline-block">
                                <button type="button" onClick={handleDropdown} className="btn header-item waves-effect" id="page-header-user-dropdown"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img className="rounded-circle header-profile-user" src={profileLogo} />
                                </button>
                                {openlogout === true &&

                                <div className="dropdown-menu dropdown-menu-right" style={{ display: openlogout ? 'block' : 'none'}}>
                                <a className="dropdown-item d-block" href="#"></a>
                                 <div className="dropdown-divider"></div>
                                     <Link className="dropdown-item text-danger"  to="/logout">
                                         <i className="bx bx-power-off font-size-17 align-middle mr-1 text-danger"></i> Logout
                                     </Link> 
                                </div>

                                }
                                
                            </div>
                
                        </div>
                    </div>
                     {/* <Modal />  */}
                     
                    {/* {openModal === true && (<Modal open={openModal} onClose={() =>setOpenModal(false)} waybillId={"1002"}  />) }  */}
                    {openModal === true && (<Modal waybillId={waybillId}  closeModal={setOpenModal} />) }      
        </header>
    
       
      )
//   }else{
// <>
// </>
//   }

 
}