import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Pagination from "./Pagination";
import ModalReSheet from "./modalReSheet";

export default function ReturnHandoverConfirmSheet({parcelTypeA,zoneNames,cancelReason,adminInfo}) {
  document.title = "Logistic Client Portal | Return Handover Sheet";
  
  const [inputfdate, setInputfdate]   		= useState(''); 
  const [inputtdate, setInputtdate]   		= useState(''); 
  const [riderConfirm, setRiderConfirm]     = useState(''); 
  const [hubConfirm, setHubConfirm]   		= useState(''); 
  const [adminConfirm, setAdminConfirm]     = useState(''); 

  const [modalIsOpen, setModalIsOpen]       = useState(false);
  const [modalData, setModalData]           = useState('');

  const [hData, setHData]                   = useState([]);
  const [allParcelD, setAllParcelD]       = useState([]); 
  const [spinner, setSpinner] = useState(true);

  const ClientIdN = sessionStorage.getItem('UserID');  
   
  async function sheetDetailsRequest(LgClient,Fdate,Tdate,RiderC,HubC,AdminC){
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getReSheet`, {
        method: 'POST', 
        body: JSON.stringify({             
            clientID: LgClient,
            inputfdate: Fdate,
            inputtdate: Tdate,
            riderConfirm: RiderC,
            hubConfirm: HubC,
            adminConfirm: AdminC,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setHData(data.Clstatus);
          setSpinner(false);
          setCurrentPage(1);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

	var inputfdateK = '';
	var inputtdateK = '';
	var riderConfirmK = '';
	var hubConfirmK = '';
	var adminConfirmK = '';
	
	useEffect(() => {
	    sheetDetailsRequest(ClientIdN,inputfdateK,inputtdateK,riderConfirmK,hubConfirmK,adminConfirmK);
	    
	}, [])

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = hData.slice(firstIndex, lastIndex); 

  const handleFDateChange = (e) => {
    setInputfdate(e.target.value);
  };
  
   const handleTDateChange = (e) => {
    setInputtdate(e.target.value);
  };
  
   const handleRiderConfirm = (e) => {
    setRiderConfirm(e.target.value);
  };
  
   const handleHubConfirm = (e) => {
    setHubConfirm(e.target.value);
  };
  
   const handleAdminConfirm = (e) => {
    setAdminConfirm(e.target.value);
  };

  const searchHandler = (e) => {
        e.preventDefault();
        sheetDetailsRequest(ClientIdN,inputfdate,inputtdate,riderConfirm,hubConfirm,adminConfirm)
  };

  useEffect(() => {
    async function allParcelsRequestr() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getAllParcels`, {
          method: 'POST', 
          body: JSON.stringify({             
            clientID: ClientIdN,               
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setAllParcelD(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    allParcelsRequestr()
  }, []);
  
  return (
    <>
        <div id="layout-wrapper">
            <TopBar/>
            <SideBar/>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row"  style={{padding: '20px',  paddingLeft: '0px'}}>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">                              
                                    <h4 className="font-size-18" >Return Handover Sheet</h4>                               
                            </div>
                        </div>

                        <div className="row"> 
                            <div className="col-12 col-md-12 col-lg-12"> 
                                <div className="row">                            
                                
                                <div className="form-group col-3 col-md-3 col-lg-3">  
                                    <label htmlFor="fdate">Sheets Date From :</label>
                                    <input className="form-control" id="fdate" name="fdate" type="date" placeholder="From Date" value={inputfdate} onChange={handleFDateChange}/>
                                </div>
                                            
                                <div className="form-group col-3 col-md-3 col-lg-3"> 
                                    <label htmlFor="tdate">Sheets Date To :</label>
                                    <input className="form-control" id="tdate" name="tdate" type="date" placeholder="To Date" value={inputtdate} onChange={handleTDateChange} />
                                </div>
                                
                                <div className="form-group col-3 col-md-3 col-lg-3">
                                    <label htmlFor="riderConfirm">Rider Confirm :</label>
                                    <select className="form-control" name="riderConfirm" id="riderConfirm" value={riderConfirm} onChange={handleRiderConfirm} >
                                        <option value="">Select Rider Confirm</option>
                                        <option value="pending">Pending</option>
                                        <option value="complete">Complete</option> 
                                    </select>
                                </div>                                
                                
                                <div className="form-group col-3 col-md-3 col-lg-3">
                                    <label htmlFor="hubConfirm">Hub Confirm :</label>
                                    <select className="form-control" name="hubConfirm" id="hubConfirm" value={hubConfirm} onChange={handleHubConfirm}>
                                        <option value="">Select Hub Confirm</option>
                                        <option value="pending">Pending</option>
                                        <option value="complete">Complete</option> 
                                    </select>
                                </div>
                                
                                <div className="form-group col-3 col-md-3 col-lg-3">
                                    <label htmlFor="adminConfirm">Super Admin Confirm :</label>
                                    <select className="form-control" name="adminConfirm" id="adminConfirm" value={adminConfirm} onChange={handleAdminConfirm}>
                                        <option value="">Select Super Admin Confirm</option>
                                        <option value="pending">Pending</option>
                                        <option value="complete">Complete</option> 
                                    </select>
                                </div>
                                
                                </div> 
                            </div>                              
                        </div>

                        <div className="row">
                            <div className="col-10 col-md-10 col-lg-10"> </div>
                            <div className="col-2 col-md-2 col-lg-2">
                                <div className="form-group">  
                                    <button className="btn btn-primary btn-block" id="searchBtn" onClick={searchHandler}> Search</button>  
                                </div> 
                            </div>  
                        </div> 
                        
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card  mt-3">
                                    <div className="card-body">                                                                                                   
                                                        
                                        <div id="client_list_load"> 
                                            <p><b className="" style={{}}>Count - {hData.length}</b></p>
                                                <div id="target-content">
                                                {
                                                (hData.length !== 0) ? (
                                                    <div className="table-responsive">
                                                        <table  className="table">
                                                            <thead  className="thead-dark">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Sheets Date</th>
                                                                    <th>Waybill Count</th>
                                                                    <th>Rider Confirm</th>
                                                                    <th>Vendor Confirm</th>
                                                                    <th>Hub Confirm</th>
                                                                    <th>Super Admin Confirm</th>  
                                                                    <th>Comments</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                                        
                                                            <tbody>
                                                            {
                                                            records.map(item=>{
                                                            return(                                                                                              
                                                                <tr key={item.id}>    
                                                                    <td>{item.id}</td>
                                                                    <td>{item.scan_date}</td>
                                                                    <td>{item.waybill_count ? item.waybill_count : "0"}</td>
                                                                    <td>
                                                                        {
                                                                        (() => {
                                                                        if(item.confirm_status === 'pending') {
                                                                            return (
                                                                                <>
                                                                                    <i className="fas fa-question text-warning fa-1x ml-3"></i>
                                                                                </>
                                                                            )
                                                                        }else if(item.confirm_status === 'complete'){
                                                                            return (
                                                                                <>
                                                                                    <i className="fas fa-check text-info fa-1x ml-3"></i>
                                                                                </>
                                                                            )
                                                                        }
                                                                        })()  
                                                                        }  
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                        (() => {
                                                                        if(item.client_confirm_status === 'pending') {
                                                                            return (
                                                                                <>
                                                                                    <i className="fas fa-question text-warning fa-1x ml-3"></i>
                                                                                </>
                                                                            )
                                                                        }else if(item.client_confirm_status === 'complete'){
                                                                            return (
                                                                                <>
                                                                                    <i className="fas fa-check text-info fa-1x ml-3"></i>
                                                                                </>
                                                                            )
                                                                        }
                                                                        })()  
                                                                        }  
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                        (() => {
                                                                        if(item.hub_confirm_status === 'pending') {
                                                                            return (
                                                                                <>
                                                                                    <i className="fas fa-question text-warning fa-1x ml-3"></i>
                                                                                </>
                                                                            )
                                                                        }else if(item.hub_confirm_status === 'complete'){
                                                                            return (
                                                                                <>
                                                                                    <i className="fas fa-check text-info fa-1x ml-3"></i>
                                                                                </>
                                                                            )
                                                                        }
                                                                        })()  
                                                                        }  
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                        (() => {
                                                                        if(item.admin_confirm_status === 'pending') {
                                                                            return (
                                                                                <>
                                                                                    <i className="fas fa-question text-warning fa-1x ml-3"></i>
                                                                                </>
                                                                            )
                                                                        }else if(item.admin_confirm_status === 'complete'){
                                                                            return (
                                                                                <>
                                                                                    <i className="fas fa-check text-info fa-1x ml-3"></i>
                                                                                </>
                                                                            )
                                                                        }
                                                                        })()  
                                                                        }  
                                                                    </td>
                                                                    <td>{item.comments ? item.comments : " - "}</td>
                                                                    <td>
                                                                        <button type="button" className="btn btn-info btn-sm" onClick={()=> { setModalData(item); setModalIsOpen(true); }}> View </button> 
                                                                    </td>
                                                                </tr>                                                                                            
                                                            )
                                                            })
                                                            }                                                                                   
                                                                            
                                                            </tbody>
                                                        </table>
                                                        <nav>                                                                       
                                                            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={hData} itemPerPage={recordsPerPage}/>
                                                        </nav>
                                                    </div>
                                                ): (
                                                    spinner ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                                                    <div className="card bg-danger">
                                                        <div className="card-body">
                                                            <h5 className="text-center text-white"> No Records</h5> 
                                                        </div> 
                                                    </div>
                                                )
                                                }
                                                </div>
                                                            {modalIsOpen === true && (<ModalReSheet modalData={modalData} modalIsClose={setModalIsOpen} dataIsChoose={setHData} pageIsChoose={setCurrentPage} parcelTypeA={parcelTypeA} zoneNames={zoneNames} cancelReason={cancelReason} adminInfo={adminInfo} allParcelD={allParcelD} searchData={riderConfirm} searchfdate={inputfdate} searchtdate={inputtdate} searchhub={hubConfirm} searchadmin={adminConfirm}  />) }                                                                                                                      
                                                           
                                        </div>
                                                        
                                    </div>
                                </div>
                            </div> 
                        </div>
                                                                                                      
                    </div>
                </div>

                <footer className="footer">
                    <Footer/>
                </footer>
            </div>
        </div>
        <div className={(modalIsOpen) ? "modal-backdrop show" : ""}></div>
    </>
  )
}
