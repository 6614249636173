import React, { useRef, useEffect, useState }  from 'react';
import { useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import logo from '../images/logo-light.png';


function RemoveParcelPrintLabel() {
    
    const [isPrint, setIsPrint]                   = useState(false);
    const [isPrintingremove, setIsPrintingremove] = useState(false); 
    // let { id }         = useParams();
    // let  {loggedUser}  = useParams();

    let  {loggedUser}  = useParams();
    let  {waybill}  = useParams();

    console.log({waybill})

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      
    });

    const [parcel, setParcel] = useState([]);
    const [setting, setSetting] = useState('');

    document.title = "Logistic Client Portal | Print Parcel";

    const myStyle = {
      lineHeight: '0',
      marginBlockEnd: '8px',
      marginBlockStart: '8px',
      borderColor: 'rgb(154 154 154)',
  
      marginTop: '0.7rem ',
      marginBottom: '0.7rem'
      };
  
      const tableStyle = {
        borderCollapse: 'separate'
    }

    useEffect(() => {
        settingRequest()
      }, []);

    useEffect(() => {
        if(isPrint){
            if(isPrintingremove){
               handlePrint(); 
            }
        }

      }, [isPrint, isPrintingremove, handlePrint]);



    // const [Data, setData] = useState([{
    //     recipientName : "",
    //     recipientAddress: "",
    //     recipientContatNo: "",
    //     pickupFrom: "",
    //     pickupAddress: "",
    //     pickupMobile: "",
    //     orderID: "",
    //     pDescription: "",
        
    // }]);

    // axios.post('http://localhost/hs-login-system-master/print_label.php', {waybill_id:id})
    //         .then((res) => {
    //             //console.log(res.data)
    //             setData(res.data);
    //         })
    //         .catch(err => console.log(err));

    async function settingRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
            method: 'POST',          
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
              console.log(data.Clstatus);
              setSetting(data.Clstatus);
              setIsPrintingremove(true);
            })
        } catch (error) {
          console.log(error.message)
        }
      }
    
useEffect(() => {
    
    fetchData();
    sessionStorage.setItem("UserID", loggedUser); 
}, []);

async function fetchData() {
    // const res = await axios.post(`${process.env.REACT_APP_URL}/index.php/printLabel`, {waybill_id:id});
    // setData(res.data);

    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/printLabel`, {
        method: 'POST', 
        body: JSON.stringify({
            waybill_id: waybill,
                                       
        }),         
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        },
    } )
        .then((respose) => {
        if (respose.ok) {
            return respose.json()
        }
        throw new Error('error')
        })
        .then((data) => {
         
        setParcel(data.Clstatus);
        setIsPrint(true);
        
        })
    } catch (error) {
    console.log(error.message)
    }
}
    
    // useEffect(() => {
    //     fetchData();
        
    //     window.print();
    //     console.log('dddd')
    // }, [])

    // useEffect(() => {
    //     sessionStorage.setItem("UserID", loggedUser); 
    // }, []);
    //fetchData();

    console.log(parcel) 

    return(

        <div ref={componentRef}>
            { 
            (isPrint) ? (
              (isPrintingremove) ? (
            
    <div className="bodyclz" style={{ fontFamily: 'initial', color: '#000000', backgroundColor: 'white', height: '148.5mm', margin: '0'}}>
      
    <div style={{height: '145mm', width:'102mm', float:'left', border:'solid grey 1px', margin: '8px 0px 0px 8px'}}>

        <center>
            <table style={tableStyle}>
                <tbody>
                    <tr>
                        <td style={{fontSize: '12px'}}>
    
                            <img src={logo} alt="imgLogo" className="src" style={{width: '135px', marginRight: '80px', marginTop: '5px'}} />
                        </td>
                        <td style={{fontSize: '12px'}}>
                            <label style={{fontSize: '14px', fontWeight: '800', margin:'0'}}>Waybill ID : {waybill}</label>
                           {parcel[0] && (parcel[0].exchange === 1) &&
                           <p style={{margin: 'auto', textAlign: 'center', fontSize: '12px'}}>Exchange</p>
                           }                               
                        </td>
                    </tr>
                </tbody>
            </table>
        </center>
        <hr style={myStyle} />
        <p style={{paddingLeft: '25px', fontSize: '15px',lineHeight: '1.2'}}>
            <b style={{fontWeight: 'bold' }} >Recipient Details</b>
            <br/>
            {/* <br/> */}
            Name: {parcel[0] && (parcel[0].recipientName)}   
            <br/>
            Address: {parcel[0] && (parcel[0].recipientAddress)}    
            <br/>
            Contact No: {parcel[0] && (parcel[0].recipientMobile)} 
            <br/>
        </p>
        <hr style={myStyle} />
        <p style={{paddingLeft: '25px', fontSize: '15px', lineHeight: '1.2'}}>
            <b style={{fontWeight: 'bold'}} >From</b>
            <br/>
            {/* <br/> */}
            Name: {parcel[0] && (parcel[0].pickupFrom)}   
            <br/>
            Address: {parcel[0] && (parcel[0].pickupAddress)}   
            <br/>
            Contact No: {parcel[0] && (parcel[0].pickupMobile)} 
            <br/>
            {parcel[0] && (parcel[0].orderID !== '') &&
            <span> Order ID: {parcel[0] && (parcel[0].orderID)} / </span>
            }
            Parcel Desc: {parcel[0] && (parcel[0].pDescription)}
            {parcel[0] && (parcel[0].clientNote !== '') &&
            <span> {parcel[0] && (parcel[0].clientNote)} </span>
            }

        </p>
        <hr style={myStyle}/>

        <div>     
            {
                (() => {
                    if(setting.qr === 'on' && setting.barcode === 'on') {
                            return (
                              <center>
                              <table className="table" style={{tableStyle, paddingLeft: '30px', margin: '-10px'}}>
                              <tr>
                                <td > </td>
                                <td style={{fontSize: '12px'}}> 
                           
                               {parcel[0] && (parcel[0].paymentType === 'COD') && 
                               <div><p style={{fontSize: '16px', textAlign:'center', fontWeight: '900'}}> COD: {parcel[0] && (parcel[0].CODAmount)} LKR </p>
                               </div>  
                               }
                                </td>
                                <td></td>
                             </tr>
                               
                            </table>
                            
                              <table style={tableStyle} className="table">
                                <tr>
                             <td style={{fontSize: '12px'}}> <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${waybill}&choe=UTF-8`} /> </td>
                                  
                              <td style={{fontSize: '12px'}}> 
                              <img style={{width: '150px'}} src= {`https://ozonebilling.ozonedesk.com/barcode/barcode.php?text=${waybill}&size=50&print=true`}/>
                              </td>
                                  
                                </tr>
                                
                                
                              </table> 
                          
                             </center> 
                            )
                        } else if (setting.qr === 'on') {
                            return (
                              <>
                              {parcel[0] && (parcel[0].paymentType === 'COD') && 
       
                                <div style={{width: '250px', margin: 'auto'}}>
                                  <p style={{fontSize: '16px', textAlign:'center', fontWeight: '900'}}> COD : {parcel[0] && (parcel[0].CODAmount)} LKR </p></div>  
                                
                                }

                              <div style={{width: '250px', textAlign: 'center', margin: 'auto'}}>
                              <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${waybill}&choe=UTF-8`} />
                              </div> 

                              </>
                            )
                        } else if (setting.barcode === 'on'){
                            return (
                                <>
                                {parcel[0] && (parcel[0].paymentType === 'COD') && 
       
                                  <div style={{width: '250px', margin: 'auto'}}>
                                    <p style={{fontSize: '16px', textAlign:'center', fontWeight: '900'}} > COD : {parcel[0] && (parcel[0].CODAmount)} LKR </p></div>  
                                  
                                }
                                <div style={{width: '250px', textAlign: 'center', margin: 'auto'}}>
                               
                                <img style={{width: '150px'}} src= {`https://ozonebilling.ozonedesk.com/barcode/barcode.php?text=${waybill}&size=50&print=true`}/>
                                </div> 

                                </>
                            )
                        }
                })()  
            }  
        </div> 


    </div>
    </div>
            ) : (
            
              <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
                <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
              </div> 
            
            )
    
            ): (
            <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div>
                                                    
            )
    }
  </div>
        // <div style={{height: '145mm', width:'102mm', float:'left', border:'solid grey 1px', margin: '8px 0px 0px 8px'}}>
   
        //     <center>
        //         <table class="table">
        //             <tbody>
        //                 <tr>
        //                     <td>
        //                         <img src={logo} alt="imgLogo" className="src" style={{width: '135px', marginRight: '80px', marginTop: '5px'}} />
        //                     </td>
        //                     <td>
        //                         <label>Waybill ID : {id}</label>
        //                     </td>
        //                 </tr>
        //             </tbody>
        //         </table>
        //     </center>
        //     <hr/>
        //     <p style={{paddingLeft: '25px', fontSize: '15px'}}>
        //         <b>Recipient Details</b>
        //         <br/>
        //         <br/>
        //         Name: {Data[0].recipientName ? Data[0].recipientName : '-'}   
        //         <br/>
        //         Address: {Data[0].recipientAddress ? Data[0].recipientAddress : '-'}   
        //         <br/>
        //         Contact No: {Data[0].recipientContatNo ? Data[0].recipientContatNo : '-'}  
        //         <br/>
        //     </p>
        //     <hr/>
        //     <p style={{paddingLeft: '25px', fontSize: '15px'}}>
        //         <b>From</b>
        //         <br/>
        //         Name: {Data[0].pickupFrom ? Data[0].pickupFrom : '-'}   
        //         <br/>
        //         Address: {Data[0].pickupAddress ? Data[0].pickupAddress : '-'}  
        //         <br/>
        //         Contact No: {Data[0].pickupMobile ? Data[0].pickupMobile : '-'}  
        //         <br/>
   
        //         Order ID: {Data[0].orderID ? Data[0].orderID : '-'} / Parcel Desc: {Data[0].pDescription ? Data[0].pDescription : '-'} 
   
        //     </p>
        //     <hr/>
        //     <div>
        //         {/* <img src="https://ozonebilling.ozonedesk.com/barcode/barcode.php?text=155981&amp;size=50&amp;print=true"/> */}
        //     </div>     

        // </div>
    )
}

export default RemoveParcelPrintLabel;


// **********************************************************


/*
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logo from '../images/logo-light.png';
// import { Helmet } from 'react-helmet';

function NewParcelPrint() {

    let { loggedUser } = useParams();
    let { id } = useParams();

    useEffect(() => {
        
        // const queryParameters = new URLSearchParams(window.location.search)
        // const logId = queryParameters.get("loggedUser")
        // const newWaybill = queryParameters.get("waybill")
        
        sessionStorage.setItem("UserID", loggedUser);

        window.print();
    }, [])
    document.title = "Logistic Client Portal | Print Parcel";
    return(
    <div>
      
        <div style={{height: '145mm', width:'102mm', float:'left', border:'solid grey 1px', margin: '8px 0px 0px 8px'}}>
   
            <center>
                <table class="table">
                    <tbody>
                        <tr>
                            <td>
                                <img src={logo} alt="imgLogo" className="src" style={{width: '135px', marginRight: '80px', marginTop: '5px'}} />
                            </td>
                            <td>
                                <label>Waybill ID : {id}</label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </center>
            <hr/>
            <p style={{paddingLeft: '25px', fontSize: '15px'}}>
                <b>Recipient Details</b>
                <br/>
                <br/>
                Name: jj    
                <br/>
                Address: ytjtyjty   
                <br/>
                Contact No:01234567899   
                <br/>
            </p>
            <hr/>
            <p style={{paddingLeft: '25px', fontSize: '15px'}}>
                <b>From</b>
                <br/>
                Name: ABC   
                <br/>
                Address: katuwandeniya watta   
                <br/>
                Contact No:0717329326   
                <br/>
   
                Order ID: uyky / Parcel Desc: uyyukuy 
   
            </p>
            <hr/>
            <div>
                
            </div>     

        </div>
    </div>
    )
}

export default NewParcelPrint;

*/