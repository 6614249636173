import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";

function ModalPaymentData({paymentModalData, modalIsClose}) {
      
    const [batchParcel, setBatchParcel] = useState([]);
    const [clLoad, setclLoad]           = useState('');
    const [batchMinus, setBatchMinus]   = useState([]);
    
    const [spinner, setSpinner] = useState(true);

    const ClientD = sessionStorage.getItem('UserID');    
    
    
    var totalCOD                = 0;
    var totalDelivery           = 0;
    var totalcodafterDelivery   = 0;
    var codCommisstionParcelTotal = 0;
    var totalCancel = 0;
    var totalDiscount = 0;
    var newPayAmountNew = 0;    
    var newPayAmount = 0;

    var countn  = 0;

    
      useEffect(() => {
        async function batchParcelRequest() {
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getbatchParcel`, {
                method: 'POST', 
                body: JSON.stringify({             
                    batchNum: paymentModalData.batchNum,               
                  }),         
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                    setBatchParcel(data.Clstatus);
                    setSpinner(false);
                })
            } catch (error) {
              console.log(error.message)
            }
          }
          batchParcelRequest();       
      }, [])
      
      useEffect(() => {
        async function cDetailsRequest() {
          try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
              method: 'POST', 
              body: JSON.stringify({             
                  clientID: ClientD,
                 }),         
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            } )
              .then((respose) => {
                if (respose.ok) {
                  return respose.json()
                }
                throw new Error('error')
              })
              .then((data) => {          
                setclLoad(data.Clstatus);
               
              })
          } catch (error) {
            console.log(error.message)
          }
        }
        cDetailsRequest();
      }, []);
      
      useEffect(() => {
        async function batchMinusRequest() {
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getbatchMinus`, {
                method: 'POST', 
                body: JSON.stringify({             
                    batchNum: paymentModalData.batchNum,               
                  }),         
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                    setBatchMinus(data.Clstatus);
                 
                })
            } catch (error) {
              console.log(error.message)
            }
          }
          batchMinusRequest();       
      }, [])
      
//   async function sheetRequest(){
//     try {
//         await fetch(`${process.env.REACT_APP_URL}/index.php/getReSheet`, {
//         method: 'POST', 
//         body: JSON.stringify({             
//             clientID: ClientD,
//             inputfdate: searchfdate,
//             inputtdate: searchtdate,
//             riderConfirm: searchData,
//             hubConfirm: searchhub,
//             adminConfirm: searchadmin,
//            }),         
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//       })
//         .then((respose) => {
//           if (respose.ok) {
//             return respose.json()
//           }
//           throw new Error('error')
//         })
//         .then((data) => {
//             dataIsChoose(data.Clstatus);
//             pageIsChoose(1);
//         })
//     } catch (error) {
//       console.log(error.message)
//     }
//   }   
    //   const ClConfirmHandler = (e) => {

    //     e.preventDefault();

    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You must Confirm before do this",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#4caf50',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Confirm'

    //     }).then((result) => {

    //         if (result.isConfirmed) {                      
                   
    //             async function vConfirmRequest() {
    //                 try {
    //                   await fetch(`${process.env.REACT_APP_URL}/index.php/vendorConfirm`, {
    //                     method: 'POST',
    //                     body: JSON.stringify({
    //                         Hid: modalData.id, 
    //                         ClientD: ClientD,                        
    //                     }),
    //                     headers: {
    //                       'Content-Type': 'application/x-www-form-urlencoded',
    //                     },
    //                   } )
    //                     .then((respose) => {
    //                       if (respose.ok) {
    //                         return respose.json()
    //                       }
    //                       throw new Error('error')
    //                     })
    //                     .then((data) => {                                       
                                   
    //                       if(data.Clstatus === "success"){
                            
    //                         Swal.fire({
    //                           title: "Good job!",
    //                           text: "Order has been Confirm!",
    //                           icon: "success",
    //                           confirmButtonText: "OK",
    //                           timer: 1000,
    //                         });
                           
    //                       }else{
          
    //                         Swal.fire({
    //                           title: "Warning",
    //                           text: "Not Successful!",
    //                           icon: "error",
    //                           confirmButtonText: "OK",
    //                           timer: 1000,
    //                         });
          
    //                       }
    //                       sheetRequest();
    //                       modalIsClose(false); 
                                             
    //                     })
    //                 } catch (error) {
    //                   console.log(error.message)
    //                 }
    //             }

    //             vConfirmRequest();
    //         }else{
            
    //         }
    //     })  

    //   };

    return (
        <div className="modal" id="modalBTNLoad" style={{display: 'block', overflow: 'auto'}}>
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content"  id="modalDivLoad"> 
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel" style={{marginLeft: 'auto'}}> 
                        Vendor Payment - {clLoad.companyName} ({ClientD}) / {paymentModalData.date}                                                             
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> modalIsClose(false)}>
                            <span aria-hidden="true" style={{color: '#ffffff00'}}>&times;</span>
                        </button>
                    </div>

                    <div className="modal-body"> 
                        <div className="container-fluid">
                        {
                        (batchParcel.length !== 0) ? (
                            <div>
                                <div className="row" style={{paddingLeft: '20px'}}>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                        <h4 className="font-size-14">Vendor :  {clLoad.companyName} ({ClientD})</h4>
                                        <h4 className="font-size-14">Batch Number : {paymentModalData.batchNum}</h4>
                                        {
                                                (() => {
                                                
                                                  if(paymentModalData.payStatus == 'paid'){
                                                    
                                                    return(
                                                        <p style={{color:'green', fontWeight: '500', fontSize: '16px'}}>Paid</p>
                                                    )
                                                      
                                                  }else if(paymentModalData.payslip){
                                                    return(
                                                        <p style={{color:'#dcad00', fontWeight: '500', fontSize: '16px'}}>Processing</p>
                                                    )  
                                                  }else{
                                                    return(
                                                        <p style={{color:'red', fontWeight: '500', fontSize: '16px'}}>Unpaid</p>
                                                    )   
                                                  }                                                  
                                                })()  
                                        }
                             
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead  className="thead-dark">
                                                                <tr>
                                                                    <th>Parcel Info</th>
                                                                    <th>Delivered Date</th>
                                                                    <th style={{textAlign: 'right'}}>COD Amount</th>
                                                                    <th style={{textAlign: 'right'}}>Delivery Fee</th>
                                                                    <th style={{textAlign: 'right'}}>COD After Delivery Fee</th>
                                                                    <th style={{textAlign: 'right'}}>COD Commission
                                                                        {paymentModalData.cod_commission_limit !== null ? (
                                                                                    <span> ({paymentModalData.cod_commission_limit} LKR Limit)</span>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                        }
                                                                    </th>
                                                                    <th  style={{textAlign: 'right'}}>Return Fee                                   
                                                                        {paymentModalData.cancelFee ? (
                                                                                    <span>({paymentModalData.cancelFee}%)</span>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                        }
                                                                    </th>
                                                                    <th  style={{textAlign: 'right'}}>Discount
                                                                        {paymentModalData.discount ? (
                                                                                    <span>({paymentModalData.discount}%)</span>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                        }                                   
                                                                    </th>
                                                                </tr>
                                                        </thead>

                                                        <tbody>
                                                        { 
                                                        batchParcel.map(item=>{
                                                            return( 
                                                                <>
                                                                {
                                                                    (() => {
                                                                        if(((item.deliveryStatus == 'Complete') || (item.deliveryStatus == 'Cancel')) && (item.hand_over_vendor != '0')){
                                                                            if((item.paymentType == 'COD') && (item.deliveryStatus == 'Complete')){
                                                                                return (
                                                                                    <tr key={item.id}>
                                                                                        <td>{item.id}                              
                                                                                            <br/>{item.paymentType}
                                                                                            <br/>{item.orderID ? (
                                                                                                <span>Order ID :  {item.orderID}</span>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )
                                                                                            }
                                                                                        </td>
                                                                                        <td>{item.deliveredDate}</td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){
                                                                                                if(item.CODAmount){
                                                                                                    var newCodAmount = item.CODAmount
                                                                                                }else{
                                                                                                    var newCodAmount = 0
                                                                                                }
                                                                                                
                                                                                                totalCOD = (parseFloat(newCodAmount) + parseFloat(totalCOD)).toFixed(2);
                                                                                                    return (
                                                                                                        <>
                                                                                                       
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmount).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else if(item.deliveryStatus == 'Cancel'){
                                                                                                    return (
                                                                                                        <>0.00</>
                                                                                                    )  
                                                                                                }else{
                                                                                                    return (
                                                                                                    <>0.00</>
                                                                                                    )
                                                                                                }
                                                                                            })()  
                                                                                        }
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){
                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmount = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmount = 0
                                                                                                    }
                                                                                                totalDelivery = (parseFloat(newDelAmount) + parseFloat(totalDelivery)).toFixed(2);
                                                                                                
                                                                                                    return (
                                                                                                        <>
                                                                                                        
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmount).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }                                                                               
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                if(item.CODAmount){
                                                                                                    var newCodAmounto = item.CODAmount
                                                                                                }else{
                                                                                                    var newCodAmounto = 0
                                                                                                }

                                                                                                if(item.deliveryFee){
                                                                                                    var newDelAmounto = item.deliveryFee
                                                                                                }else{
                                                                                                    var newDelAmounto = 0
                                                                                                }
                                                                                                
                                                                                                var codafterDelivery = newCodAmounto-newDelAmounto 
                                                                                                
                                                                                                totalcodafterDelivery = (parseFloat(codafterDelivery) + parseFloat(totalcodafterDelivery)).toFixed(2);
                                                                                                    return (
                                                                                                        <>
                                                                                                       
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codafterDelivery).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        } 
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}> 
                                                                                        {
                                                                                            (() => {
                                                                                                    if(item.CODAmount){
                                                                                                    var newCodAmountS = item.CODAmount
                                                                                                    }else{
                                                                                                    var newCodAmountS = 0
                                                                                                    }

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountS = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountS = 0
                                                                                                    }
                                                                                                    
                                                                                                    if(paymentModalData.cod_commission_limit){
                                                                                                        var newcomLimit = paymentModalData.cod_commission_limit
                                                                                                    }else{
                                                                                                        var newcomLimit = 0
                                                                                                    }
                                                                                                    
                                                                                                var codafterDeliveryCOD = (parseFloat(newCodAmountS) - parseFloat(newDelAmountS)).toFixed(2);
                                                                                                // var codafterDeliveryCOD = newCodAmountS-newDelAmountS

                                                                                                if(codafterDeliveryCOD<=newcomLimit){ 

                                                                                                    if(paymentModalData.cod_commission_limit_min){
                                                                                                        var newcomLimitmin = paymentModalData.cod_commission_limit_min
                                                                                                    }else{
                                                                                                        var newcomLimitmin = 0
                                                                                                    }

                                                                                                    var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmin))/100).toFixed(2);
                                                                                                    codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                                                                    // var codCommisstionParcel = codafterDeliveryCOD*newcomLimitmin/100;
                                                                                                    // codCommisstionParcelTotal = codCommisstionParcel+codCommisstionParcelTotal
                                                                                                    return (
                                                                                                        <>
                                                                                                        {paymentModalData.cod_commission_limit_min !== null ? (
                                                                                                            <span>{parseFloat(codCommisstionParcel).toFixed(2)}<br/>{paymentModalData.cod_commission_limit_min} % Below</span>
                                                                                                        ) : (
                                                                                                            <> - </>
                                                                                                        )
                                                                                                        }
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                }else{

                                                                                                    if(paymentModalData.cod_commission_limit_max){
                                                                                                        var newcomLimitmax = paymentModalData.cod_commission_limit_max
                                                                                                    }else{
                                                                                                        var newcomLimitmax = 0
                                                                                                    }

                                                                                                    var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmax))/100).toFixed(2); 
                                                                                                    codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                                                                    // var codCommisstionParcel = codafterDeliveryCOD*newcomLimitmax/100;
                                                                                                    // codCommisstionParcelTotal = codCommisstionParcel+codCommisstionParcelTotal
                                                                                                    return (
                                                                                                    <>
                                                                                                    {paymentModalData.cod_commission_limit_max !== null ? (
                                                                                                        <span>{parseFloat(codCommisstionParcel).toFixed(2)}<br/>{paymentModalData.cod_commission_limit_max} % Above</span>
                                                                                                        ) : (
                                                                                                        <> - </>
                                                                                                        )
                                                                                                    }
                                                                                                    </>                                                                                                                
                                                                                                    )

                                                                                                }
                                                                                                
                                                                                                                                                    
                                                                                            
                                                                                                
                                                                                            })()  
                                                                                        }                                               
                                                                                        </td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                            {
                                                                                                (() => {
                                                                                                    if(item.deliveryStatus == 'Cancel'){
                                                                                                    
                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountCa = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountCa = 0
                                                                                                        }

                                                                                                        if(paymentModalData.cancelFee){
                                                                                                            var newCancelFee = paymentModalData.cancelFee
                                                                                                        }else{
                                                                                                            var newCancelFee = 0
                                                                                                        }

                                                                                                    var cancelFeeNew  = ((parseFloat(newDelAmountCa) * parseFloat(newCancelFee))/100).toFixed(2);  
                                                                                                    // var cancelFeeNew = newDelAmountCa*newCancelFee/100 
                                                                                                   
                                                                                                    totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
                                                                                                        return (
                                                                                                            <>
                                                                                                            
                                                                                                             {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                                                                            </>                                                                                                                
                                                                                                        )
                                                                                                                                                        
                                                                                                    }else{
                                                                                                    return (
                                                                                                        <> - </>
                                                                                                    )
                                                                                                    }
                                                                                                })()  
                                                                                            }                                     
                                                                                        </td>  
                                                                                        <td style={{textAlign: 'right'}}>                                         
                                                                                        {
                                                                                            
                                                                                                (() => {
                                                                                                
                                                                                                    countn = countn+1
                                                                                                    
                                                                                                    if(item.deliveryStatus == 'Complete'){

                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountDis = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountDis = 0
                                                                                                        }

                                                                                                        if(paymentModalData.discount){
                                                                                                            var newdiscFee = paymentModalData.discount
                                                                                                        }else{
                                                                                                            var newdiscFee = 0
                                                                                                        }

                                                                                                    var discountedAmount  = ((parseFloat(newDelAmountDis)/100) * parseFloat(newdiscFee)).toFixed(2); 
                                                                                                    // var discountedAmount = newDelAmountDis/100*newdiscFee 
                                                                                                   
                                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                    return (
                                                                                                            <>
                                                                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                            
                                                                                                            </>                                                                                                                
                                                                                                        )
                                                                                                                                                        
                                                                                                    }else if(item.deliveryStatus == 'Cancel'){

                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountDisO = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountDisO = 0
                                                                                                        }

                                                                                                        if(paymentModalData.discount){
                                                                                                            var newdiscFeeO = paymentModalData.discount
                                                                                                        }else{
                                                                                                            var newdiscFeeO = 0
                                                                                                        }

                                                                                                        if(paymentModalData.cancelFee){
                                                                                                            var newCancelFeeO = paymentModalData.cancelFee
                                                                                                        }else{
                                                                                                            var newCancelFeeO = 0
                                                                                                        }
                                                                                                        
                                                                                                    var cancelFeeNew      = ((parseFloat(newDelAmountDisO) * parseFloat(newCancelFeeO))/100).toFixed(2); 
                                                                                                    var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeO)).toFixed(2); 
                                                                                                    // var cancelFeeNew = newDelAmountDisO*newCancelFeeO/100 
                                                                                                    // var discountedAmount = cancelFeeNew/100*newdiscFeeO 

                                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                    
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                       
                                                                                                        </>
                                                                                                    )
                                                                                                    }else{
                                                                                                    
                                                                                                    return (
                                                                                                    <>0.00</>
                                                                                                    )
                                                                                                    }
                                                                                                })()  
                                                                                        }
                                                                                        
                                                                                        </td>  
                                                                                    </tr>
                                                                                )
                                                                            }else if((item.deliveryStatus == 'Cancel') && (item.paymentType == 'COD')){
                                                                                return (
                                                                                    <tr key={item.id}>
                                                                                        <td>{item.id}<br/>{item.paymentType}</td>
                                                                                        <td>{item.deliveredDate}</td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){
                                                                                                if(item.CODAmount){
                                                                                                    var newCodAmountTh = item.CODAmount
                                                                                                }else{
                                                                                                    var newCodAmountTh = 0
                                                                                                }
                                                                                               
                                                                                                totalCOD = (parseFloat(newCodAmountTh) + parseFloat(totalCOD)).toFixed(2); 
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountTh).toFixed(2))}
                                                                                                      
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else if(item.deliveryStatus == 'Cancel'){
                                                                                                    return (
                                                                                                        <>0.00</>
                                                                                                    )  
                                                                                                }else{
                                                                                                return (
                                                                                                    <>0.00</>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }  
                                                                                            
                                                                                        </td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountTh = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountTh = 0
                                                                                                    }

                                                                                                totalDelivery = (parseFloat(newDelAmountTh) + parseFloat(totalDelivery)).toFixed(2);
                                                                                                // totalDelivery = item.deliveryFee+totalDelivery; 
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountTh).toFixed(2))}
                                                                                                     
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }  
                                                                                            
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        -
                                                                                        </td>
                                                                            
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                            -  
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Cancel'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountCaTh = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountCaTh = 0
                                                                                                    }

                                                                                                    if(paymentModalData.cancelFee){
                                                                                                        var newCancelFeeTh = paymentModalData.cancelFee
                                                                                                    }else{
                                                                                                        var newCancelFeeTh = 0
                                                                                                    }
                                                                                                    
                                                                                                var cancelFeeNew  = ((parseFloat(newDelAmountCaTh) * parseFloat(newCancelFeeTh))/100).toFixed(2);  
                                                                                                // var cancelFeeNew = newDelAmountCaTh*newCancelFeeTh/100 
                                                                                                totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);

                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                                                                       
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }
                                                                                                
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            
                                                                                            (() => {
                                                                                            
                                                                                            countn = countn+1
                                                                                            
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisTh = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisTh = 0
                                                                                                    }

                                                                                                    if(paymentModalData.discount){
                                                                                                        var newdiscFeeTh = paymentModalData.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeTh = 0
                                                                                                    }
                                                                                                    
                                                                                                var discountedAmount  = ((parseFloat(newDelAmountDisTh)/100) * parseFloat(newdiscFeeTh)).toFixed(2);  
                                                                                                // var discountedAmount = newDelAmountDisTh/100*newdiscFeeTh 
                                                                                                totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                
                                                                                                return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                      
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else if(item.deliveryStatus == 'Cancel'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisOS = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisOS = 0
                                                                                                    }

                                                                                                    if(paymentModalData.discount){
                                                                                                        var newdiscFeeOS = paymentModalData.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeOS = 0
                                                                                                    }

                                                                                                    if(paymentModalData.cancelFee){
                                                                                                        var newCancelFeeOS = paymentModalData.cancelFee
                                                                                                    }else{
                                                                                                        var newCancelFeeOS = 0
                                                                                                    }
                                                                                                    
                                                                                                var cancelFeeNew      = ((parseFloat(newDelAmountDisOS) * parseFloat(newCancelFeeOS))/100).toFixed(2);
                                                                                                var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOS)).toFixed(2); 
                                                                                                // var cancelFeeNew = newDelAmountDisOS*newCancelFeeOS/100 
                                                                                                // var discountedAmount = cancelFeeNew/100*newdiscFeeOS 
                                                                                                
                                                                                                totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);

                                                                                                return (
                                                                                                    <>
                                                                                                    {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                    
                                                                                                    </>
                                                                                                )
                                                                                                }else{
                                                                                                return (
                                                                                                <>0.00</>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }
                                                                                        
                                                                                        </td>  
                                                                                    </tr>
                                                                                )   
                                                                            }else if(item.paymentType != 'COD'){
                                                                                return (
                                                                                    <tr key={item.id}>
                                                                                        <td>{item.id}<br/>{item.paymentType}</td>                                
                                                                                        <td>{item.deliveredDate}</td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                            {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){
                                                                                                    if(item.CODAmount){
                                                                                                    var newCodAmountF = item.CODAmount
                                                                                                    }else{
                                                                                                    var newCodAmountF = 0
                                                                                                    }
                                                                                                    
                                                                                                    totalCOD = (parseFloat(newCodAmountF) + parseFloat(totalCOD)).toFixed(2);
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountF).toFixed(2))}
                                                                                                       
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                        
                                                                                                }else if(item.deliveryStatus == 'Cancel'){
                                                                                                    return (
                                                                                                        <>0.00</>
                                                                                                    )  
                                                                                                }else{
                                                                                                    return (
                                                                                                    <>0.00</>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                            }  
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountFi = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountFi = 0
                                                                                                    }
                                                                                                totalDelivery = (parseFloat(newDelAmountFi) + parseFloat(totalDelivery)).toFixed(2);
                                                                                                
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountFi).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                        
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }  
                                                                                            
                                                                                        </td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        -
                                                                                        </td>                                                              
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                            -  
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Cancel'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountCaSix = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountCaSix = 0
                                                                                                    }

                                                                                                    if(paymentModalData.cancelFee){
                                                                                                        var newCancelFeeSix = paymentModalData.cancelFee
                                                                                                    }else{
                                                                                                        var newCancelFeeSix = 0
                                                                                                    }
                                                                                                    
                                                                                                var cancelFeeNew  = ((parseFloat(newDelAmountCaSix) * parseFloat(newCancelFeeSix))/100).toFixed(2); 
                                                                                                // var cancelFeeNew = newDelAmountCaSix*newCancelFeeSix/100 

                                                                                                totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
                                                                                                
                                                                                                    return (
                                                                                                        <>
                                                                                                       {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                        
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }
                                                                                                
                                                                                        </td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                        
                                                                                            (() => {
                                                                                            countn = countn+1
                                                                                            
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisE = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisE = 0
                                                                                                    }

                                                                                                    if(paymentModalData.discount){
                                                                                                        var newdiscFeeE = paymentModalData.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeE = 0
                                                                                                    }
                                                                                                    
                                                                                                var discountedAmount  = ((parseFloat(newDelAmountDisE)/100) * parseFloat(newdiscFeeE)).toFixed(2); 
                                                                                                // var discountedAmount = newDelAmountDisE/100*newdiscFeeE 
                                                                                                totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                
                                                                                                return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                      
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                        
                                                                                                }else if(item.deliveryStatus == 'Cancel'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisOE = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisOE = 0
                                                                                                    }

                                                                                                    if(paymentModalData.discount){
                                                                                                        var newdiscFeeOE = paymentModalData.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeOE = 0
                                                                                                    }

                                                                                                    if(paymentModalData.cancelFee){
                                                                                                        var newCancelFeeOE = paymentModalData.cancelFee
                                                                                                    }else{
                                                                                                        var newCancelFeeOE = 0
                                                                                                    }
                                                                                                    
                                                                                                var cancelFeeNew = ((parseFloat(newDelAmountDisOE) * parseFloat(newCancelFeeOE))/100).toFixed(2); 
                                                                                                var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOE)).toFixed(2); 
                                                                                                // var cancelFeeNew = newDelAmountDisOE*newCancelFeeOE/100 
                                                                                                // var discountedAmount = cancelFeeNew/100*newdiscFeeOE 
                                                                                                totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                
                                                                                                return (
                                                                                                    <>
                                                                                                    {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                    
                                                                                                    </>
                                                                                                )
                                                                                                }else{
                                                                                                return (
                                                                                                <>0.00</>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }                                                                                     
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }                                                                            
                                                                        }
                                                                    })()  
                                                                }
                                                                </>
                                                            )
                                                        })
                                                        }   
                                                            <tr style={{fontWeight: '500'}}>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>Total</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCOD).toFixed(2))}</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalDelivery).toFixed(2))}</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalcodafterDelivery).toFixed(2))}</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcelTotal).toFixed(2))}</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCancel).toFixed(2))}

                                                                {
                                                                    (() => {
                                                                    var sumReturnNDelivFee      = (parseFloat(totalDelivery) + parseFloat(totalCancel)).toFixed(2);
                                                                    var finalsumReturnNDelivFee = (parseFloat(sumReturnNDelivFee) - parseFloat(totalDiscount)).toFixed(2);
                                                                    // var sumReturnNDelivFee      = totalDelivery+totalCancel;
                                                                    // var finalsumReturnNDelivFee = sumReturnNDelivFee-totalDiscount;
                                                                    
                                                                    newPayAmount     = (parseFloat(totalCOD) - parseFloat(finalsumReturnNDelivFee)-parseFloat(codCommisstionParcelTotal)).toFixed(2);    
                                                                    // newPayAmount                = totalCOD-finalsumReturnNDelivFee-codCommisstionParcelTotal;  
                                                                    
                                                                    if(paymentModalData.creditBlance != '0'){
                                                                        newPayAmountNew      = (parseFloat(newPayAmount) + parseFloat(paymentModalData.creditBlance)).toFixed(2);
                                                                        // newPayAmountNew = newPayAmount+paymentModalData.creditBlance;
                                                                    }else{
                                                                        newPayAmountNew = newPayAmount;
                                                                    }
                                                                        
                                                                    })()  
                                                                }
                                                                </td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalDiscount).toFixed(2))}</td>
                                                            </tr>

                                                            <tr style={{fontWeight: '500'}}>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                {
                                                                    (() => {
                                                                    
                                                                    if(newPayAmount< 0){
                                                                        return(<>Sub Due Amount</>)
                                                                        
                                                                    }else{
                                                                        return(<>Sub Pay Amount</>)
                                                                    }
                                                                        
                                                                    })()  
                                                                }
                                                                </td>
                                                                <td style={{textAlign: 'right'}}>
                                                                {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newPayAmount).toFixed(2))}
                                                                </td>
                                                            </tr>
                                                            {
                                                                (() => {
                                                                
                                                                if(paymentModalData.creditBlance == '0'){
                                                                
                                                                    if(paymentModalData.amount){
                                                                        var newpAmount = paymentModalData.amount
                                                                    }else{
                                                                        var newpAmount = 0
                                                                    }
                                                                    
                                                                    if(paymentModalData.tax_amount){
                                                                        var newpTaxAmount = paymentModalData.tax_amount
                                                                    }else{
                                                                        var newpTaxAmount = 0
                                                                    }
                                                                    
                                                                    var TotalCredit = (parseFloat(newPayAmountNew) - parseFloat(newpAmount)-parseFloat(newpTaxAmount)).toFixed(2);  
                                                                    // var TotalCredit = newPayAmountNew-newpAmount-newpTaxAmount
                                                                    
                                                                    return(
                                                                    <tr style={{fontWeight: '800'}}>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                    Credit Balance
                                                                    </td>                                                                    
                                                                    <td style={{textAlign: 'right'}}>
                                                                    {
                                                                        (() => {
                                                                        if(TotalCredit == '0.00'){
                                                                            return(
                                                                             <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(TotalCredit).toFixed(2))}</>
                                                                            
                                                                            )
                                                                        }else{
                                                                        return(<>- {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(TotalCredit).toFixed(2))}</>)
                                                                        }
                                                                        })()  
                                                                    }

                                                                    
                                                                    </td>
                                                                    </tr>
                                                                )
                                                                    
                                                                }else{
                                                                    return(
                                                                    <tr style={{fontWeight: '800'}}>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                    Credit Balance
                                                                    </td>
                                                                    <td style={{textAlign: 'right'}}>
                                                                    {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(paymentModalData.creditBlance).toFixed(2))}
                                                                    
                                                                    </td>
                                    
                                                                </tr>
                                                                
                                                                )
                                                                }
                                                                    
                                                                })()  
                                                            }

                                                            {
                                                                (() => {
                                                                
                                                                if(paymentModalData.tax_status == 1){
                                                                    
                                                                    return(
                                                                        <tr style={{fontWeight: '800'}}>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                            Tax Amount
                                                                            </td>
                                                                            <td style={{textAlign: 'right'}}>
                                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(paymentModalData.tax_amount).toFixed(2))}
                                                                                                                                
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                    
                                                                }                                                  
                                                                })()  
                                                            }

                                                            {
                                                                (() => {
                                                                
                                                                if(paymentModalData.creditBlance == '0'){
                                                                
                                                                    if(paymentModalData.amount){
                                                                        var newpAmountb = paymentModalData.amount
                                                                    }else{
                                                                        var newpAmountb = 0
                                                                    }
                                                                    return(
                                                                    <tr style={{fontWeight: '800'}}>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                    {
                                                                        (() => {
                                                                        if(newPayAmount < 0){
                                                                            return(
                                                                            <>Due Amount</>
                                                                            )
                                                                        }else{
                                                                            return(
                                                                            <>Pay Amount</>
                                                                            )
                                                                        }
                                                                        })()  
                                                                    }
                                                                    </td>
                                                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newpAmountb).toFixed(2))}
                                                                    </td>
                                                                    </tr>
                                                                )
                                                                    
                                                                }else{
                                                                    return(
                                                                    <tr style={{fontWeight: '800'}}>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                    {
                                                                        (() => {
                                                                        if(newPayAmount < 0){
                                                                            return(
                                                                            <>Due Amount</>
                                                                            )
                                                                        }else{
                                                                            return(
                                                                            <>Pay Amount</>
                                                                            )
                                                                        }
                                                                        })()  
                                                                    }
                                                                    </td>
                                                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(paymentModalData.amount).toFixed(2))}</td>
                                    
                                                                </tr>
                                                                
                                                                )
                                                                }
                                                                    
                                                                })()  
                                                            }
                                                        </tbody>
                                                   
                                                    </table>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{margin: '-55px 8px 20px 20px', marginTop: '5px'}}>
                                    <p>Invoice Count : {countn}</p>
                                    {
                                                (() => {
                                                
                                                  if(paymentModalData.payStatus == 'paid'){
                                                    if(newPayAmount<0){

                                                        return(
                                                            <>
                                                            <div className="col-lg-12">
                                                                <hr/><h5 style={{color: 'red'}}>{paymentModalData.note_minus}</h5>
                                                            </div>

                                                            {
                                                            (batchMinus.length !== 0) ? (
                                                                <table className="table table-bordered">
                                                                    <thead className="thead-dark">
                                                                        <tr>
                                                                            <th>Date</th>
                                                                            <th>Invoice Amount</th> 
                                                                            <th>Paid Amount</th>
                                                                            <th>Credit</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {                                                                                         
                                                                        batchMinus.map(itemM=>{
                                                                        return(
                                                                        <tr key={itemM.id}>
                                                                            <td>{itemM.date}</td>
                                                                            <td>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(itemM.total_amount).toFixed(2))}</td>
                                                                            <td>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(itemM.paid_amount).toFixed(2))}</td>
                                                                            <td>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(itemM.creaditBalance).toFixed(2))}</td>
                                                                        </tr>
                                                                        )
                                                                        })
                                                                    } 
                                                                    </tbody>
                                                                </table>
                                                                ): (
                                                                    <></>
                                                                )
                                                            } 
                                                            <div className="col-lg-12">
                                                                <img src="https://www.onlygfx.com/wp-content/uploads/2017/11/paid-stamp-1.png" style={{width: '100px', marginBottom: '20px'}} alt="" />
                                                            </div>

                                                            {
                                                                (() => {
                                                                
                                                                if(paymentModalData.payslip){
                                                    
                                                                        let arr             = paymentModalData.payslip.split('/');
                                                                        const firstElement  = arr[0];
                                                                        const secondElement = arr[2];
                                                                        
                                                                        const full_upload_url = firstElement+"//"+secondElement;
                                                                        
                                                                        if(full_upload_url == "https://box1.ozonedesk.info"){
                                                                        
                                                                            return(
                                                                            <a className="btn btn-success btn-sm" target="_blank" href={`${paymentModalData.payslip}`}>View Slip</a>
                                                                            )
                                                                    
                                                                        }else{
                                                                         
                                                                            return(
                                                                            <a className="btn btn-success btn-sm" target="_blank" href={`${process.env.REACT_APP_UPLOAD_URL}/admin/${paymentModalData.payslip}`}>View Slip</a>
                                                                            )
                                                                    
                                                                        }
                                                                        
                                                                    
                                                                    
                                                                }                                                  
                                                                })()  
                                                            }

                                                            <div className="col-lg-12">
                                                            {
                                                                    (() => {
                                                                    if(paymentModalData.note){
                                                                        return(
                                                                            <p>Note : {paymentModalData.note}</p>
                                                                    )
                                                                    }                                                  
                                                                    })()  
                                                            }
                                                            </div>
                                                            </>
                                                        )

                                                    
                                                    }else{
                                                        return(
                                                            <>
                                                            <div className="col-lg-12">
                                                                <img src="https://www.onlygfx.com/wp-content/uploads/2017/11/paid-stamp-1.png" style={{width: '100px', marginBottom: '20px'}} alt="" />
                                                            </div>
                                                            {
                                                                (() => {
                                                                
                                                                if(paymentModalData.payslip){
                                                    
                                                                        let arr             = paymentModalData.payslip.split('/');
                                                                        const firstElement  = arr[0];
                                                                        const secondElement = arr[2];
                                                                        
                                                                        const full_upload_url = firstElement+"//"+secondElement;
                                                                        
                                                                        if(full_upload_url == "https://box1.ozonedesk.info"){
                                                                        
                                                                        return(
                                                                            <a className="btn btn-success btn-sm" target="_blank" href={`${paymentModalData.payslip}`}>View Slip</a>
                                                                        )
                                                                    
                                                                        }else{
                                                                        
                                                                        return(
                                                                            <a className="btn btn-success btn-sm" target="_blank" href={`${process.env.REACT_APP_UPLOAD_URL}/admin/${paymentModalData.payslip}`}>View Slip</a>
                                                                        )
                                                                    
                                                                        }
                                                                        
                                                                    
                                                                    
                                                                }                                                  
                                                                })()  
                                                            }
                                                            <div className="col-lg-12">
                                                                {
                                                                        (() => {
                                                                        
                                                                        if(paymentModalData.note){
                                                            
                                                                            return(
                                                                                <p>Note : {paymentModalData.note}</p>
                                                                        )
                                                                            
                                                                        }                                                  
                                                                        })()  
                                                                }
                                                            
                                                            </div>
                                                            </>
                                                        )    
                                                    }
                                                    
                                                   
                                                      
                                                  }else if(paymentModalData.payslip){
                                                    return(
                                                        <></>
                                                    )  
                                                  }else{
                                                    return(
                                                        <div className="col-lg-12">
                                                        <hr/><p style={{color:'red', fontWeight: '500', fontSize: '16px'}}>Unpaid</p></div>
                                                        
                                                    )   
                                                  }                                                  
                                                })()  
                                    }
                                    <div className="col-lg-12">
                                        <hr/>
                                        <p>Figures : <p style={{fontWeight: '500'}}>Sub Pay Amount = Total COD Amount - (Total Return Fee + Total Delivery Fee + Total COD Commission - Total Discount)</p></p>
                                    
                                        <p style={{fontWeight: '500'}}>COD Commission = (COD Amount - Delivery Fee) * COD Commission Fee(%)</p>
                                        {
                                                (() => {
                                                
                                                  if(paymentModalData.tax_status == 1){
                                                    
                                                    return(
                                                        <p style={{fontWeight: '500'}}>Tax Amount = ((Total Return Fee + Total Delivery Fee + Total COD Commission)-Total Discount) * Tax Value (%)</p>
                                                    )
                                                      
                                                  }                                                  
                                                })()  
                                        }
                                        
                                        {
                                                (() => {
                                                
                                                  if(paymentModalData.tax_status == 1){
                                                    
                                                    return(
                                                        <p style={{fontWeight: '500'}}>Pay Amount = Sub Pay Amount - Credit Balance - Tax Amount</p>
                                                    )
                                                      
                                                  }else{
                                                    return(
                                                        <p style={{fontWeight: '500'}}>Pay Amount = Sub Pay Amount - Credit Balance</p>
                                                    ) 
                                                  }                                                  
                                                })()  
                                        }
                                        
                                        </div>
                                </div>
                            </div>
                        ): (
                         spinner ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                                                    
                            <>
                            <div className="row">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <h4 className="font-size-18">Vendor Payment Release- {clLoad.fName} {clLoad.lName} ({ClientD})</h4>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: '20px'}}>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body"> 
                                            <div className="card bg-danger">
                                                <div className="card-body">
                                                    <h5 className="text-center text-white"> No Records</h5> 
                                                </div> 
                                            </div> 
                                        </div> 
                                    </div> 
                                </div> 
                            </div> 
                            </>
                        )
                        }
                        </div>     
                    </div>

                    
            </div>
        </div>
        </div>
    )
}

 export default ModalPaymentData;


