import React, { useRef, useEffect, useState }  from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
import logo from '../images/logo-light.png';
import './print.css';

function LabelBulkPrint (){
   
  let  {loggedUser}     = useParams();
  let  {pdate}          = useParams();
  let  {pFrom}          = useParams();
  let  {pTo}            = useParams();
  let  {pStatus}        = useParams();

  var count        = 0;
  
  const [isPrintingLabel, setIsPrintingLabel]   = useState(false); 
  const [isPrintingLabelS, setIsPrintingLabelS] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [settings, setSettings] = useState('');
  const [uParcels, setUParcels] = useState([]);
  
    async function settingRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
            method: 'POST',          
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
            // console.log(data.Clstatus);
            setSettings(data.Clstatus);
            setIsPrintingLabelS(true);
            })
        } catch (error) {
        console.log(error.message)
        }
    }
    
    useEffect(() => {
    async function parcelLRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getLabelPrint`, {
            method: 'POST', 
            body: JSON.stringify({
                clientId: loggedUser,  
                pdate: pdate,  
                pFrom: pFrom,  
                pTo: pTo, 
                pStatus: pStatus,         
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
            // console.log(data.Clstatus);
            setUParcels(data.Clstatus);
            setIsPrintingLabel(true);
            })
        } catch (error) {
        console.log(error.message)
        }
    }
    parcelLRequest();   
    sessionStorage.setItem("UserID", loggedUser);  
    }, []);

    useEffect(() => {
        settingRequest()
        
      }, []);

    
    useEffect(() => {
        if (isPrintingLabel) {
            if(isPrintingLabelS){
                handlePrint()
            }
        }
    }, [isPrintingLabel, isPrintingLabelS, handlePrint]);


  document.title = "Logistic Client Portal | Label Print";

  const myStyle = {
    lineHeight: '0',
    marginBlockEnd: '8px',
    marginBlockStart: '8px',
    borderColor: 'rgb(154 154 154)',

    marginTop: '8px',
    marginBottom: '8px'
    };

    const tableStyle = {
      borderCollapse: 'separate'
    }

    const breakAfterStyle = {
        pageBreakAfter: 'always'
    }
  

  return (
  <div ref={componentRef} style={{ backgroundColor: 'white'}} className="printLayoutContainer">
    {
        (isPrintingLabel) ? (
            (isPrintingLabelS) ? (
        <>
    {/* <div className="bodyclz" style={{ fontFamily: 'initial', color: '#000000', backgroundColor: 'white', height: '148.5mm', margin: '0'}}> */}
    <div className="row" style={{ fontFamily: 'initial', color: '#000000', backgroundColor: 'white', height: '155mm', margin: '0'}}>

    {                                                                                         
                                    uParcels.map(item=>{
                                    return( 
                                    <span key={item.id} >                                                                                           
                                        <div style={{height: '145mm', width:'102mm', float:'left', border:'solid grey 1px', margin: '8px 0px 0px 8px'}}>

                                        <center>
                                            <table style={tableStyle}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{fontSize: '12px'}}>
                                    
                                                            <img src={logo} alt="imgLogo" className="src" style={{width: '135px', marginRight: '80px', marginTop: '5px'}} />
                                                        </td>
                                                        <td style={{fontSize: '12px'}}>
                                                            <label style={{fontSize: '14px', fontWeight: '800', margin:'0'}}>Waybill ID : {item.id}</label>
                                                           {item.paymentType === 'COD' && 
                                                            <p style={{fontSize: '16px', textAlign:'center', fontWeight: '900', margin: '0px 0px 4px 0px', lineHeight: 'normal'}}> COD: {item.CODAmount} LKR </p>
                                                           }
                                                           {item.exchange === 1 &&
                                                           <p style={{margin: 'auto', textAlign: 'center', fontSize: '12px'}}>Exchange</p>
                                                           }                               
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </center>

                                        <hr style={myStyle} />
                                        <p style={{paddingLeft: '25px', fontSize: '15px',lineHeight: 'normal'}}>
                                            <b style={{fontWeight: 'bold' }} >Recipient Details</b>
                                            <br/>
                                            {/* <br/> */}
                                            Name: {item.recipientName}   
                                            <br/>
                                            Address: {item.recipientAddress}    
                                            <br/>
                                            Contact No: {item.recipientMobile} 
                                            <br/>
                                        </p>
                                        <hr style={myStyle} />
                                        <p style={{paddingLeft: '25px', fontSize: '15px', lineHeight: 'normal'}}>
                                            <b style={{fontWeight: 'bold'}} >From</b>
                                            <br/>
                                            {/* <br/> */}
                                            Name: {item.pickupFrom}   
                                            <br/>
                                            Address: {item.pickupAddress}   
                                            <br/>
                                            Contact No: {item.pickupMobile} 
                                            <br/>
                                            {item.orderID !== '' &&
                                            <span> Order ID: {item.orderID} / </span>
                                            }
                                            Parcel Desc: {item.pDescription}
                                            {item.clientNote !== '' &&
                                            <span> {item.clientNote} </span>
                                            }
                                
                                        </p>
                                        <hr style={myStyle}/>
                                
                                        <div>     
                                            {
                                                (() => {
                                                    if(settings.qr === 'on' && settings.barcode === 'on') {
                                                            return (
                                                              <center>
                                                             <table style={tableStyle} className="table">
                                                                <tr>
                                                                    <td style={{fontSize: '12px'}}> <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${item.id}&choe=UTF-8`} /> </td>
                                                                        
                                                                    {/* <td style={{fontSize: '12px'}}> <img style={{width: '150px'}} src={`https://barcode.tec-it.com/barcode.ashx?data=${item.id}`} /></td> */}
                                                                    <td style={{fontSize: '12px'}}> <img style={{width: '145px'}} src={`${process.env.REACT_APP_URL}/barcode.php?text=${item.id}&size=50&print=true`}/></td>   
                                                                </tr>
                                                                
                                                                
                                                              </table> 
                                                          
                                                             </center> 
                                                            )
                                                        } else if (settings.qr === 'on') {
                                                            return (
                                                              <>
                                                              <div style={{width: '250px', textAlign: 'center', margin: 'auto'}}>
                                                              <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${item.id}&choe=UTF-8`} />
                                                              </div> 
                                
                                                              </>
                                                            )
                                                        } else if (settings.barcode === 'on'){
                                                            return (
                                                                <>
                                                                <div style={{width: '250px', textAlign: 'center', margin: 'auto'}}>
                                                                {/* <img style={{width: '150px'}} src= {`https://barcode.tec-it.com/barcode.ashx?data=${item.id}`}/> */}
                                                                <img style={{width: '150px'}} src={`${process.env.REACT_APP_URL}/barcode.php?text=${item.id}&size=50&print=true`}/> 
                                                                </div> 
                                
                                                                </>
                                                            )
                                                        }
                                                })()  
                                            }  
                                        </div> 
                                
                                
                                        </div> 
                                        {
                                            
                                            (() => {
                                                 count = count+1
                                                if(count === 6){
                                                    count = 0
                                                    return (
                                                        <div style={breakAfterStyle} className="breakAfter"></div>
                                                    )
                                                                                                       
                                                }
                                            })()
                                        }
                                        
                                    </span>  
                                    )
                                    
                                    })
                                    }      
    </div>
     </>
            ) : (
            
              <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
                <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
              </div> 
            
            )
        ) : (
        <>
          <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
            <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
          </div> 
        </>
        )
    }
  </div>
  );
}
export default LabelBulkPrint;