import React, { useRef, useEffect, useState }  from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
import logo from '../images/logo-light.png';
import './print.css';

function VendorPayListaAchivePrint ({adminInfo}){
    
  let  {Client}  = useParams();
  let  {batchNum}        = useParams();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  document.title = "Logistic Client Portal | Vendor Payment Release Print";

var today = new Date();

var todaytime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

var todaydate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

var currntDate = todaydate+" "+todaytime;

var totalCOD                = 0;
var totalCancel             = 0;
var totalDelivery           = 0;
var totalcodafterDelivery   = 0;
var codCommisstionParcelTotal = 0;
var totalDiscount           = 0;
var newPayAmount            = 0;
var newPayAmountNew         = 0; 

var countn  = 0;

  const [clientData, setclientData] = useState([]); 
  
  useEffect(() => {
    async function clientDetailsRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: Client,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setclientData(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
      clientDetailsRequest();
      sessionStorage.setItem("UserID", Client); 
  }, []);

  
  const [batchNParcel, setBatchNParcel]             = useState([]);
  const [batchPayHistry, setBatchPayHistry]         = useState([]);
  const [archBatchPayHistry, setArchBatchPayHistry] = useState([]);
  const [pBatchPayHistry, setpBatchPayHistry] = useState([]);
  
  const [spinnerPrint, setSpinnerPrint]         = useState(false);
  const [isPrintingLabelP, setIsPrintingLabelP] = useState(false); 
 
  useEffect(() => {
    async function archbatchParcelNRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getArchivebatchParcel`, {
            method: 'POST', 
            body: JSON.stringify({             
                batchNum: batchNum,               
              }),         
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
                setArchBatchPayHistry(data.Clstatus);
                setSpinnerPrint(true);
            })
        } catch (error) {
          console.log(error.message)
        }
      }
      archbatchParcelNRequest();       
  }, [])

  useEffect(() => {
    async function pbatchParcelNRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getPbatchParcel`, {
            method: 'POST', 
            body: JSON.stringify({             
                batchNum: batchNum,               
              }),         
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
                setpBatchPayHistry(data.Clstatus);
                setIsPrintingLabelP(true);
            })
        } catch (error) {
          console.log(error.message)
        }
      }
      pbatchParcelNRequest();       
  }, [])

  useEffect(() => {
    async function batchParcelNRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getbatchParcel`, {
            method: 'POST', 
            body: JSON.stringify({             
                batchNum: batchNum,               
              }),         
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
                setBatchNParcel(data.Clstatus);
             
            })
        } catch (error) {
          console.log(error.message)
        }
      }
      batchParcelNRequest();       
  }, [])
  
  useEffect(() => {
    async function batchPayNRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getbatchPayment`, {
            method: 'POST', 
            body: JSON.stringify({             
                batchNum: batchNum,               
              }),         
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
                setBatchPayHistry(data.Clstatus);
             
            })
        } catch (error) {
          console.log(error.message)
        }
      }
      batchPayNRequest();       
  }, [])
  
    
    useEffect(() => {
        if (spinnerPrint) {
          if(isPrintingLabelP){
           handlePrint()
          }
        }
    }, [spinnerPrint, isPrintingLabelP, handlePrint]);


  const myStyle = {
    border: '1px solid #94d0e8', 
    marginTop: '8px',
    marginBottom: '8px'
    };

      
        
  return (
  <body ref={componentRef} style={{ backgroundColor: 'white', height: '29.7cm', width: '21cm', fontFamily: 'times new roman', color: '#000000'}} className="printLayoutContainer">    
       { 
       (spinnerPrint) ? (
           (isPrintingLabelP) ? (
           <>
              <div className="row" style={{  textAlign: 'center', display: 'block',margin: '10px'}}>
                  <center><img src={logo} alt="imgLogo" style={{width: '175px', marginTop: '10px'}} /></center>
              </div> 
              <hr style={{border: '1px solid #94d0e8'}}/>
              <div className="row"  style={{ display: 'block',margin: '10px'}}>
              
                  <h3 style={{  textAlign: 'center', marginBottom: '-15px', fontSize: '18.72px',  fontFamily: 'times new roman'}}> Vendor Payment Release </h3>
                  <p style={{  textAlign: 'center', marginBottom: '25px', marginTop: '16px', fontSize: '16px', lineHeight: 'normal', fontFamily: 'times new roman'}}> (Date: {currntDate})</p>
                  <h4 style={{  textAlign: 'center', marginTop: '-12px', marginBottom: '21.28px', fontSize: '16px', fontFamily: 'times new roman'}}>  Vendor : {clientData.companyName} ({Client})</h4>   
                  {
                      (archBatchPayHistry.length !== 0) ? (
                          <>
                           
                           <table style={{width: '100%', border: '1px solid #e4e4e0', padding: '10px', borderCollapse: 'separate', color: 'black'}}>
                              <thead>
                                  <tr style={{fontSize: '16px'}}>
                                      <th style={{padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Parcel Info</th>
                                      <th style={{padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Client Info</th>
                                      <th style={{padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Delivered Date</th>
                                      <th style={{padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Collect By</th>         
                                      <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>COD Amount</th>
                                      <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Delivery Fee</th>
                                      <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>COD After Delivery Fee</th>
                                      <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>COD Commission
                                          {batchPayHistry.cod_commission_limit ? (
                                                      <span>({batchPayHistry.cod_commission_limit} LKR Limit)</span>
                                                    ) : (
                                                      <></>
                                                    )
                                          }
                                      </th>
                                      <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Return Fee
                                          {batchPayHistry.cancelFee ? (
                                                      <span>({batchPayHistry.cancelFee}%)</span>
                                                    ) : (
                                                      <></>
                                                    )
                                          }
                                          </th>
                                      <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Discount
                                          {batchPayHistry.discount ? (
                                                          <span>({batchPayHistry.discount}%)</span>
                                                      ) : (
                                                          <></>
                                                      )
                                          }
                                      </th> 
                                  </tr>
                              </thead>
                          <tbody>
                          {                                                                                                                 
                              archBatchPayHistry.map(item=>{
                              return( 
                                  
                                  <>
                                  {
                                  (() => {
                                    if(((item.deliveryStatus == 'Complete') || (item.deliveryStatus == 'Cancel')) && ((item.hand_over_vendor != '0'))){
                                      if((item.paymentType == 'COD') && (item.deliveryStatus == 'Complete')){
                                        return (
                                          <tr key={item.id}>
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.waybill_id}                              
                                              <br/>{item.paymentType}
                                              <br/>{item.orderID ? (
                                                    <span>Order ID :  {item.orderID}</span>
                                                  ) : (
                                                    <></>
                                                  )
                                                  }
                                            </td>
                               
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.pickupFrom}</td>
      
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.deliveredDate}</td>
      
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.deliveryBy ? (
                                                    <span>Rider ID:  {item.deliveryBy}</span>
                                                  ) : (
                                                    <> - </>
                                                  )
                                                  }<br/>
                                                  {{...adminInfo.find(element => element.id == item.deliveryBy)}.fname} {{...adminInfo.find(element => element.id == item.deliveryBy)}.lname}
                                                  {
                                                      (() => {
                                                          if(item.paymentType == 'COD'){
                                                          if(item.codCollectFromRider == 'Complete'){
                                                              return (
                                                                  <></>                                                                                                                
                                                              )
                                                          }else{
                                                              return (
                                                                  <>
                                                                  <br/><p style={{color: 'red'}}>COD Collect From Rider - Pending</p>
                                                                  </>                                                                                                                
                                                              )
                                                          }
                                                                                                             
                                                          }else{
                                                              return (
                                                              <></>
                                                              )
                                                          }
                                                      })()  
                                                  }
                                            </td>
                                            
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                              (() => {
                                                  if(item.deliveryStatus == 'Complete'){
                                                    if(item.CODAmount){
                                                      var newCodAmount = item.CODAmount
                                                    }else{
                                                      var newCodAmount = 0
                                                    }
                                                    
                                                    totalCOD = (parseFloat(totalCOD) + parseFloat(newCodAmount)).toFixed(2);
                                                      return (
                                                          <>
                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmount).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else if(item.deliveryStatus == 'Cancel'){
                                                      return (
                                                          <>0.00</>
                                                      )  
                                                  }else{
                                                      return (
                                                        <>0.00</>
                                                      )
                                                  }
                                              })()  
                                            }
                                            </td> 
                               
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                              {
                                                  (() => {
                                                      if(item.deliveryStatus == 'Complete'){
                                                          if(item.deliveryFee){
                                                              var newDelAmount = item.deliveryFee
                                                          }else{
                                                              var newDelAmount = 0
                                                          }
      
                                                      totalDelivery = (parseFloat(newDelAmount) + parseFloat(totalDelivery)).toFixed(2);
                                                      
                                                          
                                                          return (
                                                              <>
                                                               {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmount).toFixed(2))}
                                                              </>                                                                                                                
                                                          )
                                                                                                          
                                                      }else{
                                                      return (
                                                          <> - </>
                                                      )
                                                      }
                                                  })()  
                                              }                                                                               
                                            </td> 
                               
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                              (() => {
                                                  if(item.deliveryStatus == 'Complete'){
      
                                                      if(item.CODAmount){
                                                          var newCodAmounto = item.CODAmount
                                                      }else{
                                                          var newCodAmounto = 0
                                                      }
      
                                                      if(item.deliveryFee){
                                                          var newDelAmounto = item.deliveryFee
                                                      }else{
                                                          var newDelAmounto = 0
                                                      }
                                                      
                                                      
                                                      var codafterDelivery = (parseFloat(newCodAmounto) - parseFloat(newDelAmounto)).toFixed(2);
                                                      
                                                      totalcodafterDelivery = (parseFloat(codafterDelivery) + parseFloat(totalcodafterDelivery)).toFixed(2);
      
                                                    
                                                      return (
                                                          <>
                                                           {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codafterDelivery).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            } 
                                            </td> 
                              
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}> 
      
                                            {
                                              (() => {
      
                                                  if(item.CODAmount){
                                                      var newCodAmountS = item.CODAmount
                                                  }else{
                                                      var newCodAmountS = 0
                                                  }
      
                                                  if(item.deliveryFee){
                                                          var newDelAmountS = item.deliveryFee
                                                  }else{
                                                          var newDelAmountS = 0
                                                  }
                                                      
                                                  if(batchPayHistry.cod_commission_limit){
                                                          var newcomLimit = batchPayHistry.cod_commission_limit
                                                  }else{
                                                          var newcomLimit = 0
                                                  }
      
                                                  var codafterDeliveryCOD = (parseFloat(newCodAmountS) - parseFloat(newDelAmountS)).toFixed(2);
      
                                                  if(codafterDeliveryCOD<=newcomLimit){ 
                                                  
                                                      if(batchPayHistry.cod_commission_limit_min){
                                                          var newcomLimitmin = batchPayHistry.cod_commission_limit_min
                                                      }else{
                                                          var newcomLimitmin = 0
                                                      }
      
                                                      var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmin))/100).toFixed(2);
                                                      codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                      
                                                      return (
                                                          <>
                                                          {batchPayHistry.cod_commission_limit_min ? (
                                                              <span>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcel).toFixed(2))}<br/>{batchPayHistry.cod_commission_limit_min} % Below</span>
                                                          ) : (
                                                              <> - </>
                                                          )
                                                          }
                                                          </>                                                                                                                
                                                      )
                                                    }else{
      
                                                      if(batchPayHistry.cod_commission_limit_max){
                                                          var newcomLimitmax = batchPayHistry.cod_commission_limit_max
                                                      }else{
                                                          var newcomLimitmax = 0
                                                      }
      
                                                      var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmax))/100).toFixed(2); 
                                                      codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                      
                                                      return (
                                                        <>
                                                        {batchPayHistry.cod_commission_limit_max ? (
                                                            <span>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcel).toFixed(2))}<br/>{batchPayHistry.cod_commission_limit_max} % Above</span>
                                                          ) : (
                                                            <> - </>
                                                          )
                                                        }
                                                        </>                                                                                                                
                                                      )
      
                                                    }
                                                   
                                              })()  
                                            }                                               
                                            </td>
                                                       
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                              {
                                                  (() => {
                                                      if(item.deliveryStatus == 'Cancel'){
      
                                                          if(item.deliveryFee){
                                                              var newDelAmountCa = item.deliveryFee
                                                          }else{
                                                              var newDelAmountCa = 0
                                                          }
      
                                                          if(batchPayHistry.cancelFee){
                                                              var newCancelFee = batchPayHistry.cancelFee
                                                          }else{
                                                              var newCancelFee = 0
                                                          }
      
                                                          var cancelFeeNew  = ((parseFloat(newDelAmountCa) * parseFloat(newCancelFee))/100).toFixed(2);  
                                                          
                                                          totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
      
                                                          return (
                                                              <>
                                                               {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                              </>                                                                                                                
                                                          )
                                                                                                          
                                                      }else{
                                                      return (
                                                          <> - </>
                                                      )
                                                      }
                                                  })()  
                                              }                                     
                                            </td>
                      
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                          
                                              {                                       
                                                      (() => {
                                                      
                                                          countn = countn+1
                                                          
                                                          if(item.deliveryStatus == 'Complete'){
      
                                                              if(item.deliveryFee){
                                                                  var newDelAmountDis = item.deliveryFee
                                                              }else{
                                                                  var newDelAmountDis = 0
                                                              }
      
                                                              if(batchPayHistry.discount){
                                                                  var newdiscFee = batchPayHistry.discount
                                                              }else{
                                                                  var newdiscFee = 0
                                                              }
      
                                                          var discountedAmount  = ((parseFloat(newDelAmountDis)/100) * parseFloat(newdiscFee)).toFixed(2); 
                                                           
                                                      
                                                          totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                  
                                                          return (
                                                                  <>
                                                                   {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                  </>                                                                                                                
                                                              )
                                                                                                              
                                                          }else if(item.deliveryStatus == 'Cancel'){
      
                                                              if(item.deliveryFee){
                                                                  var newDelAmountDisO = item.deliveryFee
                                                              }else{
                                                                  var newDelAmountDisO = 0
                                                              }
      
                                                              if(batchPayHistry.discount){
                                                                  var newdiscFeeO = batchPayHistry.discount
                                                              }else{
                                                                  var newdiscFeeO = 0
                                                              }
      
                                                              if(batchPayHistry.cancelFee){
                                                                  var newCancelFeeO = batchPayHistry.cancelFee
                                                              }else{
                                                                  var newCancelFeeO = 0
                                                              }
      
                                                            var cancelFeeNew      = ((parseFloat(newDelAmountDisO) * parseFloat(newCancelFeeO))/100).toFixed(2); 
                                                            var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeO)).toFixed(2);
                                                            
                                                            totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                          return (
                                                              <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                          )
                                                          }else{
                                                          
                                                          return (
                                                          <>0.00</>
                                                          )
                                                          }
                                                      })()  
                                              }
                                              
                                            </td>                                                               
                                          </tr>                                                                                                               
                                        )
                                      }else if((item.deliveryStatus == 'Cancel') && (item.paymentType == 'COD')){
                                        return (
                                          <tr key={item.id}>
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.waybill_id}<br/>{item.paymentType}</td>
                               
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.pickupFrom}</td> 
                              
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.deliveredDate}</td>
      
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                              {item.deliveryBy ? (
                                                    <span>Rider ID:  {item.deliveryBy}</span>
                                                  ) : (
                                                    <> - </>
                                                  )
                                              }<br/>
                                              {{...adminInfo.find(element => element.id == item.deliveryBy)}.fname} {{...adminInfo.find(element => element.id == item.deliveryBy)}.lname}
                                            </td>
      
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                              (() => {
                                                  if(item.deliveryStatus == 'Complete'){
                                                    if(item.CODAmount){
                                                      var newCodAmountTh = item.CODAmount
                                                    }else{
                                                      var newCodAmountTh = 0
                                                    }
      
                                                    totalCOD = (parseFloat(newCodAmountTh) + parseFloat(totalCOD)).toFixed(2); 
                                                     
                                                      return (
                                                          <>
                                                           {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountTh).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else if(item.deliveryStatus == 'Cancel'){
                                                      return (
                                                          <>0.00</>
                                                      )  
                                                  }else{
                                                    return (
                                                      <>0.00</>
                                                  )
                                                  }
                                              })()  
                                            }  
                                              
                                            </td> 
                              
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                              (() => {
                                                  if(item.deliveryStatus == 'Complete'){
      
                                                      if(item.deliveryFee){
                                                          var newDelAmountTh = item.deliveryFee
                                                      }else{
                                                          var newDelAmountTh = 0
                                                      }
      
                                                      totalDelivery = (parseFloat(newDelAmountTh) + parseFloat(totalDelivery)).toFixed(2);
                                                    
                                                      return (
                                                          <>
                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountTh).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            }  
                                              
                                            </td> 
       
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                              (() => {
                                                  if(item.deliveryStatus == 'Complete'){
      
                                                      if(item.deliveryFee){
                                                          var newDelAmountM = item.deliveryFee
                                                      }else{
                                                          var newDelAmountM = 0
                                                      }
      
                                                      if(item.CODAmount){
                                                          var newCodAmountM = item.CODAmount
                                                      }else{
                                                          var newCodAmountM = 0
                                                      }
      
                                                      var codafterDelivery = (parseFloat(newCodAmountM) - parseFloat(newDelAmountM)).toFixed(2);
                                                      totalcodafterDelivery = (parseFloat(codafterDelivery) + parseFloat(totalcodafterDelivery)).toFixed(2);
                                                      return (
                                                          <>
                                                           {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codafterDelivery).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            } 
                                            </td>
                              
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                              -  
                                            </td>
                               
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                              (() => {
                                                    if(item.deliveryStatus == 'Cancel'){
      
                                                      if(item.deliveryFee){
                                                          var newDelAmountCa = item.deliveryFee
                                                      }else{
                                                          var newDelAmountCa = 0
                                                      }
      
                                                      if(batchPayHistry.cancelFee){
                                                          var newCancelFee = batchPayHistry.cancelFee
                                                      }else{
                                                          var newCancelFee = 0
                                                      }
      
                                                      var cancelFeeNew  = ((parseFloat(newDelAmountCa) * parseFloat(newCancelFee))/100).toFixed(2);  
                                                  
                                                      totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
      
                                                      return (
                                                          <>
                                                         {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            }
                                                  
                                            </td>
                               
                        
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                              
                                              (() => {
                                                
                                                countn = countn+1
                                                
                                                  if(item.deliveryStatus == 'Complete'){
      
                                                      if(item.deliveryFee){
                                                          var newDelAmountDis = item.deliveryFee
                                                      }else{
                                                          var newDelAmountDis = 0
                                                      }
      
                                                      if(batchPayHistry.discount){
                                                          var newdiscFee = batchPayHistry.discount
                                                      }else{
                                                          var newdiscFee = 0
                                                      }
      
                                                      var discountedAmount  = ((parseFloat(newDelAmountDis)/100) * parseFloat(newdiscFee)).toFixed(2);  
                                                      
                                                  
                                                      totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                      return (
                                                              <>
                                                                 {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                              </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else if(item.deliveryStatus == 'Cancel'){
      
                                                      if(item.deliveryFee){
                                                          var newDelAmountDisO = item.deliveryFee
                                                      }else{
                                                          var newDelAmountDisO = 0
                                                      }
      
                                                      if(batchPayHistry.discount){
                                                          var newdiscFeeO = batchPayHistry.discount
                                                      }else{
                                                          var newdiscFeeO = 0
                                                      }
      
                                                      if(batchPayHistry.cancelFee){
                                                          var newCancelFeeO = batchPayHistry.cancelFee
                                                      }else{
                                                          var newCancelFeeO = 0
                                                      }
      
                                                      var cancelFeeNew      = ((parseFloat(newDelAmountDisO) * parseFloat(newCancelFeeO))/100).toFixed(2);
                                                      var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeO)).toFixed(2);
                                                      
                                                      totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                      return (
                                                          <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                      )
                                                  }else{
                                                    return (
                                                    <>0.00</>
                                                    )
                                                  }
                                              })()  
                                            }
                                          
                                            </td>
                                          </tr>                                                                                                                
                                        )
                                      }else if(item.paymentType != 'COD'){
                                        return (
                                          <tr key={item.id}>
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.waybill_id}<br/>{item.paymentType}</td>
                               
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.pickupFrom}</td>
      
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>{item.deliveredDate}</td>
      
                                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                                  {item.deliveryBy ? (
                                                    <span>Rider ID:  {item.deliveryBy}</span>
                                                  ) : (
                                                    <> - </>
                                                  )
                                                  }<br/>
                                                  {{...adminInfo.find(element => element.id == item.deliveryBy)}.fname} {{...adminInfo.find(element => element.id == item.deliveryBy)}.lname}
                                            </td> 
                 
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                              {
                                                (() => {
                                                    if(item.deliveryStatus == 'Complete'){
                                                      if(item.CODAmount){
                                                        var newCodAmountF = item.CODAmount
                                                      }else{
                                                        var newCodAmountF = 0
                                                      }
      
                                                      totalCOD = (parseFloat(newCodAmountF) + parseFloat(totalCOD)).toFixed(2);
                                                       
                                                        return (
                                                            <>
                                                           {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountF).toFixed(2))}
                                                            </>                                                                                                                
                                                        )
                                                                                                            
                                                    }else if(item.deliveryStatus == 'Cancel'){
                                                        return (
                                                            <>0.00</>
                                                        )  
                                                    }else{
                                                      return (
                                                        <>0.00</>
                                                    )
                                                    }
                                                })()  
                                              }  
                                            </td> 
                 
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                              (() => {
                                                  if(item.deliveryStatus == 'Complete'){
                                                  
                                                      if(item.deliveryFee){
                                                          var newDelAmountThn = item.deliveryFee
                                                      }else{
                                                          var newDelAmountThn = 0
                                                      }
      
                                                      totalDelivery = (parseFloat(newDelAmountThn) + parseFloat(totalDelivery)).toFixed(2);
      
                                                      return (
                                                          <>
                                                           {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountThn).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                          
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            }  
                                              
                                            </td> 
      
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            -
                                            </td>
                              
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                              -  
                                            </td>
                  
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                              (() => {
                                                    if(item.deliveryStatus == 'Cancel'){
      
                                                      if(item.deliveryFee){
                                                          var newDelAmountCaTh = item.deliveryFee
                                                      }else{
                                                          var newDelAmountCaTh = 0
                                                      }
      
                                                      if(batchPayHistry.cancelFee){
                                                          var newCancelFeeTh = batchPayHistry.cancelFee
                                                      }else{
                                                          var newCancelFeeTh = 0
                                                      }
      
                                                      var cancelFeeNew  = ((parseFloat(newDelAmountCaTh) * parseFloat(newCancelFeeTh))/100).toFixed(2); 
                                                      
                                                      totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
      
                                                      return (
                                                          <>
                                                         {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                          
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            }
                                                  
                                            </td>            
           
                                            <td style={{textAlign: 'right', border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}}>
                                            {
                                             
                                              (() => {
                                                countn = countn+1
                                                
                                                    if(item.deliveryStatus == 'Complete'){
      
                                                      if(item.deliveryFee){
                                                          var newDelAmountDisTh = item.deliveryFee
                                                      }else{
                                                          var newDelAmountDisTh = 0
                                                      }
      
                                                      if(batchPayHistry.discount){
                                                          var newdiscFeeTh = batchPayHistry.discount
                                                      }else{
                                                          var newdiscFeeTh = 0
                                                      }
      
                                                      var discountedAmount  = ((parseFloat(newDelAmountDisTh)/100) * parseFloat(newdiscFeeTh)).toFixed(2); 
                                                      totalDiscount         = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                    return (
                                                          <>
                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                          
                                                  }else if(item.deliveryStatus == 'Cancel'){
      
                                                      if(item.deliveryFee){
                                                          var newDelAmountDisOS = item.deliveryFee
                                                      }else{
                                                          var newDelAmountDisOS = 0
                                                      }
      
                                                      if(batchPayHistry.discount){
                                                          var newdiscFeeOS = batchPayHistry.discount
                                                      }else{
                                                          var newdiscFeeOS = 0
                                                      }
      
                                                      if(batchPayHistry.cancelFee){
                                                          var newCancelFeeOS = batchPayHistry.cancelFee
                                                      }else{
                                                          var newCancelFeeOS = 0
                                                      }
      
                                                      var cancelFeeNew = ((parseFloat(newDelAmountDisOS) * parseFloat(newCancelFeeOS))/100).toFixed(2); 
                                                      var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOS)).toFixed(2); 
                                                      
                                                      totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                      return (
                                                          <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                      )
                                                  }else{
                                                    return (
                                                    <>0.00</>
                                                    )
                                                  }
                                              })()  
                                            }                                   
                                            </td>
                                          </tr>                                                                                                               
                                        )
                                      }
                                                                                                                                                  
                                    }
      
                                  })()  
                                  }                            
                                  </>                       
                              )                                    
                              })
                          }
      
                          {                                                                                                                 
                              pBatchPayHistry.map(items=>{
                              return(                             
                                  <> 
                                  {
                                  (() => {
                                    if(((items.deliveryStatus == 'Complete') || (items.deliveryStatus == 'Cancel')) && ((items.hand_over_vendor != '0'))){
                                      if((items.paymentType == 'COD') && (items.deliveryStatus == 'Complete')){
                                        return (
                                          <tr key={items.id}>
                                            <td>{items.waybill_id}                              
                                              <br/>{items.paymentType}
                                              <br/>{items.orderID ? (
                                                    <span>Order ID :  {items.orderID}</span>
                                                  ) : (
                                                    <></>
                                                  )
                                                  }
                                            </td>
                               
                                            <td>{items.pickupFrom}</td>
      
                                            <td>{items.deliveredDate}</td>
      
                                            <td>{items.deliveryBy ? (
                                                    <span>Rider ID:  {items.deliveryBy}</span>
                                                  ) : (
                                                    <> - </>
                                                  )
                                                  }<br/>
                                                  {{...adminInfo.find(element => element.id == items.deliveryBy)}.fname} {{...adminInfo.find(element => element.id == items.deliveryBy)}.lname}
                                                  {
                                                      (() => {
                                                          if(items.paymentType == 'COD'){
                                                          if(items.codCollectFromRider == 'Complete'){
                                                              return (
                                                                  <></>                                                                                                                
                                                              )
                                                          }else{
                                                              return (
                                                                  <>
                                                                  <br/><p style={{color: 'red'}}>COD Collect From Rider - Pending</p>
                                                                  </>                                                                                                                
                                                              )
                                                          }
                                                                                                             
                                                          }else{
                                                              return (
                                                              <></>
                                                              )
                                                          }
                                                      })()  
                                                  }
                                            </td>
                                            
                                            <td style={{textAlign: 'right'}}>
                                            {
                                              (() => {
                                                  if(items.deliveryStatus == 'Complete'){
                                                    if(items.CODAmount){
                                                      var newCodAmount = items.CODAmount
                                                    }else{
                                                      var newCodAmount = 0
                                                    }
                                                    
                                                    totalCOD = (parseFloat(totalCOD) + parseFloat(newCodAmount)).toFixed(2);
                                                      return (
                                                          <>
                                                         {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmount).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else if(items.deliveryStatus == 'Cancel'){
                                                      return (
                                                          <>0.00</>
                                                      )  
                                                  }else{
                                                      return (
                                                        <>0.00</>
                                                      )
                                                  }
                                              })()  
                                            }
                                            </td> 
                               
                                            <td style={{textAlign: 'right'}}>
                                              {
                                                  (() => {
                                                      if(items.deliveryStatus == 'Complete'){
                                                          if(items.deliveryFee){
                                                              var newDelAmount = items.deliveryFee
                                                          }else{
                                                              var newDelAmount = 0
                                                          }
      
                                                      totalDelivery = (parseFloat(newDelAmount) + parseFloat(totalDelivery)).toFixed(2);
                                                      
                                                          
                                                          return (
                                                              <>
                                                               {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmount).toFixed(2))}
                                                              </>                                                                                                                
                                                          )
                                                                                                          
                                                      }else{
                                                      return (
                                                          <> - </>
                                                      )
                                                      }
                                                  })()  
                                              }                                                                               
                                            </td> 
                               
                                            <td style={{textAlign: 'right'}}>
                                            {
                                              (() => {
                                                  if(items.deliveryStatus == 'Complete'){
      
                                                      if(items.CODAmount){
                                                          var newCodAmounto = items.CODAmount
                                                      }else{
                                                          var newCodAmounto = 0
                                                      }
      
                                                      if(items.deliveryFee){
                                                          var newDelAmounto = items.deliveryFee
                                                      }else{
                                                          var newDelAmounto = 0
                                                      }
                                                      
                                                      
                                                      var codafterDelivery = (parseFloat(newCodAmounto) - parseFloat(newDelAmounto)).toFixed(2);
                                                      
                                                      totalcodafterDelivery = (parseFloat(codafterDelivery) + parseFloat(totalcodafterDelivery)).toFixed(2);
      
                                                    
                                                      return (
                                                          <>
                                                           {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codafterDelivery).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            } 
                                            </td> 
                              
                                            <td style={{textAlign: 'right'}}> 
      
                                            {
                                              (() => {
      
                                                  if(items.CODAmount){
                                                      var newCodAmountS = items.CODAmount
                                                  }else{
                                                      var newCodAmountS = 0
                                                  }
      
                                                  if(items.deliveryFee){
                                                          var newDelAmountS = items.deliveryFee
                                                  }else{
                                                          var newDelAmountS = 0
                                                  }
                                                      
                                                  if(batchPayHistry.cod_commission_limit){
                                                          var newcomLimit = batchPayHistry.cod_commission_limit
                                                  }else{
                                                          var newcomLimit = 0
                                                  }
      
                                                  var codafterDeliveryCOD = (parseFloat(newCodAmountS) - parseFloat(newDelAmountS)).toFixed(2);
                                                  
      
                                                  if(codafterDeliveryCOD<=newcomLimit){ 
                                                  
                                                      if(batchPayHistry.cod_commission_limit_min){
                                                          var newcomLimitmin = batchPayHistry.cod_commission_limit_min
                                                      }else{
                                                          var newcomLimitmin = 0
                                                      }
      
                                                      var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmin))/100).toFixed(2);
                                                      codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                                                                    
                                                      
                                                      
                                                      return (
                                                          <>
                                                          {batchPayHistry.cod_commission_limit_min ? (
                                                              <span>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcel).toFixed(2))}<br/>{batchPayHistry.cod_commission_limit_min} % Below</span>
                                                          ) : (
                                                              <> - </>
                                                          )
                                                          }
                                                          </>                                                                                                                
                                                      )
                                                    }else{
      
                                                      if(batchPayHistry.cod_commission_limit_max){
                                                          var newcomLimitmax = batchPayHistry.cod_commission_limit_max
                                                      }else{
                                                          var newcomLimitmax = 0
                                                      }
      
                                                      var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmax))/100).toFixed(2); 
                                                      codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                      
                                                      return (
                                                        <>
                                                        {batchPayHistry.cod_commission_limit_max ? (
                                                            <span>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcel).toFixed(2))}<br/>{batchPayHistry.cod_commission_limit_max} % Above</span>
                                                          ) : (
                                                            <> - </>
                                                          )
                                                        }
                                                        </>                                                                                                                
                                                      )
      
                                                    }
                                                   
                                              })()  
                                            }                                               
                                            </td>
                                                       
                                            <td style={{textAlign: 'right'}}>
                                              {
                                                  (() => {
                                                      if(items.deliveryStatus == 'Cancel'){
      
                                                          if(items.deliveryFee){
                                                              var newDelAmountCa = items.deliveryFee
                                                          }else{
                                                              var newDelAmountCa = 0
                                                          }
      
                                                          if(batchPayHistry.cancelFee){
                                                              var newCancelFee = batchPayHistry.cancelFee
                                                          }else{
                                                              var newCancelFee = 0
                                                          }
      
                                                          
                                                          var cancelFeeNew  = ((parseFloat(newDelAmountCa) * parseFloat(newCancelFee))/100).toFixed(2); 
                                                      
                                                          totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
      
                                                          return (
                                                              <>
                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                              </>                                                                                                                
                                                          )
                                                                                                          
                                                      }else{
                                                      return (
                                                          <> - </>
                                                      )
                                                      }
                                                  })()  
                                              }                                     
                                            </td>
                      
                                            <td style={{textAlign: 'right'}}>
                                          
                                              {                                       
                                                      (() => {
                                                      
                                                          countn = countn+1
                                                          
                                                          if(items.deliveryStatus == 'Complete'){
      
                                                              if(items.deliveryFee){
                                                                  var newDelAmountDis = items.deliveryFee
                                                              }else{
                                                                  var newDelAmountDis = 0
                                                              }
      
                                                              if(batchPayHistry.discount){
                                                                  var newdiscFee = batchPayHistry.discount
                                                              }else{
                                                                  var newdiscFee = 0
                                                              }
      
                                                          var discountedAmount  = ((parseFloat(newDelAmountDis)/100) * parseFloat(newdiscFee)).toFixed(2); 
                                                          
                                                      
                                                          totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                  
                                                          return (
                                                                  <>
                                                                 {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                  </>                                                                                                                
                                                              )
                                                                                                              
                                                          }else if(items.deliveryStatus == 'Cancel'){
      
                                                              if(items.deliveryFee){
                                                                  var newDelAmountDisO = items.deliveryFee
                                                              }else{
                                                                  var newDelAmountDisO = 0
                                                              }
      
                                                              if(batchPayHistry.discount){
                                                                  var newdiscFeeO = batchPayHistry.discount
                                                              }else{
                                                                  var newdiscFeeO = 0
                                                              }
      
                                                              if(batchPayHistry.cancelFee){
                                                                  var newCancelFeeO = batchPayHistry.cancelFee
                                                              }else{
                                                                  var newCancelFeeO = 0
                                                              }
      
                                                          var cancelFeeNew      = ((parseFloat(newDelAmountDisO) * parseFloat(newCancelFeeO))/100).toFixed(2); 
                                                          var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeO)).toFixed(2); 
                                                          
      
                                                          totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                          return (
                                                              <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                          )
                                                          }else{
                                                          
                                                          return (
                                                          <>0.00</>
                                                          )
                                                          }
                                                      })()  
                                              }
                                              
                                            </td>                                                               
                                          </tr>                                                                                                               
                                        )
                                      }else if((items.deliveryStatus == 'Cancel') && (items.paymentType == 'COD')){
                                        return (
                                          <tr key={items.id}>
                                            <td>{items.waybill_id}<br/>{items.paymentType}</td>
                               
                                            <td>{items.pickupFrom}</td> 
                              
                                            <td>{items.deliveredDate}</td>
      
                                            <td>
                                              {items.deliveryBy ? (
                                                    <span>Rider ID:  {items.deliveryBy}</span>
                                                  ) : (
                                                    <> - </>
                                                  )
                                              }<br/>
                                              {{...adminInfo.find(element => element.id == items.deliveryBy)}.fname} {{...adminInfo.find(element => element.id == items.deliveryBy)}.lname}
                                            </td>
      
                                            <td style={{textAlign: 'right'}}>
                                            {
                                              (() => {
                                                  if(items.deliveryStatus == 'Complete'){
                                                    if(items.CODAmount){
                                                      var newCodAmountTh = items.CODAmount
                                                    }else{
                                                      var newCodAmountTh = 0
                                                    }
      
                                                    totalCOD = (parseFloat(newCodAmountTh) + parseFloat(totalCOD)).toFixed(2); 
                                                     
                                                      return (
                                                          <>
                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountTh).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else if(items.deliveryStatus == 'Cancel'){
                                                      return (
                                                          <>0.00</>
                                                      )  
                                                  }else{
                                                    return (
                                                      <>0.00</>
                                                  )
                                                  }
                                              })()  
                                            }  
                                              
                                            </td> 
                              
                                            <td style={{textAlign: 'right'}}>
                                            {
                                              (() => {
                                                  if(items.deliveryStatus == 'Complete'){
      
                                                      if(items.deliveryFee){
                                                          var newDelAmountTh = items.deliveryFee
                                                      }else{
                                                          var newDelAmountTh = 0
                                                      }
      
                                                      totalDelivery = (parseFloat(newDelAmountTh) + parseFloat(totalDelivery)).toFixed(2);
                                                    
                                                      return (
                                                          <>
                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountTh).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            }  
                                              
                                            </td> 
       
                                            <td style={{textAlign: 'right'}}>
                                            {
                                              (() => {
                                                  if(items.deliveryStatus == 'Complete'){
      
                                                      if(items.deliveryFee){
                                                          var newDelAmountM = items.deliveryFee
                                                      }else{
                                                          var newDelAmountM = 0
                                                      }
      
                                                      if(items.CODAmount){
                                                          var newCodAmountM = items.CODAmount
                                                      }else{
                                                          var newCodAmountM = 0
                                                      }
      
                                                      var codafterDelivery = (parseFloat(newCodAmountM) - parseFloat(newDelAmountM)).toFixed(2);
                                                      totalcodafterDelivery = (parseFloat(codafterDelivery) + parseFloat(totalcodafterDelivery)).toFixed(2);
                                                      return (
                                                          <>
                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codafterDelivery).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            } 
                                            </td>
                              
                                            <td style={{textAlign: 'right'}}>
                                              -  
                                            </td>
                               
                                            <td style={{textAlign: 'right'}}>
                                            {
                                              (() => {
                                                    if(items.deliveryStatus == 'Cancel'){
      
                                                      if(items.deliveryFee){
                                                          var newDelAmountCa = items.deliveryFee
                                                      }else{
                                                          var newDelAmountCa = 0
                                                      }
      
                                                      if(batchPayHistry.cancelFee){
                                                          var newCancelFee = batchPayHistry.cancelFee
                                                      }else{
                                                          var newCancelFee = 0
                                                      }
      
                                                       
                                                      var cancelFeeNew  = ((parseFloat(newDelAmountCa) * parseFloat(newCancelFee))/100).toFixed(2); 
                                                      totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
      
                                                      return (
                                                          <>
                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            }
                                                  
                                            </td>
                               
                        
                                            <td style={{textAlign: 'right'}}>
                                            {
                                              
                                              (() => {
                                                
                                                countn = countn+1
                                                
                                                  if(items.deliveryStatus == 'Complete'){
      
                                                      if(items.deliveryFee){
                                                          var newDelAmountDis = items.deliveryFee
                                                      }else{
                                                          var newDelAmountDis = 0
                                                      }
      
                                                      if(batchPayHistry.discount){
                                                          var newdiscFee = batchPayHistry.discount
                                                      }else{
                                                          var newdiscFee = 0
                                                      }
      
                                                      
                                                      var discountedAmount  = ((parseFloat(newDelAmountDis)/100) * parseFloat(newdiscFee)).toFixed(2);  
                                                      totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                      return (
                                                              <>
                                                              {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                              </>                                                                                                                
                                                      )
                                                                                                         
                                                  }else if(items.deliveryStatus == 'Cancel'){
      
                                                      if(items.deliveryFee){
                                                          var newDelAmountDisO = items.deliveryFee
                                                      }else{
                                                          var newDelAmountDisO = 0
                                                      }
      
                                                      if(batchPayHistry.discount){
                                                          var newdiscFeeO = batchPayHistry.discount
                                                      }else{
                                                          var newdiscFeeO = 0
                                                      }
      
                                                      if(batchPayHistry.cancelFee){
                                                          var newCancelFeeO = batchPayHistry.cancelFee
                                                      }else{
                                                          var newCancelFeeO = 0
                                                      }
      
                                                      var cancelFeeNew      = ((parseFloat(newDelAmountDisO) * parseFloat(newCancelFeeO))/100).toFixed(2);
                                                      var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeO)).toFixed(2); 
                                                      
                                                      totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                      return (
                                                          <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                      )
                                                  }else{
                                                    return (
                                                    <>0.00</>
                                                    )
                                                  }
                                              })()  
                                            }
                                          
                                            </td>
                                          </tr>                                                                                                                
                                        )
                                      }else if(items.paymentType != 'COD'){
                                        return (
                                          <tr key={items.id}>
                                            <td>{items.waybill_id}<br/>{items.paymentType}</td>
                               
                                            <td>{items.pickupFrom}</td>
      
                                            <td>{items.deliveredDate}</td>
      
                                            <td>
                                                  {items.deliveryBy ? (
                                                    <span>Rider ID:  {items.deliveryBy}</span>
                                                  ) : (
                                                    <> - </>
                                                  )
                                                  }<br/>
                                                  {{...adminInfo.find(element => element.id == items.deliveryBy)}.fname} {{...adminInfo.find(element => element.id == items.deliveryBy)}.lname}
                                            </td> 
                 
                                            <td style={{textAlign: 'right'}}>
                                              {
                                                (() => {
                                                    if(items.deliveryStatus == 'Complete'){
                                                      if(items.CODAmount){
                                                        var newCodAmountF = items.CODAmount
                                                      }else{
                                                        var newCodAmountF = 0
                                                      }
      
                                                      totalCOD = (parseFloat(newCodAmountF) + parseFloat(totalCOD)).toFixed(2);
                                                       
                                                        return (
                                                            <>
                                                           {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountF).toFixed(2))}
                                                            </>                                                                                                                
                                                        )
                                                                                                            
                                                    }else if(items.deliveryStatus == 'Cancel'){
                                                        return (
                                                            <>0.00</>
                                                        )  
                                                    }else{
                                                      return (
                                                        <>0.00</>
                                                    )
                                                    }
                                                })()  
                                              }  
                                            </td> 
                 
                                            <td style={{textAlign: 'right'}}>
                                            {
                                              (() => {
                                                  if(items.deliveryStatus == 'Complete'){
                                                  
                                                      if(items.deliveryFee){
                                                          var newDelAmountThn = items.deliveryFee
                                                      }else{
                                                          var newDelAmountThn = 0
                                                      }
      
                                                      totalDelivery = (parseFloat(newDelAmountThn) + parseFloat(totalDelivery)).toFixed(2);
      
                                                      return (
                                                          <>
                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountThn).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                          
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            }  
                                              
                                            </td> 
      
                                            <td style={{textAlign: 'right'}}>
                                            -
                                            </td>
                              
                                            <td style={{textAlign: 'right'}}>
                                              -  
                                            </td>
                  
                                            <td style={{textAlign: 'right'}}>
                                            {
                                              (() => {
                                                    if(items.deliveryStatus == 'Cancel'){
      
                                                      if(items.deliveryFee){
                                                          var newDelAmountCaTh = items.deliveryFee
                                                      }else{
                                                          var newDelAmountCaTh = 0
                                                      }
      
                                                      if(batchPayHistry.cancelFee){
                                                          var newCancelFeeTh = batchPayHistry.cancelFee
                                                      }else{
                                                          var newCancelFeeTh = 0
                                                      }
      
                                                      var cancelFeeNew  = ((parseFloat(newDelAmountCaTh) * parseFloat(newCancelFeeTh))/100).toFixed(2); 
                                                      totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
      
                                                      return (
                                                          <>
                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                          
                                                  }else{
                                                    return (
                                                      <> - </>
                                                  )
                                                  }
                                              })()  
                                            }
                                                  
                                            </td>            
           
                                            <td style={{textAlign: 'right'}}>
                                            {
                                             
                                              (() => {
                                                countn = countn+1
                                                
                                                    if(items.deliveryStatus == 'Complete'){
      
                                                      if(items.deliveryFee){
                                                          var newDelAmountDisTh = items.deliveryFee
                                                      }else{
                                                          var newDelAmountDisTh = 0
                                                      }
      
                                                      if(batchPayHistry.discount){
                                                          var newdiscFeeTh = batchPayHistry.discount
                                                      }else{
                                                          var newdiscFeeTh = 0
                                                      }
                                                      
                                                      var discountedAmount  = ((parseFloat(newDelAmountDisTh)/100) * parseFloat(newdiscFeeTh)).toFixed(2); 
                                                      
                                                      totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                    return (
                                                          <>
                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                          </>                                                                                                                
                                                      )
                                                                                                          
                                                  }else if(items.deliveryStatus == 'Cancel'){
      
                                                      if(items.deliveryFee){
                                                          var newDelAmountDisOS = items.deliveryFee
                                                      }else{
                                                          var newDelAmountDisOS = 0
                                                      }
      
                                                      if(batchPayHistry.discount){
                                                          var newdiscFeeOS = batchPayHistry.discount
                                                      }else{
                                                          var newdiscFeeOS = 0
                                                      }
      
                                                      if(batchPayHistry.cancelFee){
                                                          var newCancelFeeOS = batchPayHistry.cancelFee
                                                      }else{
                                                          var newCancelFeeOS = 0
                                                      }
                                                      
                                                      var cancelFeeNew = ((parseFloat(newDelAmountDisOS) * parseFloat(newCancelFeeOS))/100).toFixed(2); 
                                                      var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOS)).toFixed(2); 
      
                                                      
                                                      
                                                      totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
      
                                                      return (
                                                          <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                      )
                                                  }else{
                                                    return (
                                                    <>0.00</>
                                                    )
                                                  }
                                              })()  
                                            }                                   
                                            </td>
                                          </tr>                                                                                                               
                                        )
                                      }
                                                                                                                                                  
                                    }
      
                                  })()  
                                  }                            
                                  </>                       
                              )                                    
                              })
                          }
      
                              <tr style={{fontWeight: '500'}}>
      
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>Total</td>
                                      <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCOD).toFixed(2))}</td>
                                      <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalDelivery).toFixed(2))}</td>
                                      <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalcodafterDelivery).toFixed(2))}</td>
                                      <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcelTotal).toFixed(2))}</td>
                                      <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCancel).toFixed(2))} 
                                      
                                          {
                                              (() => {
      
                                                  var sumReturnNDelivFee      = (parseFloat(totalDelivery) + parseFloat(totalCancel)).toFixed(2);
                                                  var finalsumReturnNDelivFee = (parseFloat(sumReturnNDelivFee) - parseFloat(totalDiscount)).toFixed(2);
                                                  
                                                  newPayAmount     = (parseFloat(totalCOD) - parseFloat(finalsumReturnNDelivFee)-parseFloat(codCommisstionParcelTotal)).toFixed(2);                                 
                                                  
                                                                              
                                                  if(batchPayHistry.creditBlance != '0'){
                                                      
                                                      newPayAmountNew      = (parseFloat(newPayAmount) + parseFloat(batchPayHistry.creditBlance)).toFixed(2);
                                                  }else{
                                                      newPayAmountNew = newPayAmount;
                                                  }
                                                                             
                                              })()  
                                          }
                                      </td>
                                      <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>
                                      {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalDiscount).toFixed(2))}
                                      </td>
                              </tr> 
      
                              <tr style={{fontWeight: '800'}}>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{border: '1px solid #ffffff'}}></td>
                                      <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                          {
                                              (() => {
                                                                          
                                                  if(newPayAmountNew< 0){
                                                      return(<>Sub Due Amount</>)
                                                                              
                                                  }else{
                                                      return(<>Sub Pay Amount</>)
                                                  }
                                                                              
                                              })()  
                                          }
                                      </td>
                                      <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>
                                      {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newPayAmountNew).toFixed(2))}
                                      </td>
                              </tr>  
      
                                                                  {
                                                                      (() => {
                                                                      
                                                                      if(batchPayHistry.creditBlance == '0'){
                                                                      
                                                                          if(batchPayHistry.amount){
                                                                              var newpAmount = batchPayHistry.amount
                                                                          }else{
                                                                              var newpAmount = 0
                                                                          }
                                                                          
                                                                          
                                                                          var TotalCredit = (parseFloat(newPayAmountNew) - parseFloat(newpAmount)).toFixed(2);  
                                                                          
                                                                          return(
                                                                          <tr style={{fontWeight: '800'}}>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                          Credit Balance
                                                                          </td>                                                                    
                                                                          <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>
                                                                          {
                                                                              (() => {
                                                                              if(TotalCredit == '0.00'){
                                                                                  return(
                                                                                  <>
                                                                                  {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(TotalCredit).toFixed(2))}
                                                                                  </>
                                                                                  )
                                                                              }else{
                                                                              return(<>- {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(TotalCredit).toFixed(2))}</>)
                                                                              }
                                                                              })()  
                                                                          }
      
                                                                          
                                                                          </td>
                                                                          </tr>
                                                                      )
                                                                          
                                                                      }else{
                                                                          return(
                                                                          <tr style={{fontWeight: '800'}}>
                                                                              <td style={{border: '1px solid #ffffff'}}></td>
                                                                              <td style={{border: '1px solid #ffffff'}}></td>
                                                                              <td style={{border: '1px solid #ffffff'}}></td>
                                                                              <td style={{border: '1px solid #ffffff'}}></td>
                                                                              <td style={{border: '1px solid #ffffff'}}></td>
                                                                              <td style={{border: '1px solid #ffffff'}}></td>
                                                                              <td style={{border: '1px solid #ffffff'}}></td>
                                                                              <td style={{border: '1px solid #ffffff'}}></td>
                                                                              <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                              Credit Balance
                                                                              </td>
                                                                              <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>
                                                                              
                                                                              {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(batchPayHistry.creditBlance).toFixed(2))}
                                                                              </td>
                                                                          </tr>
                                                                      
                                                                          )
                                                                      }
                                                                          
                                                                      })()  
                                                                  }
      
                                                                  {
                                                                      (() => {
                                                                      
                                                                      if(batchPayHistry.creditBlance == '0'){
                                                                      
                                                                          if(batchPayHistry.amount){
                                                                              var newpAmountb = batchPayHistry.amount
                                                                          }else{
                                                                              var newpAmountb = 0
                                                                          }
                                                                          return(
                                                                          <tr style={{fontWeight: '800'}}>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}> 
                                                                          {
                                                                              (() => {
                                                                              if(newPayAmount < 0){
                                                                                  return(
                                                                                  <>Due Amount</>
                                                                                  )
                                                                              }else{
                                                                                  return(
                                                                                  <>Pay Amount</>
                                                                                  )
                                                                              }
                                                                              })()  
                                                                          }
                                                                          </td>
                                                                          <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>
                                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newpAmountb).toFixed(2))}
                                                                          
                                                                          </td>
                                                                          </tr>
                                                                      )
                                                                          
                                                                      }else{
                                                                          if(batchPayHistry.amount){
                                                                              var newpAmountb = batchPayHistry.amount
                                                                          }else{
                                                                              var newpAmountb = 0
                                                                          }
                                                                          return(
                                                                          <tr style={{fontWeight: '800'}}>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{border: '1px solid #ffffff'}}></td>
                                                                          <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                          {
                                                                              (() => {
                                                                              if(newPayAmount < 0){
                                                                                  return(
                                                                                  <>Due Amount</>
                                                                                  )
                                                                              }else{
                                                                                  return(
                                                                                  <>Pay Amount</>
                                                                                  )
                                                                              }
                                                                              })()  
                                                                          }
                                                                          </td>
                                                                          <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>
                                                                          {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newpAmountb).toFixed(2))}
                                                                         </td>
                                          
                                                                      </tr>
                                                                      
                                                                      )
                                                                      }
                                                                          
                                                                      })()  
                                                                  }
                          </tbody>
                          </table>
      
      
                          <h4 style={{margin:'10px'}}>
                                          {
                                              (() => {
                                                                          
                                                  if(batchPayHistry.note){
                                                      return(<>Note : {batchPayHistry.note}</>)
                                                                              
                                                  }
                                                                              
                                              })()  
                                          }                                    
                          </h4>  
      
                          <table style={{ width: '100%', marginTop: '50px', borderCollapse: 'separate'}}>
                              <tbody>
                                  <tr>
                                      <h4 style={{fontSize: '16px'}}>Payment Proof</h4>
                                      <div className="row" style={{margin: '20px', width: '200px'}}>
                                          <img src={`admin/${batchPayHistry.payslip}`} alt="" />
                                      </div>   
                                  </tr>
                              </tbody>
                          </table>
      
      
                          <hr style={myStyle}/>
                          <p style={{fontSize: '10px', textAlign:'center', marginTop: '0px', marginBottom: '0px', lineHeight: 'normal'}}>Date : {currntDate}  -   Solution By oZoneDesk</p>
                          <hr  style={myStyle}/>
        
        
                          </>
      
                      ): (
                          <>
                          <div className="row">
                              <div className="col-md-12" style={{margin: 'auto', textAlign: 'center'}}>
                                  <h4>Invalid Batch Number</h4>
                              </div>
                          </div>
                          <hr style={myStyle}/>
                          <p style={{fontSize: '10px', textAlign:'center', marginTop: '0px', marginBottom: '0px', lineHeight: 'normal'}}>Date : {currntDate}  -   Solution By oZoneDesk</p>
                          <hr  style={myStyle}/>
                          </>                                          
                      )
                  } 
                   
              
              </div>
            </>
            ) : (
            
              <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
                <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
              </div> 
            
            )
         ) : (
        
          <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
            <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
          </div> 
        
        )
       }
  </body>
  );
}
export default VendorPayListaAchivePrint;