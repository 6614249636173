import React,{useState} from 'react'
import { Link } from 'react-router-dom'
// import jwt from 'jwt-decode'
// import Axios from 'axios'
// import { useNavigate } from "react-router-dom";
// import clientsiteLogo from "../images/logo-light.png";
// import profileLogo from "../images/1617605084.jpg";
// import  ReactDOM  from 'react-dom/client';
// import Modal from '../view/modal';
import DropdownNew from './Dropdown';
import { CSVLink } from "react-csv";

export default function SideBar() {
        // const userToken = jwt(localStorage.getItem('token'));
         
    
    const loggedUser = sessionStorage.getItem('UserID'); 

    // const [comments,setComments]=useState([])

    //   const fetchComments=async()=>{
    //     const response=await Axios(`http://localhost/hs-login-system-master/api.php/clientwebhook/${loggedUser}`);
    //     setComments(response.data)    
    //   }

    //   useEffect(() => {
    //     fetchComments();
    //   }, [])

    //   useEffect(() => {
    //     console.log(comments)
    //   }, [comments])




    //   const fetchComments = useCallback(async () => {
    //   try {
    //     await fetch('http://localhost/hs-login-system-master/api.php/clientwebhook', {
    //       headers: {
    //         Authorization: 'Bearer ' + localStorage.getItem('token'),
    //       },
    //     })
    //       .then((respose) => {
    //         if (respose.ok) {
    //           return respose.json()
    //         }
    //         throw new Error('error')
    //       })
    //       .then((data) => {
    //         setComments(data.status)
    //       })
    //   } catch (error) {
    //     console.log(error.message)
    //   }
    // }, [])


    // async function fetchComments() {
    //     try {
    //       await fetch('http://localhost/hs-login-system-master/api.php/clientwebhook', {
    //         method: 'POST',
    //         body: JSON.stringify({
    //             // loggedUser: loggedUser,
    //         }),
    //         headers: {
    //           Authorization: 'Bearer ' + localStorage.getItem('token'),
    //         },
    //       })
    //         .then((respose) => {
    //           if (respose.ok) {
    //             return respose.json()
    //           }
    //           throw new Error('error')
    //         })
    //         .then((data) => {
    //             setComments(data.status)
    //         })
    //     } catch (error) {
    //       console.log(error.message)
    //     }
    //   }





  
    // useEffect(() => {
    //     fetchComments()
    // }, [fetchComments]);

    // //   useEffect(() => {
    // //     fetchComments();
    // //   }, [])

    //   useEffect(() => {
    //     console.log(comments)
    //   }, [comments])


    // let location = useLocation();

//     const isActive = (path) =>{
//     if(location.pathname === path){
//         return { color: "#ff9900" };
//     }else{
//         return { color: "#7b7875" };
//     }
//   };

   const sub_menu_icon = <i className='ti-arrow-right'></i>;

    // var url_get =  window.location.href; 
    // const lastItem = url_get.substring(url_get.lastIndexOf('/') + 1); 
    
    // if(lastItem == "index"){
    //     var color_code = '<?php  get_color_picker_root_set_color(16) ?>';
    //      $(".colorDash").css("color",color_code);  
       
    // } 

    // if((sessionStorage.getItem('UserID')) !== null){   
    //     // setAuthenticate("userLogged")
    //     (localStorage.setItem('loggedUser', "userLogged"));

      
        const [isAddActive, setIsAddActive] = useState(false);

        const handleAddActive = event => {
        
            setIsAddActive(current => !current);
        };


        const [dropdown, setDropdown] = useState(false);
        const [dropdown1, setDropdown1] = useState(false);
        const [dropdown2, setDropdown2] = useState(false);
        const [dropdown3, setDropdown3] = useState(false);
        const [dropdown4, setDropdown4] = useState(false);
        const [dropdown5, setDropdown5] = useState(false);
        const [dropdown6, setDropdown6] = useState(false);
        // const [toggle, setToggle] = useState(null)  
        // const clickHandler = (menuId) => {
        //     console.log(`clicked`)  
        //     setToggle(menuId)
        //   }



         const dropDownShow = () => {
            // if (window.innerWidth < 960) {
            //   setDropdown(false);
            // } else {
            //   setDropdown(true);
            // }
             if(dropdown === true){
                setDropdown(false);
                setDropdown1(false);
                setDropdown2(false);
                setDropdown3(false);
                setDropdown4(false);
                setDropdown5(false);
                setDropdown6(false);
             }else{
                setDropdown(true);
                setDropdown1(false);
                setDropdown2(false);
                setDropdown3(false);
                setDropdown4(false);
                setDropdown5(false);
                setDropdown6(false);
             }   
           
          };

          const dropDownShow1 = () => {
            // if (window.innerWidth < 960) {
            //   setDropdown(false);
            // } else {
            //   setDropdown(true);
            // }
             if(dropdown1 === true){
                setDropdown1(false);
                setDropdown(false);
                setDropdown2(false);
                setDropdown3(false);
                setDropdown4(false);
                setDropdown5(false);
                setDropdown6(false);
             }else{
                setDropdown1(true);
                setDropdown(false);
                setDropdown2(false);
                setDropdown3(false);
                setDropdown4(false);
                setDropdown5(false);
                setDropdown6(false);
             }   
           
          };

          const dropDownShow2 = () => {           
             if(dropdown2 === true){
                setDropdown2(false);
                setDropdown(false);
                setDropdown1(false);
                setDropdown3(false);
                setDropdown4(false);
                setDropdown5(false);
                setDropdown6(false);
             }else{
                setDropdown2(true);
                setDropdown(false);
                setDropdown1(false);
                setDropdown3(false);
                setDropdown4(false);
                setDropdown5(false);
                setDropdown6(false);
             }   
           
          };

          const dropDownShow3 = () => {           
            if(dropdown3 === true){
                setDropdown3(false);
                setDropdown(false);
                setDropdown1(false);
                setDropdown2(false);
                setDropdown4(false);
                setDropdown5(false);
                setDropdown6(false);
            }else{
                setDropdown3(true);
                setDropdown(false);
                setDropdown1(false);
                setDropdown2(false);
                setDropdown4(false);
                setDropdown5(false);
                setDropdown6(false);
            }   
          
         };

         const dropDownShow4 = () => {           
            if(dropdown4 === true){
                setDropdown4(false);
                setDropdown(false);
                setDropdown1(false);
                setDropdown2(false);
                setDropdown3(false);
                setDropdown5(false);
                setDropdown6(false);
            }else{
                setDropdown4(true);
                setDropdown3(false);
                setDropdown(false);
                setDropdown1(false);
                setDropdown2(false);
                setDropdown5(false);
                setDropdown6(false);
            }   
          
         };

         const dropDownShow5 = () => {           
            if(dropdown5 === true){
                setDropdown5(false);
                setDropdown4(false);
                setDropdown(false);
                setDropdown1(false);
                setDropdown2(false);
                setDropdown3(false);
                setDropdown6(false);
            }else{
                setDropdown5(true);
                setDropdown4(false);
                setDropdown3(false);
                setDropdown(false);
                setDropdown1(false);
                setDropdown2(false);
                setDropdown6(false);
            }   
          
         }; 

         const dropDownShow6 = () => {           
            if(dropdown6 === true){
                setDropdown6(false);
                setDropdown5(false);
                setDropdown4(false);
                setDropdown(false);
                setDropdown1(false);
                setDropdown2(false);
                setDropdown3(false);
            }else{
                setDropdown6(true);
                setDropdown5(false);
                setDropdown4(false);
                setDropdown3(false);
                setDropdown(false);
                setDropdown1(false);
                setDropdown2(false);
            }   
          
         }; 

        // const twoWay = "enable";

        // const onMouseEnter = () => {
        //     if (window.innerWidth < 960) {
        //       setDropdown(false);
        //     } else {
        //       setDropdown(true);
        //     }
        //   };
        
        //   const onMouseLeave = () => {
        //     if (window.innerWidth < 960) {
        //       setDropdown(false);
        //     } else {
        //       setDropdown(false);
        //     }
        //   };

const myComponent1 = {
    // width: '100px',
    overflow: 'auto',
    scrollbarWidth: 'none',
    height: '100%',
    // ZIndex: '-1000'
    // overflowX: 'hidden',
    // overflowY: 'scroll'
};


        const csvData = [
            ['ID','Parcel Type', 'Order ID', 'Parcel Description', 'Recipient Name', 'Recipient Mobile', 'Recipient Address', 'Recipient City', 'COD Amount', 'Exchange'],
            ["", "", "", "", "", "", "", "", "", "0"]
          ];
          
        return (
   
            <div className="vertical-menu" id="color-vertical-menu">
                
              <div data-simplebar className="h-100" style={myComponent1}>           
                            <div id="sidebar-menu" >                       
                                <ul className="metismenu list-unstyled" id="side-menu">                         
                                    <li>
                                      <Link className="waves-effect" to="/index">
                                      <span className="colorDash ti-dashboard"></span>&nbsp;&nbsp;&nbsp;  <span className="colorDash"> Welcome</span>
                                      </Link>                               
                                    </li>
        
                                    <li>
                                        <Link className="waves-effect" to="/dashboard">
                                            <span className="colorDash ti-dashboard"></span>&nbsp;&nbsp;&nbsp;  <span className="colorDash"> Dashboard</span>
                                        </Link>                             
                                    </li>
                                                         
                                    <li>
                                    {/* <NavLink to="#" onClick={e => e.preventDefault()}> */} 
                                    {/* <a href="#" onClick={e => e.preventDefault()} className="has-arrow waves-effect"> */}
                                    {/* onClick={dropDownShow1} */}
                                        <a type="button" className="has-arrow waves-effect" onClick={dropDownShow1} >
                                            <i className="fas fa-people-carry"></i>
                                            <span> Pickup Request</span>
                                        </a>
                                        {dropdown1 && 
                                        <ul className="sub-menu" aria-expanded="false">
                                            <li>
                                                {/* <a href={`ClientTrainingPDF?logId=${loggedUser}`} target="_blank">
                                                 
                                                    {sub_menu_icon} 
                                                    Client Training PDF</a> */}
                                                    <a href={`${process.env.REACT_APP_URL}/ClientTrainingPDF/ClientTrainingPDF.pdf`} target="_blank">{sub_menu_icon}Client Training PDF</a>
                                                    {/* <Link to={`/ClientTrainingPDF/${loggedUser}`} target="_blank">
                                                 
                                                    {sub_menu_icon} 
                                                    Client Training PDF</Link> */}
                                            </li>       
                                            <li>
                                                <Link to="/add_new_parcel" className={isAddActive ? 'active' : ''} onClick={handleAddActive} >{sub_menu_icon}New Parcel</Link>
                                            </li>       
                                            <li>
                                                <Link to="/with_existing_waybill" className={isAddActive ? 'active' : ''} onClick={handleAddActive}>{sub_menu_icon}With Existing-Waybill</Link>
                                            </li>
                                            <li>
                                                <Link to="/waybill_generator" className={isAddActive ? 'active' : ''} onClick={handleAddActive}>{sub_menu_icon}Waybill generator </Link>
                                            </li>
                                            <li>
                                            <CSVLink className={isAddActive ? 'active' : ''} onClick={handleAddActive} data={csvData} filename={"waybills_upload_template.csv"}>{sub_menu_icon}Waybill Upload Template</CSVLink>
                                                {/* <Link to="/waybill_generate_template" className={isAddActive ? 'active' : ''} onClick={handleAddActive}>{sub_menu_icon} Waybill Upload Template</Link> */}
                                            </li>       
                                            <li>
                                                <Link to="/waybill_upload_csv" className={isAddActive ? 'active' : ''} onClick={handleAddActive}>{sub_menu_icon}Waybill Upload by CSV </Link>
                                            </li> 
                                            <li>
                                                <Link to="/waybill_upload_history" className={isAddActive ? 'active' : ''} onClick={handleAddActive}>{sub_menu_icon}Waybill Upload History</Link>
                                            </li>
                                            <li>
                                                <Link to="/unused_order">{sub_menu_icon}Unused Waybill</Link>
                                            </li> 
                                            <li>
                                                <Link to="/label_print">{sub_menu_icon}Label Print</Link>
                                            </li> 
                                        </ul>
                                        }
                                    </li>

                                    <li >
                                    <a type="button"  className='has-arrow waves-effect' onClick={dropDownShow2} >
                                    <i className="ti-package"></i> <span> Parcel Management</span>
                                    </a>
                                    {dropdown2 && 
                                    
                                    // <Dropdown />
                                    <ul className="sub-menu" aria-expanded="false">                                                                             
                                        <li>
                                                <Link to="/all_order">{sub_menu_icon}All Parcel</Link>
                                        </li> 
                                        <li>
                                                <Link to="/all_search">{sub_menu_icon}Search Parcel</Link>
                                        </li>
                                        <li>
                                                <Link to="/WaitingParcel">{sub_menu_icon}Waiting Parcel</Link>
                                        </li>  
                                        <li>
                                                <Link to="/pickup_order">{sub_menu_icon}Pickup By Rider</Link>
                                        </li> 
                                        <li>
                                                <Link to="/pending_order">{sub_menu_icon}Processing Parcels</Link>
                                        </li>
                                        <li>
                                                <Link to="/dispatched_order">{sub_menu_icon}Dispatched Parcels</Link>
                                        </li> 

                                        <li>
                                                <Link to="/complete_order">{sub_menu_icon}Delivered Parcel</Link>
                                        </li> 
                                        <li>
                                                <Link to="/cancel_order">{sub_menu_icon}Return Parcel</Link>
                                        </li>
                                        <li>
                                                <Link to="/remove_order">{sub_menu_icon}Removed Parcel</Link>
                                        </li>  
                                        <li>
                                                <Link to="/rescheduled_order">{sub_menu_icon}Rescheduled Parcels</Link>
                                        </li> 
                                        <li>
                                                <Link to="/exchange_order">{sub_menu_icon}Exchange Parcels</Link>
                                        </li>
                                        <li>
                                                <Link to="/all_city_order">{sub_menu_icon}City Wise Parcels</Link>
                                        </li>
                                        <li>
                                                <Link to="/all_archive_order">{sub_menu_icon}All Archive Parcels</Link>
                                        </li> 
                                    </ul>
                                    }
                                    </li>

                                    <li >
                                    <a type="button"  className='has-arrow waves-effect' onClick={dropDownShow} >
                                    <i className="ti-files"></i> <span> Reports</span>
                                    </a>
                                    {dropdown && 
                                    
                                    // <Dropdown />
                                        <ul className="sub-menu" aria-expanded="false">                                                                              
                                            <li>
                                                <Link to="/delivered_report">{sub_menu_icon}Delivered Report</Link>
                                            </li> 
                                            <li>
                                                <Link to="/pending_return_list">{sub_menu_icon}Pending Return Hand Over to Vender</Link>
                                            </li> 
                                            <li>
                                                <Link to="/complete_return_list">{sub_menu_icon}Complete Return Hand Over to Vender</Link>
                                            </li> 
                                        </ul>
                                    }
                                    </li> 

                                    <li >
                                    <a type="button"  className='has-arrow waves-effect' onClick={dropDownShow3} >
                                    <i className="fas fa-exchange-alt"></i> <span> Return Parcel</span>
                                    </a>
                                    {dropdown3 && 
                                    
                                    // <Dropdown />
                                        <ul className="sub-menu" aria-expanded="false">                                                                              
                                            <li>
                                                <Link to="/return_parcel_handover_confirm_sheet">{sub_menu_icon}Return Handover Sheets</Link>
                                            </li> 
                                        </ul>
                                    }
                                    </li> 

                                    <li >
                                    <a type="button"  className='has-arrow waves-effect' onClick={dropDownShow4} >
                                    <i className="ti-files"></i> <span> Finance</span>
                                    </a>
                                    {dropdown4 && 
                                    
                                    // <Dropdown />
                                        <ul className="sub-menu" aria-expanded="false">                                                                                                                     
                                            <li>
                                                <Link to="/ongoing_invoice">{sub_menu_icon}Pending Payout Release</Link>
                                            </li>
                                            <li>
                                                <Link to="/pending_paid_invoice">{sub_menu_icon}Pending Payout History</Link>
                                            </li>
                                            <li>
                                                <Link to="/paid_invoice">{sub_menu_icon}Complete Payout History</Link>
                                            </li>
                                            <li>
                                                <Link to="/paid_archive_invoice">{sub_menu_icon}Archive Payout History</Link>
                                            </li> 
                                        </ul>
                                    }
                                    </li> 

                                    <li>
                                      <Link className="waves-effect" to="/client_profile">
                                      <span className="colorDash1 fas fa-user"></span>&nbsp;&nbsp;&nbsp;  <span className="colorDash1"> Profile</span>
                                      </Link>                               
                                    </li>
        
                                    <li>
                                        <Link className="waves-effect" to="/pickup_option">
                                            <span className="colorDash2 fas fa-motorcycle"></span>&nbsp;&nbsp;&nbsp;  <span className="colorDash2"> Pickup Option</span>
                                        </Link>                             
                                    </li>    

                                    <li >
                                    <a type="button"  className='has-arrow waves-effect' onClick={dropDownShow5} >
                                    <i className="fas fa-ticket-alt"></i> <span>Trouble Ticket </span>
                                    </a>
                                    {dropdown5 && 
                                    
                                    // <Dropdown />
                                        <ul className="sub-menu" aria-expanded="false">                                                                                                                     
                                            <li>
                                                <Link to="/changes_request">{sub_menu_icon}Add Ticket</Link>
                                            </li>
                                            <li>
                                                <Link to="/trouble_ticket">{sub_menu_icon}Ticket List</Link>
                                            </li>
                                            
                                        </ul>
                                    }
                                    </li>

                                    <li >
                                    <a type="button"  className='has-arrow waves-effect' onClick={dropDownShow6} >
                                    <i className="fas fa-plug"></i> <span>API</span>
                                    </a>
                                    {dropdown6 && 
                                    
                                    <DropdownNew />
//                                         <ul className="sub-menu" aria-expanded="false">                                                                                                                     
//                                             <li>
//                                                 <Link to="/client_api">{sub_menu_icon} Pickup Request</Link>
//                                             </li>
//                                             {/* {
//         comments && comments.map(comment=>{
//           return(
//             <div key={comment.id} style={{alignItems:'center',margin:'20px 60px'}}>
//             <h4>{comment.name}</h4>
//             <p>{comment.email}</p>
//           </div>
//           )

//         })
//         comments.webhook_api_status
//       } */}
//                                             {userToken.user.webhook_api_status === "enable" &&
//                                             <li>
//                                             <Link to="/client_two_way_api">{sub_menu_icon} Reverse API</Link>
//                                             </li>
//                                             }
//  {/* {userToken.user.webhook_api_status} */}
                                            
                                            
                                            
//                                         </ul>
                                    }
                                    </li> 

                                </ul>
                            </div>
                            
                        </div>

                       

                    </div>                     
          )
    // }else{
    //     // setAuthenticate("userNotLogged")
    //     (localStorage.setItem('loggedUser', "userNotLogged"));
    //     return (
   
    //         <>
    //         </>                     
    //       ) 
    // }

  
}