import { useState, useEffect } from "react";

function PrintTableBody({item, getid, cityNames, zoneNames, parcelType, id, rider, hub}) {

    const [riderName, setRiderName] = useState([]);
    const [hubName, setHubName] = useState([{name:""}]);

    useEffect(() => {
        // getHubName();
        // if(item.rider_id !== null) {
        //     getRiderName(item.rider_id);
        // }
    },[])

    
// ***********************************************************
    // async function getRiderName(id) {
        
    //     try {
    //         await fetch(`${process.env.REACT_APP_URL}/index.php/getRiderName`, {
    //         method: 'POST', 
    //         body: JSON.stringify({
    //             riderid: id                           
    //         }),         
    //         headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //     } )
    //         .then((respose) => {
    //         if (respose.ok) {
    //             return respose.json()
    //         }
    //         throw new Error('error')
    //         })
    //         .then((data) => {
    //          //console.log(data.Clstatus);
    //          setRiderName(data.Clstatus);
             
            
            
    //         })
    //     } catch (error) {
    //     console.log(error.message)
    //     }
    // }

// *************************************************************************************

    // async function getHubName() {
    //     //console.log(val)
    //     try {
    //         await fetch(`${process.env.REACT_APP_URL}/index.php/getHubName`, {
    //         method: 'POST', 
    //         body: JSON.stringify({
    //             hubid: item.hub_id                              
    //         }),         
    //         headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //     } )
    //         .then((respose) => {
    //         if (respose.ok) {
    //             return respose.json()
    //         }
    //         throw new Error('error')
    //         })
    //         .then((data) => {
    //          //console.log(data.Clstatus);
    //          setHubName(data.Clstatus);
            
    //         })
    //     } catch (error) {
    //     console.log(error.message)
    //     }
    // }

// **************************************************************************************


    return(
        <>    
             <tr className="tr_border1" style={{border:'1px solid red',color:"#000000",lineHeight:"normal"}}>   
                 <td>{id}</td>
                <td>
                    {getid(item)}
                     <br/>
                    {item.orderDate}
                    <br/>
                    {
                        (item.deliveryStatus == 'Waiting') && (<><span className="badge" style={{color: '#fff',backgroundColor: 'green',border:"none"}}>{item.deliveryStatus}</span><br/></>)
                    }
                    {
                        (item.deliveryStatus == 'Pickup') && (<><span className="badge badge-warning" style={{border:'none'}}>{item.deliveryStatus}</span><br/></>)
                    }
                    {
                        (item.deliveryStatus == 'Pending') && (<><span className="badge badge-info" style={{border:'none'}}>Processing</span><br/></>)
                    }
                    {
                        (item.deliveryStatus == 'Complete') && (<><span className="badge badge-success" style={{border:'none'}} >Delivered</span><br/></>)
                    }
                    {
                        (item.deliveryStatus == 'Reschedule') && (<><span className="badge badge-primary" style={{border:'none'}} >{item.deliveryStatus}</span><br/></>)
                    }
                    {
                        (item.deliveryStatus == 'Cancel') && (<><span className="badge badge-danger" style={{border:'none'}}>Return</span><br/></>)
                    }
                    {
                        (item.deliveryStatus == 'Dispatched') && (<><span className="badge" style={{color: '#fff',backgroundColor: '#f838f6', border:'none'}}>{item.deliveryStatus}</span><br/></>)
                    }
                    {
                        (item.deliveryStatus == 'Delete') && (<><span className="badge" style={{color: '#fff',backgroundColor: 'red', border:'none'}}>Remove</span><br/></>)
                    }
                    
                    
                    {/* <span className="badge" style={{color: '#fff', backgroundColor: '#ff7600', border:'none'}}>{hubName[0] && (hubName[0].name)}</span> <br/> */}
                    {item.hub_id != null &&(<><span className="badge" style={{color: '#fff', backgroundColor: '#ff7600', border:'none'}}>{{...hub.find(element => element.id == item.hub_id)}.name } </span><br/></>)} 
                    {item.rider_id != null &&(<><span className="badge badge-success" style={{border:'none'}}>{{...rider.find(element => element.id == item.rider_id)}.fname } {{...rider.find(element => element.id == item.rider_id)}.lname } {`(${item.rider_id})`}</span><br/></>)} 
                     
                    {/* {item.rider_id != null && (<><span className="badge badge-success">{riderName[0] && (`${riderName[0].fname} ${riderName[0].lname} (${item.rider_id})`)}</span><br/></>)} */}
                    {/* {item.rider_id != null && (<><span className="badge badge-success">{riderName[0] && (`${riderName[0].fname} ${riderName[0].lname}`)}</span><br/></>)} */}
                    {item.orderID != null ?
                    <b>Order ID : {item.orderID}</b>
                     :null}
                 </td>
                 <td>
                    
                    {{...parcelType.find(element => element.id == item.pType)}.pName}<br/>
                    {item.pDescription != null?
                    
                    <p>Parcel Description: {item.pDescription}</p>:null  
                }                 
                 </td> 
                 <td>
                    {item.recipientName}<br/>
                    {item.recipientMobile}<br/>
                    {item.recipientAddress}<br/>
                    {{...cityNames.find(element => element.cid == item.zoneCity)}.cname}<br/>
                    {{...zoneNames.find(element => element.cid == item.recipientCity)}.cname}  
                 </td>
                 <td>
                    {item.pickupFrom}<br/>
                    {item.pickupAddress} <br/>
                    {{...zoneNames.find(element => element.cid == item.pickupCity)}.cname}      
                 </td>
                    <td>{item.paymentType}</td>
                 <td>
                  {item.paymentType == "COD" && (<>{item.CODAmount}.00 LKR</>)}
                    {item.deliveryFee && (<p style={{fontSize: 'smaller'}}>Delivery: {item.deliveryFee} LKR</p>)}
                 </td>  
                    
                 <td>
                    {item.adminNote ? item.adminNote : "-"}
                 </td> 
             </tr>         
             <tr></tr>
             </>
    )
}

export default PrintTableBody;