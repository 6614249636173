import { useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Swal from "sweetalert2";

export default function ChangesRequest() {  

    document.title = "Logistic Client Portal | Request Change";
    
    const [waybillNo, setWaybillNo]           = useState('');
    const [requestVal, setRequestVal]   			= useState('');
    
    const [formErrorsW, setformErrorsW] 			= useState({});
    
    const [isWaybillActive, setIsWaybillActive]       = useState(false);
    const [isWaybillShow, setIsWaybillShow]   				= useState(true);
    const [isRequestShow, setIsRequestShow]   				= useState(false);
  
    var logUser = sessionStorage.getItem("UserID");

    const WaybillNoChange = (event) => {
        setWaybillNo(event.target.value)
        setIsWaybillActive(false) 
    }

    const requestValChange = (e) => {
        setRequestVal(e.target.value);
        // setIsRequestActive(false)       
    };

    const validate = (waybillNo) => {
      const errors = {};

      if(!waybillNo){
        errors.waybillNo = "Waybill ID is required!";  
        setIsWaybillActive(true)    
      }else{
        setIsWaybillActive(false)
       }

      return errors;
    }

    const submitWaybill = (event) => {
      event.preventDefault()
            
      setformErrorsW(validate(waybillNo));        

      var errorLength =  (Object.keys(validate(waybillNo)).length)
                    
        if(errorLength === 0 ){

        async function WaybillConfirmRequest() {
			      try {
			          await fetch(`${process.env.REACT_APP_URL}/index.php/waybillConfirm`, {
			          method: 'POST', 
			          body: JSON.stringify({             
			              clientID: logUser,
			              waybillNo: waybillNo,
			             }),         
			          headers: {
			            'Content-Type': 'application/x-www-form-urlencoded',
			          },
			        } )
			          .then((respose) => {
			            if (respose.ok) {
			              return respose.json()
			            }
			            throw new Error('error')
			          })
			          .then((data) => {
			          	
			          	if(data.Clstatus === "yes"){
			          		
			          		setIsWaybillShow(false); 
        						setIsRequestShow(true);
        						
			          	}else{
			          		setWaybillNo('');
			          		
			          		Swal.fire({
				            	title: "Warning",
				            	text: "Invalid Waybill ID!",
				            	icon: "error",
				            	confirmButtonText: "OK",
				            	timer: 1000,
				           });
				           
				           
			          	}
			           
			          })
			      } catch (error) {
			        console.log(error.message)
			      }
    		}
    		
    		WaybillConfirmRequest();
    
         
        }
    }

    const submitReqChange = (event) => {
      event.preventDefault()

    async function TicketAddRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/ticketAdd`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: logUser,
              waybillNo: waybillNo,
              requestVal: requestVal,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
          	
          	if(data.Clstatus === "success"){
          		
						Swal.fire({
	            title: "Good job!",
	            text: "Successfully Requested!",
	            icon: "success",
	            confirmButtonText: "OK",
	            timer: 1000,
	           });          		
          	}else{
          	
          		Swal.fire({
	            	title: "Warning",
	            	text: "Not Successful!",
	            	icon: "error",
	            	confirmButtonText: "OK",
	            	timer: 1000,
	           });		
          	}
          	
            
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    
      TicketAddRequest();

         setWaybillNo('');
         setRequestVal('');
         setIsWaybillShow(true); 
         setIsRequestShow(false);
        
    }
    
    return (
      <div>
  <div id="layout-wrapper">

  <TopBar/>

  <SideBar/>
    
    <div className="main-content">

      <div className="page-content">
        <div className="container-fluid">
               
                <div className="row">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <div className="page-title-box">
                      <h4 className="font-size-18" >Request Change</h4>
                     </div>
                    </div>
                </div>

                <div className="row justify-content-md-center">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="card-body">
                            	<div data-repeater-list="outer-group" className="outer" >
			                          <div data-repeater-item className="outer"  id="wayIDdivFirst" style={{display: isWaybillShow ? 'block' : 'none'}}>
			                            <div className="form-group">
			                              <label htmlFor="formname"> Waybill ID :</label> 
			                              <input type="number" name="bill_amount" id="bill_amount" className="form-control" value={waybillNo} onChange={WaybillNoChange}/>
			                              <p style={{display: isWaybillActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrorsW.waybillNo}</p>
			                            </div>
			                            
			                            <div className="inner-repeater mb-4">
			                            <button id="Generate" type="button" className="btn btn-success inner" onClick={submitWaybill}>Go</button>
			                            </div>
			                          </div>
                          
		                            <div data-repeater-item className="outer" id="wayIDdivSec" style={{display: isRequestShow ? 'block' : 'none'}}>
		                             <div className="form-group">
		                              <label htmlFor="formname">Request :</label> 
		                               <textarea  className="form-control"  name="request" id="request" cols="30" rows="2" value={requestVal} onChange={requestValChange}></textarea>
		                             </div>
		                             
		                             <div className="inner-repeater mb-4">
		                            	<button id="goNextBtn" type="button" className="btn btn-success inner" onClick={submitReqChange}>Confirm</button>
		                             </div>
		                          	</div>
                        			</div>
                            </div>
                        </div>
                    </div>    
                </div>
          
     
        </div>
   
      </div>
    
      <footer className="footer">
        <Footer/>
      </footer>

    </div>
 

  </div>
     
</div>

 

    )
}
