import { Link } from 'react-router-dom'
// import TopBar from '../Components/TopBar';
// import SideBar from '../Components/SideBar';
// import testSideBar from '../Components/testSideBar';
export default function Home() {
  document.title = "Logistic Client Portal | Home Page";
  return (
    <div className="home">
      {/* <TopBar/>
      <SideBar/> */}
      <h1>Home Page</h1>
      <div className="panel">
        <Link to="/register">Register</Link>
        {localStorage.getItem('token') ? (
          <>
            <Link to="/profile">Profile</Link>
            <Link to="/logout">Log Out</Link>
          </>
        ) : (
          <Link to="/">Login</Link>
        )}



      </div>
    </div>
  )
}
