import { useEffect, useState } from "react";
import ModalContent from '../view/modalContent';
import ModalContentOne from '../view/modalContentOne';
// import * as React from 'react';
// import ReactDOM from "react-dom";

// function Modal(props) {
//   return ReactDOM.createPortal(JSX_MODAL, document.querySelector("#modal"));
    // <div>

{/* Hello, {props.name}
    </div>
} */}

// const handleClick = (e) => {
//     e.preventDefault();
//     document.getElementById('testnewid01').style.display='none';
//   }


// style={ {props} === true ? { display: 'block'} : {display: 'block'}}


function Modal({closeModal, waybillId}) {

    const [adminUserInfo, setAdminUserInfo]       = useState([]);
    const [clientUserInfo, setClientUserInfo]     = useState([]);

    function logUserInfoRequest(){
                                        
        fetch(`${process.env.REACT_APP_URL}/index.php/getAllUsers`, {
        method: 'POST',                
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        },
        } )
        .then((respose) => {
        if (respose.ok) {
            return respose.json()

        }
        throw new Error('error')
        })
        .then((data) => {
        // console.log(data.Clstatus);
          setAdminUserInfo(data.Clstatus);
                              
        })

    }
    
    function logClientInfoRequest(){
                                  
      fetch(`${process.env.REACT_APP_URL}/index.php/getAllclients`, {
      method: 'POST',                
      headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      },
      } )
      .then((respose) => {
      if (respose.ok) {
          return respose.json()

      }
      throw new Error('error')
      })
      .then((data) => {
       
      setClientUserInfo(data.Clstatus);
     
      })

  }

    useEffect(() => {
        logUserInfoRequest();
        logClientInfoRequest();
    },[])

    return (
      
  <div id="testnewid01" className="modalNew" >
  <span onClick={()=> closeModal(false)} className="closeNew" title="Close Modal">×</span>
      <form className="Newmodal-content" action="/action_page.php">
          <div className="containerNew">
  
  
  <div className="row justify-content-md-center">
          
      <div className="col-lg-7">
          <div  id="NEwviewDiv" >
          {/* <p>
            {waybillId}
            </p> */}
              <div className="col-md-12 text-center">
              <ModalContentOne adminUserInfo={adminUserInfo} clientUserInfo={clientUserInfo} waybillOne={waybillId}/>
                  {/* <i className="fas fa-circle-notch fa-spin" style={{fontSize:'50px'}}></i> */}
              </div>  
             
          </div>   
  
      </div>
      <div className="col-lg-5">
          <div className="row" id="NEWloadDiv">
              <div className="col-md-12 text-center" style={{}}>
                  {/* <i className="fas fa-circle-notch fa-spin" style={{fontSize:'50px'}}></i> */}
                 <ModalContent wayNbillId={waybillId}/>
              </div>  
          </div>
      </div>
  </div>  
  
  
  
  </div>
  </form>
  </div>
    )
  }




// function Modal(open, onClose, waybillId) {
//     if(!open) return null
//     return (
// <div id="testnewid01" className="modalNew" >
// <span onClick={onClose} className="closeNew" title="Close Modal">×</span> 
//   {/* <span onClick={()=> props.closeModal(false)} className="closeNew" title="Close Modal">×</span> */}
//       <form className="Newmodal-content" action="/action_page.php">
//           <div className="containerNew">
  
  
//   <div className="row justify-content-md-center">
          
//       <div className="col-lg-7">
//           <div  id="NEwviewDiv" >
//           <p>Hi there 
//             {/* {props.waybillId} */}
//           {waybillId}
//           </p>
//               {/* <div className="col-md-12 text-center" style={{fontpaddingSize:'128px 0px'}}>
//                   <i className="fas fa-circle-notch fa-spin" style={{fontSize:'50px'}}></i>
//               </div>   */}
             
//           </div>   
  
//       </div>
//       <div className="col-lg-5">
//           <div className="row" id="NEWloadDiv">
//               <div className="col-md-12 text-center" style={{fontpaddingSize:'128px 0px'}}>
//                   <i className="fas fa-circle-notch fa-spin" style={{fontSize:'50px'}}></i>
//               </div>  
//           </div>
//       </div>
//   </div>  
  
  
  
//   </div>
//   </form>
//   </div>
//     );
//   }

export default Modal;


