import { useContext, useEffect, useState, useRef, Fragment } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { parcelData } from "../App";
import PrintTableBody from "../Components/PrintTableBody";
import logo from '../images/logo-light.png';
import "./printsearch.css";


function PrintSearch({cityNames, zoneNames}) {

    
    let  {loggeduser}  = useParams();
    const [isPrint, setIsPrint] = useState(false); 

    const componentRef = useRef();
    
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      
    });

    //console.log(handlePrint);

    const riderArr = [];
    
    let title;

    var today = new Date();

    var todaytime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

    var todaydate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    var datetime = todaydate+" "+todaytime;
    
    // var datetime =  `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`; 
    
   

    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValues, setSearchValues] = useState({
        waybill_id:"",
        search:"",
        parcelType:"",
        fdate:"",
        tdate:"",
        cityzone:"",
        paymentType:"",
        order_id : "",
        status:[],
        type:""
    })
    const[data, setData] = useState([]);
    const [parcelType, setParcelType] = useState([]);
    const [rider, setRider] = useState([]);
    const [hub, setHub] = useState([]);
   
    //const [riderName, setRiderName] = useState([{fname: '', lname: ''}]);

    

    useEffect(() => {
        sessionStorage.setItem("UserID", loggeduser);
        setSearchValues({
            waybill_id:searchParams.get('waybill_id'),
            search:searchParams.get('search'),
            parcelType:searchParams.get('parcelType'),
            fdate:searchParams.get('fdate'),
            tdate:searchParams.get('tdate'),
            cityzone:searchParams.get('cityzone') ? searchParams.get('cityzone') : "",
            paymentType:searchParams.get('paymentType'),
            order_id:searchParams.get('orderID'),
            status: searchParams.get('status').split(",")[0] != "" ? [...searchParams.get('status').split(",")] : [],
            type: searchParams.get('type')
        })

        getParcelType();
        getRider();
        getHub();

    }, [])

    useEffect(() => {
        
        const ClientId = sessionStorage.getItem('UserID');
        console.log(`clientid ${ClientId}`)
        fetchData(ClientId);
        
    }, [searchValues])

    // async function done() {
    //     await fetchData();
        
    // }
    
    switch(searchParams.get('type')) {
        case "allparcel" :
            title = "All Parcels"
            break;
        case "waitingParcel" :
            title = "Waiting Parcels"
            break;
        case "returnParcel" :
            title = "Return Parcels"
            break;
        case "rescheduleParcel" :
            title = "Reschedule Parcels"
            break;
        case "removeParcel" :
            title = "Remove Parcels"
            break;
        case "processingParcel" :
            title = "Processing Parcels"
            break;
        case "pickupParcel" :
            title = "Pickup Parcels"
            break;
        case "exchangeParcel" :
            title = "Exchange Parcels"
            break;
        case "dispatchedParcel" :
            title = "Dispatched Parcels"
            break;
        case "deliveredParcel" :
            title = "Delivered Parcels"
            break;
        case "citywiseParcel" :
            title = "Citywise Parcels"
            break;
        case "allarchiveparcel" : 
            title = "All Archive Parcels"
            break; 
        default:
            title = ""
    }
    
    async function fetchData(ClientId) {
        if(searchValues.type === "allparcel") {
        // axios.post(`${process.env.REACT_APP_URL}/allparcel.php`, {...searchValues, clientID : user})
        //     .then((res) => {
        //         //console.log(res.data)
        //         setData(res.data);
        //     })
        //     .catch(err => console.log(err));
                // ***********
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/allParcels`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                //console.log(data.Clstatus);
                setData(data.Clstatus);
                setIsPrint(true);
                
                })
            } catch (error) {
            console.log(error.message)
            }
        }

        if(searchValues.type === "waitingParcel") {
            // axios.post(`${process.env.REACT_APP_URL}/waiting_parcel.php`, {...searchValues, clientID : user})
            //     .then((res) => {
            //         //console.log(res.data)
            //         setData(res.data);
            //     })
            //     .catch(err => console.log(err));

            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/waiting_parcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }
        }

        if(searchValues.type === "returnParcel") {

           try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/returnParcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }
        }

        if(searchValues.type === "rescheduleParcel") {
            

            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/rescheduledParcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }
        }

        if(searchValues.type === "removeParcel") {
            
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/removeParcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }
        }
        if(searchValues.type === "processingParcel") {
            

            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/processingParcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }
        }

        if(searchValues.type === "pickupParcel") {
            

            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/pickupParcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }

            
        }

        if(searchValues.type === "exchangeParcel") {
            

            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/exchangeParcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }
        }

        if(searchValues.type === "allarchiveparcel") {
            

            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/allArchiveParcels`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }
        }

        if(searchValues.type === "dispatchedParcel") {
            

            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/dispatchedParcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }
        }

        if(searchValues.type === "deliveredParcel") {
            

            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/deliveredParcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }
        }

        if(searchValues.type === "citywiseParcel") {
            
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/citywiseParcel`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    ...searchValues                                
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    //console.log(data.Clstatus);
                    setData(data.Clstatus);
                    setIsPrint(true);
                })
            } catch (error) {
            console.log(error.message)
            }

        }
    }

    async function getRider() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getRider`, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
                console.log(data.Clstatus);
                //riderArr.push(...data.Clstatus);
                setRider(data.Clstatus);
              
             
            })
        } catch (error) {
          console.log(error.message)
        }
    }

    async function getHub() {
        //console.log(val)
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/gethubArr`, {
            method: 'POST', 
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             //console.log(data.Clstatus);
             setHub(data.Clstatus);
            
            })
        } catch (error) {
        console.log(error.message)
        }
    }

    // function getParcelType() {
    //     axios.get(`${process.env.REACT_APP_URL}/index.php/getParcelType`)
    //         .then(res => {
    //             setParcelType(res.data.Clstatus);
    //         })
    //         .catch(err => console.log(err))
    // }

    async function getParcelType() {
       try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelType`, {
            method: 'POST',          
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {          
                setParcelType(data.Clstatus);
            })
        } catch (error) {
          console.log(error.message)
        }
    }

    async function getRiderName(id) {
        
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getRiderName`, {
            method: 'POST', 
            body: JSON.stringify({
                riderid: id                           
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             //console.log(data.Clstatus);
             //setRiderName(data.Clstatus);
             return `${data.Clstatus[0].fname} ${data.Clstatus[0].lname}`;
            
            
            })
        } catch (error) {
        console.log(error.message)
        }
    }

    function getid(item){
        
        if(searchValues.type==="allarchiveparcel"){
            var id = item.waybill_id;
        }else{
            var id = item.id;
        }

        return id;
    }

    console.log(riderArr)
   
   
    useEffect(() => {
        if(isPrint){
            handlePrint();
        }
        
      }, [isPrint, handlePrint]);

    
    return(
         <div className="panel panel-default" id="mainpanel" ref={componentRef}>

            {
                (isPrint) ? (
                    <>

<div className="panel-heading"> 
		 <div className="col-md-12">
         <table width="100%" align="center" style={{fontSize: "12px",fontFamily: "Arial, Helvetica, sans-serif",marginTop:"20px",color:"#000000"}}> 
          <tbody><tr>
            <td align="left" width="65%">
              <div>
                <img src={logo} style={{width: '135px'}}/>
              </div>
            </td>
            <td align="left">
               <p>{title}</p>
               <lable><strong style={{fontWeight: 'bold'}}>Generate Date: {datetime} </strong></lable><br/>
            </td> 
          </tr>
       </tbody></table>
        
       <br/>
       <table width="100%" id="search_table">
          <tbody><tr>
            <th style={{textAlign:'center',color:"#000000"}} colSpan="4"> Filter </th>  
          </tr>
          <tr>
            <th style={{align:"left",color:"#000000"}}> Waybill ID </th>
            <td style={{color:"#000000"}}>{searchValues.waybill_id != "" ? searchValues.waybill_id : 'N/A'}</td>
            <th style={{align:"left",color:"#000000"}}> Request </th>
            <td style={{color:"#000000"}}>{searchValues.search != "" ? searchValues.search : 'N/A'} </td> 
          </tr>  
             
          <tr> 
            <th style={{align:"left",color:"#000000"}}> Parcel Type </th>
            <td style={{color:"#000000"}}>{searchValues.parcelType != "" ? searchValues.parcelType : 'N/A'}</td>
            <th style={{align:"left",color:"#000000"}}> Payment Type </th>
            <td style={{color:"#000000"}}>{searchValues.paymentType != "" ? searchValues.paymentType : 'N/A'}</td>
          </tr>  
          <tr>
            <th style={{align:"left",color:"#000000"}}> Order From </th>
            <td style={{color:"#000000"}}>{searchValues.fdate != "" ? searchValues.fdate : 'N/A'}</td>
            <th style={{align:"left",color:"#000000"}}> Order To </th>
            <td style={{color:"#000000"}}>{searchValues.tdate != "" ? searchValues.tdate : 'N/A'}</td> 
          </tr> 
         
          <tr>
            <th style={{align:"left",color:"#000000"}}> Order ID </th>
            <td style={{color:"#000000"}}>{searchValues.order_id != "" ? searchValues.order_id : 'N/A'}</td> 
            <th style={{align:"left",color:"#000000"}}> Status </th>
            <td style={{color:"#000000"}}>
                {searchValues.status != "" ? searchValues.status.toString() : 'N/A'}  
           </td>
          </tr>

          {/* <tr> 
            <th align="left"> Status </th>
            <td colspan="3">
                {searchValues.status != "" ? searchValues.status.toString() : 'N/A'}  
           </td>
          </tr>  */}
         
       </tbody></table>
       <br/>
  
	  </div>
		</div>


            
             <div className="panel-body">

 <div id="tablediv" className="col-sm-12" align="center">
     <table width="100%" style={{fontSize: '12px'}} cellPadding="2" cellSpacing="3">
         <thead style={{backgroundColor:"#EAEAEA"}}>
             <tr>
                 <td style={{backgroundColor:"#EAEAEA",color:"#000000"}}>#</td>
                 <td style={{backgroundColor:"#EAEAEA",color:"#000000"}}>Order Info</td>
                 <td style={{backgroundColor:"#EAEAEA",color:"#000000"}}>Parcel Info</td> 
                 <td style={{backgroundColor:"#EAEAEA",color:"#000000"}} >Recipient Info</td>
                <td style={{backgroundColor:"#EAEAEA",color:"#000000"}} >Pickup Info</td>
                 <td style={{backgroundColor:"#EAEAEA",color:"#000000"}} >Pay Method</td>
                 <td style={{backgroundColor:"#EAEAEA",color:"#000000"}} >Amount</td>
                 <td style={{backgroundColor:"#EAEAEA",color:"#000000"}} >Admin Note</td>
                </tr>
            </thead>
            <tbody>
             <tr></tr>
            {data.map( (item, i) => {
                //getRiderName(item.rider_id);
                return (

                    <PrintTableBody key={i} item={item} cityNames ={cityNames} zoneNames={zoneNames} getid={getid} parcelType={parcelType} rider={rider} hub={hub} id={i+1}/>

            // <Fragment key={i}>    
            //  <tr className="tr_border1" style={{border:'1px solid red',color:"#000000",lineHeight:"normal"}}>   
            //      <td>{i+1}</td>
            //     <td>
            //         {getid(item)}
            //          <br/>
            //         {item.orderDate}
            //         <br/>
            //         {
            //             (item.deliveryStatus == 'Waiting') && (<><span className="badge" style={{color: '#fff',backgroundColor: 'green',border:"none"}}>{item.deliveryStatus}</span><br/></>)
            //         }
            //         {
            //             (item.deliveryStatus == 'Pickup') && (<><span className="badge badge-warning" style={{border:'none'}}>{item.deliveryStatus}</span><br/></>)
            //         }
            //         {
            //             (item.deliveryStatus == 'Pending') && (<><span className="badge badge-info" style={{border:'none'}}>Processing</span><br/></>)
            //         }
            //         {
            //             (item.deliveryStatus == 'Complete') && (<><span className="badge badge-success" style={{border:'none'}} >Delivered</span><br/></>)
            //         }
            //         {
            //             (item.deliveryStatus == 'Reschedule') && (<><span className="badge badge-primary" style={{border:'none'}} >{item.deliveryStatus}</span><br/></>)
            //         }
            //         {
            //             (item.deliveryStatus == 'Cancel') && (<><span className="badge badge-danger" style={{border:'none'}}>Return</span><br/></>)
            //         }
            //         {
            //             (item.deliveryStatus == 'Dispatched') && (<><span className="badge" style={{color: '#fff',backgroundColor: '#f838f6', border:'none'}}>{item.deliveryStatus}</span><br/></>)
            //         }
            //         {
            //             (item.deliveryStatus == 'Delete') && (<><span className="badge" style={{color: '#fff',backgroundColor: 'red', border:'none'}}>Remove</span><br/></>)
            //         }
                    
                    
            //         <span className="badge" style={{color: '#fff', backgroundColor: '#ff7600', border:'none'}}>Test Hub</span> <br/>
                    
                    
            //         {/* {item.rider_id != null && (<><span className="badge badge-success">{riderName[0] && (`${riderName[0].fname} ${riderName[0].lname}`)}</span><br/></>)} */}
            //         {item.orderID != null ?
            //         <b>Order ID : {item.orderID}</b>
            //          :null}
            //      </td>
            //      <td>
                    
            //         {{...parcelType.find(element => element.id == item.pType)}.pName}<br/>
            //         {item.pDescription != null?
                    
            //         <p>Parcel Description: {item.pDescription}</p>:null  
            //     }                 
            //      </td> 
            //      <td>
            //         {item.recipientName}<br/>
            //         {item.recipientMobile}<br/>
            //         {item.recipientAddress}<br/>
            //         {{...cityNames.find(element => element.cid == item.zoneCity)}.cname}<br/>
            //         {{...zoneNames.find(element => element.cid == item.recipientCity)}.cname}  
            //      </td>
            //      <td>
            //         {item.pickupFrom}<br/>
            //         {item.pickupAddress} <br/>
            //         {{...zoneNames.find(element => element.cid == item.pickupCity)}.cname}      
            //      </td>
            //         <td>{item.paymentType}</td>
            //      <td>
            //       {item.paymentType == "COD" && (<>{item.CODAmount}.00 LKR</>)}
            //         {item.deliveryFee && (<p style={{fontSize: 'smaller'}}>Delivery: {item.deliveryFee} LKR</p>)}
            //      </td>  
                    
            //      <td>
            //         {item.adminNote ? item.adminNote : "-"}
            //      </td> 
            //  </tr>         
            //  <tr></tr>
            //  </Fragment>
             )
            })}
         </tbody>
     </table>
     </div>
 </div> 
 </>
 ): (
    <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div>
                                                    
    )

        }
         </div>

    )
}

export default PrintSearch;



// ***************************************************************************** /////////

// import { useContext, useEffect, useState } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { useSearchParams } from "react-router-dom";
// import { parcelData } from "../App";
// import logo from '../images/logo-light.png';
// import "./printsearch.css";

// //const {dataParcel} = useContext(parcelData);



// function PrintSearch({cityNames, zoneNames}) {

//     let title;
//     //const date = new Date();
//     var datetime =  `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`; 
    
//     //const {state} = useContext(parcelData);
//     //console.log(state + ' bbbbs')
//     // const[data, setData] = useState('');
//     // const {setDataParcel, dataParcel} = useContext(parcelData);
//     // setDataParcel('bbbb')
//     // console.log(`${id} ${user}`)
//     //setData(dataParcel)

//     const [searchParams, setSearchParams] = useSearchParams();
//     const [searchValues, setSearchValues] = useState({
//         waybill_id:"",
//         search:"",
//         parcelType:"",
//         fdate:"",
//         tdate:"",
//         cityzone:"",
//         paymentType:"",
//         status:[],
//         type:""
//     })
//     const[data, setData] = useState([]);

//     useEffect(() => {
//         setSearchValues({
//             waybill_id:searchParams.get('waybill_id'),
//             search:searchParams.get('search'),
//             parcelType:searchParams.get('parcelType'),
//             fdate:searchParams.get('fdate'),
//             tdate:searchParams.get('tdate'),
//             cityzone:searchParams.get('cityzone') ? searchParams.get('cityzone') : "",
//             paymentType:searchParams.get('paymentType'),
//             status: searchParams.get('status').split(",")[0] != "" ? [...searchParams.get('status').split(",")] : [],
//             type: searchParams.get('type')
//         })

//         window.print();

//     }, [])

//     useEffect(() => {
//         fetchData();
//     }, [searchValues])
    
//     switch(searchParams.get('type')) {
//         case "allparcel" :
//             title = "All Parcels"
//             break;
//         case "waitingParcel" :
//             title = "Waiting Parcels"
//             break;
//         case "returnParcel" :
//             title = "Return Parcels"
//             break;
//         case "rescheduleParcel" :
//             title = "Reschedule Parcels"
//             break;
//         case "removeParcel" :
//             title = "Remove Parcels"
//             break;
//         case "processingParcel" :
//             title = "Processing Parcels"
//             break;
//         case "pickupParcel" :
//             title = "Pickup Parcels"
//             break;
//         case "exchangeParcel" :
//             title = "Exchange Parcels"
//             break;
//         case "dispatchedParcel" :
//             title = "Dispatched Parcels"
//             break;
//         case "deliveredParcel" :
//             title = "Delivered Parcels"
//             break;
//         case "citywiseParcel" :
//             title = "Citywise Parcels"
//             break;
//         default:
//             title = ""
//     }
    
//     function fetchData() {
//         if(searchValues.type === "allparcel") {
//         axios.post('http://65.108.144.120/backend/allparcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "waitingParcel") {
//         axios.post('http://65.108.144.120/backend/waiting_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "returnParcel") {
//         axios.post('http://65.108.144.120/backend/return_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "rescheduleParcel") {
//         axios.post('http://65.108.144.120/backend/rescheduled_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "removeParcel") {
//         axios.post('http://65.108.144.120/backend/remove_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "processingParcel") {
//         axios.post('http://65.108.144.120/backend/processing_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "pickupParcel") {
//         axios.post('http://65.108.144.120/backend/pickup_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "exchangeParcel") {
//         axios.post('http://65.108.144.120/backend/exchange_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "dispatchedParcel") {
//         axios.post('http://65.108.144.120/backend/dispatched_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "deliveredParcel") {
//         axios.post('http://65.108.144.120/backend/delivered_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }

//         if(searchValues.type === "citywiseParcel") {
//         axios.post('http://65.108.144.120/backend/city_wise_parcel.php', searchValues)
//             .then((res) => {
//                 //console.log(res.data)
//                 setData(res.data);
//             })
//             .catch(err => console.log(err));
//         }
//     }
    
    
    
//     //style={{width:'200px', backgroundColor:'#EAEAEA'}}

//     return(
//          <div className="panel panel-default" id="mainpanel">

// <div class="panel-heading"> 
// 		 <div class="col-md-12">
//          <table width="100%" align="center" style={{fontSize: "12px",fontFamily: "Arial, Helvetica, sans-serif",marginTop:"20px"}}> 
//           <tbody><tr>
//             <td align="left" width="65%">
//               <div>
//                 <img src={logo} style={{width: '135px'}}/>
//               </div>
//             </td>
//             <td align="left">
//                <p>{title}</p>
//                <lable><strong>Generate Date: {datetime} </strong></lable><br/>
//             </td> 
//           </tr>
//        </tbody></table>
        
//        <br/>
//        <table width="100%" id="search_table">
//           <tbody><tr>
//             <th style={{textAlign:'center'}} colspan="4"> Filter </th>  
//           </tr>
//           <tr>
//             <th align="left"> Waybill ID </th>
//             <td>{searchValues.waybill_id != "" ? searchValues.waybill_id : 'N/A'}</td>
//             <th align="left"> Request </th>
//             <td>{searchValues.search != "" ? searchValues.search : 'N/A'} </td> 
//           </tr>  
             
//           <tr> 
//             <th align="left"> Parcel Type </th>
//             <td>{searchValues.parcelType != "" ? searchValues.parcelType : 'N/A'}</td>
//             <th align="left"> Payment Type </th>
//             <td>{searchValues.paymentType != "" ? searchValues.paymentType : 'N/A'}</td>
//           </tr>  
//           <tr>
//             <th align="left"> Order From </th>
//             <td>{searchValues.fdate != "" ? searchValues.fdate : 'N/A'}</td>
//             <th align="left"> Order To </th>
//             <td>{searchValues.tdate != "" ? searchValues.tdate : 'N/A'}</td> 
//           </tr> 
         
//           <tr> 
//             <th align="left"> Status </th>
//             <td colspan="3">
//                 {searchValues.status != "" ? searchValues.status.toString() : 'N/A'}  
//            </td>
//           </tr> 
         
//        </tbody></table>
//        <br/>
  
// 	  </div>
// 		</div>


            
//              <div className="panel-body">

//  <div id="tablediv" className="col-sm-12" align="center">
//      <table width="100%" style={{fontSize: '12px'}} cellPadding="2" cellSpacing="3">
//          <thead>
//              <tr>
//                  <td bgcolor="#EAEAEA" class="active">#</td>
//                  <td width="200" bgcolor="#EAEAEA" class="active" >Order Info</td>
//                  <td width="200" bgcolor="#EAEAEA" class="active">Parcel Info</td> 
//                  <td width="200" bgcolor="#EAEAEA" class="active">Recipient Info</td>
//                 <td width="200" bgcolor="#EAEAEA" class="active">Pickup Info</td>
//                  <td width="200" bgcolor="#EAEAEA" class="active">Pay Method</td>
//                  <td width="200" bgcolor="#EAEAEA" class="active">Amount</td>
//                  <td width="200" bgcolor="#EAEAEA" class="active">Admin Note</td>
//                 </tr>
//             </thead>
//             <tbody>
//              <tr></tr>
//             {data.map( (item, i) => {
//                 return (

//             <>    
//              <tr className="tr_border1" style={{border:'1px solid red'}}>   
//                  <td>{i+1}</td>
//                 <td>
//                     {item.id} <br/>
//                     {item.orderDate}
//                     <br/>
//                     {
//                         (item.deliveryStatus == 'Waiting') && (<><span className="badge" style={{color: '#fff',backgroundColor: 'green'}}>{item.deliveryStatus}</span><br/></>)
//                     }
//                     {
//                         (item.deliveryStatus == 'Pickup') && (<><span className="badge badge-warning">{item.deliveryStatus}</span><br/></>)
//                     }
//                     {
//                         (item.deliveryStatus == 'Pending') && (<><span className="badge badge-info" >Processing</span><br/></>)
//                     }
//                     {
//                         (item.deliveryStatus == 'Complete') && (<><span className="badge badge-success" >Delivered</span><br/></>)
//                     }
//                     {
//                         (item.deliveryStatus == 'Reschedule') && (<><span className="badge badge-primary" >{item.deliveryStatus}</span><br/></>)
//                     }
//                     {
//                         (item.deliveryStatus == 'Cancel') && (<><span className="badge badge-danger" >Return</span><br/></>)
//                     }
//                     {
//                         (item.deliveryStatus == 'Dispatched') && (<><span className="badge" style={{color: '#fff',backgroundColor: '#f838f6'}}>{item.deliveryStatus}</span><br/></>)
//                     }
//                     {
//                         (item.deliveryStatus == 'Delete') && (<><span className="badge" style={{color: '#fff',backgroundColor: 'red'}}>Remove</span><br/></>)
//                     }
                    
                    
//                     <span class="badge" style={{color: '#fff', backgroundColor: '#ff7600'}}>Test Hub</span> <br/>
//                     <b>Order ID : {item.orderID}</b>
//                  </td>
//                  <td>
//                     {item.pType} kg<br/> 
//                     Parcel Description: {item.pDescription}                   
//                  </td> 
//                  <td>
//                     {item.recipientName}<br/>
//                     {item.recipientMobile}<br/>
//                     {item.recipientAddress}<br/>
//                     {{...cityNames.find(element => element.cid == item.zoneCity)}.cname}<br/>
//                     {{...zoneNames.find(element => element.cid == item.recipientCity)}.cname}  
//                  </td>
//                  <td>
//                     {item.pickupFrom}<br/>
//                     {item.pickupAddress} <br/>
//                     {{...zoneNames.find(element => element.cid == item.pickupCity)}.cname}      
//                  </td>
//                     <td>{item.paymentType}</td>
//                  <td>
//                     <p style={{fontSize: 'smaller'}}>Delivery:0 LKR</p>
//                  </td>  
                    
//                  <td>
//                     {item.adminNote ? item.adminNote : "-"}
//                  </td> 
//              </tr>         
//              <tr></tr>
//              </>
//              )
//             })}
//          </tbody>
//      </table>
//      </div>
//  </div>
//          </div>

//     )
// }

//export default PrintSearch;