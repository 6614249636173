import axios from "axios";
import { useEffect, useState } from "react";
import './modal.css';

function ModalA({waybill, title, modalIsClose, adminNUserInfo, clientNInfo}) {
    let i = 0;
    // const [parcel, setParcel] = useState([{
    //     paymentType:"",
    //     hub_id:""
    // }]);

    const [parcel, setParcel]   = useState([]);
    const [spinner, setSpinner] = useState(true);
    
    const [hubName, setHubName] = useState("");
    const [userName, setUserName] = useState("");
    const [logDetails, setLogDetails] = useState([{
        log:"",
        date:""
    }]);

    
    async function hubInfoRequestN(logHub) {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getHub`, {
          method: 'POST', 
          body: JSON.stringify({
            hubID: logHub,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            // console.log(data.Clstatus);
            setHubName(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }

    useEffect(() => {
        if(waybill != ''){

            getData();
            getLogDetails();
        }
    },[waybill])

    useEffect(() => {
        if(parcel[0]) {
            getHubName();
            getUserData();
            
            if(parcel[0].hub_id){
                const HubNid = parcel[0].hub_id;         
                hubInfoRequestN(HubNid);      
            }
        }

    }, [parcel])

    async function getData() {
        // axios.post(`${process.env.REACT_APP_URL}/modal.php`, {waybillId:waybill})
        //     .then((res) => {
        //         setParcel(res.data);
                
        //     })
        //     .catch(err => console.log(err));

        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getModalData`, {
            method: 'POST', 
            body: JSON.stringify({
                waybillId : waybill                               
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             //console.log(data.Clstatus);
             setParcel(data.Clstatus);
            
            })
        } catch (error) {
        console.log(error.message)
        }
    }

    function getHubName() {
        
        // axios.post(`${process.env.REACT_APP_URL}/get_hub_name.php`, {hubid: parcel[0].hub_id})
        //     .then((res) => {
                
        //         setHubName(res.data[0].name);
        //     })
        //     .catch(err => console.log(err));
    }

    async function getUserData() {
        
        // axios.post(`${process.env.REACT_APP_URL}/get_user_data.php`, {id: parcel[0].pickupBy})
        //     .then((res) => {
                
        //         setUserName(`${res.data[0].fname} ${res.data[0].lname}`);
        //     })
        //     .catch(err => console.log(err));

        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getUserData`, {
            method: 'POST', 
            body: JSON.stringify({
                id: parcel[0].pickupBy                               
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             //console.log(data.Clstatus); 
             
             setUserName(data.Clstatus[0] ? `${data.Clstatus[0].fname} ${data.Clstatus[0].lname}` : '');
            
            })
        } catch (error) {
        console.log(error.message)
        }

    }

    async function getLogDetails() {
        
        // axios.post(`${process.env.REACT_APP_URL}/get_log.php`, {id: waybill})
        //     .then((res) => {
                
        //         setLogDetails(res.data);
        //     })
        //     .catch(err => console.log(err));

        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getLogDetails`, {
            method: 'POST', 
            body: JSON.stringify({
                id: waybill                              
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             console.log(data.Clstatus);
             //setParcel(data.Clstatus);
             setLogDetails(data.Clstatus);
             setSpinner(false);
            })
        } catch (error) {
        console.log(error.message)
        }
    }

    //console.log(logDetails)
    //console.log(userName)
    //console.log(parcel[0].pickupBy)
    return(
        <div className="modal" id="modalBTNLoad" style={{display: 'block', overflow: 'auto'}}>
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle" style={{marginLeft: 'auto'}}>Parcel&nbsp;{title} | Parcel ID:&nbsp;{waybill}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {modalIsClose(false)}}>
                            <span aria-hidden="true" style={{color: '#ffffff00'}}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-7 col-lg-7 col-md-7">
                                    <div className="container card">
                                        <div className="row">
                                            <h5 style={{margin:'auto'}} className="text-center mb-3">Waybill Timeline</h5>
                                        </div>
                                        <div className="row">
                                           
                                                
                                                   {
                                                     (spinner) ? (
                                                        <div className="card" style={{  textAlign: 'center', display: 'block', width: '100%' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> 
                                                         
                                                        ): (
                                                       
                                                         logDetails?.map((item) => {
                                                            i++;
                                                            
                                                            return (
                                                            <div>
                                                                <ul className="timelines">
                                                                    <li key={i} className="timelines-item rounded p-4 mb-3" style={{boxShadow: '1px 1px 14px 3px #d8cece', textAlign: 'center'}}>
                                                                        <div className="timelines-arrow"></div>
                                                                        <h2 className="h5 mb-0"> {item.log} </h2>
                                                                        <span className="small text-gray"><i className="fa fa-clock-o mr-1"></i>{item.date}</span>
                                                                        <p className="text-small mt-2 font-weight-light">
                                                                        {
                                                                            (() => {
                                                                                if((item.user_type).toLowerCase() === 'admin' || (item.user_type).toLowerCase() === 'hub admin') {
                                                                                        return (
                                                                                        <>By : {{...adminNUserInfo.find(element => element.id == item.userid)}.fname} {{...adminNUserInfo.find(element => element.id == item.userid)}.lname} ({item.userid})</> 
                                                                                        )
                                                                                }else if((item.user_type).toLowerCase() === 'vendor'){
                                                                                        return (
                                                                                            <>By :  {{...clientNInfo.find(element => element.id == item.userid)}.fName} {{...clientNInfo.find(element => element.id == item.userid)}.lName} ({item.userid})</>
                                                                                        )
                                                                                }else{
                                                                                   return(<></>) 
                                                                                }
                                                                            })()  
                                                                        } 
                                                                        </p>
                                                                    </li>
                                                                </ul> 
                                                            </div>
                                                            )
                                                            })   
                                                        
                                                         
                                                            // spinner ? 
                                                            // <div className="card bg-danger">
                                                            //     <div className="card-body">
                                                            //         <h5 className="text-center text-white"> No Records</h5> 
                                                            //     </div> 
                                                            // </div>
                                                        )
                                                      
                                                    }
                                                 
                                                    
                                                
                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 col-lg-5 col-md-5">
                                    <div className="row">
                                        <h5 style={{margin:'auto'}} className="mb-3">Parcel Details</h5>
                                    </div>
                                    <div className="row" style={{fontWeight: '500', border: '1px solid #ece5e5', borderRadius: '15px',boxShadow: '1px 1px 14px 3px #d8cece',fontSize: '14px'}}>
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body" style={{paddingBottom:'0px',marginBottom:'0px'}}> 
                                                    <table className="table" style={{width:'100%', paddingBottom:'0px',marginBottom:'0px'}}> 
                                                        <tbody>
                                                            <tr>
                                                                <td style={{border:'none'}}> Delevery Fee</td>
                                                                <td style={{border:'none'}}>:</td>
                                                                <td style={{border:'none'}}>{parcel[0] ? (parcel[0].deliveryFee == 0 ? "-" : `${parcel[0].deliveryFee} LKR`) : ""}</td>
                                                            </tr> 
                                                            <tr>
                                                                <td>Payment Method</td>
                                                                <td>:</td>
                                                                <td>{parcel[0] ? parcel[0].paymentType:""}</td>
                                                            </tr>  
                                                            <tr>
                                                                <td>Hub</td>
                                                                <td>:</td>
                                                                <td>{hubName.name}</td>
                                                            </tr>   
                                                        </tbody>
                                                    </table> 
                                                </div> 
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h5 style={{margin:'auto'}} className="mb-3 mt-3">Recipient Details</h5>
                                    </div>
                                    <div className="row" style={{fontWeight: '500', border: '1px solid #ece5e5', borderRadius: '15px',boxShadow: '1px 1px 14px 3px #d8cece',fontSize: '14px'}}>
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body" style={{paddingBottom:'0px',marginBottom:'0px'}}> 
                                                    <table className="table" style={{width:'100%', paddingBottom:'0px',marginBottom:'0px'}}> 
                                                        <tbody>
                                                            <tr>
                                                                <td style={{border:'none'}}> Name</td>
                                                                <td style={{border:'none'}}>:</td>
                                                                <td style={{border:'none'}}>{parcel[0] != null ? parcel[0].recipientName:""}</td>
                                                            </tr> 
                                                            <tr>
                                                                <td>Address</td>
                                                                <td>:</td>
                                                                <td>{parcel[0] ? parcel[0].recipientAddress:""}</td>
                                                            </tr>  
                                                            <tr>
                                                                <td>Contact No</td>
                                                                <td>:</td>
                                                                <td>{parcel[0] ? parcel[0].recipientMobile:""}</td>
                                                            </tr>   
                                                        </tbody>
                                                    </table> 
                                                </div> 
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h5 style={{margin:'auto'}} className="mb-3 mt-3">Pickup Details</h5>
                                    </div>
                                    <div className="row" style={{fontWeight: '500', border: '1px solid #ece5e5', borderRadius: '15px',boxShadow: '1px 1px 14px 3px #d8cece',fontSize: '14px'}}>
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body" style={{paddingBottom:'0px',marginBottom:'0px'}}> 
                                                    <table className="table" style={{width:'100%', paddingBottom:'0px',marginBottom:'0px'}}> 
                                                        <tbody>
                                                            <tr>
                                                                <td style={{border:'none'}}> Pickup BY</td>
                                                                <td style={{border:'none'}}>:</td>
                                                                <td style={{border:'none'}}>{userName != "undefined undefined" ? userName : "-"}</td>
                                                            </tr> 
                                                            <tr>
                                                                <td>Pickup From</td>
                                                                <td>:</td>
                                                                <td>{parcel[0] ? parcel[0].pickupFrom:""}</td>
                                                            </tr>  
                                                            <tr>
                                                                <td>Address</td>
                                                                <td>:</td>
                                                                <td>{parcel[0] ? parcel[0].pickupAddress:""}</td>
                                                            </tr>   
                                                            <tr>
                                                                <td>Contact No</td>
                                                                <td>:</td>
                                                                <td>{parcel[0] ? parcel[0].pickupMobile:""}</td> 
                                                            </tr>   
                                                        </tbody>
                                                    </table> 
                                                </div> 
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
            </div>
        </div>
    )
}

export default ModalA;