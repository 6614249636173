import { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';

import Waybill from '../Components/search-fields/Waybill';
import Search from '../Components/search-fields/Search';
import ParcelType from '../Components/search-fields/ParcelType';
import FromDate from '../Components/search-fields/FromDate';
import ToDate from '../Components/search-fields/ToDate';
import PaymentType from '../Components/search-fields/PaymentType';
import OrderID from '../Components/search-fields/OrderId';
import TableBody from "../Components/TableBody";
import Pagination from "./Pagination";
import ModalView from "./ModalView";
import ModalEdit from "./ModalEdit";
import ModalA from "./ModalA";


import { handleExport } from "./printExport";

function Wa({cityNames, zoneNames}) {

    const [Data, setData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const ClientId = sessionStorage.getItem('UserID');
    //const [cityNames, setCityName] = useState([]);
    //const [zoneNames, setZoneName] = useState([]);

    const [editModalIsOpen, setEditModalIsOpen]       = useState(false);
    const [editWaybillID, setEditWaybill]             = useState('');

    useEffect(() => {
        getData();        
    }, [])

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = Data.slice(firstIndex, lastIndex);
    

    const[display, setDisplay] = useState('none');
    const[waybillID, setwaybill] = useState('');

    function changeDisplay(val) {
        setDisplay(val);
    }
    
    const [val, setVal] = useState({
        waybill_id:"",
        search:"",
        parcelType:"",
        fdate:"",
        tdate:"",
        paymentType:"",
        order_id:""
    });

    function handleChange(e) {
        const inputValue = e.target.value;
        const inputName = e.target.name;
        //setVal(e.target.value);

        setVal({...val, [inputName]:inputValue});
        
    }

   
    
    async function handleSearch() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/waiting_parcel`, {
            method: 'POST', 
            body: JSON.stringify({
                clientId: ClientId,  
                waybill_id: val.waybill_id,
                search: val.search,
                parcelType: val.parcelType,
                fdate: val.fdate,
                tdate: val.tdate,
                paymentType: val.paymentType, 
                order_id: val.order_id,                    
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {            
            setData(data.Clstatus);
            setVal({waybill_id: '', search: ''});
            setCurrentPage(1);
            })
        } catch (error) {
        console.log(error.message)
        }
    }

    // async function getData() {
    //     try {
    //         await fetch(`${process.env.REACT_APP_URL}/index.php/waiting_parcel`, {
    //         method: 'POST', 
    //         body: JSON.stringify({
    //             clientId: ClientId, 
    //             waybill_id: '',
    //             search: '',
    //             parcelType: '',
    //             fdate: '',
    //             tdate: '',
    //             paymentType: '',
    //             order_id: '',                                       
    //         }),         
    //         headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //     } )
    //         .then((respose) => {
    //         if (respose.ok) {
    //             return respose.json()
    //         }
    //         throw new Error('error')
    //         })
    //         .then((data) => {
    //         // console.log(data.Clstatus);
    //         setData(data.Clstatus);
            
    //         })
    //     } catch (error) {
    //     console.log(error.message)
    //     }
    // }

    async function getData() {
        //console.log(val)
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/waiting_parcel`, {
            method: 'POST', 
            body: JSON.stringify({
                clientID: ClientId, 
                waybill_id: val.waybill_id,                  
                search: val.search,
                paymentType: val.paymentType,
                parcelType: val.parcelType,
                order_id: val.order_id,
                fdate: val.fdate,
                tdate: val.tdate,
                //status: val.status                                
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             console.log(data.Clstatus);
            setData(data.Clstatus);
            setCurrentPage(1);
            })
        } catch (error) {
        console.log(error.message)
        }
    }

    document.title = "Logistic Client Portal | Waiting Order List";

    return(
        <>
        <div id="layout-wrapper">
            <TopBar/>
            <SideBar/>
            <div className='main-content'>
                <div className='page-content'>
                    <div className='container-fluid'>
                        <div className="row mt-3 mb-3">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <h4 className="font-size-18">Waiting Order List</h4>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                                <div className='row'>
                                    
                                    <Waybill func={handleChange}/>
                                    <Search func={handleChange}/>
                                    <ParcelType func={handleChange}/>
                                    <FromDate func={handleChange} label="Order From"/>
                                    <ToDate func={handleChange} label="Order To"/>  
                                    <PaymentType func={handleChange}/>
                                    <OrderID func={handleChange}/>
                                </div>

                                <div className="row">
                                    <div className="col-8 col-md-6 col-lg-6"> </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-block" id="searchBtn" onClick={getData}> Search</button>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            {/* <button className="btn btn-info btn-block" id="btnSeartchExport" onClick={() => {handleExport('http://localhost/hs-login-system-master/waiting_parcel.php', setData, val)}}> Search &amp; Export</button> */}
                                            <button className="btn btn-info btn-block" id="btnSeartchExport" onClick={() => {handleExport(`${process.env.REACT_APP_URL}/index.php/waiting_parcel`, setData, {...val, clientID : ClientId})}}> Search &amp; Export</button>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            {/* <button className="btn btn-success btn-block" id="btnSearchPrint"> Print</button> */}
                                            <Link to={`/parcel-print/${ClientId}?waybill_id=${val.waybill_id}&search=${val.search}&parcelType=${val.parcelType}&orderID=${val.order_id}&fdate=${val.fdate}&tdate=${val.tdate}&paymentType=${val.paymentType}&status=Waiting&type=waitingParcel`} className="btn btn-success btn-block" target="_blank" onClick={getData}>Print</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-xl-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div id="aa">
                                                    <p><span className="badge" style={{color: '#fff', backgroundColor: '#0054a6'}}>Total Parcel Count - {Data.length}</span></p>
                                                    <div id='target-content'>
                                                        {
                                                            (Data.length !== 0) ? (
                                                                <div className='table-responsive'>
                                                                    <table className='table'>
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th style={{width:'125px'}}>Order Info</th>
                                                                                <th style={{width:'150px'}}>Parcel Info</th>
                                                                                <th style={{width:'150px'}}>Recipient Info</th>
                                                                                <th style={{width:'150px'}}>Pickup Info</th> 
                                                                                <th style={{width:'150px'}}>Pay Method</th>
                                                                                <th style={{width:'170px'}}>Amount</th>
                                                                                <th style={{width:'155px'}}>Admin Note</th>
                                                                                <th style={{width:'150px'}}>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                records.map( (item) => {
                                                                                    return (
                                                                                    
                                                                                        <TableBody key={item.id} item = {item} cityNames = {cityNames} zoneNames = {zoneNames} setwaybill = {setwaybill} changeDisplay={changeDisplay} type = "waiting" setEditModalIsOpen={setEditModalIsOpen} setEditWaybill = {setEditWaybill} setModalIsOpen={setModalIsOpen}/>
                                                                                    
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <nav>
                                                                        
                                                                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={Data} itemPerPage={recordsPerPage}/>
                                                                    </nav>
                                                                </div>
                                                            ) : (
                                                                <div className="card bg-danger">
                                                                    <div className="card-body">
                                                                        <h5 className="text-center text-white"> No parcel</h5> 
                                                                    </div> 
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ModalView display={display} waybill = {waybillID} func={changeDisplay}/> */}
        </div>

        {editModalIsOpen === true &&(<ModalEdit EWaybill = {editWaybillID} modalEditIsClose={setEditModalIsOpen}/>)}
        <div className={(editModalIsOpen) ? "modal-backdrop show" : ""}></div>

        {modalIsOpen === true &&(<ModalA waybill = {waybillID} title = "Waiting" modalIsClose={setModalIsOpen}/>)}
        <div className={(modalIsOpen) ? "modal-backdrop show" : ""}></div>
        </>
    )
}

export default Wa;