import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Pagination from "./Pagination";
import {Link, useParams} from "react-router-dom";

export default function CsvWaybillHistryTble({parcelTypeA,zoneNames}) {
  document.title = "Logistic Client Portal | Waybill Upload History";

  let  {upId}     = useParams();

  const [parcelHistory, setParcelHistory]   = useState('');
  const [isWaybillUploadHtyLoad, setIsWaybillUploadHtyLoad]   = useState(true);

  const ClientPHistry = sessionStorage.getItem('UserID');

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = parcelHistory.slice(firstIndex, lastIndex); 
  
  async function parcelHistryRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getUploadParcel`, {
        method: 'POST', 
        body: JSON.stringify({             
            uploadId: upId,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
            setParcelHistory(data.Clstatus);
            setIsWaybillUploadHtyLoad(false);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {   
    parcelHistryRequest();
    
  }, [])


  return (
    <>
    <div id="layout-wrapper">
      <TopBar/>
      <SideBar/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <div className="page-title-box">
                        <h4 className="font-size-18">Waybill Upload History / Upload ID: {upId}</h4>
                    </div>
                </div>
                <div className="col-sm-6" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    <Link to={`/csvWaybillUploadBulkPrint/${upId}/${ClientPHistry}`} className="btn btn-success btn-sm" target="_blank" style={{float:'right'}} >Print</Link>                
                </div>
              </div>
                                  
              <div className="row">
                <div className="col-xl-12">
                  <div className="card  mt-3">
                    <div className="card-body">
                                          
                      <div id=""> 
                        {
                          (parcelHistory.length !== 0) ? (
                          <>
                            <p><b>Count - {parcelHistory.length}</b></p>

                            <div id="target-content">
                              <div className="table-responsive">
                                <table  className="table">
                                  <thead  className="thead-dark">
                                    <tr>
                                      <th>Order Info</th>
                                      <th>Parcel Info</th>   
                                      <th>Recipient Info</th>   
                                      <th>Pickup Info</th>
                                      <th>Pay Method</th>     
                                      <th>Amount</th>
                                      <th>Client Note</th>  
                                    </tr>
                                  </thead> 
                                                                         
                                  <tbody>
                                    {                                                                                         
                                    records.map(item=>{
                                    return(                                                                                              
                                      <tr key={item.id}>    
                                        <td className="text-left">{item.id}<br/>{item.orderDate}</td>
                                        <td className="text-left">
                                        {{...parcelTypeA.find(element => element.id == item.pType)}.pName}<br/>{item.pDescription}</td>
                                        <td>
                                            {item.recipientName}
                                            <br/>{item.recipientMobile}
                                            <br/>{item.recipientAddress}
                                            <br/>{{...zoneNames.find(element => element.cid == item.recipientCity)}.cname}
                                        </td>
                                        <td>
                                            {item.pickupFrom}
                                            <br/>{item.pickupAddress}
                                            <br/>{{...zoneNames.find(element => element.cid == item.pickupCity)}.cname}
                                        </td>
                                        <td>{item.paymentType}</td>
                                        <td>{item.CODAmount} LKR
                                            <br/><p style={{fontSize: 'smaller'}}>(Delivery: {item.deliveryFee} LKR)</p>
                                        </td>
                                        <td>
                                            {
                                                (() => {
                                                    if(item.clientNote){
                                                        return (
                                                            <>{item.clientNote}</>                                                                                                                
                                                        )
                                                                                                           
                                                    }else{
                                                        return (
                                                            <> - </>
                                                        )  
                                                    }
                                                })()  
                                            }  
                                        </td>  
                                                                                  
                                       </tr>                                                                                            
                                    )
                                    })
                                    }                                                                                                                                                              
                                  </tbody>                                                                                                  
                                </table>
          
                                <nav>                                                                       
                                  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={parcelHistory} itemPerPage={recordsPerPage}/>
                                </nav>
                              </div>
                            </div>
                           
                          </>
                          ): (
                          isWaybillUploadHtyLoad ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                              <div className="card bg-danger">
                                  <div className="card-body">
                                      <h5 className="text-center text-white"> No Records</h5> 
                                  </div> 
                              </div>
                          )
                        } 
                      </div>
                      
                    </div>
                  </div>
                </div> 
              </div>

            </div>
          </div>
          <footer className="footer">
            <Footer/>
          </footer>
        </div>
    </div>
    </>
  )
}
