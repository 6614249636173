import { useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';

export default function DeliveredReport() {  

    document.title = "Logistic Client Portal | Delivered Report";
    
    const [parcelType, setparcelType]           = useState('');
    const [parcelFromDate, setparcelFromDate]   = useState('');
    const [parcelToDate, setparcelToDate]       = useState('');
    
    const [formErrors, setformErrors] = useState({});
    
    const [isPTypeActive, setIsPTypeActive]           = useState(false);
    const [isPFromdateActive, setIsPFromdateActive]   = useState(false);
    const [isPTodateActive, setIsPTodateActive]       = useState(false);
    
    const TypeChange = (event) => {
        setparcelType(event.target.value)
        setIsPTypeActive(false) 
    }

    const FromDateHandler = (e) => {
        setparcelFromDate(e.target.value);
        setIsPFromdateActive(false)       
    };

    const ToDateHandler = (e) => {        
        e.preventDefault();
        setparcelToDate(e.target.value);           
        setIsPTodateActive(false)       
    };

    const validate = (parcelType,parcelFromDate,parcelToDate) => {
      const errors = {};

      if(!parcelType){
        errors.parcelType = "This field is required!";  
        setIsPTypeActive(true)    
      }else{
        setIsPTypeActive(false)
       }

      if(!parcelFromDate){
        errors.parcelFromDate = "This field is required!";      
        setIsPFromdateActive(true)
      }else{
        setIsPFromdateActive(false)
      }

      if(!parcelToDate){
        errors.parcelToDate = "This field is required!";
        setIsPTodateActive(true)
      }else{
        setIsPTodateActive(false)
      }

      return errors;
    }

    const submitDelHandler = (event) => {
      event.preventDefault()

      var logUser = sessionStorage.getItem("UserID");
            
      setformErrors(validate(parcelType,parcelFromDate,parcelToDate));        

      var errorLength =  (Object.keys(validate(parcelType,parcelFromDate,parcelToDate)).length)
                    
         if(errorLength === 0 ){

         window.open(`/delivered_report_load/${logUser}/${parcelType}/${parcelFromDate}/${parcelToDate}`);

         setparcelType('');
         setparcelFromDate('');
         setparcelToDate('');                  
        }
      }

    
    return (
      <div>
  <div id="layout-wrapper">

  <TopBar/>

  <SideBar/>
    
    <div className="main-content">

      <div className="page-content">
        <div className="container-fluid" style={{backgroundColor: '',margin: '' }}>
               
                <div className="row">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <div className="page-title-box">
                      <h4 className="font-size-18" >Delivered Report</h4>
                     </div>
                    </div>
                </div>

                <div className="row justify-content-md-center">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="card-body">
                                <form id="deliverReportFrm" name="deliverReportFrm" onSubmit={submitDelHandler} method="post">
                                    <div className="form-group">
                                        <label htmlFor="type">Type :</label>
                                        <select className="form-control" id="parcelType" name="parcelType" value={parcelType} onChange={TypeChange}  >
                                            <option value="">Select Type</option>
                                            <option value="Complete">Delivered</option>
                                        </select>
                                        <p style={{display: isPTypeActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrors.parcelType}</p>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label htmlFor="fromdate">From:</label>
                                        <input type="date" name="needDate" className="form-control" id="needDate" value={parcelFromDate} onChange={FromDateHandler} />
                                        <p style={{display: isPFromdateActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrors.parcelFromDate}</p>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="todate">To:</label>
                                        <input type="date" name="needToDate" className="form-control" id="needToDate" value={parcelToDate} onChange={ToDateHandler} />
                                        <p style={{display: isPTodateActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrors.parcelToDate}</p>
                                    </div>
                                       
                                    <div className="inner-repeater mb-4">
                                        <div style={{float: 'right',marginTop: '10px' }}>
                                            <button id="goNextBtn" type="submit" className="btn btn-success inner">Go</button>
                                        </div> 
                                    </div>
                                </form>   
                            </div>
                        </div>
                    </div>    
                </div>
          
     
        </div>
   
      </div>
    
      <footer className="footer">
        <Footer/>
      </footer>

    </div>
 

  </div>
     
</div>

 

    )
}
