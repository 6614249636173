import { useEffect, useState } from "react";
import axios from "axios";

function ParcelType(props) {

    const [parcelType, setParcelType] = useState([{id:'',pName:'',pSize:''}]);

    useEffect(() => {
        getParcelType();
    },[])
    
    // function getParcelType() {
    //     axios.get(`${process.env.REACT_APP_URL}/index.php/getParcelType`)
    //         .then(res => {
    //             setParcelType(res.data.Clstatus);
    //         })
    //         .catch(err => console.log(err))
    // }

    async function getParcelType() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelType`, {
            method: 'POST',          
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {          
                setParcelType(data.Clstatus);
            })
        } catch (error) {
          console.log(error.message)
        }
    }

    console.log(parcelType)

    return(
        <div className="col-3 col-md-3 col-lg-3 form-group">
            <label Htmlfor="parcelType">Parcel Type :</label>
            <select onChange={props.func} name="parcelType" id="parcelType" className="form-control">
                <option value="">Select Parcel Type</option>
                {
                    parcelType.map((item, index) => {
                        return(
                            <option key={index} value={item.id}>{`${item.pName} - ${item.pSize}`}</option>
                        )
                    })
                }
            </select>
        </div>
        
    )
}

export default ParcelType;