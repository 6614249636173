import { useState, useEffect } from "react";
import axios from "axios";
import {Link} from "react-router-dom";

import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';

import Waybill from '../Components/search-fields/Waybill';
import Search from '../Components/search-fields/Search';
import ParcelType from '../Components/search-fields/ParcelType';
import FromDate from '../Components/search-fields/FromDate';
import ToDate from '../Components/search-fields/ToDate';
import PaymentType from '../Components/search-fields/PaymentType';
import OrderId from "../Components/search-fields/OrderId";
import TableBody from "../Components/TableBody";
import Pagination from "./Pagination";

import ModalView from "./ModalView";
import ModalA from "./ModalA";
import { handleExport } from "./printExport";
import TableHead from "../Components/TableHead";

if((sessionStorage.getItem('UserID')) !== null){   
    
    (localStorage.setItem('loggedUser', "userLogged"))

}else{
    
    (localStorage.setItem('loggedUser', "userNotLogged")) 
}

const user = sessionStorage.getItem('UserID');


function DeliveredParcel({cityNames, zoneNames, adminInfo, clientInfo}) {

    if((sessionStorage.getItem('UserID')) !== null){   
    
        (localStorage.setItem('loggedUser', "userLogged"))
    
    }else{
        
        (localStorage.setItem('loggedUser', "userNotLogged")) 
    }
    
    
    const ClientId = sessionStorage.getItem('UserID');

    const[Data, setData] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    
     const [isSaBtnInactive, setIsSaBtnInactive]    = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = Data.slice(firstIndex, lastIndex);
    
    const[display, setDisplay] = useState('none');
    const[waybillID, setwaybill] = useState('');
    


    useEffect(() => {
        getData();
        //handleSearch();
    }, [])

    function changeDisplay(val) {
        setDisplay(val);
    }
    
    const [val, setVal] = useState({
        waybill_id:"",
        search:"",
        parcelType:"",
        fdate:"",
        tdate:"",
        paymentType:"",
        order_id:""
    });

    function handleChange(e) {
        const inputValue = e.target.value;
        const inputName = e.target.name;
        

        setVal({...val, [inputName]:inputValue});
        
    }
    

    function handleSearch() {
        axios.post(`${process.env.REACT_APP_URL}/delivered_parcel.php`, {...val, clientID : user})
            .then((res) => {
                //console.log(res.data)
                setData(res.data);
                setVal({
                    waybill_id:"",
                    search:"",
                    parcelType:val.parcelType,
                    fdate:val.fdate,
                    tdate:val.tdate,
                    paymentType:val.paymentType,
                    orderID:""
                })
                setCurrentPage(1);
            })
            .catch(err => console.log(err));
    }

    // function getData() {
    //     axios.get('http://localhost/hs-login-system-master/delivered_parcel.php')
    //         .then((res) => {
    //             //console.log(res.data)
    //             setData(res.data);
    //         })
    //         .catch(err => console.log(err));
    // }

    async function getData() {
        setIsSaBtnInactive(true);
        //console.log(val)
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/deliveredParcel`, {
            method: 'POST', 
            body: JSON.stringify({
                clientID: ClientId, 
                waybill_id: val.waybill_id,                  
                search: val.search,
                paymentType: val.paymentType,
                parcelType: val.parcelType,
                order_id: val.order_id,
                fdate: val.fdate,
                tdate: val.tdate,
                //status: val.status                                
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             console.log(data.Clstatus);
            setData(data.Clstatus);
            setSpinner(false);
            setCurrentPage(1);
            setVal({waybill_id: "",
            search: "",
            parcelType:val.parcelType,
            fdate:val.fdate,
            tdate:val.tdate,
            paymentType:val.paymentType,
            order_id:""});
            })
        } catch (error) {
        console.log(error.message)
        }
        setIsSaBtnInactive(false); 
    }

    document.title = "Logistic Client Portal | Delivered Order List";

    return(
        <>
            {(localStorage.getItem('loggedUser')) === "userLogged"? <TopBar/> : <></> }

            {(localStorage.getItem('loggedUser')) === "userLogged"? <SideBar/> : <></> }

            <div className='main-content'>
                <div className='page-content'>
                    <div className='container-fluid'>
                        <div className="row mt-3 mb-3">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <h4 className="font-size-18">Delivered Parcel</h4>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                                <div className='row'>
                                    
                                    <Waybill func={handleChange} value = {val.waybill_id}/>
                                    <Search func={handleChange} value = {val.search}/>
                                    <ParcelType func={handleChange} value = {val.parcelType}/>
                                    <FromDate func={handleChange} label="Delivered From" value = {val.fdate}/>
                                    <ToDate func={handleChange} label="Delivered To" value = {val.tdate}/>  
                                    <PaymentType func={handleChange} value = {val.paymentType}/>
                                    <OrderId func={handleChange} value = {val.order_id}/>

                                </div>
 
                                <div className="row">
                                    <div className="col-8 col-md-6 col-lg-6"> </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-block" id="searchBtn" onClick={getData} disabled={isSaBtnInactive ? true : false}> Search</button>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <button className="btn btn-info btn-block" id="btnSeartchExport" onClick={() => handleExport(`${process.env.REACT_APP_URL}/index.php/deliveredParcel`,setData, setCurrentPage, {...val, clientID : ClientId})}> Search &amp; Export</button>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            {/* <button class="btn btn-success btn-block" id="btnSearchPrint"> Print</button> */}
                                            <Link to={`/parcel-print/${ClientId}?waybill_id=${val.waybill_id}&search=${val.search}&parcelType=${val.parcelType}&orderID=${val.order_id}&fdate=${val.fdate}&tdate=${val.tdate}&paymentType=${val.paymentType}&status=Delivered&type=deliveredParcel`} className="btn btn-success btn-block" target="_blank" onClick={getData}>Print</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-xl-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div id="aa">
                                                    <p><span className="badge" style={{color: '#fff', backgroundColor: '#0054a6'}}>Total Parcel Count - {Data.length}</span></p>
                                                    <div id='target-content'>
                                                        {
                                                            (Data.length !== 0) ? (
                                                                <div className='table-responsive'>
                                                                    <table className='table'>
                                                                        <thead className="thead-dark">
                                                                            <TableHead/>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                records.map( (item) => {
                                                                                    return (
                                                                                        
                                                                            
                                                                                            <TableBody key={item.id} item = {item} cityNames = {cityNames} zoneNames = {zoneNames} setwaybill = {setwaybill} changeDisplay={changeDisplay} type="delivered" setModalIsOpen={setModalIsOpen} dataIsChoose={getData}/>
                                                                                        
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <nav>
                                                                        
                                                                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={Data} itemPerPage={recordsPerPage}/>
                                                                    </nav>
                                                                </div>
                                                            ) : (
                                                                spinner ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                                                                <div className="card bg-danger">
                                                                    <div className="card-body">
                                                                        <h5 className="text-center text-white"> No parcel</h5> 
                                                                    </div> 
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            {modalIsOpen === true &&(<ModalA waybill = {waybillID} title = "Delivered" adminNUserInfo={adminInfo} clientNInfo={clientInfo} modalIsClose={setModalIsOpen}/>)}
            
            <div className={(modalIsOpen) ? "modal-backdrop show" : ""}></div>
        </>
    )
}

export default DeliveredParcel;