import { useEffect, useState } from "react";
import Swal from "sweetalert2";

function ModalTuk({modalTukIsClose}) {
      
    const [tukCount, setTukCount] 								= useState('');
    const [isTukCountActive, setIsTukCountActive] = useState(false);
		const [pkTErrors, setPkTErrors] 							= useState({});

    const ClientD = sessionStorage.getItem('UserID');  
    
    const tukCountHandler = (event) => {
        setTukCount(event.target.value);
        setIsTukCountActive(false)
    }
    
    const validate = (tukCount) => {
      const errors = {};

      if(!tukCount){
        errors.tukCount = "Parcel Count Required";  
        setIsTukCountActive(true)    
      }else{
        setIsTukCountActive(false)
      }

      return errors;
    }


	
    const PkTConfirmHandler = (e) => {

        		e.preventDefault();
						setPkTErrors(validate(tukCount));
						
      			var errorLength =  (Object.keys(validate(tukCount)).length) 
      			
      			if(errorLength === 0 ){
				            async function pkTConfirmRequest() {
		                    try {
		                      await fetch(`${process.env.REACT_APP_URL}/index.php/pickupCount`, {
		                        method: 'POST',
		                        body: JSON.stringify({
		                            parcel_count: tukCount, 
		                            option_select: "Tuk",
		                            clId:ClientD,
		                        }),
		                        headers: {
		                          'Content-Type': 'application/x-www-form-urlencoded',
		                        },
		                      } )
		                        .then((respose) => {
		                          if (respose.ok) {
		                            return respose.json()
		                          }
		                          throw new Error('error')
		                        })
		                        .then((data) => {                                       
		                                   
		                          if(data.Clstatus === "success"){
		                            
			                				setTukCount('');
					                    
						                    Swal.fire({
	                              title: "Good job!",
	                              text: "Successfully Requested",
	                              icon: "success",
	                              confirmButtonText: "OK",
	                              timer: 1000,
	                            	});
		                           
		                          }else{
		                          	
			                          Swal.fire({
	                              title: "Warning",
	                              text: "Not Successful!",
	                              icon: "error",
	                              confirmButtonText: "OK",
	                              timer: 1000,
	                            	});	
		                          }
		                          
		                          modalTukIsClose(false); 
		                                             
		                        })
		                    } catch (error) {
		                      console.log(error.message)
		                    }
		                }
		                pkTConfirmRequest();
      			}
    };

    return (
        <div className="modal" id="" style={{display: 'block'}}>
        	<div className="modal-dialog" role="document">
                <div className="modal-content"> 
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">You Have Requested a Tuk</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> {modalTukIsClose(false)}}>
                            <span aria-hidden="true" style={{color: '#ffffff00'}}>&times;</span>
                        </button>
                    </div>

                    <div className="modal-body"> 
	                    <form action="">
								        <div className="row">
								        	<div className="col-md-12">
									          <div className="form-group">
									          	<label htmlFor=""> Parcel Count</label>
									          	<input type="text" id="tukCount" className="form-control" value={tukCount} onChange={tukCountHandler} />
									          	<p style={{display: isTukCountActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{pkTErrors.tukCount}</p>
									          </div>
								          </div>
								        </div>
								      </form>   
                		</div>
                
		                <div className="modal-footer">
							        <button type="button" className="btn btn-primary" id="bikebtn" onClick={PkTConfirmHandler} >Confirm</button>
							      </div>
                
            	</div>
        </div>
      </div>    
    )
}

export default ModalTuk;


