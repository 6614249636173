import { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Pagination from "./Pagination";
import ModalPaymentData from "./modalPaymentData";
import { exportHandlerPaid } from "./printExportFinancePaid";

export default function PaidInvoice() {
  document.title = "Logistic Client Portal | Paid Invoice History";
  
  const [batchID, setBatchId]         = useState(''); 
  const [inputfdate, setInputfdate]   = useState(''); 
  const [inputtdate, setInputtdate]   = useState(''); 
  
  const [modalPaidBatchIsOpen, setModalPaidBatchIsOpen]   = useState(false);
  const [paidPaymentModalData, setPaidPaymentModalData]   = useState('');

  const [paidBatchData, setPaidBatchData]                 = useState([]);
  const [spinner, setSpinner] = useState(true);

  const ClientIdN = sessionStorage.getItem('UserID');  

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = paidBatchData.slice(firstIndex, lastIndex); 
  
  async function paidInvoicesRequest(){
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getPaidInvoice`, {
        method: 'POST', 
        body: JSON.stringify({             
            clientID: ClientIdN,
            inputfdate: '',
            inputtdate: '',
            batchid: '',       
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setPaidBatchData(data.Clstatus);
          setSpinner(false);
          setCurrentPage(1);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
	paidInvoicesRequest();	    
  }, [])

  const handleBatchID = (e) => {
    setBatchId(e.target.value);
  };

  const handleFDateChange = (e) => {
    setInputfdate(e.target.value);
  };
  
   const handleTDateChange = (e) => {
    setInputtdate(e.target.value);
  };
    
  const searchHandler = (e) => {
        e.preventDefault();
        async function paidInvoicesRequest(){
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getPaidInvoice`, {
                method: 'POST', 
                body: JSON.stringify({             
                    clientID: ClientIdN,
                    inputfdate: inputfdate,
                    inputtdate: inputtdate,
                    batchid: batchID,       
                   }),         
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                    setPaidBatchData(data.Clstatus);
                    setSpinner(false);
                  setCurrentPage(1);
                })
            } catch (error) {
              console.log(error.message)
            }
          }
          paidInvoicesRequest()
  };

  const expoertandSearch = (e) => {
    e.preventDefault();

    exportHandlerPaid(`${process.env.REACT_APP_URL}/index.php/batchPaidNHistory`, setPaidBatchData, batchID, inputfdate, inputtdate, ClientIdN);

    async function paidInvoicesRequesto(){
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getPaidInvoice`, {
                method: 'POST', 
                body: JSON.stringify({             
                    clientID: ClientIdN,
                    inputfdate: inputfdate,
                    inputtdate: inputtdate,
                    batchid: batchID,       
                   }),         
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                    setPaidBatchData(data.Clstatus);
                    setSpinner(false);
                    setCurrentPage(1);
                })
            } catch (error) {
              console.log(error.message)
            }
    }
    paidInvoicesRequesto()
  };
  
  
  return (
    <>
        <div id="layout-wrapper">
            <TopBar/>
            <SideBar/>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row"  style={{padding: '20px',  paddingLeft: '0px'}}>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">                              
                                    <h4 className="font-size-18" >Paid Invoice History</h4>                               
                            </div>
                        </div>

                        <div className="row"> 
                            <div className="col-12 col-md-12 col-lg-12"> 
                                <div className="row">                                                         
                                    <div className="form-group col-4 col-md-4 col-lg-4">  
                                        <label htmlFor="fdate">Batch ID :</label>
                                        <input className="form-control" id="batch_id" name="batch_id" type="text" placeholder="Batch ID" value={batchID} onChange={handleBatchID}/>
                                    </div>
                                                
                                    <div className="form-group col-4 col-md-4 col-lg-4"> 
                                        <label htmlFor="tdate">From :</label>
                                        <input className="form-control" id="fdate" name="fdate" type="date" placeholder="From Date" value={inputfdate} onChange={handleFDateChange} />
                                    </div>

                                    <div className="form-group col-4 col-md-4 col-lg-4"> 
                                        <label htmlFor="tdate">From :</label>
                                        <input className="form-control" id="tdate" name="tdate" type="date" placeholder="To Date" value={inputtdate} onChange={handleTDateChange} />
                                    </div>                                
                                </div> 
                            </div>                              
                        </div>

                        <div className="row">
                            <div className="col-8 col-md-8 col-lg-8"> </div>
                            <div className="col-2 col-md-2 col-lg-2">
                                <div className="form-group">  
                                    <label htmlFor="formname" className="text-white">A</label>
                                    <button className="btn btn-primary btn-block" id="searchBtn" onClick={searchHandler}> Search</button>  
                                </div> 
                            </div>

                            <div className="col-2 col-md-2 col-lg-2">
                                <div className="form-group">  
                                    <label htmlFor="formname" className="text-white">A</label>
                                    <button className="btn btn-success btn-block" id="btnExportVenPenpay" onClick={expoertandSearch}> Search & Export</button>  
                                </div> 
                            </div>  
                        </div> 
                        
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card  mt-3">
                                    <div className="card-body">                                                                                                   
                                                        
                                        <div id="client_list_load"> 
                                            <p><b className="" style={{}}>Count - {paidBatchData.length}</b></p>
                                                <div id="target-content">
                                                {
                                                (paidBatchData.length !== 0) ? (
                                                    <div className="table-responsive">
                                                        <table  className="table">
                                                            <thead  className="thead-dark">
                                                                <tr>
                                                                    <th style={{width: '100px'}}>ID</th>
                                                                    <th style={{width: '150px'}}>Pay Date</th>
                                                                    <th style={{width: '250px'}}>Batch number</th>
                                                                    <th style={{width: '250px'}}>Invoice Count</th>
                                                                    <th style={{width: '170px', textAlign: 'center'}}>Slip</th>
                                                                    <th style={{width: '170px', textAlign: 'right'}}>Amount</th>
                                                                    <th style={{width: '140px', textAlign: 'center'}}>Action</th>
                                                                </tr>
                                                            </thead>
                                                                        
                                                            <tbody>
                                                            {
                                                            records.map(item=>{
                                                            return(                                                                                              
                                                                <tr key={item.id}>    
                                                                    <td>{item.id}</td>
                                                                    <td>{item.date}</td>
                                                                    <td>{item.batchNum}</td>
                                                                    <td>{item.invoiceCount ? item.invoiceCount : " - "}</td>
                                                                    
                                                                    <td style={{textAlign: 'center'}}> 
                                                                    
                                                                        {
                                                                        (() => {
                                                                        if(item.payslip) {
                                                                        
                                                                        let arr             = item.payslip.split('/');
                                                                        const firstElement  = arr[0];
                                                                        const secondElement = arr[2];
                                                                        
                                                                        const full_upload_url = firstElement+"//"+secondElement;
                                                                        
                                                                        if(full_upload_url == "https://box1.ozonedesk.info"){
                                                                        
                                                                            return (
                                                                                <>
                                                                                    <a href={`${item.payslip}`} target="_blank;">View Slip</a>
                                                                                </>
                                                                            )
                                                                            
                                                                        }else{
                                                                        
                                                                            return (
                                                                                <>
                                                                                    <a href={`${process.env.REACT_APP_UPLOAD_URL}/admin/${item.payslip}`} target="_blank;">View Slip</a>
                                                                                </>
                                                                            ) 
                                                                        }
                                                
                                                                            
                                                                        }else{
                                                                        
                                                                            return (
                                                                                <>
                                                                                    <p style={{color: 'red'}}>Not Yet Upload</p>
                                                                                </>
                                                                            )
                                                                        }
                                                                        })()  
                                                                        }  
                                                                    </td>
                                                                    <td style={{textAlign: 'right'}}>
                                                                        {
                                                                        (() => {
                                                                        if(item.amount){
                                                                            return (
                                                                                <>
                                                                                    {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(item.amount).toFixed(2))}
                                                                                </>
                                                                            )
                                                                        }
                                                                        })()  
                                                                        }  
                                                                    </td>
                                                                    <td style={{textAlign: 'center'}}>
                                                                        <button className="btn btn-success btn-sm mr-1" onClick={()=> { setPaidPaymentModalData(item); setModalPaidBatchIsOpen(true); }} >View</button>
                                                                        
                                                                        {
                                                                        (() => {
                                                                        if(item.csv_url) {
                                                                        
                                                                        let arrc             = item.csv_url.split('/');
                                                                        const firstElementc  = arrc[0];
                                                                        const secondElementc = arrc[2];
                                                                        
                                                                        const full_upload_urlc = firstElementc+"//"+secondElementc;
                                                                        
                                                                        if(full_upload_urlc == "https://box1.ozonedesk.info"){
                                                                           
                                                                            return (
                                                                                <>
                                                                                    <a className="btn btn-info btn-sm mr-1" href={`${item.csv_url}`}>CSV</a> 
                                                                                </>
                                                                            )
                                                                            
                                                                        }else{
                                                                        
                                                                            return (
                                                                                <>
                                                                                    <a className="btn btn-info btn-sm mr-1" href={`${process.env.REACT_APP_UPLOAD_URL}/admin/${item.csv_url}`}>CSV</a> 
                                                                                </>
                                                                            )   
                                                                        }
                                                                        
                                                                            
                                                                        }
                                                                        })()  
                                                                        } 
                                                                        <Link to={`/vendor_pay_list_print/${item.batchNum}/${ClientIdN}`} className="btn btn-primary btn-sm" target="_blank">Print</Link>
                                                                    </td>
                                                                </tr>                                                                                            
                                                            )
                                                            })
                                                            }                                                                                   
                                                                            
                                                            </tbody>
                                                        </table>
                                                        <nav>                                                                       
                                                            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={paidBatchData} itemPerPage={recordsPerPage}/>
                                                        </nav>
                                                    </div>
                                                ): (
                                                    spinner ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                                                    <div className="card bg-danger">
                                                        <div className="card-body">
                                                            <h5 className="text-center text-white"> No Records</h5> 
                                                        </div> 
                                                    </div>
                                                )
                                                }
                                                </div>
                                                    {modalPaidBatchIsOpen === true && (<ModalPaymentData paymentModalData={paidPaymentModalData} modalIsClose={setModalPaidBatchIsOpen} />) }                                                                                                                      
                                                           
                                        </div>
                                                        
                                    </div>
                                </div>
                            </div> 
                        </div>
                                                                                                      
                    </div>
                </div>

                <footer className="footer">
                    <Footer/>
                </footer>
            </div>
        </div>
        <div className={(modalPaidBatchIsOpen) ? "modal-backdrop show" : ""}></div>
    </>
  )
}
