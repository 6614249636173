import React, { useRef, useEffect, useState }  from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
import logo from '../images/logo-light.png';
import './print.css';

function CompleteReturnListLoad ({parcelTypeA,zoneNames,cancelReason}){
    
  let  {clientid}  = useParams();
  let  {type}      = useParams();
  
   const [isPrintingCom, setIsPrintingCom] = useState(false); 

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  document.title = "Logistic Client Portal | Complete Return Hand Over To Vendor List Print";

var today = new Date();

var todaytime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

var todaydate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

var currntDate = todaydate+" "+todaytime;

  const [clientData, setclientData] = useState([]); 
  
  useEffect(() => {
    async function clientDetailsRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: clientid,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setclientData(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
      clientDetailsRequest();
      sessionStorage.setItem("UserID", clientid); 
  }, []);


  const [dCarcels, setCParcels] = useState([]);
  
    useEffect(() => {
    async function parcelDRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getRCParcel`, {
            method: 'POST', 
            body: JSON.stringify({
                clientId: clientid,  
                ptype: type,                         
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {            
                setCParcels(data.Clstatus);
                setIsPrintingCom(true);
            })
        } catch (error) {
        console.log(error.message)
        }
    }
    parcelDRequest();   
     
    }, []);   

    // useEffect(() => {
    // handlePrint()
    // }, [handlePrint]);
    
    useEffect(() => {
    if (isPrintingCom) {
     
        handlePrint()
    }
  }, [isPrintingCom, handlePrint]);


  const myStyle = {
    border: '1px solid #94d0e8', 
    marginTop: '8px',
    marginBottom: '8px'
    };

        
        
  return (
  <body ref={componentRef} style={{ backgroundColor: 'white', height: '29.7cm', width: '21cm', fontFamily: 'times new roman', color: '#000000'}} className="printLayoutContainer">   
   {
          (isPrintingCom) ? (
          <>
        <div className="row" style={{  textAlign: 'center', display: 'block',margin: '10px'}}>
            <center><img src={logo} alt="imgLogo" style={{width: '175px', marginTop: '10px'}} /></center>
        </div> 
    
        <div className="row"  style={{ display: 'block',margin: '10px'}}>
            <h3 style={{  textAlign: 'center', fontSize: '18.72px', marginBlockStart: '1em', marginBlockEnd: '1em', fontFamily: 'times new roman'}}> Return & Exchange Parcel List</h3>
            <p style={{  textAlign: 'center', fontSize: '16px', lineHeight: 'normal', fontFamily: 'times new roman'}}> (Date: {currntDate})</p>
            <h4 style={{  textAlign: 'center', marginTop: '-12px', fontSize: '16px', fontFamily: 'times new roman'}}>  Vendor : {clientData.companyName} ({clientid})</h4>   
            {
                (dCarcels.length !== 0) ? (
                    <>
                     
                     <table style={{width: '100%', border: '1px solid #e4e4e0', padding: '10px', borderCollapse: 'separate', color: 'black'}}>
                        <thead>
                            <tr style={{fontSize: '16px'}}>
                                <th style={{width: '100px', border: '1px solid #e4e4e0', padding: '10px', fontWeight: 'bold', inlineSize: '', height: '18px'}}>Waybill ID</th>   
                                <th style={{width: '100px', border: '1px solid #e4e4e0', padding: '10px', fontWeight: 'bold', inlineSize: '', height: '18px'}}>Date</th>         
                                <th style={{width: '100px', border: '1px solid #e4e4e0', padding: '10px', fontWeight: 'bold', inlineSize: '', height: '18px'}}>Parcel Info</th>
                                <th style={{width: '100px', textAlign: 'center', border: '1px solid #e4e4e0', padding: '10px', fontWeight: 'bold', inlineSize: '', height: '18px'}}>Recipient Info</th>
                                <th style={{width: '100px', textAlign: 'right', border: '1px solid #e4e4e0', padding: '10px', fontWeight: 'bold', inlineSize: '', height: '18px'}}>COD</th>
                                <th style={{width: '100px', border: '1px solid #e4e4e0', padding: '10px', fontWeight: 'bold', inlineSize: '', height: '18px'}}>Reason</th> 
                            </tr>
                        </thead>
                    <tbody>
                    {                                                                                                                 
                        dCarcels.map(item=>{
                        return( 
                       
                         <tr key={item.id} style={{border: '1px solid #e4e4e0', padding: '10px', fontSize: '16px', lineHeight: 'normal'}}>
                            <td  style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}} >{item.id}</td>
                            <td style={{border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle'}}>{item.cancellation_date ? item.cancellation_date : "-"}</td>
                            <td style={{border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle'}}>
                                {{...parcelTypeA.find(element => element.id == item.pType)}.pName}
                                
                                 <br/>
                                 {item.pDescription}
                                  <br/>
                                 {
                                    (item.orderID) && (<><b>Order ID : {item.orderID}</b></>)
                                 }
                                
                                
                                {
                                    (item.clientNote) && (<><b>Note : {item.clientNote}</b></>)
                                } 
                                 
                            </td>
   
                                 
                                <td style={{border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle'}}>{item.recipientName}
                                 <br/>{item.recipientMobile}
                                  <br/>{item.recipientAddress}
                                  <br/>{{...zoneNames.find(element => element.cid == item.recipientCity)}.cname}
                                 </td>
                                 
                            
                               <td  style={{width: '170px', textAlign: 'right', border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle'}}>
                                {
                                    (item.paymentType === "COD") && (<>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(item.CODAmount).toFixed(2))} LKR<br/></>)
                                } 
                               </td>    
                               <td style={{border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle'}}>
                                {
                                            
                                    (() => {
                                        if(item.exchange === '1'){
                                            return (
                                                <>Exchange</>
                                            )
                                        }else if(item.cancellation_reason){
                                            return (
                                                <>{{...cancelReason.find(element => element.id == item.cancellation_reason)}.reason}</>
                                            )
                                        }else{
                                            return (
                                                <> - </>
                                            )
                                        }
                                    })()
                                }
                                        
                                </td> 
                         </tr>
                        
                        )
                                    
                        })
                    }    
                    </tbody>
                    </table>

                    <table style={{ width: '100%', marginTop: '50px', borderCollapse: 'separate'}}>
                    <tbody>
                        <tr >
                            <td style={{width: '50%'}} > 
                                <div style={{borderBottom: '1px solid #b5abab', width: '50%', margin: 'auto'}}><p style={{padding: '2px', textAlign:'center'}}></p></div>
                            </td>
                        
                            <td style={{width: '50%'}}> 
                                <div style={{borderBottom: '1px solid #b5abab', width: '50%', margin: 'auto'}}><p style={{padding: '2px', textAlign:'center'}}></p></div>
                            </td>
                        </tr>
                        <tr>
                            <td> 
                                <div style={{width: '50%', margin: 'auto'}}><p style={{padding: '2px', textAlign:'center'}}>Authorized Signature</p></div>
                            </td>
                            <td> 
                                <div style={{width: '50%', margin: 'auto'}}><p style={{padding: '2px', textAlign:'center'}}>Vendor's Signature</p></div>
                            </td>
                    </tr>
                    </tbody>
                    </table>

                    <hr style={myStyle}/>
                    <p style={{fontSize: '10px', textAlign:'center', marginTop: '0px', marginBottom: '0px', lineHeight: 'normal'}}>Date : {currntDate}  -   Solution By oZoneDesk</p>
                    <hr  style={myStyle}/>
  
  
                    </>

                ): (
                    <h4 style={{ textAlign: 'center'}}> Invalid</h4>
                )
            } 
             
        
        </div> 
        </>   
        ) : (
        <>
          <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
            <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
          </div> 
        </>
        )
    }
  </body>
  );
}
export default CompleteReturnListLoad;