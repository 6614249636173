import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './App.css'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

// Bootstrap CSS
import "./assets/css/bootstrap.min.css";
import './assets/css/icons.min.css';
import './assets/css/app.min.css';
// import swal from 'sweetalert';

// import "bootstrap/dist/css/bootstrap.min.css";
// import "./assets/libs/jquery/jquery.min";
import "../node_modules/jquery/dist/jquery.min";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";


// import "./assets/libs/bootstrap/js/bootstrap.bundle.min";
import "sweetalert/dist/sweetalert.min";

// import "./assets/libs/metismenu/metisMenu.min";
// import "./assets/libs/simplebar/simplebar.min";
// import "./assets/libs/node-waves/waves.min";




// import "./assets/js/app.js";


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
