import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import ModalPro from "./modalPro";

export default function ClientProfile({cityNames, bankD, branchD}) {
 
  document.title = "Logistic Client Portal | Client Profile";

  const [exClient, setExClient]   					= useState('');
  const [modalPrIsOpen, setModalPrIsOpen]       = useState(false);
  const [modalDataPr, setModalDataPr]           = useState('');
    

  const ClientIdD = sessionStorage.getItem('UserID');
  
  useEffect(() => {
    async function DetailsRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: ClientIdD,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {          
            setExClient(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    DetailsRequest();
  }, []);


  return (
    <>
    <div id="layout-wrapper">
      <TopBar/>
      <SideBar/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              
              <div className="row" style={{padding: '20px'}}>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                     {/* <div className="page-title-box"> */}
                        <h4 className="font-size-18">Profile</h4>
                     {/* </div> */}
                </div>
              </div>
              
             
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="card" style={{height:'100%'}}> 
                        <div className="card-body">               
                            <h4 className="card-title" style={{background: '#dbdbdb55', padding: '25px'}}>Basic Information</h4>                        
                            <table className="table">
                            	<tbody>
                                <tr>
                                    <td>Email (Username)</td>
                                    <td>{exClient.email}</td>
                                </tr>
                                <tr>
                                    <td>Password</td>
                                    <td>{exClient.password}</td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td>{exClient.fName} {exClient.lName}</td>
                                </tr>
                                <tr>
                                    <td>Company Name</td>
                                    <td>{exClient.companyName}</td>
                                    </tr>
                                <tr>
                                    <td>Contact Number</td>
                                    <td>{exClient.contactno}</td>
                                </tr>
                                <tr>
                                    <td>NIC</td>
                                    <td>{exClient.nic}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{exClient.address}</td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                      <td>
                                      {
                                        (() => {
                                            if(exClient.city) {
                                                return (
                                                      <>
                                                        {{...cityNames.find(element => element.cid == exClient.city)}.cname}
                                                      </>
                                                )
                                        }else{
                                                return (
                                                      <></>
                                                )
                                        }
                                        })()  
                                      } 
                                      </td> 
                                </tr>
                              </tbody>  
                            </table>
                        </div>                   
                    </div>        
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="card"> 
                        <div className="card-body">                                                    
                            <table className="table">
                            	<tbody>
                                <tr>
                                    <td style={{borderTop: '1px solid #ffffff'}}>Password</td>
                                    <td style={{borderTop: '1px solid #ffffff'}}><button type="button" className="btn btn-primary" onClick={()=> { setModalDataPr(exClient); setModalPrIsOpen(true); }}>Reset</button></td>
                                </tr>
                              </tbody>                                    
                                
                            {/* <script>
                                $("#resetPwBtn").click(function(){
                                    
                                    var oldPw = $("#old_pw").val();
                                    var newPw = $("#new_pw").val();
                                    $.post( "profile_pw_reset.php", { oldPw:oldPw, newPw:newPw })
                                    .done(function( data ) {
                                        $("#old_pw").val('');
                                        $("#new_pw").val('');
                                        $("#pwChangedDiv").html(data);
                                    });
                                });
                            </script> */}
                                                                                                                   
                            </table>           
                            {modalPrIsOpen === true && (<ModalPro modalDataPr={modalDataPr} modalPrIsClose={setModalPrIsOpen}  />) }  
                        </div>
                    </div>
                        
                    <div className="card"  style={{height:'100%'}}> 
                        <div className="card-body"> 
                        
                            
                        <h4 className="card-title"  style={{background: '#dbdbdb55', padding: '25px'}}>Bank Information</h4>
                            <table className="table">
                            	<tbody>
		                            <tr>
		                                <td>Account Name</td>
		                                <td>{exClient.accName}</td>
		                            </tr>
		                            <tr>
		                                <td>Account Number</td>
		                                <td>{exClient.AccNumber}</td>
		                            </tr>
		                            <tr>
		                                <td>Bank Name</td>
		                                  <td>
		                                
		                                  {
                                        (() => {
                                            if(exClient.bankName) {
                                                return (
                                                      <>
                                                        {{...bankD.find(element => element.id == exClient.bankName)}.bank_name} ({{...bankD.find(element => element.id == exClient.bankName)}.bank_code})
                                                      </>
                                                )
                                        }else{
                                                return (
                                                      <></>
                                                )
                                        }
                                        })()  
                                      }  
                                      </td> 
		                            </tr>
		                            <tr>
		                                <td>Branch</td>
		                                  <td>
		                                  {
                                        (() => {
                                            if(exClient.branch) {
                                                return (
                                                      <>
                                                        {{...branchD.find(element => element.id == exClient.branch)}.branch_name} ({{...branchD.find(element => element.id == exClient.branch)}.branch_code})
                                                      </>
                                                )
                                        }else{
                                                return (
                                                      <></>
                                                )
                                        }
                                        })()  
                                      } 
		                                  </td>
		                            </tr>
	                            </tbody>
                            </table>
                        		<hr/>
                            <div className="card-body" style={{margin: 'auto', textAlign: 'center'}}>
                            <h3 style={{color: '#0078d4'}}> You can not change profile info</h3>
                            <h4 style={{color: 'red'}}>Please contact your agent</h4>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
          <footer className="footer">
            <Footer/>
          </footer>
        </div>
    </div>
     <div className={(modalPrIsOpen) ? "modal-backdrop show" : ""}></div>
    </>
  )
}
