import React, { useRef, useEffect, useState }  from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
import logo from '../images/logo-light.png';
import './print.css';

function CsvBulkPrint (){

  let  {uploadId}     = useParams();
  let  {loggedNUser}  = useParams();
  var count        = 0;
  
  const [isPrintingcsv, setIsPrintingcsv]       = useState(false); 
  const [isPrintingSetCsv, setIsPrintingSetCsv] = useState(false); 

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [setting, setSetting] = useState('');
  const [uParcel, setUParcel] = useState([]);
  
    async function settingRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
            method: 'POST',          
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
            // console.log(data.Clstatus);
            setSetting(data.Clstatus);
            setIsPrintingSetCsv(true);
            })
        } catch (error) {
        console.log(error.message)
        }
    }
    
    useEffect(() => {
    async function parcelURequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getUploadParcel`, {
            method: 'POST', 
            body: JSON.stringify({
                uploadId: uploadId,         
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
            // console.log(data.Clstatus);
            setUParcel(data.Clstatus);
            setIsPrintingcsv(true);
            })
        } catch (error) {
        console.log(error.message)
        }
    }
    parcelURequest();   
    sessionStorage.setItem("UserID", loggedNUser);  
    }, []);

    useEffect(() => {
        settingRequest()
        
      }, []);

    useEffect(() => {
        if (isPrintingcsv) {
          if(isPrintingSetCsv){
            handlePrint()
          }
        }
    }, [isPrintingcsv, isPrintingSetCsv, handlePrint]);


  document.title = "Logistic Client Portal | Parcel Bulk Print";

  const myStyle = {
    lineHeight: '0',
    marginBlockEnd: '8px',
    marginBlockStart: '8px',
    borderColor: 'rgb(154 154 154)',

    marginTop: '0.7rem ',
    marginBottom: '0.7rem'
    };

    const tableStyle = {
      borderCollapse: 'separate'
    }

    const breakAfterStyle = {
        pageBreakAfter: 'always'
    }
  

  return (
  <div ref={componentRef} style={{ backgroundColor: 'white'}} className="printLayoutContainer">
     {
        (isPrintingcsv) ? (
        
        (isPrintingSetCsv) ? (
        <>
    <div className="row" style={{ fontFamily: 'initial', color: '#000000', backgroundColor: 'white', height: '155mm', margin: '0'}}>

    {                                                                                         
                                    uParcel.map(item=>{
                                    return( 
                                    <span key={item.id} >                                                                                           
                                        <div style={{height: '145mm', width:'102mm', float:'left', border:'solid grey 1px', margin: '8px 0px 0px 8px'}}>

                                        <center>
                                            <table style={tableStyle}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{fontSize: '12px'}}>
                                    
                                                            <img src={logo} alt="imgLogo" className="src" style={{width: '135px', marginRight: '80px', marginTop: '5px'}} />
                                                        </td>
                                                        <td style={{fontSize: '12px'}}>
                                                            <label style={{fontSize: '14px', fontWeight: '800', margin:'0'}}>Waybill ID : {item.id}</label>
                                                           {item.exchange === 1 &&
                                                           <p style={{margin: 'auto', textAlign: 'center', fontSize: '12px'}}>Exchange</p>
                                                           }                               
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </center>

                                        <hr style={myStyle} />
                                        <p style={{paddingLeft: '25px', fontSize: '15px',lineHeight: '1.2'}}>
                                            <b style={{fontWeight: 'bold' }} >Recipient Details</b>
                                            <br/>
                                            {/* <br/> */}
                                            Name: {item.recipientName}   
                                            <br/>
                                            Address: {item.recipientAddress}    
                                            <br/>
                                            Contact No: {item.recipientMobile} 
                                            <br/>
                                        </p>
                                        <hr style={myStyle} />
                                        <p style={{paddingLeft: '25px', fontSize: '15px', lineHeight: '1.2'}}>
                                            <b style={{fontWeight: 'bold'}} >From</b>
                                            <br/>
                                            {/* <br/> */}
                                            Name: {item.pickupFrom}   
                                            <br/>
                                            Address: {item.pickupAddress}   
                                            <br/>
                                            Contact No: {item.pickupMobile} 
                                            <br/>
                                            {item.orderID !== '' &&
                                            <span> Order ID: {item.orderID} / </span>
                                            }
                                            Parcel Desc: {item.pDescription}
                                            {item.clientNote !== '' &&
                                            <span> {item.clientNote} </span>
                                            }
                                
                                        </p>
                                        <hr style={myStyle}/>
                                
                                        <div>     
                                            {
                                                (() => {
                                                    if(setting.qr === 'on' && setting.barcode === 'on') {
                                                            return (
                                                              <center>
                                                              <table className="table" style={{tableStyle, paddingLeft: '30px', margin: '-10px'}}>
                                                              <tr>
                                                                <td > </td>
                                                                <td style={{fontSize: '12px'}}> 
                                                           
                                                               {item.paymentType === 'COD' && 
                                                               <div><p style={{fontSize: '16px', textAlign:'center', fontWeight: '900'}}> COD: {item.CODAmount} LKR </p>
                                                               </div>  
                                                               }
                                                                </td>
                                                                <td></td>
                                                             </tr>
                                                               
                                                            </table>
                                                            
                                                              <table style={tableStyle} className="table">
                                                                <tr>
                                                             <td style={{fontSize: '12px'}}> <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${item.id}&choe=UTF-8`} /> </td>
                                                                  
                                                              {/* <td style={{fontSize: '12px'}}> <img style={{width: '150px'}} src={`https://barcode.tec-it.com/barcode.ashx?data=${item.id}`} /></td> */}
                                                              <td style={{fontSize: '12px'}}> <img style={{width: '150px'}} src={`${process.env.REACT_APP_URL}/barcode.php?text=${item.id}&size=50&print=true`}/></td>   
                                                                </tr>
                                                                
                                                                
                                                              </table> 
                                                          
                                                             </center> 
                                                            )
                                                        } else if (setting.qr === 'on') {
                                                            return (
                                                              <>
                                                              {item.paymentType === 'COD' && 
                                       
                                                                <div style={{width: '250px', margin: 'auto'}}>
                                                                  <p style={{fontSize: '16px', textAlign:'center', fontWeight: '900'}}> COD : {item.CODAmount} LKR </p>
                                                                </div>  
                                                                
                                                                }
                                
                                                              <div style={{width: '250px', textAlign: 'center', margin: 'auto'}}>
                                                              <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${item.id}&choe=UTF-8`} />
                                                              </div> 
                                
                                                              </>
                                                            )
                                                        } else if (setting.barcode === 'on'){
                                                            return (
                                                                <>
                                                                {item.paymentType === 'COD' && 
                                       
                                                                  <div style={{width: '250px', margin: 'auto'}}>
                                                                    <p style={{fontSize: '16px', textAlign:'center', fontWeight: '900'}} > COD : {item.CODAmount} LKR </p></div>  
                                                                  
                                                                }
                                                                <div style={{width: '250px', textAlign: 'center', margin: 'auto'}}>
                                                                {/* <img style={{width: '150px'}} src= {`https://barcode.tec-it.com/barcode.ashx?data=${item.id}`}/> */}
                                                                <img style={{width: '150px'}} src={`${process.env.REACT_APP_URL}/barcode.php?text=${item.id}&size=50&print=true`}/> 
                                                                </div> 
                                
                                                                </>
                                                            )
                                                        }
                                                })()  
                                            }  
                                        </div> 
                                
                                
                                        </div> 
                                        {
                                            
                                            (() => {
                                                 count = count+1
                                                if(count === 4){
                                                    // count = 0
                                                    return (
                                                        <div style={breakAfterStyle} className="breakAfter"></div>
                                                    )
                                                                                                       
                                                }
                                            })()
                                        }
                                        
                                    </span>  
                                    )
                                    
                                    })
                                    }      
    </div>
      </> 
        ) : (
            
              <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
                <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
              </div> 
            
        )
            
        ) : (
        <>
          <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
            <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
          </div> 
        </>
        )
    }
  </div>
  );
}
export default CsvBulkPrint;