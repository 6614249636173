import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";


export default function SiteMaintenaneClient() {
  document.title = "Logistic Client Portal | Maintenance Mode";

  const navigate = useNavigate();

  const [maintainSetting, setMaintainSetting] = useState('');
  
  async function settingMTRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
        method: 'POST',          
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setMaintainSetting(data.Clstatus);
         
        })
    } catch (error) {
      console.log(error.message)
    }
  }
   
  useEffect(() => {
    settingMTRequest();   
  }, []);


  useEffect(() => {
    if(maintainSetting.maintenance_mode_client === '0') {
    navigate('/index');
    }
    },[maintainSetting.maintenance_mode_client])

//   var imgyrl = require(`../${maintainSetting.logo}`);

//   function ExtractImageName(logo) {
//     // console.log(logo);
//     return require(`../${logo}`);
//   }

  return (
    <>
        <div className="row">
            <div className="col-6 offset-3">
                <div className="contentsite">
                    <img src={require('../images/logo-light.png')}  alt="AdminLTE Logo" className="brand-image" style={{opacity: '.8', width: '250px'}}/><hr/>                   
                    <h3>Warning! Under Maintenance<br/>{maintainSetting.maintenance_message_client}</h3>             
                </div>
            </div>
        </div>
    </>
  )
}
