import { useEffect, useState } from "react";
// import { Link } from 'react-router-dom'
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Swal from "sweetalert2";

export default function ExistingWaybill() {
  document.title = "Logistic Client Portal | Existing Waybill";


    const [waybillID, setWaybillID]                 = useState('');
    const [parcelType, setparcelType]               = useState('');
    const [hiddenParcelType, sethiddenParcelType]   = useState('');
    const [parceldes, setparceldes]                 = useState('');
    const [orderId, setorderId]                     = useState('');
    const [RecName, setRecName]                     = useState('');
    const [RecContact, setRecContact]               = useState('');
    const [RecAddress, setRecAddress]               = useState('');
    const [city, setcity]                           = useState('');
    const [exchange, setExchange]                   = useState(false);
    const [payMethod, setPayMethod]                 = useState('');
    const [CODamount, setCODamount]                 = useState('');
    const [isAnonymous, setisAnonymous]             = useState(false);

    const [formErrors, setformErrors]               = useState({});

    const [isPdesActive, setIsPdesActive]           = useState(false);
    const [isWayActive, setIsWayActive]             = useState(false);
    const [isPTypeActive, setIsPTypeActive]         = useState(false);
    const [isRnameActive, setIsRnameActive]         = useState(false);
    const [isRcontactActive, setIsRcontactActive]   = useState(false);
    const [isRaddressactActive, setIsRaddressactActive] = useState(false);
    const [isRcityactActive, setIsRcityactActive] = useState(false);
    const [ispayMethodActive, setIspayMethodActive] = useState(false);
    const [iscodActive, setIscodActive] = useState(false);
    
    async function parcelTypeRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelType`, {
            method: 'POST',          
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {          
            setparcelType(data.Clstatus);         
            })
        } catch (error) {
        console.log(error.message)
        }
    }
   
    useEffect(() => {
        parcelTypeRequest()     
    }, []);

    const waybillIDHandler = (event) => {
        setWaybillID(event.target.value);    
        setIsWayActive(false)
    }

    const handleTypeChange = (e) => {      
        e.preventDefault();
        sethiddenParcelType(e.target.value);               
        setIsPTypeActive(false)
    };

    const parceldesHandler = (event) => {
        setparceldes(event.target.value);
        setIsPdesActive(false)
    }

    const orderIdHandler = (event) => {
        setorderId(event.target.value)
    }

    const RecNameHandler = (event) => {
        setRecName(event.target.value)
        setIsRnameActive(false)
    }

    const RecContactHandler = (event) => {
        setRecContact(event.target.value)
        setIsRcontactActive(false)        
    }

    const RecAddressHandler = (event) => {
        setRecAddress(event.target.value)
        setIsRaddressactActive(false)
    }

    const cityHandler = (event) => {
        setcity(event.target.value)     
        setIsRcityactActive(false)
    }

    const exchangeHandler = (event) => {      
        setExchange(current => !current);
    }

    const handleChange = (e) => {
        setPayMethod(e.target.value);
        setIspayMethodActive(false)       
    }

    const CODamountHandler = (event) => {
        setCODamount(event.target.value)
        setIscodActive(false)      
    }

    const validate = (waybillID,parceldes,hiddenParcelType,RecName,RecContact,RecAddress,city,payMethod,CODamount) => {
    
        const errors = {};

        if(!waybillID){
            errors.waybillID = "This field is required!";  
            setIsWayActive(true)    
          }else{
            setIsWayActive(false)
          }
  
        if(!parceldes){
          errors.parceldes = "This field is required!";  
          setIsPdesActive(true)    
        }else{
          setIsPdesActive(false)
        }
  
        if(!hiddenParcelType){
          errors.hiddenParcelType = "This field is required!";      
          setIsPTypeActive(true)
        }else{
          setIsPTypeActive(false)
        }
  
        if(!RecName){
          errors.RecName = "This field is required!";
          setIsRnameActive(true)
        }else{
          setIsRnameActive(false)
        }
  
        if(!RecContact){
          errors.RecContact = "This field is required!";      
          setIsRcontactActive(true)
        }else{
          setIsRcontactActive(false)
        }
  
        if(!RecAddress){
          errors.RecAddress = "This field is required!";
          setIsRaddressactActive(true)
        }else{
          setIsRaddressactActive(false)
        }
  
        if(!city){
          errors.city = "This field is required!";      
          setIsRcityactActive(true)
        }else{
          setIsRcityactActive(false)
        }
  
        if(!payMethod){
          errors.payMethod = "This field is required!";      
          setIspayMethodActive(true)
        }else{
          setIspayMethodActive(false)
        }
  
        if(payMethod === "COD"){
          if(!CODamount){
            errors.CODamount = "This field is required!";
            setIscodActive(true)
          }else{
            setIscodActive(false)
          }
        }else{
          setIscodActive(false)
        }
           
        return errors;
    }

    const submitParcelHandler = (event) => {
    
    event.preventDefault()
  
    var logUser = sessionStorage.getItem("UserID");
              
    setformErrors(validate(waybillID,parceldes,hiddenParcelType,RecName,RecContact,RecAddress,city,payMethod,CODamount));        
  
    var errorLength =  (Object.keys(validate(waybillID,parceldes,hiddenParcelType,RecName,RecContact,RecAddress,city,payMethod,CODamount)).length)
                      
    if(errorLength === 0 ){
         
            async function parcelExAddRequest() {
              try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/addExParcel`, {
                  method: 'POST',
                  body: JSON.stringify({
                    waybillID: waybillID,
                    hiddenParcelType: hiddenParcelType,
                    parceldes: parceldes,
                    orderId: orderId,
                    RecName: RecName,
                    RecContact: RecContact,
                    RecAddress: RecAddress,
                    city: city,
                    exchange: exchange,
                    payMethod: payMethod,
                    CODamount: CODamount,
                    logUser:logUser,
                  }),
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                } )
                  .then((respose) => {
                    if (respose.ok) {
                      return respose.json()
                    }
                    throw new Error('error')
                  })
                  .then((data) => {
                    // console.log(data.Clstatus);               
    
                    const statusbrEx =  data.Clstatus;
                    var nameArr    = statusbrEx.split(',');
                    
                    var dataWord        =  (nameArr[0]); 
                    var WaybillID       =  (nameArr[1]);
    
                    if(dataWord === "success"){
                      setisAnonymous(false);
                      
                      Swal.fire({
                        title: "Good job!",
                        text: "Parcel Successfully Added!",
                        icon: "success",
                        confirmButtonText: "OK",
                        timer: 1500,
                      });
                      const loggedNUser = sessionStorage.getItem('UserID'); 
                      
                      window.open(`/newParcelPrint/${loggedNUser}/${WaybillID}`);
                      setWaybillID(''); 
                      setPayMethod('');
                      setparceldes('');
                      setorderId('');
                      setRecName('');
                      setRecContact('');
                      setRecAddress('');
                      setcity('');                      
                      setExchange(false);                      
                      setCODamount('');
                      setparcelType('');
                      sethiddenParcelType('');

                      parcelTypeRequest();
                    
                    }else if(dataWord === "invalidCity"){
    
                      setisAnonymous(false);
                     
                      Swal.fire({
                        title: "Warning",
                        text: "Invalid City!",
                        icon: "error",
                        confirmButtonText: "OK",
                        // timer: 1500,
                      });
    
                    }else if(dataWord === "exist"){
    
                      setisAnonymous(false);
                      
                      Swal.fire({
                        title: "Warning",
                        text: "Invalid Waybill ID!",
                        icon: "error",
                        confirmButtonText: "OK",
                        // timer: 1500,
                      });
    
                    }else{
                        setisAnonymous(false);
                      
                        Swal.fire({
                          title: "Warning",
                          text: "Parcel Add Unsuccessful!",
                          icon: "error",
                          confirmButtonText: "OK",
                          // timer: 1500,
                        });  
                    }
                                   
                  })
              } catch (error) {
                console.log(error.message)
              }
            }
        
            setisAnonymous(true);
            parcelExAddRequest();
            
    }
    }

  return (
    <div>
        <div id="layout-wrapper">
            <TopBar/>
            <SideBar/>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid" style={{backgroundColor: '#ebebf3',margin: '10px' }}>
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="page-title-box">
                                <h4 className="font-size-18">Existing Waybill</h4>
                            </div>
                            </div>

                            <div className="col-sm-6"></div>
                        </div>
                        
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                    <h4 className="card-title">Parcel Details</h4><hr/>
                                    
                                    <form  id="existingParcelAddFrm" name="existingParcelAddFrm" onSubmit={submitParcelHandler}>
                                        <div id="beforeDiv">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="formname">Existing Waybill ID:</label>
                                                    <input type="number" name="waybillID" className="form-control" id="waybillID" value={waybillID} onChange={waybillIDHandler} />
                                                    <p style={{display: isWayActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrors.waybillID}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-3">
                                                <input type="hidden" id="hiddenParcelType" name="hiddenParcelType" value={hiddenParcelType}  />
                                                <div className="form-group">
                                                    <label htmlFor="formname">Parcel Type :</label>
                                                    <select className="form-control" name="parcelType" id="parcelType" onChange={handleTypeChange}>
                                                        <option value="">Select. .</option>
                                                        {
                                                            parcelType && parcelType.map((option, index) => (
                                                                <option key={index} value={option.id}>
                                                                    {option.pName}-{option.pSize}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                    <p style={{display: isPTypeActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.hiddenParcelType}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="formname">Parcel Description:</label>
                                                    <input type="text" name="parceldesc" className="form-control" id="parceldesc" value={parceldes} onChange={parceldesHandler} />
                                                    <p style={{display: isPdesActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrors.parceldes}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="formname">Order ID:</label>
                                                    <input type="text" name="orderID" className="form-control" id="orderID" value={orderId} onChange={orderIdHandler} />
                                                </div>
                                            </div>

                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="formname">Recipient Name :</label>
                                                    <input type="text" name="fname" className="form-control" id="fname" value={RecName} onChange={RecNameHandler} />
                                                    <p style={{display: isRnameActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.RecName}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="formname">Recipient Contact No :</label>
                                                    <input type="text" name="contactno" className="form-control" id="contactno" value={RecContact} onChange={RecContactHandler}  />
                                                    <p style={{display: isRcontactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.RecContact}</p>
                                                </div>
                                            </div>
                                    
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="formemail">Recipient Address :</label>
                                                    <input type="text" name="address" className="form-control" id="address" value={RecAddress} onChange={RecAddressHandler}  />
                                                    <p style={{display: isRaddressactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.RecAddress}</p>
                                                </div>
                                            </div>
                                            
                                            
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="formname">Recipient City :</label>
                                                    <input type="text" name="city" className="form-control" id="city" value={city} onChange={cityHandler} />
                                                    <p style={{display: isRcityactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.city}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="formname">Exchange :</label>
                                                <input className="form-control"  type="checkbox" id="exchange" name="exchange" value={exchange} checked={exchange} onChange={exchangeHandler} style={{width: 'unset' }} />
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <label htmlFor="formname">Payement Method :</label>
                                                    <select className="form-control" name="paymenthod" id="paymenthod" value={payMethod} onChange={handleChange} >
                                                        <option value="">Select. .</option>
                                                        <option value="AlreadyPaid">Already Paid</option>
                                                        <option value="COD">COD</option>
                                                    </select>
                                                    <p style={{display: ispayMethodActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.payMethod}</p>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-3">
                                            {payMethod === "COD" && 
                                                <div className="form-group"  id="showcodamount">
                                                    <label htmlFor="formname">COD Amount (LKR):</label>
                                                    <input type="number" name="codAmount" className="form-control" id="codAmount" min="0" value={CODamount} onChange={CODamountHandler}  />
                                                    <p style={{display: iscodActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.CODamount}</p>
                                                </div>
                                            }
                                            </div> 
                                            
                                            <div className="col-lg-3">
                                                <div style={{float: 'right',marginTop: '30px' }} >
                                                <button id="existingBtn" type="submit" className="btn btn-success inner" disabled={isAnonymous ? true : false}>Submit</button>
                                            </div>     
                                                
                                            </div>
                                            </div>
                                            
                                        </div>
                                    </form>

                                
                                </div>
                            </div>



                        </div>
                        
                        </div>
                    </div>
                </div>
                
                <footer className="footer">
                    <Footer/>
                </footer>
            </div>
        </div>
    </div>
  )
}
