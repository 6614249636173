import { useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';

export default function AddParcel() {  

    document.title = "Logistic Client Portal | Label Print";
    
    const [parceldate, setParceldate]   = useState('');
    const [parcelFrom, setParcelFrom]   = useState('');
    const [parcelTo, setparcelTo]       = useState('');
    const [pStatus, setpStatus]         = useState('waiting');
    

    const [formErrors, setformErrors] = useState({});
    
    // const [isSubmit, setIsSubmit]     = useState(false);

    const [isPdateActive, setIsPdateActive] = useState(false);
    const [isFromActive, setIsFromActive]   = useState(false);
    const [isToActive, setIsToActive]       = useState(false);
    
    const DateHandler = (e) => {
        setParceldate(e.target.value);
        setIsPdateActive(false)       
    };

    const FromHandler = (e) => {        
        e.preventDefault();
        setParcelFrom(e.target.value);           
        setIsFromActive(false)       
    };

    const ToHandler = (event) => {
        setparcelTo(event.target.value);
        setIsToActive(false)
    }

    const StatusChange = (event) => {
        setpStatus(event.target.value)
    }

    const validate = (parceldate,parcelFrom,parcelTo) => {
      const errors = {};

      if(!parceldate){
        errors.parceldate = "This field is required!";  
        setIsPdateActive(true)    
      }else{
        setIsPdateActive(false)
       }

      if(!parcelFrom){
        errors.parcelFrom = "This field is required!";      
        setIsFromActive(true)
      }else{
        setIsFromActive(false)
      }

      if(!parcelTo){
        errors.parcelTo = "This field is required!";
        setIsToActive(true)
      }else{
        setIsToActive(false)
      }

      return errors;
    }

    const submitlabelHandler = (event) => {
      event.preventDefault()

      var logUser = sessionStorage.getItem("UserID");
            
      setformErrors(validate(parceldate,parcelFrom,parcelTo));        

      var errorLength =  (Object.keys(validate(parceldate,parcelFrom,parcelTo)).length)
                    
         if(errorLength === 0 ){

         window.open(`/label_bulk_print_copy/${logUser}/${parceldate}/${parcelFrom}/${parcelTo}/${pStatus}`);

         setParceldate('');
         setParcelFrom('');
         setparcelTo('');
         setpStatus('waiting');   
          
        }
      }

    
    return (
      <div>
  <div id="layout-wrapper">

  <TopBar/>

  <SideBar/>
    
    <div className="main-content">

      <div className="page-content">
        <div className="container-fluid" style={{backgroundColor: '#ebebf3',margin: '10px' }}>
               
               <div className="row mt-3">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <div className="page-title-box">
                      <h4 className="font-size-18" >Label Print</h4>
                     </div>
                    </div>
                    <div className="col-lg-12">
                     <div className="card">
                        <div className="card-body">
                            <div className="card-title">
                                <h4 className="card-title">Parcel Detail</h4>
                                <hr/>
                            </div>
                                    <form id="lebelprFrm" name="lebelprFrm" onSubmit={submitlabelHandler} method="post">
                                        <div className="beforeDiv">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                    <label htmlFor="formname">Date:</label>
                                                        <input type="date" name="needDate" className="form-control" id="needDate" value={parceldate} onChange={DateHandler} />
                                                        <p style={{display: isPdateActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrors.parceldate}</p>
                                                    </div>
                                                </div>                                               
                                                
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="From">From :</label>
                                                        <input type="time" id="fromTime" name="fromTime" value={parcelFrom} onChange={FromHandler} className="form-control"  />
                                                        <p style={{display: isFromActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.parcelFrom}</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="To">To :</label>
                                                        <input type="time" id="toTime" name="toTime" value={parcelTo} onChange={ToHandler} className="form-control" />
                                                        <p style={{display: isToActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.parcelTo}</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                      <div className="form-group">
                                                        <label htmlFor="status">Status :</label>
                                                        <select className="form-control" id="parcelSt" name="parcelSt" value={pStatus} onChange={StatusChange}  >
                                                            <option value="waiting">Waiting</option>
                                                            <option value="all">All</option>
                                                        </select>
                                                    </div>
                                                </div>                                                
                                            </div>

                                            <div className="row"> 
                                                <div className="col-lg-4"></div>
                                                <div className="col-lg-4"></div>
                                                <div className="col-lg-4">
                                                    <div style={{float: 'right',marginTop: '10px' }}>
                                                    <button id="goNextBtn" type="submit" className="btn btn-success inner">Go</button>
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                        </div>
                    </div>
                 </div>    
               </div> 
          
     
        </div>
   
      </div>
    
      <footer className="footer">
        <Footer/>
      </footer>

    </div>
 

  </div>
     
</div>

 

    )
}
