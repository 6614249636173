import React, { useRef, useEffect, useState }  from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
import logo from '../images/logo-light.png';
import './print.css';

function VendorPayListPrint (){
    
  let  {Client}          = useParams();
  let  {batchNum}        = useParams();

  const [isPrintingVender, setIsPrintingVender] = useState(false); 

  const componentRef = useRef();
  const handlePrint  = useReactToPrint({
    content: () => componentRef.current,
  });

  document.title = "Logistic Client Portal | Vendor Payment Release Print";

var today = new Date();

var todaytime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

var todaydate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

var currntDate = todaydate+" "+todaytime;

var totalCOD                = 0;
var totalCancel             = 0;
var totalDelivery           = 0;
var totalDiscount           = 0;
var newPayAmount            = 0;

  const [clientData, setclientData] = useState([]); 
  
  useEffect(() => {
    async function clientDetailsRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: Client,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setclientData(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
      clientDetailsRequest();
      sessionStorage.setItem("UserID", Client); 
  }, []);

  
  const [batchNParcel, setBatchNParcel]     = useState([]);
  const [batchPayHistry, setBatchPayHistry] = useState([]);
 
  useEffect(() => {
    async function batchParcelNRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getbatchParcel`, {
            method: 'POST', 
            body: JSON.stringify({             
                batchNum: batchNum,               
              }),         
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
                setBatchNParcel(data.Clstatus);
                setIsPrintingVender(true);
            })
        } catch (error) {
          console.log(error.message)
        }
      }
      batchParcelNRequest();       
  }, [])
  
  useEffect(() => {
    async function batchPayNRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getbatchPayment`, {
            method: 'POST', 
            body: JSON.stringify({             
                batchNum: batchNum,               
              }),         
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
                setBatchPayHistry(data.Clstatus);
             
            })
        } catch (error) {
          console.log(error.message)
        }
      }
      batchPayNRequest();       
  }, [])

    // useEffect(() => {
    //     settingRequest()
        
    //   }, []);
    
    useEffect(() => {
        if (isPrintingVender) {
         
            handlePrint()
        }
    }, [isPrintingVender, handlePrint]);


  const myStyle = {
    border: '1px solid #94d0e8', 
    marginTop: '8px',
    marginBottom: '8px'
    };

        
        
  return (
  <body ref={componentRef} style={{ backgroundColor: 'white', height: '29.7cm', width: '21cm', fontFamily: 'times new roman', color: '#000000'}} className="printLayoutContainer">    
   {
          (isPrintingVender) ? (
          <>
        <div className="row" style={{  textAlign: 'center', display: 'block',margin: '10px'}}>
            <center><img src={logo} alt="imgLogo" style={{width: '175px', marginTop: '10px'}} /></center>
        </div> 
        <hr style={{border: '1px solid #94d0e8'}}/>
        <div className="row"  style={{ display: 'block',margin: '10px'}}>
        {/* <h3 style="text-align: center; margin-bottom: -15px;"> Vendor Payment Release  </h3>
   <p  style="text-align: center; margin-bottom: 25px;"> (Date: <?php echo $currntDate;?>)</p>
   <h4 style="text-align: center; margin-top: -12px;">  Vendor : <?php echo $companyName." (".$clientID.")";?></h4> */}
            <h3 style={{  textAlign: 'center', marginBottom: '-15px', fontSize: '18.72px',  fontFamily: 'times new roman'}}> Vendor Payment Release </h3>
            <p style={{  textAlign: 'center', marginBottom: '25px', marginTop: '16px', fontSize: '16px', lineHeight: 'normal', fontFamily: 'times new roman'}}> (Date: {currntDate})</p>
            <h4 style={{  textAlign: 'center', marginTop: '-12px', marginBottom: '21.28px', fontSize: '16px', fontFamily: 'times new roman'}}>  Vendor : {clientData.companyName} ({Client})</h4>   
            {
                (batchNParcel.length !== 0) ? (
                    <>
                     
                     <table style={{width: '100%', border: '1px solid #e4e4e0', padding: '10px', borderCollapse: 'separate', color: 'black'}}>
                        <thead>
                            <tr style={{fontSize: '16px'}}>
                                <th style={{padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Waybill Info</th>
                                <th style={{padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Parcel Info</th>        
                                <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>COD Amount</th>
                                <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Return Fee</th>
                                <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Delivery Fee</th>
                                <th style={{textAlign: 'right', padding: '.25rem', border: '1px solid #e4e4e0', padding: '10px'}}>Discount</th> 
                            </tr>
                        </thead>
                    <tbody>
                    {                                                                                                                 
                        batchNParcel.map(item=>{
                        return( 
                       
                        <tr key={item.id} style={{border: '1px solid #e4e4e0', padding: '10px', fontSize: '16px'}}>
                            <td style={{border: '1px solid #e4e4e0',padding: '10px', verticalAlign: 'middle'}} >
                                {item.id}<br/>
                                {item.paymentType}<br/>
                                {item.orderID ? (
                                    <span>Order ID :  {item.orderID}</span>
                                ) : (
                                    <></>
                                )
                                }
                            </td>
                            <td style={{border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle'}}>{item.pType}<br/>{item.pDescription}</td>
                            <td style={{border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle',textAlign: 'right'}}>
                                {/* {{...parcelTypeA.find(element => element.id == item.pType)}.pName} */}
                                {
                                            
                                            (() => {
                                                if(item.deliveryStatus === 'Complete'){
                                                    if(item.CODAmount){
                                                        var newCodAmount = item.CODAmount
                                                    }else{
                                                        var newCodAmount = 0
                                                    }
                                                    
                                                    totalCOD = (parseFloat(newCodAmount) + parseFloat(totalCOD)).toFixed(2);
                                                        return (
                                                            <>
                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmount).toFixed(2))}
                                                            </>                                                                                                                
                                                        )
                                                    
                                                }else if(item.deliveryStatus === 'Cancel'){
                                                    return (
                                                        <> 0.00 </>
                                                    )
                                                }else{
                                                    return (
                                                        <> 0.00 </>
                                                    )
                                                }
                                            })()
                                }
                            </td>                                   
                            <td style={{border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle', textAlign: 'right'}}>
                                {
                                    (() => {
                                    if(item.deliveryStatus == 'Cancel'){
                                                                                                    
                                        if(item.deliveryFee){
                                            var newDelAmountCa = item.deliveryFee
                                        }else{
                                            var newDelAmountCa = 0
                                        }

                                        if(batchPayHistry.cancelFee){
                                            var newCancelFee = batchPayHistry.cancelFee
                                        }else{
                                            var newCancelFee = 0
                                        }
                                        
                                        var cancelFeeNew  = ((parseFloat(newDelAmountCa) * parseFloat(newCancelFee))/100).toFixed(2);  
                                            // var cancelFeeNew = newDelAmountCa*newCancelFee/100 
                                                                                                   
                                            totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
                                            return (
                                                    <>
                                                   {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                    </>                                                                                                                
                                            )
                                                                                                                                                        
                                    }else{
                                        return (
                                         <> - </>
                                        )
                                    }
                                    })()  
                                } 
                            </td>
                            <td  style={{width: '170px', textAlign: 'right', border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle'}}>
                                    {
                                    (() => {
                                        if(item.deliveryStatus == 'Complete'){
                                            if(item.deliveryFee){
                                                var newDelAmount = item.deliveryFee
                                            }else{
                                                var newDelAmount = 0
                                            }
                                            totalDelivery = (parseFloat(newDelAmount) + parseFloat(totalDelivery)).toFixed(2);
                                                                                                
                                            return (
                                                <>
                                                 {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmount).toFixed(2))}
                                                </>                                                                                                                
                                            )
                                                                                                                                                    
                                        }else{
                                            return (
                                                <> - </>
                                            )
                                        }
                                    })()  
                                    }                                                                                        
                                 
                            </td>
                            <td style={{border: '1px solid #e4e4e0', padding: '10px', verticalAlign: 'middle', textAlign: 'right'}}>                                         
                                                                                        {
                                                                                            
                                                                                                (() => {
                                                                                                
                                                                                                    if(item.deliveryStatus == 'Complete'){

                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountDis = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountDis = 0
                                                                                                        }

                                                                                                        if(batchPayHistry.discount){
                                                                                                            var newdiscFee = batchPayHistry.discount
                                                                                                        }else{
                                                                                                            var newdiscFee = 0
                                                                                                        }

                                                                                                    var discountedAmount  = ((parseFloat(newDelAmountDis)/100) * parseFloat(newdiscFee)).toFixed(2); 
                                                                                                    // var discountedAmount  = newDelAmountDis/100*newdiscFee 
                                                                                                   
                                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                    return (
                                                                                                            <>
                                                                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                           
                                                                                                            </>                                                                                                                
                                                                                                        )
                                                                                                                                                        
                                                                                                    }else if(item.deliveryStatus == 'Cancel'){

                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountDisO = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountDisO = 0
                                                                                                        }

                                                                                                        if(batchPayHistry.discount){
                                                                                                            var newdiscFeeO = batchPayHistry.discount
                                                                                                        }else{
                                                                                                            var newdiscFeeO = 0
                                                                                                        }

                                                                                                        if(batchPayHistry.cancelFee){
                                                                                                            var newCancelFeeO = batchPayHistry.cancelFee
                                                                                                        }else{
                                                                                                            var newCancelFeeO = 0
                                                                                                        }
                                                                                                        
                                                                                                    var cancelFeeNew      = ((parseFloat(newDelAmountDisO) * parseFloat(newCancelFeeO))/100).toFixed(2); 
                                                                                                    var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeO)).toFixed(2); 
                                                                                                    // var cancelFeeNew = newDelAmountDisO*newCancelFeeO/100 
                                                                                                    // var discountedAmount = cancelFeeNew/100*newdiscFeeO 

                                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                    
                                                                                                    return (
                                                                                                        <> {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                       </>
                                                                                                    )
                                                                                                    }else{
                                                                                                    
                                                                                                    return (
                                                                                                    <>0.00</>
                                                                                                    )
                                                                                                    }
                                                                                                })()  
                                                                                        }
                                                                                        
                            </td>     
                        </tr>
                        
                        )
                                    
                        })
                    } 
                        <tr style={{fontWeight: '500'}}>

                                <td style={{border: '1px solid #ffffff'}}></td>
                                <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>Total</td>
                                <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCOD).toFixed(2))}</td>
                                <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCancel).toFixed(2))}</td>
                                <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalDelivery).toFixed(2))} 
                                                                {
                                                                    (() => {
                                                                    var sumReturnNDelivFee      = (parseFloat(totalDelivery) + parseFloat(totalCancel)).toFixed(2);
                                                                    var finalsumReturnNDelivFee = (parseFloat(sumReturnNDelivFee) - parseFloat(totalDiscount)).toFixed(2);
                                                                    
                                                                    
                                                                    newPayAmount      = (parseFloat(totalCOD)- parseFloat(finalsumReturnNDelivFee)).toFixed(2);
                                                                        
                                                                      
                                                                       
                                                                    })()  
                                                                }
                                </td>
                                <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalDiscount).toFixed(2))}
                                </td>
                        </tr> 

                        <tr style={{fontWeight: '800'}}>
                                <td style={{border: '1px solid #ffffff'}}></td>
                                <td style={{border: '1px solid #ffffff'}}></td>
                                <td style={{border: '1px solid #ffffff'}}></td>
                                <td style={{border: '1px solid #ffffff'}}></td>
                                <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                    {
                                        (() => {
                                                                    
                                            if(newPayAmount< 0){
                                                return(<>Due Amount</>)
                                                                        
                                            }else{
                                                return(<>Pay Amount</>)
                                            }
                                                                        
                                        })()  
                                    }
                                </td>
                                <td style={{textAlign: 'right', padding: '10px', border: '1px solid #e4e4e0'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newPayAmount).toFixed(2))}</td>
                        </tr>  
                    </tbody>
                    </table>
                    <h4 style={{margin:'10px'}}>
                                    {
                                        (() => {
                                                                    
                                            if(batchPayHistry.note){
                                                return(<>Note : {batchPayHistry.note}</>)
                                                                        
                                            }
                                                                        
                                        })()  
                                    }                                    
                    </h4>  

                    <table style={{ width: '100%', marginTop: '50px', borderCollapse: 'separate'}}>
                        <tbody>
                            <tr>
                                <h4 style={{fontSize: '16px'}}>Payment Proof</h4>
                                <div className="row" style={{margin: '20px', width: '200px'}}>
                                    <img src={`admin/${batchPayHistry.payslip}`} alt="" />
                                </div>   
                            </tr>
                        </tbody>
                    </table>


                    <hr style={myStyle}/>
                    <p style={{fontSize: '10px', textAlign:'center', marginTop: '0px', marginBottom: '0px', lineHeight: 'normal'}}>Date : {currntDate}  -   Solution By oZoneDesk</p>
                    <hr  style={myStyle}/>
  
  
                    </>

                ): (
                    <>
                    <div className="row">
                        <div className="col-md-12" style={{margin: 'auto', textAlign: 'center'}}>
                            <h4>Invalid Batch Number</h4>
                        </div>
                    </div>
                    <hr style={myStyle}/>
                    <p style={{fontSize: '10px', textAlign:'center', marginTop: '0px', marginBottom: '0px', lineHeight: 'normal'}}>Date : {currntDate}  -   Solution By oZoneDesk</p>
                    <hr  style={myStyle}/>
                    </>                                          
                )
            } 
             
        
        </div> 
        </>   
        ) : (
        <>
          <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
            <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
          </div> 
        </>
        )
    }
  </body>
  );
}
export default VendorPayListPrint;