

function Waybill(props) {
    return(
        <div className="col-3 col-md-3 col-lg-3 form-group">
            <label Htmlfor="waybill_id">Waybill ID :</label>
            <input type="text" onChange={props.func} id="waybill_id" className="form-control" value={props.value} name="waybill_id" placeholder="Waybill ID"/>
        </div>
    )
}

export default Waybill;