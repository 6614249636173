

function FromDate(props) {
    return(
        <div className="col-3 col-md-3 col-lg-3 form-group">
            <label htmlFor="fdate">{props.label} :</label>
            <input type="date" id="fdate" name="fdate" onChange={props.func} value={props.value} className="form-control" placeholder="Form Date"/>
        </div>
    )
}

export default FromDate;