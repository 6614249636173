import React, { useRef, useEffect, useState }  from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
import logo from '../images/logo-light.png';

function ReturnClientConfirmPrint ({cancelReason, adminInfo}){

  let  {loggedNUser}  = useParams();
  let  {rHeaderId}  = useParams();
  
  const [isPrintingRetu, setIsPrintingRetu] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: 'empdata',
    // onAfterPrint: () => alert('Print Success')
  });

  const [hDetails, setHDetails]             = useState('');
  const [settingP, setSettingP]             = useState('');
  const [reHDetails, setReHDetails]         = useState([]);
  const [allParcelDN, setAllParcelDN]       = useState([]); 
  const [allParceltb, setAllParceltb]       = useState([]);
  
  useEffect(() => {
    async function settingRequestP() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
        method: 'POST',          
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {          
          setSettingP(data.Clstatus);
        })
    } catch (error) {
      console.log(error.message)
    }
  }
  
    settingRequestP()
  }, []);
  
useEffect(() => {
  async function RHeadRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getRheadDetail`, {
        method: 'POST', 
        body: JSON.stringify({
            rHeaderId: rHeaderId,
            clientID: loggedNUser,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {      
          setHDetails(data.Clstatus);        
        })
    } catch (error) {
      console.log(error.message)
    }
  }
  RHeadRequest();
    sessionStorage.setItem("UserID", loggedNUser); 
}, []);


 useEffect(() => {
        async function returnHdDetailsRequest() {
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getreturnD`, {
                method: 'POST', 
                body: JSON.stringify({             
                    returnID: rHeaderId,               
                   }),         
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                    setReHDetails(data.Clstatus);
                    
                })
            } catch (error) {
              console.log(error.message)
            }
          }
        returnHdDetailsRequest();       
      }, [])

  document.title = "Logistic Client Portal | Print Return Parcel Handover Sheet";

  const myStyle = {
    lineHeight: '0',
    marginBlockEnd: '8px',
    marginBlockStart: '8px',
    borderColor: 'rgb(0 0 0)',

    marginTop: '0.7rem ',
    marginBottom: '0.7rem'
    };

    const tableStyle = {
      borderCollapse: 'separate',
      fontSize: '12px'
  }
  
   
  useEffect(() => {
    async function allParcelsRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getAllParcels`, {
          method: 'POST', 
          body: JSON.stringify({             
            clientID: loggedNUser,               
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setAllParcelDN(data.Clstatus);
            setIsPrintingRetu(true);
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    allParcelsRequest()
  }, []);

  useEffect(() => {
    async function allParceltbRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelstb`, {
          method: 'POST', 
          body: JSON.stringify({             
            clientID: loggedNUser,               
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setAllParceltb(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    allParceltbRequest()
  }, []);

  useEffect(() => {
        if (isPrintingRetu) {
         
            handlePrint()
        }
  }, [isPrintingRetu, handlePrint]);

  return (
  <div ref={componentRef} style={{ fontFamily: 'initial', color: '#000000'}}>
  {
        (isPrintingRetu) ? (
        <>    
    <div id="invdetails">
        <div className="panel panel-default" id="mainpanel">
            <div className="panel-heading">
                 <h3 style={{textAlign: 'center', fontSize: '1.17rem', marginBlockStart: '1em', marginBlockEnd: '1em', fontWeight: 'bold', fontFamily: 'Times New Roman'}}>{settingP.cname} Return Parcel Handover Sheet</h3> 
                <div className="col-md-12">
                    <table width="100%" align="center" style={{fontSize: '12px',fontFamily: 'Arial, Helvetica, sans-serif', marginTop:'20px'}}>
                      <tbody>
                        <tr>
                            <td align="left" width="65%">
                                <div style={{marginTop:'20px'}}>
                                    <img src={logo} alt="imgLogo" style={{width: '135px'}} />
                                </div>
                            </td>
                
                
                             <td align="left" style={{lineHeight: 'normal'}}>
                                <h3 className="panel-title" style={{marginBottom: '14.04px', marginTop: '14.04px'}}><strong style={{fontWeight: 'bold', fontSize: '14.04px', fontFamily: 'Arial, Helvetica, sans-serif'}}>Sheet ID: {rHeaderId} </strong></h3>
                                <label style={{marginBottom: '0'}}><strong style={{fontWeight: 'bold'}}>Waybill Count: </strong>{hDetails.waybill_count}</label> <br/> 
                                <label style={{marginBottom: '0'}}><strong style={{fontWeight: 'bold'}}>Scan By: </strong>{{...adminInfo.find(element => element.id == hDetails.scan_by)}.fname} {{...adminInfo.find(element => element.id == hDetails.scan_by)}.lname} ({hDetails.scan_by})</label> <br/>  
                                <label style={{marginBottom: '0'}}><strong style={{fontWeight: 'bold'}}>Date: </strong>{hDetails.scan_date}</label>
                            </td>
            
                        </tr>
                      </tbody>
                    </table>
                    <hr style={myStyle} />
                </div>
            </div>
            
            <div className="panel-body">
              <div id="tablediv" className="col-sm-12" align="center">
                <table width="100%" style={tableStyle} cellPadding="2" cellSpacing="3">
                <thead>
                  <tr>
        					 <td width="200" align="center" bgcolor="#EAEAEA" className="">Waybill Number</td>
                   <td width="200" align="center" bgcolor="#EAEAEA" className="">Recipient Info</td>
                   <td width="200" align="center" bgcolor="#EAEAEA" className="">Pay Method</td>
                   <td width="200" align="center" bgcolor="#EAEAEA" className="">Returned Date</td>
                   <td width="200" align="center" bgcolor="#EAEAEA" className="">Reason</td>
                   <td width="200" align="center" bgcolor="#EAEAEA" className="">Note</td>
                  </tr>
                </thead> 
                                                                         
                <tbody>
        					 {                                                                                         
                                    reHDetails.map(item=>{
                                    return(  
                                    <tr key={item.id} style={{ lineHeight: 'normal'}}>
                                    
                                      <td align="center" bgcolor="whitesmoke">{item.waybill_id}</td>

                                      {
                                        (() => {
                                          if({...allParceltb.find(element => element.id == item.waybill_id)}){
                                              return (
                                                  <>
                                                  <td align="center" bgcolor="whitesmoke">                                      
                                                    {{...allParcelDN.find(element => element.id == item.waybill_id)}.recipientName}<br/>
                                                    {{...allParcelDN.find(element => element.id == item.waybill_id)}.recipientMobile}<br/>
                                                    {{...allParcelDN.find(element => element.id == item.waybill_id)}.recipientAddress}
                                                  </td>
                                                  <td align="center" bgcolor="whitesmoke">
                                                    {{...allParcelDN.find(element => element.id == item.waybill_id)}.paymentType}
                                                  </td>
                                                  <td align="center" bgcolor="whitesmoke">
                                                    
                                                                                  {
                                                                                      (() => {
                                                                                          if({...allParcelDN.find(element => element.id == item.waybill_id)}.cancellation_date){
                                                                                              return (
                                                                                                  <>{{...allParcelDN.find(element => element.id == item.waybill_id)}.cancellation_date}</>                                                                                                                
                                                                                              )
                                                                                                                                                
                                                                                          }else{
                                                                                              return (
                                                                                                  <> - </>
                                                                                              )  
                                                                                          }
                                                                                      })()  
                                                                                  } 
                                                  </td>
                                                  <td align="center" bgcolor="whitesmoke">
                                                  {{...allParcelDN.find(element => element.id == item.waybill_id)}.cancellation_reason ?  <>
                                                    {{...cancelReason.find(element => element.id == {...allParcelDN.find(element => element.id == item.waybill_id)}.cancellation_reason)}.reason}</> : " - "}
                                                  </td>
                                                  <td align="center" bgcolor="whitesmoke">
                                                  {{...allParcelDN.find(element => element.id == item.waybill_id)}.cancellation_note ?  <>{{...allParcelDN.find(element => element.id == item.waybill_id)}.cancellation_note}<br/></> : " - "} 
                                                  </td>
                                                  </>                                                                                                                
                                              )
                                                                                                                                    
                                          }else{
                                              return (
                                                <>
                                                <td align="center" bgcolor="whitesmoke">                                      
                                                  {{...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.recipientName}<br/>
                                                  {{...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.recipientMobile}<br/>
                                                  {{...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.recipientAddress}
                                                </td>
                                                <td align="center" bgcolor="whitesmoke">
                                                  {{...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.paymentType}
                                                </td>
                                                <td align="center" bgcolor="whitesmoke">
                                                  
                                                                                {
                                                                                    (() => {
                                                                                        if({...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.cancellation_date){
                                                                                            return (
                                                                                                <>{{...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.cancellation_date}</>                                                                                                                
                                                                                            )
                                                                                                                                              
                                                                                        }else{
                                                                                            return (
                                                                                                <> - </>
                                                                                            )  
                                                                                        }
                                                                                    })()  
                                                                                } 
                                                </td>
                                                <td align="center" bgcolor="whitesmoke">
                                                {{...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.cancellation_reason ?  <>
                                                  {{...cancelReason.find(element => element.id == {...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.cancellation_reason)}.reason}</> : " - "}
                                                </td>
                                                <td align="center" bgcolor="whitesmoke">
                                                {{...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.cancellation_note ?  <>{{...allParcelDN.find(element => element.waybill_id == item.waybill_id)}.cancellation_note}<br/></> : " - "} 
                                                </td>
                                                </>
                                              )  
                                          }
                                        })()  
                                      }
                                      
					                          </tr>
                                     )
                                    })
                                    }  
        					 
					         </tbody>     
                </table>
              </div>
              <br/><br/><br/>
              <table width="100%" align="center" style={{fontSize: '12px', fontFamily: 'Arial, Helvetica, sans-serif'}}>
                <tbody>
                  <tr>
                    <td align="center" width="50%"></td>
                    <td align="center" width="50%">..................................</td>
                  </tr>
                  <tr>
                    <td align="center" width="80%"></td>
                    <td align="center" width="20%">(Authorized By)</td>
                  </tr>
          				<tr></tr>
          				<tr></tr>
          				<tr></tr>
          				<tr></tr>
          				<tr></tr>
          				<tr></tr>
                  <tr>
                  </tr>
                </tbody>
              </table>
      
            </div>
            
        </div>
    </div>

    </>   
        ) : (
        <>
          <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
            <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
          </div> 
        </>
        )
    } 
  </div>
  );
}
export default ReturnClientConfirmPrint;