import { useEffect, useState } from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";

function TableBody({item, cityNames, zoneNames, setwaybill, changeDisplay, type, setModalIsOpen, setEditModalIsOpen, setEditWaybill,dataIsChoose}) {

    const [parcelType, setParcelType] = useState([]);
    const [hubName, setHubName] = useState([{name:""}]);
    const [riderName, setRiderName] = useState([{fname: '', lname: ''}]);

    const loggedNUser = sessionStorage.getItem('UserID');
    
    useEffect(() => {
        getHubName();
        getParcelType();
        if(item.rider_id !== null){
            getRiderName(item.rider_id)
        }
    },[])


    // function loadtable(){

    //     console.log(dataIsChoose);

    // }
    // function getHubName() {
    //     axios.post(`${process.env.REACT_APP_URL}/index.php/get_hub_name`, {hubid: item.hub_id})
    //         .then((respose) => {
    //             if (respose.ok) {
    //                 return respose.json()
    //             }
    //             throw new Error('error')
    //         })
    //         .then((data) => {
    //             console.log(data.Clstatus);
    //             setHubName(data.Clstatus);
               
    //            })
    //         .catch(err => console.log(err));
    // }

   

  const ExchangeBn = (e)=>{

    e.preventDefault();
    Swal.fire({
        title: 'Are you sure?',
        text: "You must confirm before Exchange",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
    }).then((result)=>{
        if(result.isConfirmed){
            async function exchangebtn(){
                try{
                    await fetch(`${process.env.REACT_APP_URL}/index.php/exchangeButton`, {
                        method: 'POST',
                        body: JSON.stringify({
                            
                            ClientD: loggedNUser,
                            waybill:  item.id                       
                        }),
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        } )
                        .then((respose) => {
                            if (respose.ok) {
                            return respose.json()
                            }
                            throw new Error('error')
                        })
                        .then((data) => {                                       
                                    
                            if(data.Clstatus === "success"){
                                console.log("success");
                            
                            Swal.fire({
                                title: "Good job!",
                                text: "Parcel has been Exchanged!",
                                icon: "success",
                                confirmButtonText: "OK",
                                timer: 1000,
                            });

                            dataIsChoose();
                            
                            
                            }else{
                                console.log("unsucess");
                            Swal.fire({
                                title: "Warning",
                                text: "Not Successful!",
                                icon: "error",
                                confirmButtonText: "OK",
                                timer: 1000,
                            });
            
                            }
                     
                        })

                }catch(error){
                     console.log(error.message);
                     
                }
            }
            exchangebtn()
        }else{

            Swal.fire({
                title: "",
                text: "Cancelled!",
                icon: "",
                confirmButtonText: "OK",
                timer: 1000,
            });

        }
    })    



  }


    const RemoveBtn = (e)=>{
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You must Confirm before do this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4caf50',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result)=>{
            if(result.isConfirmed){
                async function removebtn(){
                    try{
                        await fetch(`${process.env.REACT_APP_URL}/index.php/removeButton`, {
                            method: 'POST',
                            body: JSON.stringify({
                                
                                ClientD: loggedNUser,
                                waybill:  item.id                       
                            }),
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            } )
                            .then((respose) => {
                                if (respose.ok) {
                                return respose.json()
                                }
                                throw new Error('error')
                            })
                            .then((data) => {                                       
                                        
                                if(data.Clstatus === "success"){
                                    console.log("success");
                                
                                Swal.fire({
                                    title: "Good job!",
                                    text: "Parcel has been Removed!",
                                    icon: "success",
                                    confirmButtonText: "OK",
                                    timer: 1000,
                                });
                                dataIsChoose();
                                
                                }else{
                                    console.log("unsucess");
                                Swal.fire({
                                    title: "Warning",
                                    text: "Not Successful!",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    timer: 1000,
                                });
                
                                }
                         
                            })

                    }catch(error){
                         console.log(error.message);
                         
                    }
                }
                removebtn()
            }else{

                Swal.fire({
                    title: "",
                    text: "Cancelled!",
                    icon: "",
                    confirmButtonText: "OK",
                    timer: 1000,
                });

            }
        })      
    }
    
    

    async function getHubName() {
        //console.log(val)
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getHubName`, {
            method: 'POST', 
            body: JSON.stringify({
                hubid: item.hub_id                              
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             //console.log(data.Clstatus);
             setHubName(data.Clstatus);
            
            })
        } catch (error) {
        console.log(error.message)
        }
    }

    async function getRiderName(id) {
        
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getRiderName`, {
            method: 'POST', 
            body: JSON.stringify({
                riderid: id                           
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             console.log(data.Clstatus);
             setRiderName(data.Clstatus);
            
            })
        } catch (error) {
        console.log(error.message)
        }
    }

    // function getParcelType() {
    //     axios.get(`${process.env.REACT_APP_URL}/index.php/getParcelType`)
    //         .then(res => {
    //             setParcelType(res.data.Clstatus);
    //         })
    //         .catch(err => console.log(err))
    // }

    async function getParcelType() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelType`, {
            method: 'POST',          
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {          
                setParcelType(data.Clstatus);
            })
        } catch (error) {
          console.log(error.message)
        }
    }
    
    if(type == "allarchiveparcel"){
       var id =  item.waybill_id;
    }else{
       var id = item.id;
    }

    // var id = type="allarchiveparcel" ? item.waybill_id:item.id

    return(
        <tr style={{backgroundColor: item.exchange == 1 ? '#7fffd47d' : ''}}>
            <td>
                {id}{item.interface === 'API' ? <span className="badge badge-danger"> - API </span> : ''} <br/>
                {item.orderDate}
                <br/>
                {
                    (item.deliveryStatus == 'Waiting') && (<><span className="badge" style={{color: '#fff',backgroundColor: 'green'}}>{item.deliveryStatus}</span><br/></>)
                }
                {
                    (item.deliveryStatus == 'Pickup') && (<><span className="badge badge-warning">{item.deliveryStatus}</span><br/></>)
                }
                {
                    (item.deliveryStatus == 'Pending') && (<><span className="badge badge-info" >Processing</span><br/></>)
                }
                {
                    (item.deliveryStatus == 'Complete') && (<><span className="badge badge-success" >Delivered</span><br/></>)
                }
                {
                    (item.deliveryStatus == 'Reschedule') && (<><span className="badge badge-primary" >{item.deliveryStatus}</span><br/></>)
                }
                {
                    (item.deliveryStatus == 'Cancel') && (<><span className="badge badge-danger" >Return</span><br/></>)
                }
                {
                    (item.deliveryStatus == 'Dispatched') && (<><span className="badge" style={{color: '#fff',backgroundColor: '#f838f6'}}>{item.deliveryStatus}</span><br/></>)
                }
                {
                    (item.deliveryStatus == 'Delete') && (<><span className="badge" style={{color: '#fff',backgroundColor: 'red'}}>Remove</span><br/></>)
                }
                
                
                <span className="badge" style={{color: '#fff', backgroundColor: '#ff7600'}}>{hubName[0] && (hubName[0].name)}</span> <br/>
                {item.rider_id != null && (<><span className="badge badge-success">{riderName[0] && (`${riderName[0].fname} ${riderName[0].lname} (${item.rider_id})`)}</span><br/></>)}
                <b> {item.orderID ? `Order ID : ${item.orderID}` : ''}</b>
            </td>
            <td>
                
                {{...parcelType.find(element => element.id == item.pType)}.pName}<br/> 
                {item.pDescription && (<>Parcel Description: {item.pDescription}<br/></>) }
                {item.clientNote ? `Client Note : ${item.clientNote}`:''}       
            </td>
            <td>
                {item.recipientName}<br/>
                {item.recipientMobile}<br/>
                {item.recipientAddress}<br/>
                {{...cityNames.find(element => element.cid == item.zoneCity)}.cname}<br/>
                {{...zoneNames.find(element => element.cid == item.recipientCity)}.cname}
            </td>
            <td>
                {item.pickupFrom}<br/>
                {item.pickupAddress} <br/>
                {{...zoneNames.find(element => element.cid == item.pickupCity)}.cname}
                
            </td>
            <td>{item.paymentType}</td>
            <td>
                {item.paymentType == "COD" && (<>{item.CODAmount}.00 LKR</>)}
                {item.deliveryFee && (<p style={{fontSize: 'smaller'}}>Delivery: {item.deliveryFee} LKR</p>)}
            </td>
            <td> {item.adminNote ? item.adminNote : "-"} </td>
            <td>

                <div className="form-group">  
                    {/* <button type="button" className="btn btn-default btn-sm" id=""  style={{backgroundColor: '#3ca265', color: '#fff', marginRight:'3px', marginBottom: '2px'}} onClick={() => { changeDisplay('block'); setwaybill(item.id)}}> View </button> */}
                    <button type="button" className="btn btn-default btn-sm" id=""  style={{backgroundColor: '#3ca265', color: '#fff', marginRight:'3px', marginBottom: '2px'}} onClick={() => { changeDisplay('block'); setwaybill(id); setModalIsOpen(true);}} > View </button>
                    {/* <button type="button" className="btn btn-info btn-sm" onClick={()=> { setModalIsOpen(true); }}> View </button>  */}
                    {
                        (type === "waiting") && (<button type="button" className="btn btn-primary btn-sm" id="" onClick={() => { setEditWaybill(item); setEditModalIsOpen(true);}} style={{backgroundColor: '#0b6182', borderColor:'#0b6182', marginRight:'3px', marginBottom: '2px'}}> Edit </button>)
                    }
                    {
                       (type !== "allarchiveparcel") &&(<Link to={`/remove-parcel-print-label/${id}/${loggedNUser}`} className="btn btn-success btn-sm" style={{marginRight:'3px', marginBottom: '2px'}} target="_blank">Print</Link>)
                    }
                    {
                        (type === "waiting") && (<button className="btn btn-danger btn-sm" onClick={RemoveBtn} id="" style={{marginRight:'3px', marginBottom: '2px'}}>Remove</button>)
                    }
                    {
                        ((type === "waiting" || type === "processing" || type === "pickup" || type === "dispatched") && item.exchange != 1) && (<button type="button" className="btn btn-sm" onClick={ExchangeBn} style={{background: '#204886', borderColor: '#204886',color:'white', marginRight:'3px', marginBottom: '2px'}}>Mark as Exchange</button>)
                    }
                    
                </div>
                
            </td>
        </tr>
    )
}

export default TableBody;


// **********************************************************

// import {Link} from "react-router-dom";

// function TableBody({item, cityNames, zoneNames, setwaybill, changeDisplay}) {

//     return(
//         <tr key={item.id} style={{backgroundColor: item.exchange == 1 ? '#7fffd47d' : ''}}>
//             <td>
//                 {item.id} 
//                 {
//                     (item.interface == 'API') && (<> - <span className='badge badge-danger'>API</span> </>)
//                 }
//                 <br/>
//                 {item.orderDate}
//                 <br/>
                
//                    <span className="badge" style={{color: '#fff',backgroundColor: 'green'}}>{item.deliveryStatus}</span><br/>
                
                
//                 <span className="badge" style={{color: '#fff', backgroundColor: '#ff7600'}}>Test Hub</span> <br/>
//                 {
//                     (item.orderID) && (<><b>Order ID : {item.orderID}</b></>)
//                 }
                
//             </td>
//             <td>
//                 {item.pType ? `${item.pType} kg` : ""} <br/>
//                 {
//                     (item.pDescription) && (<>Parcel Description : {item.pDescription}</>)
//                 }
//                  {
//                     (item.clientNote) && (<><b>Client Note : {item.clientNote}</b></>)
//                 } 
                       
//             </td>
//             <td>
//                 {item.recipientName}<br/>
//                 {item.recipientMobile}<br/>
//                 {item.recipientAddress}<br/>
//                 {{...cityNames.find(element => element.cid == item.zoneCity)}.cname}<br/>
//                 {{...zoneNames.find(element => element.cid == item.recipientCity)}.cname}
//             </td>
//             <td>
//                 {item.pickupFrom}<br/>
//                 {item.pickupAddress} <br/>
//                 {{...zoneNames.find(element => element.cid == item.pickupCity)}.cname}
                
//             </td>
//             <td>{item.paymentType}</td>
//             <td>
//                 {
//                     (item.paymentType === "COD") && (<>{item.CODAmount} LKR<br/></>)
//                 } 
//                 <p style={{fontSize: 'smaller'}}>Delivery: {item.deliveryFee} LKR</p>
//             </td>
//             <td> {item.adminNote ? item.adminNote : "-"} </td>
//             <td>

//                 <div className="form-group">  
//                     <button type="button" className="btn btn-default btn-sm mr-1" id="viewModal155981" attr_parcel_id="155981" style={{backgroundColor: '#3ca265', color: '#fff'}} onClick={() => { changeDisplay('block'); setwaybill(item.id)}}> View </button> 
//                     {/* <a class="btn btn-success btn-sm" href="#" target="_blank">Print</a> */}
//                     {/* <Link to={`/remove-parcel-print-label/${item.id}`} className="btn btn-success btn-sm" target="_blank">Print</Link>*/}
//                 </div>
                
//             </td>
//         </tr>
//     )
// }

// export default TableBody;