import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";

function ModalPaymentArchData({paymentModalData, modalIsClose}) {
      
    const [batchParcel, setBatchParcel]                     = useState([]);
    const [clLoad, setclLoad]                               = useState('');
    const [batchPayment, setBatchPayment]                   = useState([]);
    const [archiveBatchParcel, setArchiveBatchParcel]       = useState([]);
    
    const [spinnerMa, setSpinnerMa] = useState(true);
    const [spinnerM, setSpinnerM]   = useState(true);

    const ClientD = sessionStorage.getItem('UserID');    
    
    
    var totalCOD                = 0;
    var totalDelivery           = 0;
    var totalcodafterDelivery   = 0;
    var codCommisstionParcelTotal = 0;
    var totalCancel = 0;
    var totalDiscount = 0;
    var newPayAmountNew = 0;    
    var newPayAmount = 0;

    var countn  = 0;

    useEffect(() => {
        async function batchAParcelRequest() {
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getbatchPayment`, {
                method: 'POST', 
                body: JSON.stringify({             
                    batchNum: paymentModalData.batchNum,               
                  }),         
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                    setBatchPayment(data.Clstatus);
                 
                })
            } catch (error) {
              console.log(error.message)
            }
          }
        batchAParcelRequest();       
      }, [])

    useEffect(() => {
        async function cDetailsRequest() {
          try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
              method: 'POST', 
              body: JSON.stringify({             
                  clientID: ClientD,
                 }),         
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            } )
              .then((respose) => {
                if (respose.ok) {
                  return respose.json()
                }
                throw new Error('error')
              })
              .then((data) => {          
                setclLoad(data.Clstatus);
               
              })
          } catch (error) {
            console.log(error.message)
          }
        }
        cDetailsRequest();
    }, []); 
    
    useEffect(() => {
        async function batchArParcelRequest() {
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getArchivebatchParcel`, {
                method: 'POST', 
                body: JSON.stringify({             
                    batchNum: paymentModalData.batchNum,               
                  }),         
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                    setArchiveBatchParcel(data.Clstatus);
                    setSpinnerMa(false);
                })
            } catch (error) {
              console.log(error.message)
            }
          }
        batchArParcelRequest();       
    }, [])
    
    useEffect(() => {
        async function batchParcelRequest() {
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getPbatchParcel`, {
                method: 'POST', 
                body: JSON.stringify({             
                    batchNum: paymentModalData.batchNum,               
                  }),         
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                    setBatchParcel(data.Clstatus);
                    setSpinnerM(false);
                })
            } catch (error) {
              console.log(error.message)
            }
          }
          batchParcelRequest();       
    }, [])
      
      
      
    //   useEffect(() => {
    //     async function batchMinusRequest() {
    //         try {
    //             await fetch(`${process.env.REACT_APP_URL}/index.php/getbatchMinus`, {
    //             method: 'POST', 
    //             body: JSON.stringify({             
    //                 batchNum: paymentModalData.batchNum,               
    //               }),         
    //             headers: {
    //               'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //           } )
    //             .then((respose) => {
    //               if (respose.ok) {
    //                 return respose.json()
    //               }
    //               throw new Error('error')
    //             })
    //             .then((data) => {
    //                 setBatchMinus(data.Clstatus);
                 
    //             })
    //         } catch (error) {
    //           console.log(error.message)
    //         }
    //       }
    //       batchMinusRequest();       
    //   }, [])
      
//   async function sheetRequest(){
//     try {
//         await fetch(`${process.env.REACT_APP_URL}/index.php/getReSheet`, {
//         method: 'POST', 
//         body: JSON.stringify({             
//             clientID: ClientD,
//             inputfdate: searchfdate,
//             inputtdate: searchtdate,
//             riderConfirm: searchData,
//             hubConfirm: searchhub,
//             adminConfirm: searchadmin,
//            }),         
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//       })
//         .then((respose) => {
//           if (respose.ok) {
//             return respose.json()
//           }
//           throw new Error('error')
//         })
//         .then((data) => {
//             dataIsChoose(data.Clstatus);
//             pageIsChoose(1);
//         })
//     } catch (error) {
//       console.log(error.message)
//     }
//   }   
    //   const ClConfirmHandler = (e) => {

    //     e.preventDefault();

    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You must Confirm before do this",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#4caf50',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Confirm'

    //     }).then((result) => {

    //         if (result.isConfirmed) {                      
                   
    //             async function vConfirmRequest() {
    //                 try {
    //                   await fetch(`${process.env.REACT_APP_URL}/index.php/vendorConfirm`, {
    //                     method: 'POST',
    //                     body: JSON.stringify({
    //                         Hid: modalData.id, 
    //                         ClientD: ClientD,                        
    //                     }),
    //                     headers: {
    //                       'Content-Type': 'application/x-www-form-urlencoded',
    //                     },
    //                   } )
    //                     .then((respose) => {
    //                       if (respose.ok) {
    //                         return respose.json()
    //                       }
    //                       throw new Error('error')
    //                     })
    //                     .then((data) => {                                       
                                   
    //                       if(data.Clstatus === "success"){
                            
    //                         Swal.fire({
    //                           title: "Good job!",
    //                           text: "Order has been Confirm!",
    //                           icon: "success",
    //                           confirmButtonText: "OK",
    //                           timer: 1000,
    //                         });
                           
    //                       }else{
          
    //                         Swal.fire({
    //                           title: "Warning",
    //                           text: "Not Successful!",
    //                           icon: "error",
    //                           confirmButtonText: "OK",
    //                           timer: 1000,
    //                         });
          
    //                       }
    //                       sheetRequest();
    //                       modalIsClose(false); 
                                             
    //                     })
    //                 } catch (error) {
    //                   console.log(error.message)
    //                 }
    //             }

    //             vConfirmRequest();
    //         }else{
            
    //         }
    //     })  

    //   };

    return (
        <div className="modal" id="modalBTNLoad" style={{display: 'block', overflow: 'auto'}}>
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content"  id="modalDivLoad"> 
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel" style={{marginLeft: 'auto'}}> 
                        Vendor Payment - {clLoad.companyName} ({ClientD}) / {batchPayment.date}                                                             
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> modalIsClose(false)}>
                            <span aria-hidden="true" style={{color: '#ffffff00'}}>&times;</span>
                        </button>
                    </div>

                    <div className="modal-body"> 
                        <div className="container-fluid">
                        {
                        (archiveBatchParcel.length !== 0) ? (
                            <div>
                                <div className="row" style={{paddingLeft: '20px'}}>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                        <h4 className="font-size-14">Vendor :  {clLoad.companyName} ({ClientD})</h4>
                                        <h4 className="font-size-14">Batch Number : {paymentModalData.batchNum}</h4>
                                        {
                                                (() => {
                                                
                                                  if(batchPayment.payStatus == 'paid'){
                                                    
                                                    return(
                                                        <p style={{color:'green', fontWeight: '500', fontSize: '16px'}}>Paid</p>
                                                    )
                                                      
                                                  }else if(batchPayment.payslip){
                                                    return(
                                                        <p style={{color:'#dcad00', fontWeight: '500', fontSize: '16px'}}>Processing</p>
                                                    )  
                                                  }else{
                                                    return(
                                                        <p style={{color:'red', fontWeight: '500', fontSize: '16px'}}>Unpaid</p>
                                                    )   
                                                  }                                                  
                                                })()  
                                        }
                             
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead  className="thead-dark">
                                                                <tr>
                                                                    <th>Parcel Info</th>
                                                                    <th>Delivered Date</th>
                                                                    <th style={{textAlign: 'right'}}>COD Amount</th>
                                                                    <th style={{textAlign: 'right'}}>Delivery Fee</th>
                                                                    <th style={{textAlign: 'right'}}>COD After Delivery Fee</th>
                                                                    <th style={{textAlign: 'right'}}>COD Commission
                                                                        {batchPayment.cod_commission_limit ? (
                                                                                    <span>({batchPayment.cod_commission_limit} LKR Limit)</span>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                        }
                                                                    </th>
                                                                    <th  style={{textAlign: 'right'}}>Return Fee                                   
                                                                        {batchPayment.cancelFee ? (
                                                                                    <span>({batchPayment.cancelFee}%)</span>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                        }
                                                                    </th>
                                                                    <th  style={{textAlign: 'right'}}>Discount
                                                                        {batchPayment.discount ? (
                                                                                    <span>({batchPayment.discount}%)</span>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                        }                                   
                                                                    </th>
                                                                </tr>
                                                        </thead>

                                                        <tbody>
                                                        { 
                                                        archiveBatchParcel.map(item=>{
                                                            return( 
                                                                <>
                                                                {
                                                                    (() => {
                                                                        if(((item.deliveryStatus == 'Complete') || (item.deliveryStatus == 'Cancel')) && (item.hand_over_vendor != '0')){
                                                                            if((item.paymentType == 'COD') && (item.deliveryStatus == 'Complete')){
                                                                                return (
                                                                                    <tr key={item.id}>
                                                                                        <td>{item.waybill_id}                              
                                                                                            <br/>{item.paymentType}
                                                                                            <br/>{item.orderID ? (
                                                                                                <span>Order ID :  {item.orderID}</span>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )
                                                                                            }
                                                                                        </td>
                                                                                        <td>{item.deliveredDate}</td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){
                                                                                                if(item.CODAmount){
                                                                                                    var newCodAmount = item.CODAmount
                                                                                                }else{
                                                                                                    var newCodAmount = 0
                                                                                                }
                                                                                                
                                                                                                totalCOD = (parseFloat(newCodAmount) + parseFloat(totalCOD)).toFixed(2);
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmount).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else if(item.deliveryStatus == 'Cancel'){
                                                                                                    return (
                                                                                                        <>0.00</>
                                                                                                    )  
                                                                                                }else{
                                                                                                    return (
                                                                                                    <>0.00</>
                                                                                                    )
                                                                                                }
                                                                                            })()  
                                                                                        }
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){
                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmount = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmount = 0
                                                                                                    }
                                                                                                totalDelivery = (parseFloat(newDelAmount) + parseFloat(totalDelivery)).toFixed(2);
                                                                                                
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmount).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }                                                                               
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                if(item.CODAmount){
                                                                                                    var newCodAmounto = item.CODAmount
                                                                                                }else{
                                                                                                    var newCodAmounto = 0
                                                                                                }

                                                                                                if(item.deliveryFee){
                                                                                                    var newDelAmounto = item.deliveryFee
                                                                                                }else{
                                                                                                    var newDelAmounto = 0
                                                                                                }
                                                                                                
                                                                                               
                                                                                                var codafterDelivery = (parseFloat(newCodAmounto) - parseFloat(newDelAmounto)).toFixed(2);
                                                                                                
                                                                                                totalcodafterDelivery = (parseFloat(codafterDelivery) + parseFloat(totalcodafterDelivery)).toFixed(2);
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codafterDelivery).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        } 
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}> 
                                                                                        {
                                                                                            (() => {
                                                                                                    if(item.CODAmount){
                                                                                                    var newCodAmountS = item.CODAmount
                                                                                                    }else{
                                                                                                    var newCodAmountS = 0
                                                                                                    }

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountS = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountS = 0
                                                                                                    }
                                                                                                    
                                                                                                    if(batchPayment.cod_commission_limit){
                                                                                                        var newcomLimit = batchPayment.cod_commission_limit
                                                                                                    }else{
                                                                                                        var newcomLimit = 0
                                                                                                    }

                                                                                                
                                                                                                var codafterDeliveryCOD = (parseFloat(newCodAmountS) - parseFloat(newDelAmountS)).toFixed(2);

                                                                                                if(codafterDeliveryCOD<=newcomLimit){ 

                                                                                                    if(batchPayment.cod_commission_limit_min){
                                                                                                        var newcomLimitmin = batchPayment.cod_commission_limit_min
                                                                                                    }else{
                                                                                                        var newcomLimitmin = 0
                                                                                                    }

                                                                                                    var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmin))/100).toFixed(2);
                                                                                                    codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                      
                                                                                                    
                                                                                                    return (
                                                                                                        <>
                                                                                                        {batchPayment.cod_commission_limit_min ? (
                                                                                                            <span>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcel).toFixed(2))}<br/>{batchPayment.cod_commission_limit_min} % Below</span>
                                                                                                        ) : (
                                                                                                            <> - </>
                                                                                                        )
                                                                                                        }
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                }else{

                                                                                                    if(batchPayment.cod_commission_limit_max){
                                                                                                        var newcomLimitmax = batchPayment.cod_commission_limit_max
                                                                                                    }else{
                                                                                                        var newcomLimitmax = 0
                                                                                                    }

                                                                                                    var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmax))/100).toFixed(2); 
                                                                                                    codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                      
                                                                                                    
                                                                                                    return (
                                                                                                    <>
                                                                                                    {batchPayment.cod_commission_limit_max ? (
                                                                                                        <span>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcel).toFixed(2))}<br/>{batchPayment.cod_commission_limit_max} % Above</span>
                                                                                                        ) : (
                                                                                                        <> - </>
                                                                                                        )
                                                                                                    }
                                                                                                    </>                                                                                                                
                                                                                                    )

                                                                                                }
                                                                                                
                                                                                                                                                    
                                                                                            
                                                                                                
                                                                                            })()  
                                                                                        }                                               
                                                                                        </td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                            {
                                                                                                (() => {
                                                                                                    if(item.deliveryStatus == 'Cancel'){
                                                                                                    
                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountCa = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountCa = 0
                                                                                                        }

                                                                                                        if(batchPayment.cancelFee){
                                                                                                            var newCancelFee = batchPayment.cancelFee
                                                                                                        }else{
                                                                                                            var newCancelFee = 0
                                                                                                        }

                                                                                                   
                                                                                                    var cancelFeeNew  = ((parseFloat(newDelAmountCa) * parseFloat(newCancelFee))/100).toFixed(2);  
                                                                                                    totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
                                                                                                        return (
                                                                                                            <>
                                                                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                                                                            </>                                                                                                                
                                                                                                        )
                                                                                                                                                        
                                                                                                    }else{
                                                                                                    return (
                                                                                                        <> - </>
                                                                                                    )
                                                                                                    }
                                                                                                })()  
                                                                                            }                                     
                                                                                        </td>  
                                                                                        <td style={{textAlign: 'right'}}>                                         
                                                                                        {
                                                                                            
                                                                                                (() => {
                                                                                                
                                                                                                    countn = countn+1
                                                                                                    
                                                                                                    if(item.deliveryStatus == 'Complete'){

                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountDis = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountDis = 0
                                                                                                        }

                                                                                                        if(batchPayment.discount){
                                                                                                            var newdiscFee = batchPayment.discount
                                                                                                        }else{
                                                                                                            var newdiscFee = 0
                                                                                                        }

                                                                                                    var discountedAmount  = ((parseFloat(newDelAmountDis)/100) * parseFloat(newdiscFee)).toFixed(2);
                                                                                                    
                                                                                                   
                                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                    return (
                                                                                                            <>
                                                                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                            </>                                                                                                                
                                                                                                        )
                                                                                                                                                        
                                                                                                    }else if(item.deliveryStatus == 'Cancel'){

                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountDisO = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountDisO = 0
                                                                                                        }

                                                                                                        if(batchPayment.discount){
                                                                                                            var newdiscFeeO = batchPayment.discount
                                                                                                        }else{
                                                                                                            var newdiscFeeO = 0
                                                                                                        }

                                                                                                        if(batchPayment.cancelFee){
                                                                                                            var newCancelFeeO = batchPayment.cancelFee
                                                                                                        }else{
                                                                                                            var newCancelFeeO = 0
                                                                                                        }

                                                                                                    
                                                                                                    
                                                                                                    var cancelFeeNew      = ((parseFloat(newDelAmountDisO) * parseFloat(newCancelFeeO))/100).toFixed(2); 
                                                                                                    var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeO)).toFixed(2);

                                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                    
                                                                                                    return (
                                                                                                        <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                                                                    )
                                                                                                    }else{
                                                                                                    
                                                                                                    return (
                                                                                                    <>0.00</>
                                                                                                    )
                                                                                                    }
                                                                                                })()  
                                                                                        }
                                                                                        
                                                                                        </td>  
                                                                                    </tr>
                                                                                )
                                                                            }else if((item.deliveryStatus == 'Cancel') && (item.paymentType == 'COD')){
                                                                                return (
                                                                                    <tr key={item.id}>
                                                                                        <td>{item.waybill_id}<br/>{item.paymentType}</td>
                                                                                        <td>{item.deliveredDate}</td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){
                                                                                                if(item.CODAmount){
                                                                                                    var newCodAmountTh = item.CODAmount
                                                                                                }else{
                                                                                                    var newCodAmountTh = 0
                                                                                                }
                                                                                               
                                                                                                totalCOD = (parseFloat(newCodAmountTh) + parseFloat(totalCOD)).toFixed(2); 
                                                                                                    return (
                                                                                                        <>
                                                                                                       {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountTh).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else if(item.deliveryStatus == 'Cancel'){
                                                                                                    return (
                                                                                                        <>0.00</>
                                                                                                    )  
                                                                                                }else{
                                                                                                return (
                                                                                                    <>0.00</>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }  
                                                                                            
                                                                                        </td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountTh = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountTh = 0
                                                                                                    }

                                                                                                totalDelivery = (parseFloat(newDelAmountTh) + parseFloat(totalDelivery)).toFixed(2);
                                                                                                // totalDelivery = item.deliveryFee+totalDelivery; 
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountTh).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }  
                                                                                            
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        -
                                                                                        </td>
                                                                            
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                            -  
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Cancel'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountCaTh = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountCaTh = 0
                                                                                                    }

                                                                                                    if(batchPayment.cancelFee){
                                                                                                        var newCancelFeeTh = batchPayment.cancelFee
                                                                                                    }else{
                                                                                                        var newCancelFeeTh = 0
                                                                                                    }

                                                                                                var cancelFeeNew  = ((parseFloat(newDelAmountCaTh) * parseFloat(newCancelFeeTh))/100).toFixed(2); 
                                                                                                totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);

                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }
                                                                                                
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            
                                                                                            (() => {
                                                                                            
                                                                                            countn = countn+1
                                                                                            
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisTh = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisTh = 0
                                                                                                    }

                                                                                                    if(batchPayment.discount){
                                                                                                        var newdiscFeeTh = batchPayment.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeTh = 0
                                                                                                    }

                                                                                                var discountedAmount  = ((parseFloat(newDelAmountDisTh)/100) * parseFloat(newdiscFeeTh)).toFixed(2);  

                                                                                                
                                                                                                totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                
                                                                                                return (
                                                                                                        <>
                                                                                                         {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                    
                                                                                                }else if(item.deliveryStatus == 'Cancel'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisOS = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisOS = 0
                                                                                                    }

                                                                                                    if(batchPayment.discount){
                                                                                                        var newdiscFeeOS = batchPayment.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeOS = 0
                                                                                                    }

                                                                                                    if(batchPayment.cancelFee){
                                                                                                        var newCancelFeeOS = batchPayment.cancelFee
                                                                                                    }else{
                                                                                                        var newCancelFeeOS = 0
                                                                                                    }

                                                                                                var cancelFeeNew      = ((parseFloat(newDelAmountDisOS) * parseFloat(newCancelFeeOS))/100).toFixed(2);
                                                                                                var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOS)).toFixed(2);
                                                      
                                                                                                
                                                                                                totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);

                                                                                                return (
                                                                                                    <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                                                                )
                                                                                                }else{
                                                                                                return (
                                                                                                <>0.00</>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }
                                                                                        
                                                                                        </td>  
                                                                                    </tr>
                                                                                )   
                                                                            }else if(item.paymentType != 'COD'){
                                                                                return (
                                                                                    <tr key={item.id}>
                                                                                        <td>{item.waybill_id}<br/>{item.paymentType}</td>                                
                                                                                        <td>{item.deliveredDate}</td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                            {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){
                                                                                                    if(item.CODAmount){
                                                                                                    var newCodAmountF = item.CODAmount
                                                                                                    }else{
                                                                                                    var newCodAmountF = 0
                                                                                                    }
                                                                                                    
                                                                                                    totalCOD = (parseFloat(newCodAmountF) + parseFloat(totalCOD)).toFixed(2);
                                                                                                    return (
                                                                                                        <>
                                                                                                       {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountF).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                        
                                                                                                }else if(item.deliveryStatus == 'Cancel'){
                                                                                                    return (
                                                                                                        <>0.00</>
                                                                                                    )  
                                                                                                }else{
                                                                                                    return (
                                                                                                    <>0.00</>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                            }  
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountFi = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountFi = 0
                                                                                                    }
                                                                                                totalDelivery = (parseFloat(newDelAmountFi) + parseFloat(totalDelivery)).toFixed(2);
                                                                                                
                                                                                                    return (
                                                                                                        <>
                                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountFi).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                        
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }  
                                                                                            
                                                                                        </td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        -
                                                                                        </td>                                                              
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                            -  
                                                                                        </td>
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                            (() => {
                                                                                                if(item.deliveryStatus == 'Cancel'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountCaSix = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountCaSix = 0
                                                                                                    }

                                                                                                    if(batchPayment.cancelFee){
                                                                                                        var newCancelFeeSix = batchPayment.cancelFee
                                                                                                    }else{
                                                                                                        var newCancelFeeSix = 0
                                                                                                    }

                                                                                                
                                                                                                var cancelFeeNew  = ((parseFloat(newDelAmountCaSix) * parseFloat(newCancelFeeSix))/100).toFixed(2);

                                                                                                totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
                                                                                                
                                                                                                    return (
                                                                                                        <>
                                                                                                       {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                        
                                                                                                }else{
                                                                                                return (
                                                                                                    <> - </>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }
                                                                                                
                                                                                        </td> 
                                                                                        <td style={{textAlign: 'right'}}>
                                                                                        {
                                                                                        
                                                                                            (() => {
                                                                                            countn = countn+1
                                                                                            
                                                                                                if(item.deliveryStatus == 'Complete'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisE = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisE = 0
                                                                                                    }

                                                                                                    if(batchPayment.discount){
                                                                                                        var newdiscFeeE = batchPayment.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeE = 0
                                                                                                    }

                                                                                                var discountedAmount  = ((parseFloat(newDelAmountDisE)/100) * parseFloat(newdiscFeeE)).toFixed(2); 
                                                                                               
                                                                                                totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                
                                                                                                return (
                                                                                                        <>
                                                                                                      {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                        </>                                                                                                                
                                                                                                    )
                                                                                                                                                        
                                                                                                }else if(item.deliveryStatus == 'Cancel'){

                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisOE = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisOE = 0
                                                                                                    }

                                                                                                    if(batchPayment.discount){
                                                                                                        var newdiscFeeOE = batchPayment.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeOE = 0
                                                                                                    }

                                                                                                    if(batchPayment.cancelFee){
                                                                                                        var newCancelFeeOE = batchPayment.cancelFee
                                                                                                    }else{
                                                                                                        var newCancelFeeOE = 0
                                                                                                    }

                                                                                                var cancelFeeNew = ((parseFloat(newDelAmountDisOE) * parseFloat(newCancelFeeOE))/100).toFixed(2); 
                                                                                                var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOE)).toFixed(2); 
                                                      
                                                                                                 
                                                                                                totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                                
                                                                                                return (
                                                                                                    <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                                                                )
                                                                                                }else{
                                                                                                return (
                                                                                                <>0.00</>
                                                                                                )
                                                                                                }
                                                                                            })()  
                                                                                        }                                                                                     
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }                                                                            
                                                                        }
                                                                    })()  
                                                                }
                                                                </>
                                                            )
                                                        })
                                                        }

                                                        {                                                                                                                 
                                                            batchParcel.map(items=>{
                                                            return(                             
                                                                <> 
                                                                {
                                                                (() => {
                                                                if(((items.deliveryStatus == 'Complete') || (items.deliveryStatus == 'Cancel')) && ((items.hand_over_vendor != '0'))){
                                                                    if((items.paymentType == 'COD') && (items.deliveryStatus == 'Complete')){
                                                                    return (
                                                                        <tr key={items.id}>
                                                                        <td>{items.waybill_id}                              
                                                                            <br/>{items.paymentType}
                                                                            <br/>{items.orderID ? (
                                                                                <span>Order ID :  {items.orderID}</span>
                                                                                ) : (
                                                                                <></>
                                                                                )
                                                                                }
                                                                        </td>
                                                            
                                                                        <td>{items.deliveredDate}</td>

                                                                        <td style={{textAlign: 'right'}}>
                                                                        {
                                                                            (() => {
                                                                                if(items.deliveryStatus == 'Complete'){
                                                                                if(items.CODAmount){
                                                                                    var newCodAmount = items.CODAmount
                                                                                }else{
                                                                                    var newCodAmount = 0
                                                                                }
                                                                                
                                                                                totalCOD = (parseFloat(totalCOD) + parseFloat(newCodAmount)).toFixed(2);
                                                                                    return (
                                                                                        <>
                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmount).toFixed(2))}
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                                                                    
                                                                                }else if(items.deliveryStatus == 'Cancel'){
                                                                                    return (
                                                                                        <>0.00</>
                                                                                    )  
                                                                                }else{
                                                                                    return (
                                                                                    <>0.00</>
                                                                                    )
                                                                                }
                                                                            })()  
                                                                        }
                                                                        </td> 
                                                            
                                                                        <td style={{textAlign: 'right'}}>
                                                                            {
                                                                                (() => {
                                                                                    if(items.deliveryStatus == 'Complete'){
                                                                                        if(items.deliveryFee){
                                                                                            var newDelAmount = items.deliveryFee
                                                                                        }else{
                                                                                            var newDelAmount = 0
                                                                                        }

                                                                                    totalDelivery = (parseFloat(newDelAmount) + parseFloat(totalDelivery)).toFixed(2);
                                                                                    
                                                                                        
                                                                                        return (
                                                                                            <>
                                                                                           {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmount).toFixed(2))}
                                                                                            </>                                                                                                                
                                                                                        )
                                                                                                                                        
                                                                                    }else{
                                                                                    return (
                                                                                        <> - </>
                                                                                    )
                                                                                    }
                                                                                })()  
                                                                            }                                                                               
                                                                        </td> 
                                                            
                                                                        <td style={{textAlign: 'right'}}>
                                                                        {
                                                                            (() => {
                                                                                if(items.deliveryStatus == 'Complete'){

                                                                                    if(items.CODAmount){
                                                                                        var newCodAmounto = items.CODAmount
                                                                                    }else{
                                                                                        var newCodAmounto = 0
                                                                                    }

                                                                                    if(items.deliveryFee){
                                                                                        var newDelAmounto = items.deliveryFee
                                                                                    }else{
                                                                                        var newDelAmounto = 0
                                                                                    }
                                                                                    
                                                                                     
                                                                                    var codafterDelivery = (parseFloat(newCodAmounto) - parseFloat(newDelAmounto)).toFixed(2);
                                                                                    totalcodafterDelivery = (parseFloat(codafterDelivery) + parseFloat(totalcodafterDelivery)).toFixed(2);

                                                                                
                                                                                    return (
                                                                                        <>
                                                                                     {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codafterDelivery).toFixed(2))}
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                                                                    
                                                                                }else{
                                                                                return (
                                                                                    <> - </>
                                                                                )
                                                                                }
                                                                            })()  
                                                                        } 
                                                                        </td> 
                                                            
                                                                        <td style={{textAlign: 'right'}}> 

                                                                        {
                                                                            (() => {

                                                                                if(items.CODAmount){
                                                                                    var newCodAmountS = items.CODAmount
                                                                                }else{
                                                                                    var newCodAmountS = 0
                                                                                }

                                                                                if(items.deliveryFee){
                                                                                        var newDelAmountS = items.deliveryFee
                                                                                }else{
                                                                                        var newDelAmountS = 0
                                                                                }
                                                                                    
                                                                                if(batchPayment.cod_commission_limit){
                                                                                        var newcomLimit = batchPayment.cod_commission_limit
                                                                                }else{
                                                                                        var newcomLimit = 0
                                                                                }

                                                                                
                                                                                var codafterDeliveryCOD = (parseFloat(newCodAmountS) - parseFloat(newDelAmountS)).toFixed(2);

                                                                                if(codafterDeliveryCOD<=newcomLimit){ 
                                                                                
                                                                                    if(batchPayment.cod_commission_limit_min){
                                                                                        var newcomLimitmin = batchPayment.cod_commission_limit_min
                                                                                    }else{
                                                                                        var newcomLimitmin = 0
                                                                                    }

                                                                                    var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmin))/100).toFixed(2);
                                                                                    codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                      
                                                                                    
                                                                                    
                                                                                    return (
                                                                                        <>
                                                                                        {batchPayment.cod_commission_limit_min ? (
                                                                                            <span>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcel).toFixed(2))}<br/>{batchPayment.cod_commission_limit_min} % Below</span>
                                                                                        ) : (
                                                                                            <> - </>
                                                                                        )
                                                                                        }
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                }else{

                                                                                    if(batchPayment.cod_commission_limit_max){
                                                                                        var newcomLimitmax = batchPayment.cod_commission_limit_max
                                                                                    }else{
                                                                                        var newcomLimitmax = 0
                                                                                    }

                                                                                    
                                                                                    
                                                                                    var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmax))/100).toFixed(2); 
                                                                                    codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);

                                                                                    return (
                                                                                    <>
                                                                                    {batchPayment.cod_commission_limit_max ? (
                                                                                        <span>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcel).toFixed(2))}<br/>{batchPayment.cod_commission_limit_max} % Above</span>
                                                                                        ) : (
                                                                                        <> - </>
                                                                                        )
                                                                                    }
                                                                                    </>                                                                                                                
                                                                                    )

                                                                                }
                                                                                
                                                                            })()  
                                                                        }                                               
                                                                        </td>
                                                                                    
                                                                        <td style={{textAlign: 'right'}}>
                                                                            {
                                                                                (() => {
                                                                                    if(items.deliveryStatus == 'Cancel'){

                                                                                        if(items.deliveryFee){
                                                                                            var newDelAmountCa = items.deliveryFee
                                                                                        }else{
                                                                                            var newDelAmountCa = 0
                                                                                        }

                                                                                        if(batchPayment.cancelFee){
                                                                                            var newCancelFee = batchPayment.cancelFee
                                                                                        }else{
                                                                                            var newCancelFee = 0
                                                                                        }

                                                                                        
                                                                                        var cancelFeeNew  = ((parseFloat(newDelAmountCa) * parseFloat(newCancelFee))/100).toFixed(2); 
                                                                                        totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);

                                                                                        return (
                                                                                            <>
                                                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                                                            </>                                                                                                                
                                                                                        )
                                                                                                                                        
                                                                                    }else{
                                                                                    return (
                                                                                        <> - </>
                                                                                    )
                                                                                    }
                                                                                })()  
                                                                            }                                     
                                                                        </td>
                                                    
                                                                        <td style={{textAlign: 'right'}}>
                                                                        
                                                                            {                                       
                                                                                    (() => {
                                                                                    
                                                                                        countn = countn+1
                                                                                        
                                                                                        if(items.deliveryStatus == 'Complete'){

                                                                                            if(items.deliveryFee){
                                                                                                var newDelAmountDis = items.deliveryFee
                                                                                            }else{
                                                                                                var newDelAmountDis = 0
                                                                                            }

                                                                                            if(batchPayment.discount){
                                                                                                var newdiscFee = batchPayment.discount
                                                                                            }else{
                                                                                                var newdiscFee = 0
                                                                                            }


                                                                                         
                                                                                        var discountedAmount  = ((parseFloat(newDelAmountDis)/100) * parseFloat(newdiscFee)).toFixed(2); 
                                                                                        totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);
                                                                                
                                                                                        return (
                                                                                                <>
                                                                                              {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                                </>                                                                                                                
                                                                                            )
                                                                                                                                            
                                                                                        }else if(items.deliveryStatus == 'Cancel'){

                                                                                            if(items.deliveryFee){
                                                                                                var newDelAmountDisO = items.deliveryFee
                                                                                            }else{
                                                                                                var newDelAmountDisO = 0
                                                                                            }

                                                                                            if(batchPayment.discount){
                                                                                                var newdiscFeeO = batchPayment.discount
                                                                                            }else{
                                                                                                var newdiscFeeO = 0
                                                                                            }

                                                                                            if(batchPayment.cancelFee){
                                                                                                var newCancelFeeO = batchPayment.cancelFee
                                                                                            }else{
                                                                                                var newCancelFeeO = 0
                                                                                            }

                                                                                        
                                                                                        
                                                                                        var cancelFeeNew      = ((parseFloat(newDelAmountDisO) * parseFloat(newCancelFeeO))/100).toFixed(2); 
                                                                                        var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeO)).toFixed(2); 

                                                                                        totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);

                                                                                        return (
                                                                                            <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                                                        )
                                                                                        }else{
                                                                                        
                                                                                        return (
                                                                                        <>0.00</>
                                                                                        )
                                                                                        }
                                                                                    })()  
                                                                            }
                                                                            
                                                                        </td>                                                               
                                                                        </tr>                                                                                                               
                                                                    )
                                                                    }else if((items.deliveryStatus == 'Cancel') && (items.paymentType == 'COD')){
                                                                    return (
                                                                        <tr key={items.id}>
                                                                        <td>{items.waybill_id}<br/>{items.paymentType}</td>
                                                            
                                                                        <td>{items.deliveredDate}</td>

                                                                        <td style={{textAlign: 'right'}}>
                                                                        {
                                                                            (() => {
                                                                                if(items.deliveryStatus == 'Complete'){
                                                                                if(items.CODAmount){
                                                                                    var newCodAmountTh = items.CODAmount
                                                                                }else{
                                                                                    var newCodAmountTh = 0
                                                                                }

                                                                                totalCOD = (parseFloat(newCodAmountTh) + parseFloat(totalCOD)).toFixed(2); 
                                                                                
                                                                                    return (
                                                                                        <>
                                                                                       {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountTh).toFixed(2))}
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                                                                    
                                                                                }else if(items.deliveryStatus == 'Cancel'){
                                                                                    return (
                                                                                        <>0.00</>
                                                                                    )  
                                                                                }else{
                                                                                return (
                                                                                    <>0.00</>
                                                                                )
                                                                                }
                                                                            })()  
                                                                        }  
                                                                            
                                                                        </td> 
                                                            
                                                                        <td style={{textAlign: 'right'}}>
                                                                        {
                                                                            (() => {
                                                                                if(items.deliveryStatus == 'Complete'){

                                                                                    if(items.deliveryFee){
                                                                                        var newDelAmountTh = items.deliveryFee
                                                                                    }else{
                                                                                        var newDelAmountTh = 0
                                                                                    }

                                                                                    totalDelivery = (parseFloat(newDelAmountTh) + parseFloat(totalDelivery)).toFixed(2);
                                                                                
                                                                                    return (
                                                                                        <>
                                                                                       {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountTh).toFixed(2))}
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                                                                    
                                                                                }else{
                                                                                return (
                                                                                    <> - </>
                                                                                )
                                                                                }
                                                                            })()  
                                                                        }  
                                                                            
                                                                        </td> 
                                    
                                                                        <td style={{textAlign: 'right'}}>
                                                                            - 
                                                                        </td>
                                                            
                                                                        <td style={{textAlign: 'right'}}>
                                                                            -  
                                                                        </td>
                                                            
                                                                        <td style={{textAlign: 'right'}}>
                                                                        {
                                                                            (() => {
                                                                                if(items.deliveryStatus == 'Cancel'){

                                                                                    if(items.deliveryFee){
                                                                                        var newDelAmountCa = items.deliveryFee
                                                                                    }else{
                                                                                        var newDelAmountCa = 0
                                                                                    }

                                                                                    if(batchPayment.cancelFee){
                                                                                        var newCancelFee = batchPayment.cancelFee
                                                                                    }else{
                                                                                        var newCancelFee = 0
                                                                                    }

                                                                                     
                                                                                    var cancelFeeNew  = ((parseFloat(newDelAmountCa) * parseFloat(newCancelFee))/100).toFixed(2); 
                                                                                    totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);

                                                                                    return (
                                                                                        <>
                                                                                      {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                                                                    
                                                                                }else{
                                                                                return (
                                                                                    <> - </>
                                                                                )
                                                                                }
                                                                            })()  
                                                                        }
                                                                                
                                                                        </td>
                                                            
                                                    
                                                                        <td style={{textAlign: 'right'}}>
                                                                        {
                                                                            
                                                                            (() => {
                                                                            
                                                                            countn = countn+1
                                                                            
                                                                                if(items.deliveryStatus == 'Complete'){

                                                                                    if(items.deliveryFee){
                                                                                        var newDelAmountDis = items.deliveryFee
                                                                                    }else{
                                                                                        var newDelAmountDis = 0
                                                                                    }

                                                                                    if(batchPayment.discount){
                                                                                        var newdiscFee = batchPayment.discount
                                                                                    }else{
                                                                                        var newdiscFee = 0
                                                                                    }

                                                                                    
                                                                                    var discountedAmount  = ((parseFloat(newDelAmountDis)/100) * parseFloat(newdiscFee)).toFixed(2);  
                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);

                                                                                    return (
                                                                                            <>
                                                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                            </>                                                                                                                
                                                                                    )
                                                                                                                                    
                                                                                }else if(items.deliveryStatus == 'Cancel'){

                                                                                    if(items.deliveryFee){
                                                                                        var newDelAmountDisO = items.deliveryFee
                                                                                    }else{
                                                                                        var newDelAmountDisO = 0
                                                                                    }

                                                                                    if(batchPayment.discount){
                                                                                        var newdiscFeeO = batchPayment.discount
                                                                                    }else{
                                                                                        var newdiscFeeO = 0
                                                                                    }

                                                                                    if(batchPayment.cancelFee){
                                                                                        var newCancelFeeO = batchPayment.cancelFee
                                                                                    }else{
                                                                                        var newCancelFeeO = 0
                                                                                    }

                                                                                    
                                                                                    
                                                                                    var cancelFeeNew      = ((parseFloat(newDelAmountDisO) * parseFloat(newCancelFeeO))/100).toFixed(2);
                                                                                    var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeO)).toFixed(2); 

                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);

                                                                                    return (
                                                                                        <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                                                    )
                                                                                }else{
                                                                                return (
                                                                                <>0.00</>
                                                                                )
                                                                                }
                                                                            })()  
                                                                        }
                                                                        
                                                                        </td>
                                                                        </tr>                                                                                                                
                                                                    )
                                                                    }else if(items.paymentType != 'COD'){
                                                                    return (
                                                                        <tr key={items.id}>
                                                                        <td>{items.waybill_id}<br/>{items.paymentType}</td>
                                                            
                                                                        <td>{items.deliveredDate}</td>

                                                                        <td style={{textAlign: 'right'}}>
                                                                            {
                                                                            (() => {
                                                                                if(items.deliveryStatus == 'Complete'){
                                                                                    if(items.CODAmount){
                                                                                    var newCodAmountF = items.CODAmount
                                                                                    }else{
                                                                                    var newCodAmountF = 0
                                                                                    }

                                                                                    totalCOD = (parseFloat(newCodAmountF) + parseFloat(totalCOD)).toFixed(2);
                                                                                    
                                                                                    return (
                                                                                        <>
                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountF).toFixed(2))}
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                                                                        
                                                                                }else if(items.deliveryStatus == 'Cancel'){
                                                                                    return (
                                                                                        <>0.00</>
                                                                                    )  
                                                                                }else{
                                                                                    return (
                                                                                    <>0.00</>
                                                                                )
                                                                                }
                                                                            })()  
                                                                            }  
                                                                        </td> 
                                            
                                                                        <td style={{textAlign: 'right'}}>
                                                                        {
                                                                            (() => {
                                                                                if(items.deliveryStatus == 'Complete'){
                                                                                
                                                                                    if(items.deliveryFee){
                                                                                        var newDelAmountThn = items.deliveryFee
                                                                                    }else{
                                                                                        var newDelAmountThn = 0
                                                                                    }

                                                                                    totalDelivery = (parseFloat(newDelAmountThn) + parseFloat(totalDelivery)).toFixed(2);

                                                                                    return (
                                                                                        <>
                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountThn).toFixed(2))}
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                                                                        
                                                                                }else{
                                                                                return (
                                                                                    <> - </>
                                                                                )
                                                                                }
                                                                            })()  
                                                                        }  
                                                                            
                                                                        </td> 

                                                                        <td style={{textAlign: 'right'}}>
                                                                        -
                                                                        </td>
                                                            
                                                                        <td style={{textAlign: 'right'}}>
                                                                            -  
                                                                        </td>
                                                
                                                                        <td style={{textAlign: 'right'}}>
                                                                        {
                                                                            (() => {
                                                                                if(items.deliveryStatus == 'Cancel'){

                                                                                    if(items.deliveryFee){
                                                                                        var newDelAmountCaTh = items.deliveryFee
                                                                                    }else{
                                                                                        var newDelAmountCaTh = 0
                                                                                    }

                                                                                    if(batchPayment.cancelFee){
                                                                                        var newCancelFeeTh = batchPayment.cancelFee
                                                                                    }else{
                                                                                        var newCancelFeeTh = 0
                                                                                    }

                                                                                    var cancelFeeNew  = ((parseFloat(newDelAmountCaTh) * parseFloat(newCancelFeeTh))/100).toFixed(2); 

                                                                                    
                                                                                    totalCancel = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);

                                                                                    return (
                                                                                        <>
                                                                                    {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(cancelFeeNew).toFixed(2))}
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                                                                        
                                                                                }else{
                                                                                return (
                                                                                    <> - </>
                                                                                )
                                                                                }
                                                                            })()  
                                                                        }
                                                                                
                                                                        </td>            
                                        
                                                                        <td style={{textAlign: 'right'}}>
                                                                        {
                                                                        
                                                                            (() => {
                                                                            countn = countn+1
                                                                            
                                                                                if(items.deliveryStatus == 'Complete'){

                                                                                    if(items.deliveryFee){
                                                                                        var newDelAmountDisTh = items.deliveryFee
                                                                                    }else{
                                                                                        var newDelAmountDisTh = 0
                                                                                    }

                                                                                    if(batchPayment.discount){
                                                                                        var newdiscFeeTh = batchPayment.discount
                                                                                    }else{
                                                                                        var newdiscFeeTh = 0
                                                                                    }

                                                                                    
                                                                                    var discountedAmount  = ((parseFloat(newDelAmountDisTh)/100) * parseFloat(newdiscFeeTh)).toFixed(2); 
                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);

                                                                                return (
                                                                                        <>
                                                                                        {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}
                                                                                        </>                                                                                                                
                                                                                    )
                                                                                                                                        
                                                                                }else if(items.deliveryStatus == 'Cancel'){

                                                                                    if(items.deliveryFee){
                                                                                        var newDelAmountDisOS = items.deliveryFee
                                                                                    }else{
                                                                                        var newDelAmountDisOS = 0
                                                                                    }

                                                                                    if(batchPayment.discount){
                                                                                        var newdiscFeeOS = batchPayment.discount
                                                                                    }else{
                                                                                        var newdiscFeeOS = 0
                                                                                    }

                                                                                    if(batchPayment.cancelFee){
                                                                                        var newCancelFeeOS = batchPayment.cancelFee
                                                                                    }else{
                                                                                        var newCancelFeeOS = 0
                                                                                    }

                                                                                    var cancelFeeNew = ((parseFloat(newDelAmountDisOS) * parseFloat(newCancelFeeOS))/100).toFixed(2); 
                                                                                    var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOS)).toFixed(2); 
                                                      
                                                                                    
                                                                                    
                                                                                    totalDiscount = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);

                                                                                    return (
                                                                                        <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(discountedAmount).toFixed(2))}</>
                                                                                    )
                                                                                }else{
                                                                                return (
                                                                                <>0.00</>
                                                                                )
                                                                                }
                                                                            })()  
                                                                        }                                   
                                                                        </td>
                                                                        </tr>                                                                                                               
                                                                    )
                                                                    }
                                                                                                                                                                                
                                                                }

                                                                })()  
                                                                }                            
                                                                </>                       
                                                            )                                    
                                                            })
                                                        }





                                                            <tr style={{fontWeight: '500'}}>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>Total</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCOD).toFixed(2))}</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalDelivery).toFixed(2))}</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalcodafterDelivery).toFixed(2))}</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcelTotal).toFixed(2))}</td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCancel).toFixed(2))}

                                                                {
                                                                    (() => {
                                                                    
                                                                    var sumReturnNDelivFee      = (parseFloat(totalDelivery) + parseFloat(totalCancel)).toFixed(2);
                                                                    var finalsumReturnNDelivFee = (parseFloat(sumReturnNDelivFee) - parseFloat(totalDiscount)).toFixed(2);
                                                                        
                                                                    newPayAmount                = (parseFloat(totalCOD) - parseFloat(finalsumReturnNDelivFee)-parseFloat(codCommisstionParcelTotal)).toFixed(2);   
                                                                    
                                                                    if(batchPayment.creditBlance != '0'){
                                                                        
                                                                        newPayAmountNew      = (parseFloat(newPayAmount) + parseFloat(batchPayment.creditBlance)).toFixed(2);
                                                                    }else{
                                                                        newPayAmountNew = newPayAmount;
                                                                    }
                                                                        
                                                                    })()  
                                                                }
                                                                </td>
                                                                <td style={{textAlign: 'right'}}>{parseFloat(totalDiscount).toFixed(2)}</td>
                                                            </tr>

                                                            <tr style={{fontWeight: '800'}}>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{border: '1px solid #ffffff'}}></td>
                                                                <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                {
                                                                    (() => {
                                                                    
                                                                    if(newPayAmount< 0){
                                                                        return(<>Sub Due Amount</>)
                                                                        
                                                                    }else{
                                                                        return(<>Sub Pay Amount</>)
                                                                    }
                                                                        
                                                                    })()  
                                                                }
                                                                </td>
                                                                <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newPayAmount).toFixed(2))}</td>
                                                            </tr>
                                                            {
                                                                (() => {
                                                                
                                                                if(batchPayment.creditBlance == '0'){
                                                                
                                                                    if(batchPayment.amount){
                                                                        var newpAmount = batchPayment.amount
                                                                    }else{
                                                                        var newpAmount = 0
                                                                    }
                                                                    
                                                                    if(batchPayment.tax_amount){
                                                                        var newpTaxAmount = batchPayment.tax_amount
                                                                    }else{
                                                                        var newpTaxAmount = 0
                                                                    }
                                                                    
                                                                    var TotalCredit = (parseFloat(newPayAmountNew) - parseFloat(newpAmount) - parseFloat(newpTaxAmount)).toFixed(2);  
                                                                    
                                                                    return(
                                                                    <tr style={{fontWeight: '800'}}>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                    Credit Balance
                                                                    </td>                                                                    
                                                                    <td style={{textAlign: 'right'}}>
                                                                    {
                                                                        (() => {
                                                                        if(TotalCredit == '0.00'){
                                                                            return(TotalCredit)
                                                                        }else{
                                                                        return(<>- {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(TotalCredit).toFixed(2))}</>)
                                                                        }
                                                                        })()  
                                                                    }

                                                                    
                                                                    </td>
                                                                    </tr>
                                                                )
                                                                    
                                                                }else{
                                                                    return(
                                                                    <tr style={{fontWeight: '800'}}>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                    Credit Balance
                                                                    </td>
                                                                    <td style={{textAlign: 'right'}}>
                                                                    {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(batchPayment.creditBlance).toFixed(2))}
                                                                    </td>
                                    
                                                                </tr>
                                                                
                                                                )
                                                                }
                                                                    
                                                                })()  
                                                            }

                                                            {
                                                                (() => {
                                                                
                                                                if(batchPayment.tax_status == 1){
                                                                    
                                                                    return(
                                                                        <tr style={{fontWeight: '800'}}>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{border: '1px solid #ffffff'}}></td>
                                                                            <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                            Tax Amount
                                                                            </td>
                                                                            <td style={{textAlign: 'right'}}>
                                                                            {parseFloat(batchPayment.tax_amount).toFixed(2)}                                                     
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                    
                                                                }                                                  
                                                                })()  
                                                            }

                                                            {
                                                                (() => {
                                                                
                                                                if(batchPayment.creditBlance == '0'){
                                                                
                                                                    if(batchPayment.amount){
                                                                        var newpAmountb = batchPayment.amount
                                                                    }else{
                                                                        var newpAmountb = 0
                                                                    }
                                                                    return(
                                                                    <tr style={{fontWeight: '800'}}>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                    {
                                                                        (() => {
                                                                        if(newPayAmount < 0){
                                                                            return(
                                                                            <>Due Amount</>
                                                                            )
                                                                        }else{
                                                                            return(
                                                                            <>Pay Amount</>
                                                                            )
                                                                        }
                                                                        })()  
                                                                    }
                                                                    </td>
                                                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newpAmountb).toFixed(2))}</td>
                                                                    </tr>
                                                                )
                                                                    
                                                                }else{
                                                                    if(batchPayment.amount){
                                                                        var newpAmountbn = batchPayment.amount
                                                                    }else{
                                                                        var newpAmountbn = 0
                                                                    }

                                                                    return(
                                                                    <tr style={{fontWeight: '800'}}>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                                    {
                                                                        (() => {
                                                                        if(newPayAmount < 0){
                                                                            return(
                                                                            <>Due Amount</>
                                                                            )
                                                                        }else{
                                                                            return(
                                                                            <>Pay Amount</>
                                                                            )
                                                                        }
                                                                        })()  
                                                                    }
                                                                    </td>
                                                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newpAmountbn).toFixed(2))}</td>
                                    
                                                                </tr>
                                                                
                                                                )
                                                                }
                                                                    
                                                                })()  
                                                            }
                                                        </tbody>
                                                   
                                                    </table>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{margin: '-55px 8px 20px 20px', marginTop: '5px'}}>
                                    <p>Invoice Count : {countn}</p>
                                    {
                                                (() => {
                                                
                                                  if(batchPayment.payStatus == 'paid'){
                                                   
                                                        return(                                                          
                                                            <div className="col-lg-12">
                                                                <hr/><img src="https://www.onlygfx.com/wp-content/uploads/2017/11/paid-stamp-1.png" style={{width: '100px', marginBottom: '20px'}} alt="" /><br/>
                                                                {
                                                                (() => {
                                                                
                                                                if(batchPayment.payslip){
                                                    
                                                                    return(
                                                                        <a className="btn btn-success btn-sm" target="_blank" href={`${process.env.REACT_APP_UPLOAD_URL}/admin/${batchPayment.payslip}`}>View Slip</a>
                                                                )
                                                                    
                                                                }                                                  
                                                                })()  
                                                            }
                                                                
                                                            </div>
                                                        )

                                                  }else if(batchPayment.payslip){
                                                    return(
                                                        <></>
                                                    )  
                                                  }else{
                                                    return(
                                                        <div className="col-lg-12">
                                                            <hr/><p style={{color:'red', fontWeight: '500', fontSize: '16px'}}>Unpaid</p>
                                                        </div>
                                                        
                                                    )   
                                                  }                                                  
                                                })()  
                                    }

                                    <div className="col-lg-12">
                                        <hr/>
                                        <p>Figures : <p style={{fontWeight: '500'}}>Sub Pay Amount = Total COD Amount - (Total Return Fee + Total Delivery Fee + Total COD Commission - Total Discount)</p></p>
                                    
                                        <p style={{fontWeight: '500'}}>COD Commission = (COD Amount - Delivery Fee) * COD Commission Fee(%)</p>
                                        {
                                                (() => {
                                                
                                                  if(batchPayment.tax_status == 1){
                                                    
                                                    return(
                                                        <p style={{fontWeight: '500'}}>Tax Amount = ((Total Return Fee + Total Delivery Fee + Total COD Commission)-Total Discount) * Tax Value (%)</p>
                                                    )
                                                      
                                                  }                                                  
                                                })()  
                                        }
                                        
                                        {
                                                (() => {
                                                
                                                  if(batchPayment.tax_status == 1){
                                                    
                                                    return(
                                                        <p style={{fontWeight: '500'}}>Pay Amount = Sub Pay Amount - Credit Balance - Tax Amount</p>
                                                    )
                                                      
                                                  }else{
                                                    return(
                                                        <p style={{fontWeight: '500'}}>Pay Amount = Sub Pay Amount - Credit Balance</p>
                                                    ) 
                                                  }                                                  
                                                })()  
                                        }
                                        
                                        </div>
                                </div>
                            </div>
                        ): (
                       
                          spinnerMa ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                          
                          spinnerM ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                             <>
                            <div className="row">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <h4 className="font-size-18">Vendor Payment Release- {clLoad.fName} {clLoad.lName} ({ClientD})</h4>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: '20px'}}>
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body"> 
                                            <div className="card bg-danger">
                                                <div className="card-body">
                                                    <h5 className="text-center text-white"> No Records</h5> 
                                                </div> 
                                            </div> 
                                        </div> 
                                    </div> 
                                </div> 
                            </div> 
                            </>
                        )
                        }
                        </div>     
                    </div>

                    
            </div>
        </div>
        </div>
    )
}

 export default ModalPaymentArchData;


