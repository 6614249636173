import { useState, useEffect } from 'react' 
import { Link, useNavigate } from 'react-router-dom'
import background from "../images/final_background_01.jpg";
import clientsiteLogo from "../images/logo-light.png";
import jwt from 'jwt-decode'
// import axios from 'axios';

export default function Login() {
  const navigate = useNavigate()
  const [email, setemail] = useState('')
  const [password, setPassword] = useState('')

  // const [newuser, setNewUser] = useState()
  // const userNewToken = jwt(localStorage.getItem('token'))

  const [date , setDate] = useState();

  const getYear = () =>  setDate(new Date().getFullYear())

  useEffect(() => {
    getYear();
}, [])

  const emailHandler = (event) => {
    setemail(event.target.value)
  }

  const passwordHandler = (event) => {
    setPassword(event.target.value)
  }

  const submitHandler = (event) => {
    event.preventDefault()
    // const newClient = {
    //   username,
    //   password
    // }




// async function loginRequest() {
//       try {
//         axios.post("http://localhost/hs-login-system/test.php",newClient).then((result)=>{
//           if(result.data.Status == "valid")
//           alert("success")
//           else
//           alert("unsuccess")
//           navigate('/')
//         }).catch((err)=>{
//           alert(err)
//         }) 
//       } catch (error) {
//         console.log(error.message)
//       }
//     }

// async function loginRequest() {
//       try {
//         await fetch('http://localhost/hs-login-system-master/test.php', {
//           method: 'POST',
//           body: JSON.stringify({
//             username: username,
//             password: password,
//           }),
//         } )
//           .then((data) => {
//             if (data.Status == "valid") {
//               // localStorage.setItem('token', data.status)
//               console.log("success");
//               // navigate('/')
//             } else {
//               console.log("unsuccess");
//             }
//           })
//       } catch (error) {
//         console.log(error.message)
//       }
//     }

    async function loginRequest() {
      try {
        await fetch(`${process.env.REACT_APP_URL}/api.php/login`, {
          method: 'POST',
          body: JSON.stringify({
            username: email,
            password: password,
          }),
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            if (data.status) {
              localStorage.setItem('token', data.status)
              const userNewToken = jwt(localStorage.getItem('token'))

              const Useridv = userNewToken.user.id;
              sessionStorage.setItem("UserID", Useridv);

              (localStorage.setItem('loggedUser', "userLogged"));
              
              // const getNewUser = useCallback(async () => {
                // try {
                  //  fetch('http://localhost/hs-login-system-master/api.php/user', {
                  //   headers: {
                  //     Authorization: 'Bearer ' + localStorage.getItem('token'),
                  //   },
                  // })                   
                    // .then((data) => {
                      // console.log(data);
                      // setNewUser(data.status)
                      // const Useridv =  userNewToken.newuser.id;
                      // sessionStorage.setItem("UserID", Useridv);
                    // })
                // } catch (error) {
                //   console.log(error.message)
                // }
              // }, [])
            
              // useEffect(() => {
              //   getNewUser()
              // }, [getNewUser])



              

              // const userDetails = jwt(JSON.parse(localStorage.getItem('token')))
              // const Useridv =     userDetails.user.id;
              // var username = "2"
              

              navigate('/index')
            } else {
              setemail('');
              setPassword('');
            }
          })
      } catch (error) {
        console.log(error.message)
      }
    }

     loginRequest();
  }

 

  return (   
   <div>
     <div className="accountbg" style={{backgroundImage: `url(${background})`,backgroundSize: 'cover',backgroundPosition: 'center'}}></div>
    <div className="wrapper-page account-page-full signin_background">                      
   
        <div className="card shadow-none signin_background">
            <div className="card-block">

                <div className="account-box">
                
                    <div className="card-box shadow-none p-4" style={{marginTop:'50px'}}>
                        <div className="p-2">
                            	<div className="text-center mb-2 mt-4">
                                <a href="#">
                                      <img id="sign_in_img" src={clientsiteLogo} alt="logo" />
                                </a>
                                
                              </div>
                              
                                <h4 className="text-center mt-4 sign_to_conti_text"><strong>Sign in to continue</strong></h4>

                          <form className="mt-4 sign_in_form_text"  onSubmit={submitHandler}>

                            <div className="form-group">
                                <label htmlFor="username">Email</label>
                                <input type="email" className="form-control" id="email" name="email" placeholder="Enter Email" value={email} onChange={emailHandler} />
                            </div>


                            <div className="form-group">
                                <label htmlFor="userpassword">Password</label>
                                <input type="password" className="form-control" id="password" name="password" placeholder="Enter password" value={password} onChange={passwordHandler}/>
                            </div>
             

                            <div className="form-group row">
                                <div className="col-sm-6">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customControlInline"/>
                                        <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                    </div>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                                </div>
                            </div>

                          <div className="form-group mt-2 mb-0 row">
                                <div className="col-12 mt-3">
                                   <Link to="/reset" className="sign_in_form_text"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                                </div>
                            </div>

                        </form>

                        <div className="mt-5 pt-4 text-center">
                            <p>© {date} </p>
                        </div>

                    
                    </div>
                </div>

            </div>
        </div>

    </div>
    </div>
    </div>
   
  )
}
