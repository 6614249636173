import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from "../Components/Footer";
import { useState,useEffect } from 'react';

export default function AllSearch(){

  const ClientIdD = sessionStorage.getItem('UserID');
  const[searchP,setSearchP]       = useState("");
  const[hubName,setHubname]       = useState("");
  const[card,setCard]= useState(false);
  const [logInfo, setLogInfo]               = useState([]);
  const [archInfo, setArchInfo]             = useState('');
  const [adminUserInfo, setAdminUserInfo]       = useState([]);
  const [userInfo, setUserInfo]       = useState('');
  const[searchData,setSearchData] = useState({
    waybillnumber:""
  });

  function  handleChange(e) {
    const inputValue = e.target.value;
    const inputName  = e.target.name;

    setSearchData({...searchData, [inputName]:inputValue});    
}

const riderid = searchP.pickupBy;

console.log(searchData);

    const searchparcel =async (event)=>{
    if(event.key === 'Enter'){
           try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/getParcel`, {
              method: 'POST', 
              body: JSON.stringify({             
                  clientID: ClientIdD,
                  waybill: searchData.waybillnumber
                 }),         
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            } )
              .then((respose) => {
                if (respose.ok) {
                  return respose.json()
                }
                throw new Error('error')
              })
              .then((data) => {
                // console.log(data.Clstatus);
                setSearchP(data.Clstatus);

               
              })
          } catch (error) {
            console.log(error.message)
          }

          gethubname();
          setCard(!card);
          logInfoRequest(wid);
          logUserInfoRequest();
          userInfoRequest(riderid);
          
        }
        
      }
   console.log(searchP)

   
   
   useEffect(() => {
    async function ArchparcelModalNewRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/archiveParcel`, {
          method: 'POST', 
          body: JSON.stringify({
             waybill: wid,               
          }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {             
            setArchInfo(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    ArchparcelModalNewRequest();
}, []);

function logUserInfoRequest(){
                                    
  fetch(`${process.env.REACT_APP_URL}/index.php/getAllUsers`, {
  method: 'POST',                
  headers: {
  'Content-Type': 'application/x-www-form-urlencoded',
  },
  } )
  .then((respose) => {
  if (respose.ok) {
      return respose.json()

  }
  throw new Error('error')
  })
  .then((data) => {
  // console.log(data.Clstatus);
    setAdminUserInfo(data.Clstatus);
                        
  })

}
useEffect(() => {
  logUserInfoRequest();
},[])


  async function gethubname(){
      
    try {
      await fetch(`${process.env.REACT_APP_URL}/index.php/getHub`, {
      method: 'POST', 
      body: JSON.stringify({             
          
        hubID: searchP.hub_id

         }),         
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    } )
      .then((respose) => {
        if (respose.ok) {
          return respose.json()
        }
        throw new Error('error')
      })
      .then((data) => {
        // console.log(data.Clstatus);
        setHubname(data.Clstatus);

       
      })
  } catch (error) {
    console.log(error.message)
  }

   }

   var wid = searchData.waybillnumber;
   document.title = "Logistic Client Portal | Parcel Search";

   async function userInfoRequest(NewUserid) {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/admin`, {
        method: 'POST', 
        body: JSON.stringify({
          Userid: NewUserid,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          // console.log(data.Clstatus);
          setUserInfo(data.Clstatus);
          // return (data.Clstatus);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

   async function logInfoRequest(logWaybill) {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/parcelLog`, {
        method: 'POST', 
        body: JSON.stringify({
          waybillId: logWaybill,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setLogInfo(data.Clstatus);
          // console.log(data.Clstatus);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  

  useEffect(() => {
      logInfoRequest(wid);
  },[])

   console.log(wid);

   useEffect(() => {
    async function ArchparcelModalNewRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/archiveParcel`, {
          method: 'POST', 
          body: JSON.stringify({
             waybill: wid,               
          }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {             
            setArchInfo(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    ArchparcelModalNewRequest();
}, []);

   function getDeliveryStatusBadge(ele) {
    if (ele.deliveryStatus === 'Waiting') {
      return (
        <span className="badge" style={{color:"#fff",backgroundColor: "green", fontWeight: "500"}}>
          {ele.deliveryStatus}
        </span>
      );
    } else if (ele.deliveryStatus === 'Pickup') {
      return (
        <span className="badge badge-warning">
          {ele.deliveryStatus}
        </span>
      );
    } else if (ele.deliveryStatus === 'Pending') {
      return (
        <span className="badge badge-info">
          Processing
        </span>
      );
    } else if (ele.deliveryStatus === 'Complete') {
      return (
        <span className="badge badge-success">
          Delivered
        </span>
      );
    } else if (ele.deliveryStatus === 'Reschedule') {
      return (
        <span className="badge badge-primary">
          {ele.deliveryStatus}
        </span>
      );
    } else if (ele.deliveryStatus === 'Cancel') {
      return (
        <span className="badge badge-danger">
          Return
        </span>
      );
    } else if (ele.deliveryStatus === 'Dispatched') {
      return (
        <span className="badge" style={{color:"#fff",backgroundColor: "#f838f6", fontWeight: "500"}}>
          {ele.deliveryStatus}
        </span>
      );
    } else if (ele.deliveryStatus === 'Delete') {
      return (
        <span className="badge" style={{color:"#fff",backgroundColor: "red", fontWeight: "500"}}>
          Remove
        </span>
      );
    }
  
    // Default case if no matching deliveryStatus is found
    return null;
  }

    return(
        <>
        <div id="layout-wrapper">
            
         <TopBar/>
         <SideBar/>

         <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
            <div className="row" style={{padding: '20px'}}>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <h4 className="font-size-18">Parcel Search</h4>
                </div>
              </div>
              
              <div className="row justify-content-md-center">
                <div className='col-lg-5'>
                
                  <div className='card'>
                  
                    <div className='card-body' style={{height:"165px"}}>
                        <div className='form-group'>
                            <label>Waybill Number :</label>
                            <input className='form-control' name='waybillnumber' id='waybillnumber' type='number' onChange={(e) => handleChange(e)} onKeyDown={searchparcel} />
                        </div>
                        <div className='inner-repeater mb-4'>

                        </div>

                    </div>
                  </div>
                  
                  {/* <div className='row' id='loadDiv'> */}

              {card && (searchP!=="NoResults")?
                  <div className='card'>
                    <div className='card-body' style={{height:"185px"}}>
                      <div className='col-md-12'>
                        <div className='row' style={{padding:"8px",fontWeight:"500",border:"1px solid #ece5e5",borderRadius:"15px"}}>
                        <div className='col-md-12' style={{textAlign:"center"}}>
                        {getDeliveryStatusBadge(searchP)}
                        </div>
                        <div className='col-md-12' style={{textAlign:"center"}}>
                             Parcel ID :{searchP.id}
                        </div>
                        <div className='col-md-12' style={{textAlign:"center"}}>
                              Delivery Fee : {searchP.deliveryFee} LKR
                        </div>
                        {searchP.deliveryStatus === 'Pickup'?
                        <div className='col-md-12' style={{textAlign:"center"}}> 
                              PickUp By : {userInfo.fname ? (
                                          <span> {userInfo.fname}</span>
                                        ) : (
                                          <></>
                                        )
                                        }

                                        {userInfo.lname ? (
                                          <span> {userInfo.lname} </span>
                                        ) : (
                                          <></>
                                        )
                                        }({searchP.pickupBy})
                        </div>:null
                        }
                        <div className='col-md-12' style={{textAlign:"center"}}>
                              Responsible Person : {searchP.pickupFrom}
                              {searchP.pickupMobile !== '' ? (
                          <span>, {searchP.pickupMobile}</span>
                        ) : (
                          <></>
                        )
                        }

                        {searchP.pickupAddress !== '' ? (
                          <span>, {searchP.pickupAddress}</span>
                        ) : (
                          <></>
                        )
                        }

                        </div>

                        </div>
                      </div>
                        
                        

                    </div>
                  </div>:null
                  }

                  {/* </div> */}


                </div>
               
                <div className='col-lg-5'>
                    <div className='viewDiv'>
                    <div className="container-fluid">
                      {searchP !== "NoResults" ? ( 
                      <div className='row justify-content-md-center'>
                        <div className='col-lg-12'>
                        {card ? 
                          <div className='card'>
                            <div className='card-body'>
                              <div className='row'>
                                <h4 style={{margin:"auto"}}>Waybill ID: {searchP.id}</h4>
                              </div>
                                  <div className="">
                          <ul className="timeline">
                          {archInfo === 'hasResults' &&
                            <h3 style={{color: 'red'}}>Archive Parcel</h3>
                            }  
                            

                            {logInfo !== "NoResults" ? (
                            <>
                            {                                                                                         
                            logInfo.map(logInfo=>{
                            return( 
                            <span key={logInfo.id}>
                            {
                              (() => {
                                if(logInfo.log){
                                  var logDtl    = logInfo.log;
                                  var valore    = logDtl.split('-');
                                  var logstatus = valore[0];
                                 
                                }
                                if(logInfo.userid){
                                      
                                      if(logstatus === 'Parcel has been Delivered By Mobile App') {
                                        return (
                                          <li className="timeline-item rounded ml-3 p-4" style={{boxShadow: '1px 1px 14px 3px #d8cece'}}>
                                              <div className="timeline-arrow"></div>
                                              <h2 className="h5 mb-0">{logDtl}</h2><span className="small text-gray">
                                              <i className="fa fa-clock-o mr-1"></i>{logInfo.date}</span>
                                              <p className="text-small mt-2 font-weight-light">By : {{...adminUserInfo.find(element => element.id == logInfo.userid)}.fname} {{...adminUserInfo.find(element => element.id == logInfo.userid)}.lname} ({logInfo.userid})</p>                                            
                                            {/* <img src="" alt="" className="src" style={{width:'100px'}}/> */}
                                          </li>
                                        )
                                    } else if ((logstatus === "City Changed") || (logstatus === "Parcel Status Changed") || (logstatus === "Parcel Pay Method Changed") || (logstatus === "Parcel Description Changed")  || (logstatus === "Parcel Type Changed")) {
                                        return (
                                          <li className="timeline-item rounded ml-3 p-4" style={{boxShadow: '1px 1px 14px 3px #d8cece'}}>
                                              <div className="timeline-arrow"></div>
                                              <h2 className="h5 mb-0">{logDtl}</h2>
                                              <p>{logInfo.log_type}</p>
                                            <span className="small text-gray">
                                            
                                              <i className="fa fa-clock-o mr-1"></i>{logInfo.date}</span>
                                              <p className="text-small mt-2 font-weight-light">By : {{...adminUserInfo.find(element => element.id == logInfo.userid)}.fname} {{...adminUserInfo.find(element => element.id == logInfo.userid)}.lname} ({logInfo.userid})</p>
                                              
                                            {/* <img src="<?php echo $digital_signature;?>" alt="" className="src" style="width:100px;"> */}
                                          </li>
                                        )
                                    } else if (logstatus !== 'Parcel has been Completed'){
                                        return (
                                          <li className="timeline-item rounded ml-3 p-4" style={{boxShadow: '1px 1px 14px 3px #d8cece'}}>
                                                <div className="timeline-arrow"></div>
                                                <h2 className="h5 mb-0">{logDtl}</h2><span className="small text-gray">
                                                <i className="fa fa-clock-o mr-1"></i>{logInfo.date}</span>
                                                <p className="text-small mt-2 font-weight-light">By : {{...adminUserInfo.find(element => element.id == logInfo.userid)}.fname} {{...adminUserInfo.find(element => element.id == logInfo.userid)}.lname} ({logInfo.userid})</p>
                                                
                                          </li>
                                        )
                                    }
                                    
                                }
                                  
                              })()  
                            } 
                            </span>
                            )
                            })
                            } 


                            
                            </>
                            ):(
                              <div className="col-md-12"  style={{textAlign: 'center'}}> 
                                <div className="card bg-danger" style={{marginTop: '36px'}}>
                                    <h5 className="text-center text-light"style={{padding: '30px'}}>No Waybill Timeline</h5> 
                                    
                                </div>
                              </div>
                            )
                            } 
                          </ul>
                          </div>

                          
                            </div>
                          </div>:null}
                        </div>
                      </div>
                        ) : (
                          <div className="col-md-12"  style={{textAlign: 'center'}}> 
                              <div className="card bg-danger" style={{marginTop: '36px'}}>
                                  <h5 className="text-center text-light"style={{padding: '30px'}}>No Waybill Timeline</h5> 
                              </div>
                          </div>
                        ) } 
                      
                        </div>
                    </div>
                </div>

                   </div>
                  

                </div>
                
            </div>
        </div>

         <footer className="footer">
            <Footer/>
          </footer>
        </div> 
  </>
    )
}