import axios from "axios";
import { useEffect, useState } from "react";


let rider;
let values = [];
let riderArray = [];


var riderArrayn      = [];
var riderCityArr     = [];
var riderZoneCityArr = [];
var hubNameData = [];
var parcelType = [];

 getRiderFName(); 
 getRiderZoneName();
 getRiderCityName();
 getHubNameData();
 getParcelType();

 console.log(parcelType)
  
 async function getRiderFName() {
            
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getAllRiders`, {
            method: 'POST', 
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
                
                riderArrayn.push(...data.Clstatus)
        
            })
        } catch (error) {
            console.log(error.message)
        }
 }   

 async function getRiderZoneName() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getRiderZoneName`, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
           
            riderZoneCityArr.push(...data.Clstatus)
            
            
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }

 async function getRiderCityName() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/geRiderCityNames`, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
           
           riderCityArr.push(...data.Clstatus)
           console.log(data.Clstatus) 
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }

    async function getHubNameData() {
        //console.log(val)
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getHubNameData`, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
                hubNameData.push(...data.Clstatus);
            
            })
        } catch (error) {
        console.log(error.message)
        }
    }

    async function getParcelType() {
        
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelType`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    parcelType.push(...data.Clstatus);
                
                })
            } catch (error) {
            console.log(error.message)
            }
    }


 function download_csv_file(data, arr) {

    var today = new Date();

    var todaytime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

    var todaydate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    var datetime = todaydate+" "+todaytime;


    //let csv = `"WAYBILL ID","ORDER DATE","DELIVERY STATUS","RIDER","HUB","ORDER ID","PARCEL TYPE","PARCEL DESCRIPTION","CLIENT NOTE","RECIPIENT NAME","RECIPIENT CONTACT NUMBER","RECIPIENT ADDRESS","RECIPIENT CITY","RECIPIENT CITY ZONE","PICKUP FROM","PICKUP ADDRESS","PICKUP CITY","PAY METHOD","AMOUNT","DELIVERY FEE","ADMIN NOTE","REQUEST TYPE"\n`;
    let csv = `WAYBILL ID,ORDER DATE,DELIVERY STATUS,RIDER,HUB,ORDER ID,PARCEL TYPE,PARCEL DESCRIPTION,CLIENT NOTE,RECIPIENT NAME,RECIPIENT CONTACT NUMBER,RECIPIENT ADDRESS,RECIPIENT CITY,RECIPIENT CITY ZONE,PICKUP FROM,PICKUP ADDRESS,PICKUP CITY,PAY METHOD,AMOUNT,DELIVERY FEE,ADMIN NOTE,REQUEST TYPE\n`;

                // all
                //'WAYBILL ID', 'ORDER DATE', 'PICKUP DATE', 'PROCESSING DATE', 'DELIVERED DATE', 'RESCHEDULE DATE', 'RETURN DATE', 'DISPATCHED DATE', 'REMOVE DATE', 'DELIVERY STATUS', 'RIDER', 'HUB', 'ORDER ID', 'PARCEL TYPE', 'PARCEL DESCRIPTION', 'CLIENT NOTE', 'RECIPIENT NAME', 'RECIPIENT CONTACT NUMBER', 'RECIPIENT ADDRESS', 'RECIPIENT CITY', 'RECIPIENT CITY ZONE', 'PICKUP FROM', 'PICKUP ADDRESS', 'PICKUP CITY', 'PAY METHOD', 'AMOUNT', 'DELIVERY FEE', 'ADMIN NOTE', 'REQUEST TYPE'
                // waiting
               //'WAYBILL ID','ORDER DATE','DELIVERY STATUS', 'RIDER', 'HUB', 'ORDER ID', 'PARCEL TYPE', 'PARCEL DESCRIPTION', 'CLIENT NOTE', 'RECIPIENT NAME', 'RECIPIENT CONTACT NUMBER', 'RECIPIENT ADDRESS', 'RECIPIENT CITY', 'RECIPIENT CITY ZONE', 'PICKUP FROM', 'PICKUP ADDRESS', 'PICKUP CITY', 'PAY METHOD',  'AMOUNT', 'DELIVERY FEE', 'ADMIN NOTE', 'REQUEST TYPE'
               // disp
               //'WAYBILL ID', 'ORDER DATE', 'DELIVERY STATUS', 'RIDER', 'HUB', 'ORDER ID', 'PARCEL TYPE', 'PARCEL DESCRIPTION', 'CLIENT NOTE', 'RECIPIENT NAME', 'RECIPIENT CONTACT NUMBER', 'RECIPIENT ADDRESS', 'RECIPIENT CITY', 'RECIPIENT CITY ZONE', 'PICKUP FROM', 'PICKUP ADDRESS', 'PICKUP CITY', 'PAY METHOD',  'AMOUNT', 'DELIVERY FEE', 'ADMIN NOTE', 'REQUEST TYPE'
    
    //merge the data with CSV
    data.forEach( function(row, i) {
        
        if(row.rider_id){
            var riderIdval    = parseInt(row.rider_id);
            var newRiderIdval = parseInt(riderIdval-1);
       
            var riderFname = riderArrayn[newRiderIdval].fname;
            var riderLname = riderArrayn[newRiderIdval].lname;
            
            var riderFullName = riderFname+' '+riderLname+' ('+row.rider_id+')';
       
        }else{
           var riderFullName = '';
        }
        
        
        if(row.recipientCity){
           
           var ridercityval    = parseInt(row.recipientCity);
           var newRiderCityval = parseInt(ridercityval-1);
           
        //   console.log(newRiderCityval);
       
           var riderRecipientCity = riderZoneCityArr[newRiderCityval].cname; 
        }else{
           var riderRecipientCity = '';
        }
        
         
        if(row.zoneCity){
           
           var riderZoneyval    = parseInt(row.zoneCity);
           var newRiderZoneyval = parseInt(riderZoneyval-1);
           
        //   console.log(riderCityArr.length);
       
           var riderZoneCity = riderCityArr[newRiderZoneyval].cname;
           //console.log(riderZoneCity) 
        }else{
           var riderZoneCity = '';
        }
        
        if(row.orderDate){
            var neworderDate = ' '+row.orderDate+' ';
        }else{
            var neworderDate = '';
        }

        if(row.hub_id) {
            var hubID = parseInt(row.hub_id);
            var hubName = hubNameData.find(item => {
                if(item.id === hubID) {
                    return item.name;
                } 
            })

        }

        if(row.pType) {
            var pid = parseInt(row.pType);
            var pArr = parcelType.find(item => {
                return item.id === pid;
            })
        } 
        
        if(row.pickupCity && row.pickupCity !='0'){
          
           var riderPickupcityval    = parseInt(row.pickupCity);
           var newRiderPickupCityval = parseInt(riderPickupcityval-1);
           
            // if(newRiderPickupCityval != -1) {
                
              var riderPickCity = riderCityArr[newRiderPickupCityval].cname; 
            // } else {
            //     riderPickCity = '';
            // }
            
           
        }else{
            var riderPickCity = '';
        }
        
       
            if(row.id){
                values.push(row.id);
            }else{
                values.push('');
            }
            
            if(row.orderDate){
                values.push(neworderDate);
            }else{
                values.push('');
            }
            
            values.push(row.deliveryStatus);
            
            if(row.rider_id) {
                 values.push(riderFullName);
            }else{
                values.push('');
            }
            if(row.hub_id){
                values.push(hubName.name);
            } else {
                values.push('');
            }
            
            if(row.orderID) {
                values.push(row.orderID);
            } else {
                values.push('');
            }
            
            if(row.pType) {
                values.push(pArr.pName);
            } else {
                values.push('');
            }
            //values.push(row.pType);
            if(row.pDescription) {
                values.push(row.pDescription.split(',').join(' '));
            } else {
                values.push('');
            }

            if(row.clientNote) {
                values.push(row.clientNote);
            } else {
                values.push('');
            }

            if(row.recipientName) {
                values.push(row.recipientName);
            } else {
                values.push('');
            }

            if(row.recipientMobile) {
                values.push(row.recipientMobile);
            } else {
                values.push('');
            }

            if(row.recipientAddress) {
                values.push(row.recipientAddress.split(',').join(' '));
            } else {
                values.push('');
            }

            if(row.zoneCity){
                values.push(riderZoneCity);
            }else{
                values.push('');
            }
            
            if(row.recipientCity){
                values.push(riderRecipientCity);
            }else{
                values.push('');
            }

            if(row.pickupFrom) {
                values.push(row.pickupFrom);
            } else {
                values.push('');
            }

            if(row.pickupAddress) {
                values.push(row.pickupAddress.split(',').join(' '));
            } else {
                values.push('');
            }

            if(row.pickupCity) {
                values.push(riderPickCity);
            } else {
                values.push('');
            }

            if(row.paymentType) {
                values.push(row.paymentType);
            } else {
                values.push('');
            }

            if(row.paymentType == 'COD'){
                if(row.CODAmount) {
                    values.push(row.CODAmount);
                }else {
                    values.push('');
                }
            
            }else{
              values.push('');
            } 

            if(row.deliveryFee !="" && row.deliveryFee !=0){
               values.push(row.deliveryFee);
            }else{
                values.push('');
            } 

            if(row.adminNote) {
                values.push(row.adminNote);
            } else {
                values.push('');
            }

            if(row.interface) {
                values.push(row.interface);
            } else {
                values.push('');
            }
            
            
            
            csv += values.join(',');
            csv += "\n";
            values = [];
            //riderArray = [];
    });

    
 
    

   
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    hiddenElement.target = '_blank';
    
    //provide the name for the CSV file to be downloaded
    hiddenElement.download = `${datetime}.csv`;
    hiddenElement.click();
}


      
    



function printEx(data) {
    return new Promise((res, rej) => {
        const result = fillRiderArra(data);
        res(result);
    })
}

async function fillRiderArra(data) {
    
    return new Promise((res, rej) => {
        
        riderArray = [];
        data.forEach(function(row) {
            if(row.rider_id) {
                 getRiderName(row.rider_id);
            } else {
                riderArray.push('');
            }
        })
        res(riderArray);
    })
}

async function done(data) {
    const arr = await fillRiderArra(data);
    download_csv_file(data, arr);

}

// function setArray() {
//     return new Promise((res, rej) => {

//     })
// }


async function getRiderName(id) {
        
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getRiderName`, {
        method: 'POST', 
        body: JSON.stringify({
            riderid: id                           
        }),         
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        },
    } )
        .then((respose) => {
        if (respose.ok) {
            return respose.json()
        }
        throw new Error('error')
        })
        .then((data) => {
            
            //console.log(data.Clstatus);
            //setRiderName(data.Clstatus);
            // rider = `${data.Clstatus[0].fname} ${data.Clstatus[0].lname}`;
            //console.log(rider);
            // riderArray.push(rider);
        })
    } catch (error) {
        console.log(error.message)
    }
}

function getRiderNameB(id) {

    return new Promise((res, rej) => {
        fetch(`${process.env.REACT_APP_URL}/index.php/getRiderName`, {
            method: 'POST', 
            body: JSON.stringify({
                riderid: id                           
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
                //console.log(data.Clstatus);
                //setRiderName(data.Clstatus);
                rider = `${data.Clstatus[0].fname} ${data.Clstatus[0].lname}`;
                res(rider);
                //console.log(rider);
                //riderArray.push(rider);
            })
    })
        
        
    
}

// function getRiderNameA(data) {

//     return new Promise((res, rej) => {
//         data.forEach((item) => {

//             values.push(item.id);
//             values.push(item.orderDate);
//             values.push(item.deliveryStatus);

//             if(item.rider_id) {
                
//                      fetch(`${process.env.REACT_APP_URL}/index.php/getRiderName`, {
//                     method: 'POST', 
//                     body: JSON.stringify({
//                         riderid: item.rider_id                           
//                     }),         
//                     headers: {
//                     'Content-Type': 'application/x-www-form-urlencoded',
//                     },
//                 } )
//                     .then((respose) => {
//                     if (respose.ok) {
//                         return respose.json()
//                     }
//                     throw new Error('error')
//                     })
//                     .then((data) => {
//                         //console.log(data.Clstatus);
//                         //setRiderName(data.Clstatus);
//                         rider = `${data.Clstatus[0].fname} ${data.Clstatus[0].lname}`;
                        
//                         values.push(rider);

                        
//                         console.log(rider);
//                         //riderArray.push(rider);
                        
                        
//                     })
//                 }
               
//                 csv += values.join(',');
//                 csv += "\n";
//                 values = [];

            
//         })

//         res(csv);

        
//     })

    
// }

async function callFun(data) {
    // const res = await getRiderNameA(data);
    // console.log(res);
}

 


async function handleExport(url, func, pageIsNChoose, val) {
    
    try {
        await fetch(url, {
        method: 'POST', 
        body: JSON.stringify({
            ...val                               
        }),         
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        },
    } )
        .then((respose) => {
        if (respose.ok) {
            return respose.json()
        }
        throw new Error('error')
        })
        .then((data) => {

            done(data.Clstatus);

            // getRiderNameA(data.Clstatus)
            //     .then(res => {
            //         console.log(res)
                    
            //     })
            //     .catch(err => console.log(err))

            // fillRiderArra(data.Clstatus)
            //     .then(res => {
            //         console.log(res);
            //         download_csv_file(data.Clstatus, res);
            //     })
            //     .catch(err => console.log(err))

            // printEx(data.Clstatus)
            //     .then(res => {
            //         console.log(res);
            //         download_csv_file(data.Clstatus, res);
            //     })
            //     .catch(err => console.log(err))
            
            //download_csv_file(data.Clstatus);
            func(data.Clstatus);
            //console.log('aaaa')
             pageIsNChoose(1);
        })
    } catch (error) {
        console.log(error.message)
    }
}

export {download_csv_file, handleExport}