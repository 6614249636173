
function Search(props) {
    return (
        <div className="col-3 col-md-3 col-lg-3 form-group">
            <label Htmlfor="search">Search :</label>
            <input type="text" onChange={props.func} id="search" className="form-control" name="search" value={props.value} placeholder="Recipient | Pickup "/>
        </div>
    )
}

export default Search;