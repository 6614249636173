import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Pagination from "./Pagination";
// import {Link} from "react-router-dom";

export default function TroubleTicket() {
  document.title = "Logistic Client Portal | Trouble Ticket Request";

  const [ticketList, setTicketList] = useState([]);
  const [spinner, setSpinner] = useState(true);
  
  const [SchKey, setSchKey]   			= useState('');
  const [TypeVal, setTypeVal]       = useState('');
  const [fdate, setFdate]       		= useState('');
  const [tdate, setTdate] 					= useState('');
  
  const SchKeyChange = (event) => {
        setSchKey(event.target.value)
  }
  
  const TypeValChange = (event) => {
        setTypeVal(event.target.value)
  }
  
  const FdateChange = (event) => {
        setFdate(event.target.value)
  }
  
  const TdateChange = (event) => {
        setTdate(event.target.value)
  }

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 30;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = ticketList.slice(firstIndex, lastIndex); 

  const ClientId = sessionStorage.getItem('UserID');

  
  async function ticketListRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getTicketList`, {
        method: 'POST', 
        body: JSON.stringify({             
            SchKey: '',
            TypeVal: '',
            fdate: '',
            tdate: '',
            client: ClientId,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
            setTicketList(data.Clstatus);
            setSpinner(false);
            setCurrentPage(1);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {   
    ticketListRequest();
    
  }, [])

  const SearchSubmit = (e) => {

    e.preventDefault();
     
    async function ticketListRequestSec() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getTicketList`, {
        method: 'POST', 
        body: JSON.stringify({             
            SchKey: SchKey,
            TypeVal: TypeVal,
            fdate: fdate,
            tdate: tdate,
            client: ClientId,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
            setTicketList(data.Clstatus);
            setSpinner(false);
            setCurrentPage(1);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

    ticketListRequestSec();

  };


  return (
    <>
    <div id="layout-wrapper">
      <TopBar/>
      <SideBar/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="page-title-box">
                    <h4 className="font-size-18">Trouble Ticket Request List</h4>
                    </div>
                </div>
              </div>
                 
              <div className="row" style={{padding: '20px'}}>
               
                <div className="form-group col-md-2" style={{margin: ''}}> 
                  <label htmlFor="formname">Search :</label>
                  <input type="text" id="sch_key" name="sch_key" className="form-control" placeholder=" WayBill ID" value={SchKey} onChange={SchKeyChange} />
                </div>
        
                <div className="form-group col-md-2" style={{margin: ''}}> 
                  <label htmlFor="formname">Type :</label>
                  <select className="form-control" id="type" name="type" value={TypeVal} onChange={TypeValChange} >
                    <option value="">Select Type</option>
                    <option value="Pending">Pending</option>
                    <option value="Complete">Complete</option>
                  </select>
                </div>
                
                <div className="form-group col-md-3" style={{margin: ''}}>  
                  <label htmlFor="formname">From :</label>
                  <input className="form-control" id="fdate" name="fdate" type="date" placeholder="From Date" aria-label="From Date" value={fdate} onChange={FdateChange} />
                </div>
                            
                <div className="form-group col-md-3" style={{margin: ''}}> 
                  <label htmlFor="formname">To :</label>
                  <input className="form-control" id="tdate" name="tdate" type="date" placeholder="To Date" aria-label="To Date" value={tdate} onChange={TdateChange} />
                </div>
                
                <div className="form-group col-md-1" style={{margin: ''}}> 
                  <label htmlFor="formname"></label>
                  <button className="btn btn-primary" id="searchBtn" style={{float: 'right',marginTop: '24px'}} onClick={SearchSubmit}> Search</button>
                </div>
                 
              </div>   
                 
                                  
              <div className="row">
                <div className="col-xl-12">
                  <div className="card  mt-3">
                    <div className="card-body">
                      <div id=""> 
                        {
                          (ticketList.length !== 0) ? (
                          <>
                            <p><b>Count - {ticketList.length}</b></p>

                            <div id="target-content">
                              <div className="table-responsive">
                                <table  className="table">
                                  <thead  className="thead-dark">
                                    <tr>
                                        <th style={{width: '125px'}}>Request Info</th>
                                        <th style={{width: '250px'}}>Waybill ID</th>
                                        <th style={{width: '250px'}}>Request</th>
                                        <th style={{width: '170px'}}>Status</th>
                                        </tr>
                                  </thead> 
                                                                         
                                  <tbody>
                                    {                                                                                         
                                    records.map(item=>{
                                    return(                                                                                              
                                        <tr key={item.id}>    
	                                        <td className="text-left">{item.id}<br/>{item.date}</td>
	                                        <td className="text-left">{item.waybill_id}</td>
	                                        <td className="text-left">{item.request}</td>
	                                        <td className="text-left">{item.status}</td>
                                        </tr>                                                                                              
                                    )
                                    })
                                    }                                                                                                                                                              
                                  </tbody>                                                                                                  
                                </table>
          
                                <nav>                                                                       
                                  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={ticketList} itemPerPage={recordsPerPage}/>
                                </nav>
                              </div>
                            </div>
                           
                          </>
                          ): (
                            spinner ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                              <div className="card bg-danger">
                                  <div className="card-body">
                                      <h5 className="text-center text-white"> No Records</h5> 
                                  </div> 
                              </div>
                          )
                        } 
                      </div>
                      
                    </div>
                  </div>
                </div> 
              </div>

            </div>
          </div>
          <footer className="footer">
            <Footer/>
          </footer>
        </div>
    </div>
    </>
  )
}
