import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Pagination from "./Pagination";
import ModalUpload from "./ModalUpload";
import ModalUploadSuccess from "./ModalUploadSuccess";
import Axios from 'axios';
import Swal from "sweetalert2";
import {Link} from "react-router-dom";

export default function WaybillUploadCsv() {
  document.title = "Logistic Client Portal | Waybill Upload";

  const [externalClU, setExternalClU] = useState('');
  const [settingNU, setSettingNU]     = useState('');
  const [uploadDate, setUploadDate]   = useState('');
  const [uploadData, setuploadData]   = useState('');
  
  const [isSearchBtnInactive, setIsSearchBtnInactive]    = useState(false);

  const ClientIdNU = sessionStorage.getItem('UserID'); 

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = uploadData.slice(firstIndex, lastIndex);
  
  const [isWaybillDataLoad, setIsWaybillDataLoad]   = useState(true);

  var newSearch = "";
  
  async function uploadDetailsRequest(clientid,serchkey) {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getUpload`, {
        method: 'POST', 
        body: JSON.stringify({             
            clientIDU: clientid,
            SearchValU: serchkey,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          setuploadData(data.Clstatus);
          setCurrentPage(1);
          setIsWaybillDataLoad(false);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {   
    uploadDetailsRequest(ClientIdNU,newSearch);
    
  }, [])
  
  useEffect(() => {
    async function clientDetailsRequestU() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: ClientIdNU,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            // console.log(data.Clstatus);
            setExternalClU(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    clientDetailsRequestU();
  }, []);
  
  async function settingRequestNU() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
        method: 'POST',          
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {          
          setSettingNU(data.Clstatus);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    settingRequestNU()
  }, []);


  const handleUDateChange = (e) => {
    setUploadDate(e.target.value);
  };

  const searchUHandler = (e) => {

    e.preventDefault();
    
    setIsSearchBtnInactive(true);

    uploadDetailsRequest(ClientIdNU,uploadDate);
    
    setIsSearchBtnInactive(false);

};

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData]     = useState('');

  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [modalSuccessData, setModalSuccessData]     = useState('');

  const [waybillFile, setWaybillFile]         = useState('');
  const [formErrors, setformErrors]           = useState({});
  const [iswaybillActive, setIswaybillActive] = useState(false);
  const [isAnonymous, setisAnonymous]         = useState(false);
  
  
  

  const waybillHandler = (event) => {
    if (event.target.files) {
      setWaybillFile(event.target.files[0]);
    }
    // setWaybillFile(event.target.value);
    setIswaybillActive(false)
  }

  const validate = (waybillFile) => {
    const errors = {};

    if(!waybillFile){
      errors.waybillFile = "This field is required!";  
      setIswaybillActive(true)    
    }else{
      setIswaybillActive(false)
     }
    return errors;
  }

  const submitWaybillHandler = (event) => {
    event.preventDefault()
    
    var logUser = sessionStorage.getItem("UserID");
          
    setformErrors(validate(waybillFile));        

    var errorLengthN =  (Object.keys(validate(waybillFile)).length)
                  
       if(errorLengthN === 0 ){

        const formData = new FormData();
        formData.append("file", waybillFile);
        formData.append("logUser", logUser);

        console.log(waybillFile); 
        async function waybillUploadRequest() {         
          
          await Axios.post(`${process.env.REACT_APP_URL}/index.php/addCsvWaybill`, formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
          }).then((res) => {
            console.log(res.data.Clstatus);  
            var uploadStatus = res.data.Clstatus;

            if(uploadStatus === "seccess"){

              setWaybillFile('');  
              document.getElementById('manualCSVForm').reset();

                  Swal.fire({
                    title: "Your upload was successful.",
                    text: "Success or failure of your upload in 1 to 15 minutes is referred to the Waybill Upload History section.",
                    icon: "success",
                    confirmButtonText: "OK"
                    // timer: 2000,
                  });

                  uploadDetailsRequest(ClientIdNU,newSearch);

            }else{
                  Swal.fire({
                    title: "Warning",
                    text: "sorry there was an error!",
                    icon: "error",
                    confirmButtonText: "OK",
                    
                  });                        
            }            
            setisAnonymous(false);
            
          },error=>{
            alert(error);
          })         
        }
    
        Swal.fire({
          title: 'Are you sure?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm'
        }).then((result) => {
          if (result.isConfirmed) {

            setisAnonymous(true);
            waybillUploadRequest();
            
          }else{
            setisAnonymous(false);
            
          }
        })               
      }
    }

  return (
    <>
    <div id="layout-wrapper">
      <TopBar/>
      <SideBar/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  <h4 className="font-size-18 mt-3 mb-3">Waybill Upload By CSV</h4>
                </div>
              </div>
            {
              (() => {
              
                if(externalClU.hub_id === '') {
                
                  return (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body" style={{margin: 'auto', textAlign: 'center'}}>
                              <h3 style={{color: '#0078d4'}}>You can not upload parcel</h3>
                              <h4 style={{color: 'red'}}>Please Contact Your Admin</h4>
                              <p>Contact : {settingNU.tel}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }else{
                  return (                  
                    <div className="row justify-content-md-center">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <form name="manualCSVForm" id="manualCSVForm" onSubmit={submitWaybillHandler}> 
                              <div className="form-group">
                                <div className="row"> 
                                  <div className="form-group col-md-3">   </div> 
                                  <div className="form-group col-md-4 mt-2">
                                    <label htmlFor="">Upload CSV</label>
                                    <input type="file" name="file" id="file" className="form-control-file" style={{cursor: 'pointer'}} onChange={waybillHandler}  />
                                    <p style={{display: iswaybillActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrors.waybillFile}</p>
                                  </div> 
                                  <div className="col-md-2">
                                    <label className="text-white">U</label>
                                    <button type="submit" className="btn btn-info btn-block mt-2" disabled={isAnonymous ? true : false} id="btnUpload">Upload</button>
                                  </div> 
                                </div>
                              </div>
                            </form> 
                          </div>
                        </div>  
                      </div>
                    </div>                                                          
                  )
                }
              })()  
            }  


              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  <h4 className="font-size-18">Waybill Upload History</h4>
                </div>
              </div> 
                    
              <div className="row">
                <div className="col-xl-12">
                  <div className="card  mt-3">
                    <div className="card-body">
                      
                      <div className="row">                      
                          <div className="form-group col-md-4"></div> 
                          <div className="form-group col-md-4"></div> 
                                            
                          <div className="form-group col-3 col-md-2 col-lg-2">  
                            <label htmlFor="key_date">Upload Date</label>
                            <input className="form-control" id="key_date" name="key_date" type="date" value={uploadDate} onChange={handleUDateChange} placeholder="From Date" aria-label="From Date" />
                          </div>
                                                                                
                          <div className="form-group col-3 col-md-2 col-lg-2">
                              <label className="text-white">Search</label>
                              <button className="btn btn-primary btn-block" id="searchBtn" onClick={searchUHandler} disabled={isSearchBtnInactive ? true : false}> Search</button>
                          </div>
                      </div>
                                        
                      <div id="csv_waybill_upload_table_load"> 
                        {
                          (uploadData.length !== 0) ? (
                          <>
                            <p><b>Count - {uploadData.length}</b></p>

                            <div id="target-content">
                              <div className="table-responsive">
                                <table  className="table">
                                  <thead  className="thead-dark">
                                    <tr>
                                      <th>Id</th>
                                      <th>Upload Date</th>   
                                      <th className="text-center">Errors</th>   
                                      <th className="text-center">Success</th>     
                                      <th>Action</th> 
                                    </tr>
                                  </thead> 
                                                                         
                                  <tbody>
                                    {                                                                                         
                                    records.map(item=>{
                                    return(                                                                                              
                                      <tr key={item.id}>    
                                        <td className="text-left">{item.id}</td>
                                        <td className="text-left">                                         
                                          {
                                          (() => {
                                          if(item.double_check === 0) {
                                            return (
                                            <>
                                              <i className="fas fa-question text-warning"></i>&nbsp;
                                            </>                                                                                                                        
                                            )
                                          }else{
                                            return (
                                            <>
                                              <i className="fas fa-check text-primary"></i>&nbsp;
                                            </>
                                            )
                                          }
                                          })()  
                                          }  
                                                                                                            
                                          {item.date}
                                        </td>
                                        <td className="text-center" style={{cursor: 'pointer'}} onClick={()=> { setModalData(item); setModalIsOpen(true); }}>
                                        {
                                            (() => {
                                                if(item.double_check === 0) {
                                                        return (
                                                            <>
                                                            <i className="fas fa-question text-warning"></i>&nbsp;
                                                            </>
                                                            
                                                        )
                                                }else{
                                                        return (
                                                            <span className="badge badge-danger"> {item.unsuccess}</span>
                                                        )
                                                }
                                            })()  
                                        }     
                                            
                                        </td>
                                        <td className="text-center" style={{cursor: 'pointer'}} onClick={()=> { setModalSuccessData(item); setSuccessModalIsOpen(true); }}>
                                        {
                                            (() => {
                                                if(item.double_check === 0) {
                                                        return (
                                                            <>
                                                            <i className="fas fa-question text-warning"></i>&nbsp;
                                                            </>
                                                            
                                                        )
                                                    }else{
                                                        return (
                                                            <span className="badge badge-success"> {item.success}</span>
                                                        )
                                                    }
                                            })()  
                                        }     
                                            
                                        </td>                                             
                                        <td>
                                          <>
                                          <input type="hidden" name="csvId" value={item.id} />
                                            {/* <a className="btn btn-primary btn-sm" href='http://localhost/hs-login-system-master/uploads/24Apr2023105035_waybills_upload4.csv'>Download</a>  */}
                                         {/* <a className="btn btn-primary btn-sm" href={`${process.env.REACT_APP_UPLOAD_URL}/admin/up/${item.csv_url}`}>Download</a> */}
                                         
                                         {
                                            (() => {
                                            
                                                let arr             = item.csv_url.split('/');
                                                const firstElement  = arr[0];
                                                const secondElement = arr[2];
                                                
                                                const full_upload_url = firstElement+"//"+secondElement;
                                                
                                                  if(full_upload_url == "https://box1.ozonedesk.info"){
                                                
                                                        return (
                                                          <>
                                                          <a className="btn btn-primary btn-sm" href={`${item.csv_url}`}>Download</a>
                                                          </>                                                         
                                                        )
                                                  
                                                    
                                                  }else{
                                                  
                                                      return (
                                                          <>
                                                          <a className="btn btn-primary btn-sm" href={`${process.env.REACT_APP_UPLOAD_URL}/admin/up/${item.csv_url}`}>Download</a>
                                                          </>                                                         
                                                        )
                                                  }
                                            })()  
                                          }
                                         
                                          {
                                            (() => {
                                                if(item.success > 0) {
                                                        return (
                                                          <>
                                                          <Link to={`/csvWaybillUploadBulkPrint/${item.id}/${ClientIdNU}`} className="btn btn-success btn-sm ml-1" target="_blank">Print</Link>
                                                          <Link to={`/bulkPrinTenByTen/${item.id}/${ClientIdNU}`} className="btn btn-info btn-sm ml-1" target="_blank">Print 10x10</Link>
                                                          </>                                                         
                                                        )
                                                    }
                                            })()  
                                          }
                                          </>                                                                                              
                                        </td>                                             
                                      </tr>                                                                                            
                                    )
                                    })
                                    }                                                                                                                                                              
                                  </tbody>                                                                                                  
                                </table>

                                {modalIsOpen === true && (<ModalUpload modalData={modalData} modalIsClose={setModalIsOpen}  />) } 
                                {successModalIsOpen === true && (<ModalUploadSuccess modalData={modalSuccessData} modalIsClose={setSuccessModalIsOpen}  />) } 
                                <nav>                                                                       
                                  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={uploadData} itemPerPage={recordsPerPage}/>
                                </nav>
                              </div>
                            </div>
                           
                            {/* <ModalUpload isOpen={modalIsOpen} modalData={modalData}  /> */}
                              {/* <h1>{modalData}</h1>
                               
                              <div>
                              <button onClick={() => setModalIsOpen(false)}>X</button>
                              </div> */}
                            {/* </Modal> */}
                          </>
                          ): (
                           isWaybillDataLoad ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                              <div className="card bg-danger">
                                  <div className="card-body">
                                      <h5 className="text-center text-white"> No Records</h5> 
                                  </div> 
                              </div>
                          )
                        } 
                      </div>
                      
                    </div>
                  </div>
                </div> 
              </div>

            </div>
          </div>
          <footer className="footer">
            <Footer/>
          </footer>
        </div>
    </div>
    <div className={(modalIsOpen || successModalIsOpen) ? "modal-backdrop show" : ""}></div>
    </>
  )
}
