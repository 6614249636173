import { useEffect } from "react";
import { useParams } from 'react-router-dom'
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
// import jwt from 'jwt-decode'

export default function ClientTrainingPDF() {  

  let { logId } = useParams();

    useEffect(() => {
        // const userNewToken = jwt(localStorage.getItem('token'))
        
        // const queryParameters = new URLSearchParams(window.location.search)
        // const logId = queryParameters.get("logId")
        const Useridv = logId;

    // const Useridv = userNewToken.user.id;
        sessionStorage.setItem("UserID", Useridv); 
    },[true])

    document.title = "Logistic Client Portal | Client Training PDF";
    return (
      <>
   
  <div id="layout-wrapper">

  <TopBar/>

  <SideBar/>
    
    <div className="main-content">

      <footer className="footer">
        <Footer/>
      </footer>

    </div>
 

  </div>
     
</>

 

    )
}
