import React, { useEffect, useState }  from 'react';
import '../pages/print.css';
// import ByName from "./ByName";

export default function ModalContentOne({waybillOne,adminUserInfo, clientUserInfo}) {

    const [newModalparcel, setNewModalParcel] = useState('');
    const [logInfo, setLogInfo]               = useState([]);
    const [archInfo, setArchInfo]             = useState('');
    
    const [isLoadModalS, setIsLoadModalS]     = useState(false);
    const [spinnerTop, setSpinnerTop]         = useState(true);
    
    // const [batchInfo, setBatchInfo]     = useState('');
// var arr =  Array();
// const [productQuantity, setProductQuantity] = useState({});



    const waybillIdNew   = waybillOne;
    const logModOUser    = sessionStorage.getItem("UserID");
    
    
    useEffect(() => {
        async function parcelModalNewRequest() {
          try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/getParcel`, {
              method: 'POST', 
              body: JSON.stringify({
                  waybill: waybillIdNew,
                  clientID: logModOUser,
                 }),         
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            } )
              .then((respose) => {
                if (respose.ok) {
                  return respose.json()
                }
                throw new Error('error')
              })
              .then((data) => {
                // console.log(data.Clstatus);
                setNewModalParcel(data.Clstatus);
                setIsLoadModalS(true);
              })
          } catch (error) {
            console.log(error.message)
          }
        }
        parcelModalNewRequest();
    }, []);
    
    async function logInfoRequest(logWaybill) {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/parcelLog`, {
          method: 'POST', 
          body: JSON.stringify({
            waybillId: logWaybill,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setLogInfo(data.Clstatus);
            setSpinnerTop(false);
            // console.log(data.Clstatus);
          })
      } catch (error) {
        console.log(error.message)
      }
    }

    

    useEffect(() => {
        logInfoRequest(waybillIdNew);
    },[])
    
    useEffect(() => {
      async function ArchparcelModalNewRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/archiveParcel`, {
            method: 'POST', 
            body: JSON.stringify({
               waybill: waybillIdNew,               
            }),         
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {             
              setArchInfo(data.Clstatus);
             
            })
        } catch (error) {
          console.log(error.message)
        }
      }
      ArchparcelModalNewRequest();
  }, []);
    
    // useEffect(() => {
    //   if(modalparcel.hub_id){
    //     const HubNid = modalparcel.hub_id;         
    //     hubInfoRequest(HubNid);      
    //   }
    // },[modalparcel.hub_id])
    
    // useEffect(() => {
    //   if(modalparcel.payment_batch_num){
    //     const BatchID = modalparcel.payment_batch_num;         
    //     hubBatchRequest(BatchID);      
    //   }
    // },[modalparcel.payment_batch_num])
    
   
  return (
    <>
  {
    (isLoadModalS) ? (
     
   newModalparcel !== "NoResults" ? ( 

<div className="container-fluid">
    <div className="row justify-content-md-center">
        <div className="col-lg-12">
            <div className="" id="divload">
                <div className="container card">
                    <div className="row">
                    <h5 >Search for Waybill ID : {waybillIdNew}</h5>
                    </div>
                    <div className="row">
                        <h5 style={{margin:'auto'}}>Waybill Timeline</h5>
                    </div>

                    <div className="row" >
                        <div style={{width: '100%'}}>
                            <ul className="timeline" >
                            {archInfo === 'hasResults' &&
                            <h3 style={{color: 'red'}}>Archive Parcel</h3>
                            }  
                            

                            {
                            (spinnerTop) ? (
                                <div className="card" style={{  textAlign: 'center', display: 'block', width: '100%' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> 
                                                         
                            ): (
                                                        
                            logInfo !== "NoResults" ? (
                            <>
                            {                                                                                         
                            logInfo.map(logInfo=>{
                            return( 
                            <span key={logInfo.id}>
                            {
                              (() => {
                                if(logInfo.log){
                                  var logDtl    = logInfo.log;
                                  var valore    = logDtl.split('-');
                                  var logstatus = valore[0];
                                 
                                }
                                if(logInfo.userid){
                                      
                                      if(logstatus === 'Parcel has been Delivered By Mobile App') {
                                        return (
                                          <li className="timeline-item rounded ml-3 p-4" style={{boxShadow: '1px 1px 14px 3px #d8cece'}}>
                                              <div className="timeline-arrow"></div>
                                              <h2 className="h5 mb-0">{logDtl}</h2><span className="small text-gray">
                                              <i className="fa fa-clock-o mr-1"></i>{logInfo.date}</span>
                                              <p className="text-small mt-2 font-weight-light">
                                                                        {
                                                                            (() => {
                                                                                if((logInfo.user_type).toLowerCase() === 'admin' || (logInfo.user_type).toLowerCase() === 'hub admin') {
                                                                                        return (
                                                                                        <>By : {{...adminUserInfo.find(element => element.id == logInfo.userid)}.fname} {{...adminUserInfo.find(element => element.id == logInfo.userid)}.lname} ({logInfo.userid})</> 
                                                                                        )
                                                                                }else if((logInfo.user_type).toLowerCase() === 'vendor'){
                                                                                        return (
                                                                                            <>By :  {{...clientUserInfo.find(element => element.id == logInfo.userid)}.fName} {{...clientUserInfo.find(element => element.id == logInfo.userid)}.lName} ({logInfo.userid})</>
                                                                                        )
                                                                                }else{
                                                                                   return(<></>) 
                                                                                }
                                                                            })()  
                                                                        } 
                                              </p>                                            
                                            {/* <img src="" alt="" className="src" style={{width:'100px'}}/> */}
                                          </li>
                                        )
                                    } else if ((logstatus === "City Changed") || (logstatus === "Parcel Status Changed") || (logstatus === "Parcel Pay Method Changed") || (logstatus === "Parcel Description Changed")  || (logstatus === "Parcel Type Changed")) {
                                        return (
                                          <li className="timeline-item rounded ml-3 p-4" style={{boxShadow: '1px 1px 14px 3px #d8cece'}}>
                                              <div className="timeline-arrow"></div>
                                              <h2 className="h5 mb-0">{logDtl}</h2>
                                              <p>{logInfo.log_type}</p>
                                            <span className="small text-gray">
                                            
                                              <i className="fa fa-clock-o mr-1"></i>{logInfo.date}</span>
                                              <p className="text-small mt-2 font-weight-light">
                                                                        {
                                                                            (() => {
                                                                                if((logInfo.user_type).toLowerCase() === 'admin' || (logInfo.user_type).toLowerCase() === 'hub admin') {
                                                                                        return (
                                                                                        <>By : {{...adminUserInfo.find(element => element.id == logInfo.userid)}.fname} {{...adminUserInfo.find(element => element.id == logInfo.userid)}.lname} ({logInfo.userid})</> 
                                                                                        )
                                                                                }else if((logInfo.user_type).toLowerCase() === 'vendor'){
                                                                                        return (
                                                                                            <>By :  {{...clientUserInfo.find(element => element.id == logInfo.userid)}.fName} {{...clientUserInfo.find(element => element.id == logInfo.userid)}.lName} ({logInfo.userid})</>
                                                                                        )
                                                                                }else{
                                                                                   return(<></>) 
                                                                                }
                                                                            })()  
                                                                        }
                                              </p>
                                              
                                            {/* <img src="<?php echo $digital_signature;?>" alt="" className="src" style="width:100px;"> */}
                                          </li>
                                        )
                                    } else if (logstatus !== 'Parcel has been Completed'){
                                        return (
                                          <li className="timeline-item rounded ml-3 p-4" style={{boxShadow: '1px 1px 14px 3px #d8cece'}}>
                                                <div className="timeline-arrow"></div>
                                                <h2 className="h5 mb-0">{logDtl}</h2><span className="small text-gray">
                                                <i className="fa fa-clock-o mr-1"></i>{logInfo.date}</span>
                                                <p className="text-small mt-2 font-weight-light">
                                                                        {
                                                                            (() => {
                                                                                if((logInfo.user_type).toLowerCase() === 'admin' || (logInfo.user_type).toLowerCase() === 'hub admin') {
                                                                                        return (
                                                                                        <>By : {{...adminUserInfo.find(element => element.id == logInfo.userid)}.fname} {{...adminUserInfo.find(element => element.id == logInfo.userid)}.lname} ({logInfo.userid})</> 
                                                                                        )
                                                                                }else if((logInfo.user_type).toLowerCase() === 'vendor'){
                                                                                        return (
                                                                                            <>By :  {{...clientUserInfo.find(element => element.id == logInfo.userid)}.fName} {{...clientUserInfo.find(element => element.id == logInfo.userid)}.lName} ({logInfo.userid})</>
                                                                                        )
                                                                                }else{
                                                                                   return(<></>) 
                                                                                }
                                                                            })()  
                                                                        }
                                                </p>
                                                
                                          </li>
                                        )
                                    }
                                    
                                }else{
                                  return (
                                          <li className="timeline-item rounded ml-3 p-4" style={{boxShadow: '1px 1px 14px 3px #d8cece'}}>
                                              <div className="timeline-arrow"></div>
                                              <h2 className="h5 mb-0">{logDtl}</h2><span className="small text-gray">
                                              <i className="fa fa-clock-o mr-1"></i>{logInfo.date}</span>
                                          </li>
                                  )
                                }
                                  
                              })()  
                            } 
                            </span>
                            )
                            })
                            } 


                            
                            </>
                            ):(
                              <div className="col-md-12"  style={{textAlign: 'center'}}> 
                                <div className="card bg-danger" style={{marginTop: '36px'}}>
                                    <h5 className="text-center text-light"style={{padding: '30px'}}>No Waybill Timeline</h5> 
                                </div>
                              </div>
                            )
                            
                              )
                            }           
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   

</div>  
) : (
    <div className="col-md-12"  style={{textAlign: 'center'}}> 
        <div className="card bg-danger" style={{marginTop: '36px'}}>
            <h5 className="text-center text-light"style={{padding: '30px'}}>No Waybill Timeline</h5> 
        </div>
    </div>
  ) 
  
    ) : (
        <>
          <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
            <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
          </div> 
        </>
    ) 
  } 


   
    </>
  )
}
