import React,{useState, useEffect } from 'react'
// import testSideBar from '../Components/testSideBar';
export default function Footer() {

    const [date , setDate] = useState();

    const getYear = () =>  setDate(new Date().getFullYear())
  
    useEffect(() => {
      getYear();
  }, [])

  return (
    <>
    
      <div className="container-fluid">
  <div className="row">
    <div className="col-12">
      
      © {date}
    </div>
  </div>
  </div>
</>
  );
}
