import { useState } from "react";


import './pagination.css';

function Pagination({currentPage, setCurrentPage, data, itemPerPage}) {
    

    const[pageNumberLimit, setPageNumberLimit] = useState(10)
    const[maxPageNumberLimit, setMaxPageNumberLimit] = useState(10)
    const[minPageNumberLimit, setMinPageNumberLimit] = useState(0)

    const pages = [];
    for(let i = 1; i <= Math.ceil(data.length/itemPerPage); i++) {
        pages.push(i); 
    }

    //const indexOfLastItem = currentPage * itemPerPage;
    //const indexOfFirstItem = indexOfLastItem - itemPerPage;
    //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    function handleClick(e) {
        setCurrentPage(Number(e.target.id));
    }

    const handleNextBtn = () => {
        if (currentPage < pages.length) {
            setCurrentPage(currentPage + 1);
            if(currentPage + 1 > maxPageNumberLimit) {
                setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
                setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
            }
        }
        
    }
    const handlePrevBtn = () => {
        if(currentPage > 1) {
            setCurrentPage(currentPage - 1);
            if(currentPage - 1 <= minPageNumberLimit) {
                setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
                setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
            }
        }
        
    }

    const paginationUp = () => {
        setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }

    const paginationDown = () => {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
    
    return(
        <ul className="pageNumber">
            <li><button onClick={handlePrevBtn}>Prev</button></li>
            {minPageNumberLimit > 0 && <li onClick={paginationDown} className="morebtnm"> &hellip; </li>}
            {pages.map((number) => {
                if(number < maxPageNumberLimit+1 && number > minPageNumberLimit){
                    return (
                    <li key={number} >
                       <span key={number} id={number} onClick={handleClick} className={currentPage == number ? 'active' : null}>{number}
                       </span>
                    </li>
                    );
                }else{
                    return null;
                }
                 
            })}
            
            { maxPageNumberLimit < pages.length && (<li onClick={paginationUp} className="morebtnm"> &hellip; </li>)}
            <li><button onClick={handleNextBtn}>Next</button></li>
        </ul>
    )
     
}

export default Pagination;