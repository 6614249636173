function TableHead() {
    return (
        <tr>
            <th style={{width:'125px', borderTopLeftRadius:'15px', borderBottomLeftRadius:'15px', border:'none', paddingTop:'0.85rem', paddingBottom:'0.85rem' }}>Order Info</th>
            <th style={{width:'150px', border:'none'}}>Parcel Info</th>
            <th style={{width:'150px', border:'none'}}>Recipient Info</th>
            <th style={{width:'150px', border:'none'}}>Pickup Info</th> 
            <th style={{width:'150px', border:'none'}}>Pay Method</th>
            <th style={{width:'170px', border:'none'}}>Amount</th>
            <th style={{width:'155px', border:'none'}}>Admin Note</th>
            <th style={{width:'150px', borderTopRightRadius:'15px', borderBottomRightRadius:'15px', border:'none' }}>Action</th>
        </tr>
    )
}

export default TableHead;