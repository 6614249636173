

function ToDate(props) {
    return(
        <div className="col-3 col-md-3 col-lg-3 form-group">
            <label htmlFor="fdate">{props.label} :</label>
            <input type="date" id="tdate" name="tdate" onChange={props.func} value={props.value} className="form-control" placeholder="To Date"/>
        </div>
    )
}

export default ToDate;