

function PaymentType(props) {
    return (
        <div className="col-3 col-md-3 col-lg-3 form-group">
            <label htmlFor="paymentType">Payment Type :</label>
            <select name="paymentType" onChange={props.func} id="paymentType" className="form-control">
                <option value="">Select Payment Type</option>
                <option value="COD">COD</option>
                <option value="AlreadyPaid">AlreadyPaid</option>
            </select>
        </div>
    )
}

export default PaymentType;