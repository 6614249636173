

function OrderID(props) {
    return(
        <div className="col-3 col-md-3 col-lg-3 form-group">
            <label Htmlfor="order_id">Order ID :</label>
            <input type="text" onChange={props.func} id="order_id" className="form-control" name="order_id" placeholder="Order ID" value={props.value}/>
        </div>
    )
}

export default OrderID;