import { useState, useEffect } from "react";

import {Link} from "react-router-dom";
import axios from "axios";

import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';

import Waybill from '../Components/search-fields/Waybill';
import Search from '../Components/search-fields/Search';
import ParcelType from '../Components/search-fields/ParcelType';
import FromDate from '../Components/search-fields/FromDate';
import ToDate from '../Components/search-fields/ToDate';
import PaymentType from '../Components/search-fields/PaymentType';
import Status from "../Components/search-fields/Status";
import CityZone from "../Components/search-fields/CityZone";
import OrderId from "../Components/search-fields/OrderId";
import TableBody from "../Components/TableBody";
import Pagination from "./Pagination";

import ModalView from "./ModalView";
import ModalA from "./ModalA";
//import { handleExport } from "./printExport";
import { handleExport } from "./printExportAllParcel";
import TableHead from "../Components/TableHead";

if((sessionStorage.getItem('UserID')) !== null){   
    
    (localStorage.setItem('loggedUser', "userLogged"))

}else{
    
    (localStorage.setItem('loggedUser', "userNotLogged")) 
}

const user = sessionStorage.getItem('UserID');

function CityWiseParcel({cityNames, zoneNames, adminInfo, clientInfo}) { 

    if((sessionStorage.getItem('UserID')) !== null){   
    
        (localStorage.setItem('loggedUser', "userLogged"))
    
    }else{
        
        (localStorage.setItem('loggedUser', "userNotLogged")) 
    }

    const ClientId = sessionStorage.getItem('UserID');

    const [modalIsOpen, setModalIsOpen]       = useState(false);
    const [Data, setData] = useState([]);
    const [count, setCount] = useState([]);
    const [spinner, setSpinner] = useState(true);
    
    const [isSaBtnInactive, setIsSaBtnInactive]    = useState(false);
    
    useEffect(() => {
        getData();
        //handleSearch();
    }, [])
    

    
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = Data.slice(firstIndex, lastIndex);
    

    const[display, setDisplay] = useState('none');
    const[waybillID, setwaybill] = useState('');
    const[margin, setMargin] = useState(0);

    function changeDisplay(val) {
        setDisplay(val);
    }

    const [val, setVal] = useState({
        waybill_id:"",
        search:"",
        cityzone:"",
        parcelType:"",
        fdate:"",
        tdate:"",
        paymentType:"",
        order_id:"",
        status:[]
    });

    function setStatusArray(value) {
        const key = 'status'
        setVal({...val, [key]:value})
    }

    function handleChange(e) {
        const inputValue = e.target.value;
        const inputName = e.target.name;
        //setVal(e.target.value);

        setVal({...val, [inputName]:inputValue});
        
    }
    
    function handleSearch() {
        axios.post(`${process.env.REACT_APP_URL}/city_wise_parcel.php`, {...val, clientID:user})
            .then((res) => {
                setData(res.data);
                setVal({
                    waybill_id:"",
                    search:"",
                    cityzone:val.cityzone,
                    parcelType:val.parcelType,
                    fdate:val.fdate,
                    tdate:val.tdate,
                    paymentType:val.paymentType,
                    orderID:"",
                    status:val.status
                })
                setCurrentPage(1);
            })
            .catch(err => console.log(err));
    }

    // function getData() {
    //     axios.get('http://localhost/hs-login-system-master/city_wise_parcel.php')
    //         .then((res) => {
    //             setData(res.data);
    //         })
    //         .catch(err => console.log(err));
    // }

    async function getData() {
         setIsSaBtnInactive(true);
        //console.log(val)
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/citywiseParcel`, {
            method: 'POST', 
            body: JSON.stringify({
                clientID: ClientId, 
                waybill_id: val.waybill_id,                  
                search: val.search,
                paymentType: val.paymentType,
                parcelType: val.parcelType,
                order_id: val.order_id,
                status:val.status,
                cityzone:val.cityzone,
                fdate: val.fdate,
                tdate: val.tdate,
                //status: val.status                                
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
                //console.log(data.Clstatus);
                setData(data.Clstatus);
                setSpinner(false);
                setCurrentPage(1);
                setCount({
                    waitingCount: data.count1,
                    pickupCount: data.count2,
                    pendingCount: data.count3,
                    completeCount: data.count4,
                    rescheduleCount: data.count5,
                    cancelCount: data.count6,
                    dispatchedCount: data.count7,
                    deleteCount: data.count8,
                });
            })
        } catch (error) {
        console.log(error.message)
        }
         setIsSaBtnInactive(false); 
    }

    document.title = "Logistic Client Portal | City Wise Order List";

    return(
        <>
            {(localStorage.getItem('loggedUser')) === "userLogged"? <TopBar/> : <></> }

            {(localStorage.getItem('loggedUser')) === "userLogged"? <SideBar/> : <></> }

            <div className='main-content'>
                <div className='page-content'>
                    <div className='container-fluid'>
                        <div className="row mt-3 mb-3">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <h4 className="font-size-18">City Wise Parcels</h4>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                                <div className='row'>
                                    
                                    <Waybill func={handleChange} value = {val.waybill_id}/>
                                    <Search func={handleChange} value = {val.search}/>
                                    <CityZone func={handleChange} cityzone={zoneNames}  value = {val.cityzone}/>
                                    <ParcelType func={handleChange} value = {val.parcelType}/>
                                    <FromDate func={handleChange} label="Date From" value = {val.fdate}/>
                                    <ToDate func={handleChange} label="Date To" value = {val.tdate}/>  
                                    <PaymentType func={handleChange} value = {val.paymentType}/>
                                    <OrderId func={handleChange} value = {val.order_id}/>
                                    <Status setStatusArray={setStatusArray} />
                                    

                                </div>

                                <div className="row">
                                    <div className="col-8 col-md-6 col-lg-6"> </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-block" id="searchBtn" onClick={getData} disabled={isSaBtnInactive ? true : false}> Search</button>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <button className="btn btn-info btn-block" id="btnSeartchExport" onClick={() => {handleExport(`${process.env.REACT_APP_URL}/index.php/citywiseParcel`,setData, setCurrentPage, {...val, clientID : ClientId})}}> Search &amp; Export</button>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            {/* <button class="btn btn-success btn-block" id="btnSearchPrint"> Print</button> */}
                                            <Link to={`/parcel-print/${ClientId}?waybill_id=${val.waybill_id}&search=${val.search}&parcelType=${val.parcelType}&fdate=${val.fdate}&tdate=${val.tdate}&orderID=${val.order_id}&cityzone=${val.cityzone}&paymentType=${val.paymentType}&status=${val.status}&type=citywiseParcel`} className="btn btn-success btn-block" target="_blank" onClick={getData}>Print</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-xl-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div id="aa">
                                                    {/* <p><span className="badge" style={{color: '#fff', backgroundColor: '#0054a6'}}>Total Parcel Count - {Data.length}</span></p> */}
                                                    <p><span className="badge" style={{color: '#fff', backgroundColor: '#0054a6'}}>Total Parcel Count - {Data.length}</span> {count.waitingCount != null && (<span className="badge" style={{color: '#fff', backgroundColor: 'green'}}> Waiting - {count.waitingCount}</span>)} {count.pickupCount != null && (<span className="badge badge-warning"> Pickup - {count.pickupCount}</span>)} {count.pendingCount != null && (<span className="badge badge-info"> Processing - {count.pendingCount}</span>)} {count.completeCount != null && (<span className="badge badge-success"> Delivered - {count.completeCount}</span>)} {count.rescheduleCount != null && (<span className="badge badge-primary"> Reschedule - {count.rescheduleCount}</span>)} {count.cancelCount != null && (<span className="badge badge-danger"> Return - {count.cancelCount}</span>)} {count.dispatchedCount != null && (<span className="badge" style={{color: '#fff', backgroundColor: '#f838f6'}}> Dispatched - {count.dispatchedCount}</span>)} {count.deleteCount != null && (<span className="badge" style={{color: '#fff', backgroundColor: 'red'}}> Remove - {count.deleteCount}</span>)}</p>

                                                    <div id='target-content'>
                                                        {
                                                            (Data.length !== 0) ? (
                                                                <div className='table-responsive'>
                                                                    <table className='table'>
                                                                        <thead className="thead-dark">
                                                                            <TableHead/>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                records.map( (item) => {
                                                                                    return (
                                                                            
                                                                                        <TableBody key={item.id} item = {item} cityNames = {cityNames} zoneNames = {zoneNames} setwaybill = {setwaybill} changeDisplay={changeDisplay} type="citywise" setModalIsOpen={setModalIsOpen}/>
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <nav>
                                                                        
                                                                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={Data} itemPerPage={recordsPerPage}/>
                                                                    </nav>
                                                                </div>
                                                            ) : (
                                                                spinner ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                                                                <div className="card bg-danger">
                                                                    <div className="card-body">
                                                                        <h5 className="text-center text-white"> No parcel</h5> 
                                                                    </div> 
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {modalIsOpen === true &&(<ModalA waybill = {waybillID} title = "" adminNUserInfo={adminInfo} clientNInfo={clientInfo} modalIsClose={setModalIsOpen}/>)}
            
            <div className={(modalIsOpen) ? "modal-backdrop show" : ""}></div>
        </>
    )
}



export default CityWiseParcel;