import axios from "axios";

var riderArrayn      = [];
var riderCityArr     = [];
var riderZoneCityArr = [];
var hubNameData = [];
var parcelType = [];

getRiderFName(); 
 getRiderZoneName();
 getRiderCityName();
 getHubNameData();
 getParcelType();

async function getRiderFName() {
            
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getAllRiders`, {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        },
    } )
        .then((respose) => {
        if (respose.ok) {
            return respose.json()
        }
        throw new Error('error')
        })
        .then((data) => {
            
            riderArrayn.push(...data.Clstatus)
    
        })
    } catch (error) {
        console.log(error.message)
    }
}   

async function getRiderZoneName() {
  try {
      await fetch(`${process.env.REACT_APP_URL}/index.php/getRiderZoneName`, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    } )
      .then((respose) => {
        if (respose.ok) {
          return respose.json()
        }
        throw new Error('error')
      })
      .then((data) => {
       
        riderZoneCityArr.push(...data.Clstatus)
        
       
      })
  } catch (error) {
    console.log(error.message)
  }
}

async function getRiderCityName() {
  try {
      await fetch(`${process.env.REACT_APP_URL}/index.php/geRiderCityNames`, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    } )
      .then((respose) => {
        if (respose.ok) {
          return respose.json()
        }
        throw new Error('error')
      })
      .then((data) => {
       
       riderCityArr.push(...data.Clstatus)
       console.log(data.Clstatus) 
       
      })
  } catch (error) {
    console.log(error.message)
  }
}

async function getHubNameData() {
    //console.log(val)
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getHubNameData`, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    } )
        .then((respose) => {
        if (respose.ok) {
            return respose.json()
        }
        throw new Error('error')
        })
        .then((data) => {
            hubNameData.push(...data.Clstatus);
        
        })
    } catch (error) {
    console.log(error.message)
    }
}

async function getParcelType() {
    
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelType`, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
                parcelType.push(...data.Clstatus);
            
            })
        } catch (error) {
        console.log(error.message)
        }
}

function download_csv_file(data) {

    var today = new Date();

    var todaytime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

    var todaydate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    var datetime = todaydate+" "+todaytime;

    // const datetime =  `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
    let csv = `"WAYBILL ID","ORDER DATE","PICKUP DATE","PROCESSING DATE","DELIVERED DATE","RESCHEDULE DATE","RETURN DATE","DISPATCHED DATE","REMOVE DATE","DELIVERY STATUS","RIDER","HUB","ORDER ID","PARCEL TYPE","PARCEL DESCRIPTION","CLIENT NOTE","RECIPIENT NAME","RECIPIENT CONTACT NUMBER","RECIPIENT ADDRESS","RECIPIENT CITY","RECIPIENT CITY ZONE","PICKUP FROM","PICKUP ADDRESS","PICKUP CITY","PAY METHOD","AMOUNT","DELIVERY FEE","ADMIN NOTE","REQUEST TYPE"\n`;

                
    
    //merge the data with CSV
    data.forEach(function(row) {
            const values = [];

            if(row.rider_id){
                var riderIdval    = parseInt(row.rider_id);
                var newRiderIdval = parseInt(riderIdval-1);
           
                var riderFname = riderArrayn[newRiderIdval].fname;
                var riderLname = riderArrayn[newRiderIdval].lname;
                
                var riderFullName = riderFname+' '+riderLname+' ('+row.rider_id+')';
           
            }else{
               var riderFullName = '';
            }

            if(row.recipientCity){
           
                var ridercityval    = parseInt(row.recipientCity);
                var newRiderCityval = parseInt(ridercityval-1);
                
             //   console.log(newRiderCityval);
            
                var riderRecipientCity = riderZoneCityArr[newRiderCityval].cname; 
             }else{
                var riderRecipientCity = '';
             }
             
              
             if(row.zoneCity){
                
                var riderZoneyval    = parseInt(row.zoneCity);
                var newRiderZoneyval = parseInt(riderZoneyval-1);
                
             //   console.log(riderCityArr.length);
            
                var riderZoneCity = riderCityArr[newRiderZoneyval].cname; 
             }else{
                var riderZoneCity = '';
             }
             
             if(row.orderDate){
                 var neworderDate = ' '+row.orderDate+' ';
             }else{
                 var neworderDate = '';
             }
     
             if(row.hub_id) {
                 var hubID = parseInt(row.hub_id);
                 var hubName = hubNameData.find(item => {
                     if(item.id === hubID) {
                         return item.name;
                     } 
                 })
     
             }
     
             if(row.pType) {
                 var pid = parseInt(row.pType);
                 var pArr = parcelType.find(item => {
                     return item.id === pid;
                 })
             } 

             if(row.id){
                values.push(row.id);
             } else {
                values.push('');
             }

             if(row.orderDate){
                values.push(row.orderDate);
             } else {
                values.push('');
             }

             if(row.pickupDate){
                values.push(row.pickupDate);
             } else {
                values.push('');
             }

             if(row.processig_date){
                values.push(row.processig_date);
             } else {
                values.push('');
             }

             if(row.deliveredDate){
                values.push(row.deliveredDate);
             } else {
                values.push('');
             }
            
             if(row.reschedule_date){
                values.push(row.reschedule_date);
             } else {
                values.push('');
             }
            
             if(row.cancellation_date){
                values.push(row.cancellation_date);
             } else {
                values.push('');
             }

             if(row.dispatchedDate){
                values.push(row.dispatchedDate);
             } else {
                values.push('');
             }
            
             if(row.removedDate){
                values.push(row.removedDate);
             } else {
                values.push('');
             }

             if(row.deliveryStatus){
                values.push(row.deliveryStatus);
             } else {
                values.push('');
             }
            
            values.push(riderFullName);

            if(row.hub_id){
                values.push(hubName.name);
            } else {
                values.push('');
            }

            if(row.orderID){
                values.push(row.orderID);
            } else {
                values.push('');
            }

            
            if(row.pType) {
                values.push(pArr.pName);
            } else {
                values.push('');
            }

            if(row.pDescription) {
                values.push(row.pDescription);
            } else {
                values.push('');
            }

            if(row.clientNote) {
                values.push(row.clientNote);
            } else {
                values.push('');
            }

            if(row.recipientName) {
                values.push(row.recipientName);
            } else {
                values.push('');
            }

            if(row.recipientMobile) {
                values.push(row.recipientMobile);
            } else {
                values.push('');
            }
           
            if(row.recipientAddress) {
                values.push(row.recipientAddress.split(',').join(' '));
            } else {
                values.push('');
            }

            if(row.recipientCity) {
                values.push(riderRecipientCity);
            } else {
                values.push('');
            }
           
            values.push(riderZoneCity);
            //values.push(riderRecipientCity);
            
            if(row.pickupFrom) {
                values.push(row.pickupFrom);
            } else {
                values.push('');
            }

            if(row.pickupAddress) {
                values.push(row.pickupAddress.split(',').join(' '));
            } else {
                values.push('');
            }
            
            if(row.pickupCity) {
                values.push(row.pickupCity);
            } else {
                values.push('');
            }
            
            if(row.paymentType) {
                values.push(row.paymentType);
            } else {
                values.push('');
            }
            
            if(row.CODAmount) {
                values.push(row.CODAmount);
            } else {
                values.push('');
            }
            
            if(row.deliveryFee) {
                values.push(row.deliveryFee);
            } else {
                values.push('');
            }
            
            if(row.adminNote) {
                values.push(row.adminNote);
            } else {
                values.push('');
            }
            
            if(row.interface) {
                values.push(row.interface);
            } else {
                values.push('');
            }
            
            
            
            csv += values.join(',');
            csv += "\n";
    });
 
    

   
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    hiddenElement.target = '_blank';
    
    //provide the name for the CSV file to be downloaded
    hiddenElement.download = `${datetime}.csv`;
    hiddenElement.click();
    
    
    
    
    
    
}


async function handleExport(url, func, pageIsNChoose, val) {
    
    // try{
    //     const res = await axios.post(url, val);
    //     await download_csv_file(res.data);
    //     await func(res.data);
        
        
    // } catch(err) {
    //     console.log(err);
    // }

    ////////////////////

    try {
        await fetch(url, {
        method: 'POST', 
        body: JSON.stringify({
            ...val                               
        }),         
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        },
    } )
        .then((respose) => {
        if (respose.ok) {
            return respose.json()
        }
        throw new Error('error')
        })
        .then((data) => {
            download_csv_file(data.Clstatus);
            func(data.Clstatus);
             pageIsNChoose(1);
        })
    } catch (error) {
        console.log(error.message)
    }
}

export {download_csv_file, handleExport}