import { useEffect, useState } from "react";
import Swal from "sweetalert2";

function ModalPro({modalDataPr, modalPrIsClose}) {
      
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    
    const [isOldPwActive, setIsOldPwActive] = useState(false);
    const [isNewPwActive, setIsNewPwActive] = useState(false);
    
    const [isAlertSuccess, setIsAlertSuccess] 		= useState(false);
		const [isAlertUnsuccess, setIsAlertUnsuccess] = useState(false);
		const [isAlertInvalid, setIsAlertInvalid] 		= useState(false);

		const [pwErrors, setPwErrors] = useState({});

    const ClientD = sessionStorage.getItem('UserID');  
    
    const oldPwHandler = (event) => {
        setOldPassword(event.target.value);
        setIsOldPwActive(false)
        
        setIsAlertSuccess(false)
				setIsAlertUnsuccess(false)
				setIsAlertInvalid(false)
    }
    
    const newPwHandler = (event) => {
        setNewPassword(event.target.value);
        setIsNewPwActive(false)
        
        setIsAlertSuccess(false)
				setIsAlertUnsuccess(false)
				setIsAlertInvalid(false)
    }
    
    const validate = (oldPassword,newPassword) => {
      const errors = {};

      if(!oldPassword){
        errors.oldPassword = "This field is required!";  
        setIsOldPwActive(true)    
      }else{
        setIsOldPwActive(false)
       }

      if(!newPassword){
        errors.newPassword = "This field is required!";      
        setIsNewPwActive(true)
      }else{
        setIsNewPwActive(false)
      }

      return errors;
    }



    const PwConfirmHandler = (e) => {

        		e.preventDefault();

						setPwErrors(validate(oldPassword,newPassword));        

      			var errorLength =  (Object.keys(validate(oldPassword,newPassword)).length) 
      			
      			if(errorLength === 0 ){
      				
      				Swal.fire({
	            title: 'Are you sure?',
	            text: "You must Confirm before do this",
	            icon: 'warning',
	            showCancelButton: true,
	            confirmButtonColor: '#4caf50',
	            cancelButtonColor: '#d33',
	            confirmButtonText: 'Confirm'
	
			        }).then((result) => {
		
		            if (result.isConfirmed) {                      
										          
				            async function pwConfirmRequest() {
		                    try {
		                      await fetch(`${process.env.REACT_APP_URL}/index.php/passwordConfirm`, {
		                        method: 'POST',
		                        body: JSON.stringify({
		                            oldPassword: oldPassword, 
		                            newPassword: newPassword,
		                            clId:ClientD,
		                        }),
		                        headers: {
		                          'Content-Type': 'application/x-www-form-urlencoded',
		                        },
		                      } )
		                        .then((respose) => {
		                          if (respose.ok) {
		                            return respose.json()
		                          }
		                          throw new Error('error')
		                        })
		                        .then((data) => {                                       
		                                   
		                          if(data.Clstatus === "success"){
		                            
			                				setOldPassword('');
					                    setNewPassword('');
					                    
					                    setIsAlertSuccess(true)
															setIsAlertUnsuccess(false)
															setIsAlertInvalid(false)
		                           
		                          }else if(data.Clstatus === "invalid"){
		          
		                            
		                            setIsAlertSuccess(false)
																setIsAlertUnsuccess(false)
																setIsAlertInvalid(true)
		          
		                          }else{
		                          	setIsAlertSuccess(false)
																setIsAlertUnsuccess(true)
																setIsAlertInvalid(false)
		                          }
		                          
		                          // modalPrIsClose(false); 
		                                             
		                        })
		                    } catch (error) {
		                      console.log(error.message)
		                    }
		                }
		
		                pwConfirmRequest();
		               
		            }else{
		            
		            }
		        	})  
      				
      			}
      };

    return (
        <div className="modal" id="" style={{display: 'block'}}>
        	<div className="modal-dialog modal-md" role="document">
                <div className="modal-content"> 
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Password Reset</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> modalPrIsClose(false)}>
                            <span aria-hidden="true" style={{color: '#ffffff00'}}>&times;</span>
                        </button>
                    </div>

                    <div className="modal-body"> 
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-12 col-md-12" style={{padding: '0'}}>                                  
																	<div className="row">
                                      <div className="col-6"><label>Old Password</label></div>
                                      <div className="col-6"><input className="form-control" type="password" id="old_pw" value={oldPassword} onChange={oldPwHandler}  />
                                       <p style={{display: isOldPwActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{pwErrors.oldPassword}</p>
                                       </div>
                                     
                                  </div>
                                                    
                                  <div className="row" style={{marginTop: '10px'}}>
                                      <div className="col-6"><label>New Password</label></div>
                                      <div className="col-6"><input className="form-control" type="password" id="new_pw" value={newPassword} onChange={newPwHandler} />
                                      <p style={{display: isNewPwActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{pwErrors.newPassword}</p>
                                      </div>
                                      
                                  </div>
                                                    
                                	<div className="row" style={{marginTop: '10px'}}>
			                              <div className="col-12">
			                              	<button className="button btn-sm btn-success" id="resetPwBtn" onClick={PwConfirmHandler}  style={{float: 'right', marginTop: '10px'}}>Confrim</button>
			                              </div>
                                  </div>
                                                    
		                              <div className="row" style={{marginTop: '10px'}}>
		                                  <div className="col-12"  id="pwChangedDiv">
		                                  	<div className='alert alert-success' style={{margin: '20px', display: isAlertSuccess ? 'block' : 'none'}}>
												                	<p style={{textAlign: 'center', paddingTop: '10px'}}>Successfully Password is changed.</p>
												              	</div>
												            
													              <div className='alert alert-danger' style={{margin: '20px', display: isAlertUnsuccess ? 'block' : 'none'}}>
													                <p style={{textAlign: 'center', paddingTop: '10px'}}> Sorry, Password was not update.</p>
													              </div>
												              
													              <div className='alert alert-danger' style={{margin: '20px', display: isAlertInvalid ? 'block' : 'none'}}>
													                <p style={{textAlign: 'center', paddingTop: '10px'}}> Sorry, Invalid Old Password.</p>
													              </div>
		                                  </div>
                                </div>
                            	</div>
                        		</div>     
                    		</div>
                </div>
            </div>
        </div>
      </div>    
    )
}

export default ModalPro;


