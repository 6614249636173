import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Swal from "sweetalert2";


export default function ClientApi() {
  document.title = "Logistic Client Portal | Status Hook API DOCUMENTATION";

  const [clientData, setClientData]	                          = useState('');
  const [settingData, setSettingData]	                        = useState('');
  const [isBtnInactive, setIsBtnInactive]                     = useState(false);
  const [isHookBtnInactive, setIsHookBtnInactive]             = useState(false);
  const [isTestHookBtnInactive, setIisTestHookBtnInactive]    = useState(false);
  const [isShowBtn, setIsShowBtn]                             = useState(true);
  const [webhookURL, setWebhookURL]	                          = useState('');
  const [webhookStatus, setWebhookStatus]	                    = useState('');

  const [waybillIDTest, setWaybillIDTest]	                    = useState('');
  const [webhooktestURL, setWebhooktestURL]	                  = useState('');
  
  const [isStatusColor, setIsStatusColor]                     = useState(false);
   

  const [isWebhookURLActive, setIsWebhookURLActive]           = useState(false);
  const [isWebhookStatusActive, setIsWebhookStatusActive]     = useState(false);

  const [formErrorsHook, setformErrorsHook] = useState({});
  
  const [statusDivRes, setStatusDivRes]	           = useState('');
  const [requestDivRes, setRequestDivRes]	         = useState('Request');
  
  const ClientIdN = sessionStorage.getItem('UserID');

  useEffect(() => {
    async function exClientDRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: ClientIdN,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {          
            setClientData(data.Clstatus);
            setWebhookURL(data.Clstatus.webhook_url);
            setWebhookStatus(data.Clstatus.webhook_status);
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    exClientDRequest();
    
    
  }, []);


  async function settingD() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
        method: 'POST',          
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          
          setSettingData(data.Clstatus);
         
        })
    } catch (error) {
      console.log(error.message)
    }
  }
   
  useEffect(() => {
    settingD()
  }, []);

var today = new Date();

var todaytime = ('0'+today.getHours()).slice(-2) + ':' + ('0'+today.getMinutes()).slice(-2) + ':' + ('0'+today.getSeconds()).slice(-2);
var todaydate = today.getFullYear() + '-' + ('0'+(today.getMonth() + 1)).slice(-2) + '-' + ('0'+today.getDate()).slice(-2);

const currentTime_now                = todaydate+" "+todaytime;

function addHours(date, hours) {
    
    const dateCopy = new Date(date);
   
    dateCopy.setHours(dateCopy.getHours() + hours);
  
    return dateCopy;
}

    
if(clientData.webhook_sync_request_time){

    const datem = clientData.webhook_sync_request_time;
  
    const newDate = addHours(datem, 1);
    
    const newtodaytime = ('0'+newDate.getHours()).slice(-2) + ':' + ('0'+newDate.getMinutes()).slice(-2) + ':' + ('0'+newDate.getSeconds()).slice(-2);
        
    const newtodaydate = newDate.getFullYear() + '-' + ('0'+(newDate.getMonth() + 1)).slice(-2) + '-' + ('0'+newDate.getDate()).slice(-2);

    var webhook_sync_request_timeAfter = newtodaydate+" "+newtodaytime;

    // console.log(webhook_sync_request_timeAfter)
}else{
    var webhook_sync_request_timeAfter = "";
}

const SyncHandler = (event) => {
    event.preventDefault()
    
    setIsBtnInactive(true);
    
    async function clientTwoWayRequest() {
        try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/clientTwoWay`, {
            method: 'POST', 
            body: JSON.stringify({               
                ClientId:ClientIdN,
              }),          
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
              
              const statusRes =  data.Clstatus;
              
              if(statusRes === "success"){
                
                Swal.fire({
                  title: "Success!",
                  text: "Successfully Requested!",
                  icon: "success",
                  confirmButtonText: "OK",
                  timer: 1500,
                });
                
                setIsShowBtn(false);

              }else if(statusRes === "disabled"){

                setIsBtnInactive(false);
                
                Swal.fire({
                  title: "Warning",
                  text: "Two-way API is disabled! Can't request!",
                  icon: "error",
                  confirmButtonText: "OK",
                  // timer: 1500,
                });
                
              }else{

                setIsBtnInactive(false);
                
                Swal.fire({
                  title: "Warning",
                  text: "Request Unsuccessful!",
                  icon: "error",
                  confirmButtonText: "OK",
                  // timer: 1500,
                });

              }
                             
            })
        } catch (error) {
          console.log(error.message)
        }
    }
  
      clientTwoWayRequest();
    //   setIsBtnInactive(true)
}

const validate = (webhookURL,webhookStatus) => {
    const errors = {};

    if(!webhookURL){
      errors.webhookURL = "This field is required!";  
        setIsWebhookURLActive(true)    
    }else{
        setIsWebhookURLActive(false)
     }

    if(!webhookStatus){
      errors.webhookStatus = "This field is required!";      
        setIsWebhookStatusActive(true)
    }else{
        setIsWebhookStatusActive(false)
    }

    return errors;
}

const webhookURLHandler = (e) => {
    setWebhookURL(e.target.value);
    setIsWebhookURLActive(false); 
};

const handleWebhookStatus = (e) => {
    setWebhookStatus(e.target.value);
    setIsWebhookStatusActive(false);
};

const HookAPIHandler = (event) => {
    event.preventDefault()
    
    setIsHookBtnInactive(true);
    
    setformErrorsHook(validate(webhookURL,webhookStatus)); 

    var errorLengthHook =  (Object.keys(validate(webhookURL,webhookStatus)).length)

    if(errorLengthHook === 0 ){

        async function clientHookAPIRequest() {
            try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/clientHookAPI`, {
                method: 'POST', 
                body: JSON.stringify({               
                    ClientId:ClientIdN,
                    webhookURL:webhookURL,
                    webhookStatus:webhookStatus,
                  }),          
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                  
                  const statusHook =  data.Clstatus;
                  
                  if(statusHook === "yes"){
                    
                    Swal.fire({
                      title: "Success!",
                      text: "Successfully Completed!",
                      icon: "success",
                      confirmButtonText: "OK",
                      timer: 1500,
                    });
                                                                    
    
                  }else if(statusHook === "disabled"){
    
                    setIsBtnInactive(false);
                    
                    Swal.fire({
                      title: "Warning",
                      text: "Two-way API is disabled! Can't update",
                      icon: "error",
                      confirmButtonText: "OK",
                      // timer: 1500,
                    });
                    
                    setWebhookURL(clientData.webhook_url);
                    setWebhookStatus(clientData.webhook_status); 
                    
                  }else if(statusHook === "invalidURL"){
    
                    setIsBtnInactive(false);
                    
                    Swal.fire({
                      title: "Warning",
                      text: "Invalid Webhook URL! Can't update",
                      icon: "error",
                      confirmButtonText: "OK",
                      // timer: 1500,
                    });
    
                    setWebhookURL(clientData.webhook_url);
                    setWebhookStatus(clientData.webhook_status); 
                    
                  }else{
    
                    setIsBtnInactive(false);
                    
                    Swal.fire({
                      title: "Warning",
                      text: "Can't Update!",
                      icon: "error",
                      confirmButtonText: "OK",
                      // timer: 1500,
                    });
    
                    setWebhookURL(clientData.webhook_url);
                    setWebhookStatus(clientData.webhook_status); 
    
                  }
                                 
                })
            } catch (error) {
              console.log(error.message)
            }
        }
      
        clientHookAPIRequest();
        
    }
    setIsHookBtnInactive(false);
}

const webhookTestURLHandler = (e) => {
    setWebhooktestURL(e.target.value);
};
 
const waybillIDTestHandler = (e) => {
    setWaybillIDTest(e.target.value);
};

const testRequestHandler = (event) => {
    event.preventDefault()
    
    setIisTestHookBtnInactive(true);
    setRequestDivRes("Requesting");
    
        async function clientHookTestRequest() {
            try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/clientTestHookAPI`, {
                method: 'POST', 
                body: JSON.stringify({               
                    ClientId:ClientIdN,
                    waybillIDTest:waybillIDTest,
                    webhooktestURL:webhooktestURL,
                  }),          
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                  
                  const statuTestsHook =  data.Clstatus;
                  
                  var nameArr = statuTestsHook.split(',');
                  console.log(nameArr);
                      
                  var statusData      =  (nameArr[0]); 
                  var dataAlert       =  (nameArr[1]);
                  
                  if(dataAlert === "success"){
                   
                    setStatusDivRes(statusData);
                    
                    setWaybillIDTest('')
                    setIsStatusColor(false)                                                
    
                  }else if(dataAlert === "invalid_client"){
    
                    setStatusDivRes('Invalid Parcel');
                    setIsStatusColor(true) 
                    
                  }else if(dataAlert === "no_parcel"){
    
                    setStatusDivRes('No Parcel'); 
                    setIsStatusColor(true);
                    
                  }else if(dataAlert === "status_inactive"){
    
                    setStatusDivRes('Webhook Status Disabled');
                    setIsStatusColor(true);
                    
                  }else if(dataAlert === "no_client"){
    
                    setStatusDivRes('Webhook API Status Disabled');
                    setIsStatusColor(true);
                  }
                                 
                })
            } catch (error) {
              console.log(error.message)
            }
        }
      
        clientHookTestRequest();
        
        setRequestDivRes("Request");
    
        setIisTestHookBtnInactive(false);
}


const json = {
  "status": 204,
  "waybill_no": "100000"
};

const domainName = settingData.domain;

const phpContent = '<?php \n'+
'\n// Whitelist Our Domain \n'+

"Access-Control-Allow-Origin: "+domainName+" \n"+

"\n// Call Back Parameters\n"+

'$waybill_id          = $_POST["waybill_id"];       // Your Waybill\n'+     
'$delivery_status     = $_POST["delivery_status"];  // Current Status\n'+     
'$last_update_time    = $_POST["last_update_time"]; // API Callback Time and Date\n'+ 

"\n// Code To Update your Database\n"+

"\n?>";
                                
  return (
    <>
    <div id="layout-wrapper">
      <TopBar/>
      <SideBar/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid" style={{margin: '10px'}}>
            {
                (() => {
                    if(clientData.webhook_api_status === "disable"){
                    return (
                        <div className="row">
                            <div className="col-xl-12 col-md-3 col-sm-12 mb-4" style={{marginTop: '20px'}}>
                                <div className="alert-warning">
                                    <h4 className="alert-heading">Access Denied !</h4>
                                    <hr/>
                                    <p className="mb-0">Please Contact your account manager</p>
                                </div>
                            </div>
                        </div>                                                                                                                
                        )
                                                                                                                                                    
                    }else{
                        return (
                            <>
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <div className="page-title-box">
                                        <h4 className="font-size-18">Reverse API (Webhook) {webhook_sync_request_timeAfter}</h4>
                                    </div>
                                </div>
                                {webhook_sync_request_timeAfter <= currentTime_now &&
                                    <div className="col-sm-6">
                                        <button className="btn btn-sm btn-primary" disabled={isBtnInactive ? true : false} id="syncBtn" style={{float: 'right', display: isShowBtn ? 'block' : 'none' }} onClick={SyncHandler}>Sync Request</button>
                                    </div>
                                }                          
                            </div>

                            <div className="row align-items-center">
                                <div className="card col-sm-12" style={{backgroundColor: '#e9ecef'}}>
                                    <div className="card"> 
                                        <div className="card-body"> 
                                            <form id="twoWayAPIfrm" name="twoWayAPIfrm" method="POST">  
                                            <h4 className="card-title" style={{marginTop: '20px'}}>Status Hook Details</h4><hr/>             
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Webhook URL</label>
                                                            <input type="text" autoComplete="off" name="webhook_url" className="form-control" value={webhookURL} onChange={webhookURLHandler} id="webhook_url" placeholder="https://yourdomain.com/hook.php" />
                                                            <p style={{color:'red'}}>* https:// </p>
                                                            <p style={{display: isWebhookURLActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrorsHook.webhookURL}</p>
                                                        </div> 
                                                    </div>
           
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Webhook Status</label>
                                                            <select name="webhook_status" id="webhook_status" value={webhookStatus} onChange={handleWebhookStatus} className="form-control" style={{width:'100%'}}>
                                                                <option value="active">Active</option>
                                                                <option value="inactive">Inactive</option>
                                                            </select>
                                                            <p style={{display: isWebhookStatusActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrorsHook.webhookStatus}</p>
                                                        </div>
                                                    </div>
                   
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                        <button id="twoWaysubmitBtn" type="button" className="btn btn-primary" onClick={HookAPIHandler} disabled={isHookBtnInactive ? true : false} style={{float: 'right', marginTop: '28px'}}>Update Status Hook API</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>     
                                </div>
                            </div>

                            <div className="row align-items-center">
                                <div className="card col-sm-12" >
                                    <div className="card-body padding-top0">
                                        <h4 className="mt-2 mb-2">Test Reverse API</h4><hr/>                
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12"><label htmlFor="form-control-label">Webhook Test URL : </label></div>
                        
                                                    <div className="col-12">
                                                        <input className="form-control" type="text" id="webhook_test_url" name="webhook_test_url" value={webhooktestURL} onChange={webhookTestURLHandler}/>
                                                    </div>
                    
                                                    <div className="col-12"><label htmlFor="form-control-label" style={{marginTop: '20px'}}>Waybill ID : </label></div>
                                                        
                                                    <div className="col-12">
                                                        <input className="form-control" type="number" id="waybillIDTest" name="waybillIDTest" value={waybillIDTest} onChange={waybillIDTestHandler}/>
                                                    </div>
                                                    
                                                    <div className="col-12">
                                                        <button type="button" className="btn btn-sm btn-primary" id="waybillIDTestBtn" onClick={testRequestHandler} style={{marginTop: '20px'}} disabled={isTestHookBtnInactive ? true : false} ><span dangerouslySetInnerHTML={{__html: requestDivRes}} /></button>
                                                    </div>                                                   
                                                </div>
                                            </div>
                                            
                                            <div className="col-6">
                                                 <p id="statusDiv" style={{color: isStatusColor ? 'red' : 'rgb(0,0,0)'}} dangerouslySetInnerHTML={{__html: statusDivRes}}/>
                                            </div>
                                        </div>                                  
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center">            
                                <div className="card col-sm-12" >                            
                                    <div className="card-body padding-top0">                      
                                        <h4 className="mt-4">RESPONSE PARAMETERS</h4>
                                        <table className="table table-bordered">
                                            <thead>                                  
                                                <tr>
                                                    <th>Key</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>waybill_id</td>
                                                    <td>Waybill ID / Tracking ID</td>
                                                </tr>  
                                                <tr>
                                                    <td>delivery_status</td>
                                                    <td>Delivery Status of Parcel</td>
                                                </tr>
                                                <tr>
                                                    <td>last_update_time</td>
                                                    <td>Status Last Update Date & Time</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                
                                        <h4 className="mt-4">STATUS CODE LIST</h4>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Status Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Pickup </td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>Processing </td>
                                                    <td>3</td>
                                                </tr>
                                                <tr>
                                                    <td>Dispatched</td>
                                                    <td>4</td>
                                                </tr> 
                                                <tr>
                                                    <td>Delivered </td>
                                                    <td>5</td>
                                                </tr>
                                                <tr>
                                                    <td>Reschedule </td>
                                                    <td>6</td>
                                                </tr>
                                                <tr>
                                                    <td>Return </td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>Removed </td>
                                                    <td>8</td>
                                                </tr>
                                            </tbody>
                                        </table>
                  
                                        <h4 className="mt-4">SAMPLE PHP CODE</h4>
                                        <div className="jumbotron">
                                            <pre>{phpContent}</pre>
                                        </div>
                                        <p style={{textAlign: 'justify'}}>A webhook is an API concept that’s growing in popularity. As more and more of what we do on the web can be described by events, webhooks are becoming even more applicable. They’re incredibly useful and a resource-light way to implement event reactions.</p>
                                        <p style={{textAlign: 'justify'}}>So, what exactly is a webhook? A webhook (also called a web callback or HTTP push API) is a way for an app to provide other applications with real-time information. A webhook delivers data to other applications as it happens, meaning you get data immediately. Unlike typical APIs where you would need to poll for data very frequently in order to get it real-time. This makes webhooks much more efficient for both provider and consumer. The only drawback to webhooks is the difficulty of initially setting them up.</p>   
                                        <p style={{textAlign: 'justify'}}>Webhooks are sometimes referred to as “Reverse APIs,” as they give you what amounts to an API spec, and you must design an API for the webhook to use. The webhook will make an HTTP request to your app (typically a POST), and you will then be charged with interpreting it.</p>
                                    </div> 
                                </div>
                            </div>
                            </>
                        )
                    }
                    })()  
                    } 
            </div>
          </div>
          <footer className="footer">
            <Footer/>
          </footer>
        </div>
    </div>
    </>
  )
}
