import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";

function ModalReSheet({modalData, modalIsClose, parcelTypeA, zoneNames, cancelReason, adminInfo, allParcelD, dataIsChoose, searchData, pageIsChoose, searchfdate, searchtdate, searchhub, searchadmin}) {
      
    const [reDetails, setReDetails]             = useState([]);
    const [allParceltbl, setAllParceltbl]       = useState([]);
    const [spinnerSheet, setSpinnerSheet]       = useState(true);

    const ClientD = sessionStorage.getItem('UserID');  
        
      useEffect(() => {
        async function returnDetailsRequest() {
            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/getreturnD`, {
                method: 'POST', 
                body: JSON.stringify({             
                    returnID: modalData.id,               
                   }),         
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                    setReDetails(data.Clstatus);
                    setSpinnerSheet(false);
                })
            } catch (error) {
              console.log(error.message)
            }
          }
        returnDetailsRequest();       
      }, [])     
      
    async function sheetRequest(){
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getReSheet`, {
            method: 'POST', 
            body: JSON.stringify({             
                clientID: ClientD,
                inputfdate: searchfdate,
                inputtdate: searchtdate,
                riderConfirm: searchData,
                hubConfirm: searchhub,
                adminConfirm: searchadmin,
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
                dataIsChoose(data.Clstatus);
                pageIsChoose(1);
            })
        } catch (error) {
        console.log(error.message)
        }
    }   
      
    const ClConfirmHandler = (e) => {

        e.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "You must Confirm before do this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4caf50',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'

        }).then((result) => {

            if (result.isConfirmed) {                      
                              
                async function vConfirmRequest() {
                    try {
                      await fetch(`${process.env.REACT_APP_URL}/index.php/vendorConfirm`, {
                        method: 'POST',
                        body: JSON.stringify({
                            Hid: modalData.id, 
                            ClientD: ClientD,                        
                        }),
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                        },
                      } )
                        .then((respose) => {
                          if (respose.ok) {
                            return respose.json()
                          }
                          throw new Error('error')
                        })
                        .then((data) => {                                       
                                   
                          if(data.Clstatus === "success"){
                            
                            Swal.fire({
                              title: "Good job!",
                              text: "Order has been Confirm!",
                              icon: "success",
                              confirmButtonText: "OK",
                              timer: 1000,
                            });
                           
                          }else{
          
                            Swal.fire({
                              title: "Warning",
                              text: "Not Successful!",
                              icon: "error",
                              confirmButtonText: "OK",
                              timer: 1000,
                            });
          
                          }
                          sheetRequest();
                          modalIsClose(false); 
                                             
                        })
                    } catch (error) {
                      console.log(error.message)
                    }
                }

                vConfirmRequest();
            }else{
            
            }
        })  

      };

    useEffect(() => {
        async function allParceltbRequestm() {
          try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelstb`, {
              method: 'POST', 
              body: JSON.stringify({             
                clientID: ClientD,               
                 }),         
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            } )
              .then((respose) => {
                if (respose.ok) {
                  return respose.json()
                }
                throw new Error('error')
              })
              .then((data) => {
                setAllParceltbl(data.Clstatus);
               
              })
          } catch (error) {
            console.log(error.message)
          }
        }
        allParceltbRequestm()
    }, []);




    return (
        <div className="modal" id="modalBTNLoad" style={{display: 'block',overflow: 'auto'}}>
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content"  id="modalDivLoad"> 
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel" style={{marginLeft: 'auto'}}> 
                        ID : {modalData.id} | Return Parcel Handover Sheet                                                                
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> modalIsClose(false)}>
                            <span aria-hidden="true" style={{color: '#ffffff00'}}>&times;</span>
                        </button>
                    </div>

                    <div className="modal-body"> 
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-12 col-md-12" style={{padding: '0'}}>                                  

                                {
                                    (reDetails.length !== 0) ? (
                                    <>
                                        <div className="row mb-4">
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <table style={{width:'100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Waybill Count</td>
                                                            <td>{modalData.waybill_count}</td> 
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td>Scan By</td>
                                                            <td>
                                                                {{...adminInfo.find(element => element.id == modalData.scan_by)}.fname} {{...adminInfo.find(element => element.id == modalData.scan_by)}.lname}
                                                                ({modalData.scan_by})
                                                            </td> 
                                                        </tr> 
                                                        <tr>
                                                            <td>Date</td>
                                                            <td>{modalData.scan_date}</td> 
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
            
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <table style={{width:'100%'}}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Rider Confirm</td>
                                                            <td>
                                                                {
                                                                    (() => {
                                                                    if(modalData.confirm_status === 'pending') {
                                                                        return (
                                                                                <>
                                                                                    <i className="fas fa-question text-warning fa-1x ml-3"></i>
                                                                                </>
                                                                                )
                                                                    }else if(modalData.confirm_status === 'complete'){
                                                                        return (
                                                                                <>
                                                                                    <i className="fas fa-check text-info fa-1x ml-3"></i>
                                                                                </>
                                                                                )
                                                                    }
                                                                    })()  
                                                                }  
                                                            </td> 
                                                        </tr>
                                                        <tr>
                                                            <td>Vendor Confirm</td>
                                                            <td>
                                                                                    {
                                                                                        (() => {
                                                                                        if(modalData.client_confirm_status === 'pending') {
                                                                                            return (
                                                                                                <>
                                                                                                    <i className="fas fa-question text-warning fa-1x ml-3"></i>
                                                                                                </>
                                                                                            )
                                                                                        }else if(modalData.client_confirm_status === 'complete'){
                                                                                            return (
                                                                                                <>
                                                                                                    <i className="fas fa-check text-info fa-1x ml-3"></i>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        })()  
                                                                                    }                                                     
                                                            </td>
                                                        
                                                        </tr>
                                                        <tr>
                                                            <td>Hub Confirm</td> 
                                                            <td>
                                                                                    {
                                                                                        (() => {
                                                                                        if(modalData.hub_confirm_status === 'pending') {
                                                                                            return (
                                                                                                <>
                                                                                                    <i className="fas fa-question text-warning fa-1x ml-3"></i>
                                                                                                </>
                                                                                            )
                                                                                        }else if(modalData.hub_confirm_status === 'complete'){
                                                                                            return (
                                                                                                <>
                                                                                                    <i className="fas fa-check text-info fa-1x ml-3"></i>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        })()  
                                                                                    }                                                                                                                                               
                                                            </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Super Admin Confirm</td>
                                                            <td>
                                                                                    {
                                                                                        (() => {
                                                                                        if(modalData.admin_confirm_status === 'pending') {
                                                                                            return (
                                                                                                <>
                                                                                                    <i className="fas fa-question text-warning fa-1x ml-3"></i>
                                                                                                </>
                                                                                            )
                                                                                        }else if(modalData.admin_confirm_status === 'complete'){
                                                                                            return (
                                                                                                <>
                                                                                                    <i className="fas fa-check text-info fa-1x ml-3"></i>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        })()  
                                                                                    }                                                 
                                                            </td> 
                                                        </tr> 
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Waybill ID</th>
                                                        <th>Parcel Info</th>
                                                        <th>Recipient Info</th>
                                                        <th>Pay Method</th>
                                                        <th>Returned Date</th>
                                                        <th>Reason</th>
                                                        <th>Note</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                {                                                                                                                                      
                                                    reDetails.map((reDetail, index)=>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{reDetail.id}</td>
                                                            <td>{reDetail.waybill_id}</td>
                                                            {
                                                                (() => { 
                                                                    if({...allParceltbl.find(element => element.id == reDetail.waybill_id)}){
                                                                        return (
                                                                            <>
                                                                                 <td>
                                                                                    {{...parcelTypeA.find(element => element.id == {...allParcelD.find(element => element.id == reDetail.waybill_id)}.pType)}.pName}
                                                                                    <br/>
                                                                                    {{...allParcelD.find(element => element.id == reDetail.waybill_id)}.pDescription ?  <>{{...allParcelD.find(element => element.id == reDetail.waybill_id)}.pDescription}<br/></> : ""} 
                                                                                    {{...allParcelD.find(element => element.id == reDetail.waybill_id)}.clientNote ? <>Note: {{...allParcelD.find(element => element.id == reDetail.waybill_id)}.clientNote}</>  : ""} 
                                                                                </td>
                                                                                <td>{{...allParcelD.find(element => element.id == reDetail.waybill_id)}.recipientName}<br/>
                                                                                    {{...allParcelD.find(element => element.id == reDetail.waybill_id)}.recipientMobile}<br/>
                                                                                    {{...allParcelD.find(element => element.id == reDetail.waybill_id)}.recipientAddress}<br/>
                                                                                    {/* {{...zoneNames.find(element => element.cid == item.recipientCity)}.cname} */}
                                                                                    {{...zoneNames.find(element => element.cid == {...allParcelD.find(element => element.id == reDetail.waybill_id)}.recipientCity)}.cname}
                                                                                </td>
                                                                                <td>{{...allParcelD.find(element => element.id == reDetail.waybill_id)}.paymentType}</td>
                                                                                <td>{{...allParcelD.find(element => element.id == reDetail.waybill_id)}.cancellation_date ?  <>{{...allParcelD.find(element => element.id == reDetail.waybill_id)}.cancellation_date}</> : " - "} 
                                                                                </td>
                                                                                <td>
                                                                                    {{...allParcelD.find(element => element.id == reDetail.waybill_id)}.cancellation_reason ?  <>
                                                                                    {{...cancelReason.find(element => element.id == {...allParcelD.find(element => element.id == reDetail.waybill_id)}.cancellation_reason)}.reason}</> : " - "}
                                                                                </td>
                                                                                <td>{{...allParcelD.find(element => element.id == reDetail.waybill_id)}.cancellation_note ?  <>{{...allParcelD.find(element => element.id == reDetail.waybill_id)}.cancellation_note}<br/></> : " - "} </td> 
                                                                            </>
                                                                        )
                                                                    }else{
                                                                        return (
                                                                            <>
                                                                                 <td>
                                                                                    {{...parcelTypeA.find(element => element.id == {...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.pType)}.pName}
                                                                                    <br/>
                                                                                    {{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.pDescription ?  <>{{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.pDescription}<br/></> : ""} 
                                                                                    {{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.clientNote ? <>Note: {{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.clientNote}</>  : ""} 
                                                                                </td>
                                                                                <td>{{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.recipientName}<br/>
                                                                                    {{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.recipientMobile}<br/>
                                                                                    {{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.recipientAddress}<br/>
                                                                                    {/* {{...zoneNames.find(element => element.cid == item.recipientCity)}.cname} */}
                                                                                    {{...zoneNames.find(element => element.cid == {...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.recipientCity)}.cname}
                                                                                </td>
                                                                                <td>{{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.paymentType}</td>
                                                                                <td>{{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.cancellation_date ?  <>{{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.cancellation_date}</> : " - "} 
                                                                                </td>
                                                                                <td>
                                                                                    {{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.cancellation_reason ?  <>
                                                                                    {{...cancelReason.find(element => element.id == {...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.cancellation_reason)}.reason}</> : " - "}
                                                                                </td>
                                                                                <td>{{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.cancellation_note ?  <>{{...allParcelD.find(element => element.waybill_id == reDetail.waybill_id)}.cancellation_note}<br/></> : " - "} </td> 
                                                                            </>
                                                                        )
                                                                    }   
                                                                })()    
                                                            }

                                                           
                                                        </tr>
                                                        )
                                                        
                                                    })
                                                }  
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                    ): (
                                    spinnerSheet ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                                        <div className="card bg-danger">
                                            <div className="card-body">
                                                <h5 className="text-center text-white">No Results</h5> 
                                            </div> 
                                        </div>
                                    )
                                } 
                                </div>
                            </div>
                        </div>     
                    </div>

                    <div className="modal-footer">
                                                                    {
                                                                        (() => {
                                                                            if(modalData.client_confirm_status === 'pending'){
                                                                                if(modalData.confirm_status === 'complete' || modalData.hub_confirm_status === 'complete' ){
                                                                                    return (
                                                                                        <>
                                                                                            <button type="button" className="btn btn-primary text-white" id="btnConfirmClient" onClick={ClConfirmHandler} >Confirm</button>
                                                                                        </>
                                                                                    )
                                                                                }   
                                                                        }
                                                                        })()  
                                                                    }                           
                        <Link to={`/returnClientConfirmPrint/${modalData.id}/${ClientD}`} className="btn btn-link btn-success text-white" target="_blank">Print</Link>
                    
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default ModalReSheet;


