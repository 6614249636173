import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
	
export default function ClientApi() {
  document.title = "Logistic Client Portal | API DOCUMENTATION";

  const [clientData, setClientData]	= useState('');
  const [settingData, setSettingData]	= useState('');
 
  const ClientId = sessionStorage.getItem('UserID');
  
  useEffect(() => {
    async function exClientRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: ClientId,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {          
            setClientData(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    exClientRequest();
  }, []);
  
   
  async function settingD() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
        method: 'POST',          
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          
          setSettingData(data.Clstatus);
         
        })
    } catch (error) {
      console.log(error.message)
    }
  }
   
  useEffect(() => {
    settingD()
  }, []);

                                    
const json = {
  "status": 204,
  "waybill_no": "100000"
};

const domainName = settingData.domain;

const phpContent = '<?php \n'+
'// pickup_request function \n'+

"\nfunction pickup_request($api_key,$client_id,$recipient_name,$recipient_contact_no,$recipient_address,$recipient_city,$parcel_type,$cod_amount,$parcel_description,$order_id,$exchange){ \n"+

"\n// curl post\n"+
'\n$ch = curl_init();\n'+
'curl_setopt($ch, CURLOPT_URL,"'+domainName+'/api/p_request_v1.02.php");\n'+
'curl_setopt($ch, CURLOPT_POST, 1);\n'+
'curl_setopt($ch, CURLOPT_POSTFIELDS,\n'+
'"client_id=$client_id&api_key=$api_key&recipient_name=$recipient_name&recipient_contact_no=$recipient_contact_no&recipient_address=$recipient_address&parcel_type=$parcel_type&recipient_city=$recipient_city&parcel_description=$parcel_description&cod_amount=$cod_amount&order_id=$order_id&exchange=$exchange");\n'+
'curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);\n'+
'echo $server_output = curl_exec($ch);\n'+
'curl_close ($ch);\n'+
'\n}\n'+
'\n//call set parcel variables\n'+
'\n$api_key               = "'+clientData.api_key+'";\n'+                             
'$client_id             = "'+ClientId+'";\n'+
'$recipient_name        = "kamal";\n'+
'$recipient_contact_no  = "0755555555";\n'+
'$recipient_address     = "kottawa,pannipitiya"; \n'+
'$recipient_city        = "nugegoda"; \n'+
'$parcel_type           = "2";\n'+
'$cod_amount            = "0"; \n'+
'$parcel_description    = "book";\n'+
'$order_id              = "1022";\n'+
'$exchange              = "0";\n'+
'\n//call pickup_request function\n'+
'\npickup_request($api_key,$client_id,$recipient_name,$recipient_contact_no,$recipient_address,$recipient_city,$parcel_type,$cod_amount,$parcel_description,$order_id,$exchange);\n'+
"\n?>";
                                
  return (
    <>
    <div id="layout-wrapper">
      <TopBar/>
      <SideBar/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid" style={{margin: '10px'}}>
              
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="page-title-box">
                    <h4 className="font-size-18">YOUR API INFORMATION</h4>
                    </div>
                </div>
              </div>
              
              <div className="row align-items-center">
                  <div className="card col-sm-12" style={{backgroundColor: '#e9ecef'}}>
                    <div className="card-body">
	                    <div className="form-group row">
	                      <label htmlFor="clientidnew" className="col-sm-2" style={{fontSize: '1rem', fontWeight: '400'}}>Client ID</label>
	                      <div className="col-sm-10">
	                          <input disabled type="text"  className="form-control" id="clientidnew" placeholder={clientData.id} value={clientData.id} style={{padding:'20px'}}/>
	                      </div>
	                  	</div>
	                    <div className="form-group row">
	                      	<label htmlFor="apikey" className="col-sm-2 " style={{fontSize: '1rem', fontWeight: '400'}}>API Key</label>
	                        <div className="col-sm-10">
	                          <input disabled type="text" className="form-control" id="apikey" placeholder={clientData.api_key} value={clientData.api_key} style={{padding:'20px'}}/>
	                        </div>
	                  	</div>
	                    <div className="form-group row">
	                      	<label htmlFor="clientstatus" className="col-sm-2" style={{fontSize: '1rem', fontWeight: '400'}}>API Status</label>
	                        <div className="col-sm-10">
	                          <input disabled type="text"  className="form-control" id="clientstatus" placeholder={clientData.api_status} value={clientData.api_status} style={{padding:'20px'}}/>
	                        </div>
	                  	</div>
                    </div>
                	</div>
            	</div>
            
            <h4 className="mt-4 mb-5">API DOCUMENTATION</h4>
            
            <div className="row align-items-center">
            
           <div className="card col-sm-12" >
                           
                            <div className="card-body padding-top0">
                             
                               
                              
                              <h4 className="">PICKUP REQUEST</h4>
                              <p>You can use this endpoint directly for pickup request.</p>


                              <table className="table table-borderless" id="send_sms">
                               
                                <tbody>
                                  <tr className="alert" style={{color: '#0054a6', backgroundColor: '#0054a62e',borderColor: '#0054a6'}}>
                                    <th className="w180">ENDPOINT</th>
                                       <td>
                                   {settingData.domain}/api/p_request_v1.02.php                               
                                       </td>
                                  </tr>
                                  <tr className="alert" style={{color: '#0054a6', backgroundColor: '#0054a62e',borderColor: '#0054a6'}}>
                                    <th>TYPE</th>
                                    <td>GET OR POST</td>
                                  </tr>
                                  <tr  className="alert" style={{color: '#0054a6', backgroundColor: '#0054a62e',borderColor: '#0054a6'}}>
                                    <th>RETURN TYPE</th>
                                    <td>JSON</td>
                                  </tr>
                                </tbody>
                              </table>
                              <h4 className="mt-4">PARAMETERS</h4>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Key</th>
                                    <th>Value</th>
                                    <th>Required</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>client_id</td>
                                    <td>Your {settingData.cname} Client ID. </td>
                                    <td>YES</td>
                                  </tr>
                                  <tr>
                                    <td>api_key</td>
                                    <td>Your {settingData.cname} API key. </td>
                                    <td>YES</td>
                                  </tr>
                                   <tr>
                                    <td>recipient_name</td>
                                    <td>The Name of the Recipient.</td>
                                    <td>YES</td>
                                  </tr>
                                  <tr>
                                    <td>recipient_contact_no</td>
                                    <td>The Number of the Recipient.</td>
                                    <td>YES</td>
                                  </tr>
                                  <tr>
                                    <td>recipient_address</td>
                                    <td>The Address of the Recipient.</td>
                                    <td>YES</td>
                                  </tr>
                                  <tr>
                                    <td>recipient_city</td>
                                    <td>The City of the Recipient.</td>
                                    <td>YES</td>
                                  </tr>
                                  <tr>
                                    <td>parcel_type</td>
                                    <td>The Type of the Parcel.</td>
                                    <td>YES</td>
                                  </tr>
                                  <tr>
                                    <td>parcel_description</td>
                                    <td>The Description of the Parcel.</td>
                                    <td>YES</td>
                                  </tr>
                                  <tr>
                                    <td>cod_amount</td>
                                    <td>The COD Amount of the Parcel.</td>
                                    <td>YES</td>
                                  </tr>
                                  <tr>
                                    <td>order_id</td>
                                    <td>The orderID (Invoice ID/ Reference No) of the Parcel.</td>
                                    <td>YES</td>
                                  </tr>
                                  <tr>
                                    <td>exchange</td>
                                    <td>The Exchange Status of the Parcel. (1 - Exchange Parcel / 0 - Normal Parcel)</td>
                                    <td>YES</td>
                                  </tr> 
                                  
                                </tbody>
                              </table>
                              
                              <h4 className="mt-4">RESPONSE SAMPLE</h4>
                              
                               <div className="jumbotron">
                                <p>
                                  <pre>{JSON.stringify(json, null, 2)}</pre>
                                </p>
                              </div>
                               <h4 className="mt-4">RESPONSE PARAMETERS</h4>
                               <table className="table table-bordered">
                                <thead>
                                  
                                   <tr>
                                    <th>Key</th>
                                    <th>Description</th>
                                   </tr>
                                   
                                </thead>
                                <tbody>
                                 <tr>
                                    <td>status</td>
                                    <td>Your Status Code</td>
                                    
                                  </tr>
                                  <tr>
                                    <td>waybill_no</td>
                                    <td>Your Inserted Waybill Number</td>
                                   </tr>
                                </tbody>
                              </table>
                              
                               <h4 className="mt-4">STATUS CODE LIST</h4>
                               <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>HTTP Response</th>
                                    <th>Response</th>
                                    
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>201</td>
                                    <td>Inactive Client API Status</td>
                                  </tr>
                                  <tr>
                                    <td>202</td>
                                    <td>Invalid Apikey</td>
                                  </tr>
                                  <tr>
                                    <td>203</td>
                                    <td>Not Added the Parcel</td>
                                 </tr>
                                  <tr>
                                    <td>204</td>
                                    <td>Successfully Added the Parcel</td>
                                  </tr>
                                  <tr>
                                    <td>205</td>
                                    <td>Recipient Name Is Empty</td>
                                  </tr>
                                  <tr>
                                    <td>206</td>
                                    <td>Recipient Contact Number Is Empty</td>
                                  </tr>
                                  <tr>
                                    <td>207</td>
                                    <td>Recipient Address Is Empty</td>
                                 </tr>
                                  <tr>
                                    <td>208</td>
                                    <td>Recipient Contact Number is Invalid</td>
                                 </tr>
                                  <tr>
                                    <td>209</td>
                                    <td>Recipient City Is Empty</td>
                                   </tr>
                                  <tr>
                                    <td>210</td>
                                    <td>Parcel Type Is Empty</td>
                                   </tr>
                                  <tr>
                                    <td>211</td>
                                    <td>Parcel Type Is Not a Number</td>
                                   </tr>
                                  <tr>
                                    <td>212</td>
                                    <td>Parcel Description Is Empty</td>
                                  </tr>
                                  <tr>
                                    <td>218</td>
                                    <td>Recipient City Is Invalid</td>
                                   </tr>
                                  <tr>
                                    <td>219</td>
                                    <td>Parcel Type Is Not valid</td>
                                   </tr>
                                     <tr>
                                    <td>220</td>
                                    <td> COD amount Is Not a Number</td>
                                   </tr>
                                     <tr>
                                    <td>221</td>
                                    <td> Invalid COD amount. It must be greater than or equal to 0</td>
                                   </tr>
                                </tbody>
                              </table>
                              
                              <h4 className="mt-4">SAMPLE PHP CODE</h4>
                              <div className="jumbotron">
                                
                             <pre>{phpContent}</pre>
                                
                              </div>
                              
                            </div> 
                          </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <Footer/>
          </footer>
        </div>
    </div>
    </>
  )
}
