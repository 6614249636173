import React, { useRef, useEffect, useState }  from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
import logo from '../images/logo-light.png';

// import React, { useRef } from 'react';
// import { useReactToPrint } from 'react-to-print';

// import  ComponentToPrint  from '../pages/Team';

function NewParcelPrint (){

  let  {loggedUser}  = useParams();
  let  {waybill}  = useParams();
  
  const [isPrintingNew, setIsPrintingNew]   = useState(false);
  const [isPrintingNewS, setIsPrintingNewS] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: 'empdata',
    // onAfterPrint: () => alert('Print Success')
  });

  const [setting, setSetting] = useState('');
  const [parcel, setParcel] = useState('');

  
  
  async function settingRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
        method: 'POST',          
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          console.log(data.Clstatus);
          setSetting(data.Clstatus);
          setIsPrintingNewS(true);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

    

useEffect(() => {
  async function parcelRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getParcel`, {
        method: 'POST', 
        body: JSON.stringify({
            waybill: waybill,
            clientID: loggedUser,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          console.log(data.Clstatus);
          setParcel(data.Clstatus);
          setIsPrintingNew(true);
        })
    } catch (error) {
      console.log(error.message)
    }
  }
    parcelRequest();
    sessionStorage.setItem("UserID", loggedUser); 
}, []);

useEffect(() => {
  settingRequest()
}, []);

  useEffect(() => {
    if (isPrintingNew) {
      
         if(isPrintingNewS){
           
           handlePrint()
         }
        
    }
  }, [isPrintingNew, isPrintingNewS, handlePrint]);


// useEffect(() => {
//   // window.addEventListener("beforeunload", handlePrint);
//   // return () => {
//   //   window.removeEventListener("beforeunload", handlePrint);
//   // };
//   window.onbeforeunload = function() {
   
//     handlePrint()
//     // return true;
// };
// }, []);


  document.title = "Logistic Client Portal | Print Parcel";

  const myStyle = {
    lineHeight: '0',
    marginBlockEnd: '8px',
    marginBlockStart: '8px',
    borderColor: 'rgb(154 154 154)',

    marginTop: '0.7rem ',
    marginBottom: '0.7rem'
    };

    const tableStyle = {
      borderCollapse: 'separate'
  }
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  
  return (
  <div ref={componentRef}>
    <div className="bodyclz" style={{ fontFamily: 'initial', color: '#000000', backgroundColor: 'white', height: '148.5mm', margin: '0'}}>
      
    {
                (() => {
                    if(isPrintingNew === true && isPrintingNewS === true) {
                            return (
                             
                               <div style={{height: '145mm', width:'102mm', float:'left', border:'solid grey 1px', margin: '8px 0px 0px 8px'}}>

                                <center>
                                    <table style={tableStyle}>
                                        <tbody>
                                            <tr>
                                                <td style={{fontSize: '12px'}}>

                                                    <img src={logo} alt="imgLogo" className="src" style={{width: '135px', marginRight: '80px', marginTop: '5px'}} />
                                                </td>
                                                <td style={{fontSize: '12px'}}>
                                                    <label style={{fontSize: '14px', fontWeight: '800', margin:'0'}}>Waybill ID : {waybill}</label>
                                                  {parcel.exchange === 1 &&
                                                  <p style={{margin: 'auto', textAlign: 'center', fontSize: '12px'}}>Exchange</p>
                                                  }                               
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </center>
                                <hr style={myStyle} />
                                <p style={{paddingLeft: '25px', fontSize: '15px',lineHeight: '1.2'}}>
                                    <b style={{fontWeight: 'bold' }} >Recipient Details</b>
                                    <br/>
                                    {/* <br/> */}
                                    Name: {parcel.recipientName}   
                                    <br/>
                                    Address: {parcel.recipientAddress}    
                                    <br/>
                                    Contact No: {parcel.recipientMobile} 
                                    <br/>
                                </p>
                                <hr style={myStyle} />
                                <p style={{paddingLeft: '25px', fontSize: '15px', lineHeight: '1.2'}}>
                                    <b style={{fontWeight: 'bold'}} >From</b>
                                    <br/>
                                    {/* <br/> */}
                                    Name: {parcel.pickupFrom}   
                                    <br/>
                                    Address: {parcel.pickupAddress}   
                                    <br/>
                                    Contact No: {parcel.pickupMobile} 
                                    <br/>
                                    {parcel.orderID !== '' &&
                                    <span> Order ID: {parcel.orderID} / </span>
                                    }
                                    Parcel Desc: {parcel.pDescription}
                                    {parcel.clientNote !== '' &&
                                    <span> {parcel.clientNote} </span>
                                    }

                                </p>
                                <hr style={myStyle}/>

                                  <div>     
                                      {
                                          (() => {
                                              if(setting.qr === 'on' && setting.barcode === 'on') {
                                                      return (
                                                        <center>
                                                        <table className="table" style={{tableStyle, paddingLeft: '30px', margin: '-10px'}}>
                                                        <tr>
                                                          <td > </td>
                                                          <td style={{fontSize: '12px'}}> 
                                                    
                                                        {parcel.paymentType === 'COD' && 
                                                        <div><p style={{fontSize: '16px', textAlign:'center', fontWeight: '900'}}> COD: {parcel.CODAmount} LKR </p>
                                                        </div>  
                                                        }
                                                          </td>
                                                          <td></td>
                                                      </tr>
                                                        
                                                      </table>
                                                      
                                                        <table style={tableStyle} className="table">
                                                          <tr>
                                                      <td style={{fontSize: '12px'}}> <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${waybill}&choe=UTF-8`} /> </td>
                                                            
                                                        <td style={{fontSize: '12px'}}> <img style={{width: '150px'}} src={`https://barcode.tec-it.com/barcode.ashx?data=${waybill}`} /></td>
                                                            
                                                          </tr>
                                                          
                                                          
                                                        </table> 
                                                    
                                                      </center> 
                                                      )
                                                  } else if (setting.qr === 'on') {
                                                      return (
                                                        <>
                                                        {parcel.paymentType === 'COD' && 

                                                          <div style={{width: '250px', margin: 'auto'}}>
                                                            <p style={{fontSize: '16px', textAlign:'center', fontWeight: '900'}}> COD : {parcel.CODAmount} LKR </p></div>  
                                                          
                                                          }

                                                        <div style={{width: '250px', textAlign: 'center', margin: 'auto'}}>
                                                        <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${waybill}&choe=UTF-8`} />
                                                        </div> 

                                                        </>
                                                      )
                                                  } else if (setting.barcode === 'on'){
                                                      return (
                                                          <>
                                                          {parcel.paymentType === 'COD' && 

                                                            <div style={{width: '250px', margin: 'auto'}}>
                                                              <p style={{fontSize: '16px', textAlign:'center', fontWeight: '900'}} > COD : {parcel.CODAmount} LKR </p></div>  
                                                            
                                                          }
                                                          <div style={{width: '250px', textAlign: 'center', margin: 'auto'}}>
                                                          <img style={{width: '150px'}} src= {`https://barcode.tec-it.com/barcode.ashx?data=${waybill}`}/>
                                                          </div> 

                                                          </>
                                                      )
                                                  }
                                          })()  
                                      }  
                                  </div> 


                                </div>
                          
                           
                            )
                        }else{
                        	return(
                        		<>
										          <div className="row" style={{  textAlign: 'center', display: 'block', margin: '10px' }}>
										            <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> 
										          </div> 
										        </>
                        	)
                        }
                })()  
            }  



   
    </div>
  </div>
  );
}
export default NewParcelPrint;