import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
import Pagination from "./Pagination";
import {Link} from "react-router-dom";

export default function WaybillUploadHistory() {
  document.title = "Logistic Client Portal | Waybill Upload History";

  const [waybillHistory, setWaybillHistory]       = useState('');
  const [isWaybillHtyLoad, setIsWaybillHtyLoad]   = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 30;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = waybillHistory.slice(firstIndex, lastIndex); 

  const ClientIdHistry = sessionStorage.getItem('UserID');
  
  async function uploadHistryRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getUploadHistry`, {
        method: 'POST', 
        body: JSON.stringify({             
            ClientIdHistry: ClientIdHistry,
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
            setWaybillHistory(data.Clstatus);
            setIsWaybillHtyLoad(false);
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {   
    uploadHistryRequest();
    
  }, [])


  return (
    <>
    <div id="layout-wrapper">
      <TopBar/>
      <SideBar/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <div className="page-title-box">
                        <h4 className="font-size-18">Waybill Upload History</h4>
                    </div>
                </div>
              </div>
                                  
              <div className="row">
                <div className="col-xl-12">
                  <div className="card  mt-3">
                    <div className="card-body">
                      
                      {/* <div className="row">                      
                          <div className="form-group col-md-4"></div> 
                          <div className="form-group col-md-4"></div> 
                                            
                          <div className="form-group col-3 col-md-2 col-lg-2">  
                            <label htmlFor="key_date">Upload Date</label>
                            <input className="form-control" id="key_date" name="key_date" type="date" value={uploadDate} onChange={handleUDateChange} placeholder="From Date" aria-label="From Date" />
                          </div>
                                                                                
                          <div className="form-group col-3 col-md-2 col-lg-2">
                              <label className="text-white">Search</label>
                              <button className="btn btn-primary btn-block" id="searchBtn" onClick={searchUHandler}> Search</button>
                          </div>
                      </div>
                                         */}
                      <div id=""> 
                        {
                          (waybillHistory.length !== 0) ? (
                          <>
                            <p><b>Count - {waybillHistory.length}</b></p>

                            <div id="target-content">
                              <div className="table-responsive">
                                <table  className="table">
                                  <thead  className="thead-dark">
                                    <tr>
                                      <th>ID</th>
                                      <th>Date</th>   
                                      <th>Success</th>   
                                      <th>Unsuccess</th>
                                      <th>File</th>     
                                      <th style={{textAlign: 'center'}}>Action</th> 
                                    </tr>
                                  </thead> 
                                                                         
                                  <tbody>
                                    {                                                                                         
                                    records.map(item=>{
                                    return(                                                                                              
                                      <tr key={item.id}>    
                                        <td className="text-left">{item.id}</td>
                                        <td className="text-left">{item.date}</td>
                                        <td>{item.success}</td>
                                        <td>{item.unsuccess}</td>
                                        <td>
                                          {
                                            (() => {
                                            
                                                let arr             = item.csv_url.split('/');
                                                const firstElement  = arr[0];
                                                const secondElement = arr[2];
                                                
                                                const full_upload_url = firstElement+"//"+secondElement;
                                                
                                                if(full_upload_url == "https://box1.ozonedesk.info"){
                                                
                                                      return (
                                                          <>
                                                            <a href={`${item.csv_url}`}>View File</a>
                                                          </>                                                         
                                                      )
                                                      
                                                }else{
                                                  
                                                      return (
                                                          <>
                                                            <a href={`${process.env.REACT_APP_UPLOAD_URL}/admin/up/${item.csv_url}`}>View File</a>
                                                          </>                                                         
                                                      )
                                                }
                                            })()  
                                          }
                                        
                                        </td>  
                                        <td style={{textAlign: 'center'}}>
                                            <Link to={`/csvWaybillHistryTble/${item.id}`} className="btn btn-primary btn-sm mr-1">View</Link>
                                            <Link to={`/csvWaybillUploadBulkPrint/${item.id}/${ClientIdHistry}`} className="btn btn-success btn-sm" target="_blank">Print</Link>
                                        </td>                                           
                                       </tr>                                                                                            
                                    )
                                    }) 
                                    }                                                                                                                                                              
                                  </tbody>                                                                                                  
                                </table>
          
                                <nav>                                                                       
                                  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={waybillHistory} itemPerPage={recordsPerPage}/>
                                </nav>
                              </div>
                            </div>
                           
                          </>
                          ): (
                          isWaybillHtyLoad ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                              <div className="card bg-danger">
                                  <div className="card-body">
                                      <h5 className="text-center text-white"> No Records</h5> 
                                  </div> 
                              </div>
                          )
                        } 
                      </div>
                      
                    </div>
                  </div>
                </div> 
              </div>

            </div>
          </div>
          <footer className="footer">
            <Footer/>
          </footer>
        </div>
    </div>
    </>
  )
}
