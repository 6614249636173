import { useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';

export default function CompleteReturnList() {  

    document.title = "Logistic Client Portal | Complete Return Hand Over To Vendor";
    
    const [parcelType, setparcelType]           = useState('');    
    const [formErrors, setformErrors]           = useState({});  
    const [isPTypeActive, setIsPTypeActive]     = useState(false);
    
    const TypeChange = (event) => {
        setparcelType(event.target.value)
        setIsPTypeActive(false) 
    }

    const validate = (parcelType) => {
      const errors = {};

      if(!parcelType){
        errors.parcelType = "This field is required!";  
        setIsPTypeActive(true)    
      }else{
        setIsPTypeActive(false)
       }

      return errors;
    }

    const submitComHandler = (event) => {
      event.preventDefault()

      var logUser = sessionStorage.getItem("UserID");
            
      setformErrors(validate(parcelType));        

      var errorLength =  (Object.keys(validate(parcelType)).length)
                    
         if(errorLength === 0 ){

         window.open(`/complete_return_list_load/${logUser}/${parcelType}`);

         setparcelType('');              
        }
      }

    
    return (
      <div>
  <div id="layout-wrapper">

  <TopBar/>

  <SideBar/>
    
    <div className="main-content">

      <div className="page-content">
        <div className="container-fluid" style={{backgroundColor: '',margin: '' }}>
               
                <div className="row">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <div className="page-title-box">
                      <h4 className="font-size-18" >Complete Return Hand Over To Vendor</h4>
                     </div>
                    </div>
                </div>

                <div className="row justify-content-md-center">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="card-body">
                                <form id="CompleteReportFrm" name="CompleteReportFrm" onSubmit={submitComHandler} method="post">
                                    <div className="form-group">
                                        <label htmlFor="type">Type :</label>
                                        <select className="form-control" id="parcelType" name="parcelType" value={parcelType} onChange={TypeChange}  >
                                            <option value="">Select Type</option>
                                            <option value="Cancel">Return & Exchange</option>
                                        </select>
                                        <p style={{display: isPTypeActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrors.parcelType}</p>
                                    </div>
                                    
                                    <div className="inner-repeater mb-4">
                                        <div style={{float: 'right',marginTop: '10px' }}>
                                            <button id="goNextBtn" type="submit" className="btn btn-success inner">Go</button>
                                        </div> 
                                    </div>
                                </form>   
                            </div>
                        </div>
                    </div>    
                </div>
          
     
        </div>
   
      </div>
    
      <footer className="footer">
        <Footer/>
      </footer>

    </div>
 

  </div>
     
</div>

 

    )
}
