import axios from "axios";
function download_csv_file(data) {

    var today = new Date();

    var todaytime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

    var todaydate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    var datetime = todaydate+" "+todaytime;
    
    // const datetime =  `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
    let csv = `"ID","DATE","BATCH NUMBER","INVOICE COUNT","AMOUNT (LKR)"\n`;
    
    //merge the data with CSV
    data.forEach(function(row) {

            var amount0  = row.amount;
            var amountN  = parseFloat(amount0).toFixed(2);

            var date0    = row.date;
            var dateN    = ' '+date0+' ';

            const values = [];
            values.push(row.id);
            values.push(dateN);
            values.push(row.batchNum);
            values.push(row.invoiceCount);
            values.push(amountN);                      
            csv += values.join(',');
            csv += "\n";
    });
 
    

   
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    
    //provide the name for the CSV file to be downloaded
    hiddenElement.download = `Vendor_Pending_Payment_${datetime}.csv`;
    hiddenElement.click();
}


async function exportHandler(url, func, batchID, inputfdate, inputtdate, ClientIdN) {

    try {
        await fetch(url, {
        method: 'POST', 
        body: JSON.stringify({             
            batchID: batchID,
            inputfdate: inputfdate,
            inputtdate: inputtdate,
            client: ClientIdN,       
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
            func(data.Clstatus);
        //   setCurrentPage(1);



          download_csv_file(data.Clstatus);
            // func(data.Clstatus);
            // console.log('aaaa')
        })
    } catch (error) {
      console.log(error.message)
    }
}

export {download_csv_file, exportHandler}