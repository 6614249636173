import { useEffect, useState } from "react";

function ModalUpload({modalIsClose, modalData}) {

    const [err, setErr] = useState('');
    const [isLoadingErr, setIsLoadingErr] = useState(true); 
    
    
    async function errDetailsRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getError`, {
            method: 'POST', 
            body: JSON.stringify({             
                uploadID: modalData.id,               
               }),         
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
                setErr(data.Clstatus);
                setIsLoadingErr(false);
            })
        } catch (error) {
          console.log(error.message)
        }
      }
    
      useEffect(() => {
        errDetailsRequest();
        
      }, [])

       

    return (
        <div className="modal" id="modalBTNLoad" style={{display: 'block'}}>
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content"  id="modalDivLoad"> 
                    <div className="modal-header">
                        <h5 className="modal-title" id="pickupModalLabel" style={{marginLeft: 'auto'}}>                        
                        Error Report - ID : {modalData.id} | Date : {modalData.date}                   
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> modalIsClose(false)}>
                            <span aria-hidden="true" style={{color: '#ffffff00'}}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body"> 
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-lg-12 col-md-12"> 
                                {
                                    (err.length !== 0) ? (
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Waybill Id</th>   
                                                        <th>Reason</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                {                                                                                                                                      
                                                    err.map((errs, index)=>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td>{errs.parcel_id}</td>
                                                            <td>{errs.reason}</td> 
                                                        </tr>
                                                        )
                                                        
                                                    })
                                                }  
                                                </tbody>
                                            </table>
                                        </div>
                                    ): (
                                    isLoadingErr ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                                        <div className="card bg-danger">
                                            <div className="card-body">
                                                <h5 className="text-center text-white"> No Records</h5> 
                                            </div> 
                                        </div>
                                    )
                                } 
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default ModalUpload;


