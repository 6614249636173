import { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom'
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';
// import axios from "axios"
import Swal from "sweetalert2";
// import { Helmet } from 'react-helmet';


export default function AddParcel() {  

  // const navigate = useNavigate();


    const getInitialState = () => {
        const value = "";
        return value;
      };
      // const getPTypeInitialState = () => {
      //     const value = "";
      //     return value;
      // };
  
  
    const [payMethod, setPayMethod]   = useState(getInitialState);
    const [parceldes, setparceldes]   = useState('');
    const [orderId, setorderId]       = useState('');
    const [RecName, setRecName]       = useState('');
    const [RecContact, setRecContact] = useState('');
    const [RecAddress, setRecAddress] = useState('');
    const [city, setcity]             = useState('');
    const [exchange, setExchange]     = useState(false);
    // const [defaultChecked, setDefaultChecked]     = useState(false);
    const [CODamount, setCODamount]   = useState('');
    const [parcelType, setparcelType] = useState('');
    const [hiddenParcelType, sethiddenParcelType]   = useState('');

    const [isAnonymous, setisAnonymous]     = useState(false);

    const [formErrors, setformErrors] = useState({});
    // const [formErrors, setformErrors] = useState([]);
    
    const [isSubmit, setIsSubmit]     = useState(false);
    // const [formSuccess, setformSuccess] = useState(false);

    const [isPTypeActive, setIsPTypeActive] = useState(false);
    const [isPdesActive, setIsPdesActive] = useState(false);
    const [isRnameActive, setIsRnameActive] = useState(false);
    const [isRcontactActive, setIsRcontactActive] = useState(false);
    const [isRaddressactActive, setIsRaddressactActive] = useState(false);
    const [isRcityactActive, setIsRcityactActive] = useState(false);
    const [ispayMethodActive, setIspayMethodActive] = useState(false);
    const [iscodActive, setIscodActive] = useState(false);

    async function parcelTypeRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelType`, {
            method: 'POST',          
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
              // console.log(data.Clstatus);
              setparcelType(data.Clstatus);

              // if (data.status) {
              //   localStorage.setItem('token', data.status)
              //   const userNewToken = jwt(localStorage.getItem('token'))
  
              //   const Useridv = userNewToken.user.id;
              //   sessionStorage.setItem("UserID", Useridv);
  
              //   (localStorage.setItem('loggedUser', "userLogged"));                            
  
              //   navigate('/index')
              // } else {
              //   //set error
              // }
            })
        } catch (error) {
          console.log(error.message)
        }
      }
  
        
    useEffect(() => {
        parcelTypeRequest()     
    }, []);

 
    
    const handleChange = (e) => {
        setPayMethod(e.target.value);
        setIsSubmit(false);
        setIspayMethodActive(false)
        // if(formErrors.payMethod){
        //   setformErrors(formErrors.payMethod = "");                   
        // }
    };

    const handleTypeChange = (e) => {
        
        e.preventDefault();
        sethiddenParcelType(e.target.value);   
        // setparcelType(e.target.value);              
        setIsSubmit(false); 
        setIsPTypeActive(false)
        // if(formErrors.hiddenParcelType){
        //   setformErrors(formErrors.hiddenParcelType = "");
        // }

    };

    const parceldesHandler = (event) => {
        setparceldes(event.target.value);
        setIsSubmit(false); 
        // setformErrors(false);errors.parceldes
        // var oldformErrors = formErrors;
        // setformErrors({ parceldes: "", oldformErrors});

        // var array = [formErrors]; // make a separate copy of the array
        // var index = array.indexOf("parceldes: 'This field is required!'")
        // if (index !== -1) {
        //   array.splice(index, 1);
        //   setformErrors(array);
        //   console.log(array);
        // }
        // if(formErrors.parceldes){
        //   setformErrors(formErrors.parceldes = "");
        // }
        // setformErrors({});
        setIsPdesActive(false)

    }

    const orderIdHandler = (event) => {
        setorderId(event.target.value)
        setIsSubmit(false); 
    }

    const RecNameHandler = (event) => {
        setRecName(event.target.value)
        setIsSubmit(false);
        setIsRnameActive(false)
        // if(formErrors.RecName){
        //   setformErrors(formErrors.RecName = "");                   
        // }
    }

    const RecContactHandler = (event) => {
        setRecContact(event.target.value)
        setIsSubmit(false);
        setIsRcontactActive(false)
        // if(formErrors.RecContact){
        //   setformErrors(formErrors.RecContact = "");                   
        // }
    }
    
    const RecAddressHandler = (event) => {
        setRecAddress(event.target.value)
        setIsSubmit(false);
        setIsRaddressactActive(false)
        // if(formErrors.RecAddress){
        //   setformErrors(formErrors.RecAddress = "");                   
        // }
        // setformErrors({});
    }

    const cityHandler = (event) => {
        setcity(event.target.value)
        setIsSubmit(false);
        setIsRcityactActive(false)
        // if(formErrors.city){
        //   setformErrors(formErrors.city = "");                   
        // }
    }

    const exchangeHandler = (event) => {
        // setcity(event.target.value)
        

        // setExchange(!exchange); 
        
        setExchange(current => !current);
          setIsSubmit(false);

          // if (event.target.checked) {
          //   console.log(exchange);
          // } else {
          //   console.log(exchange);
          //   // console.log('Checkbox is NOT checked');
          // }
        
        // console.log(exchange)
    }
        
    const CODamountHandler = (event) => {
        setCODamount(event.target.value)
        setIsSubmit(false);
        setIscodActive(false)
        // if(formErrors.CODamount){
        //   setformErrors(formErrors.CODamount = "");                   
        // }

    }

    const validate = (parceldes,hiddenParcelType,RecName,RecContact,RecAddress,city,payMethod,CODamount) => {
      const errors = {};

      if(!parceldes){
        errors.parceldes = "This field is required!";  
        setIsPdesActive(true)    
      }else{
        setIsPdesActive(false)
       }

      if(!hiddenParcelType){
        errors.hiddenParcelType = "This field is required!";      
        setIsPTypeActive(true)
      }else{
        setIsPTypeActive(false)
      }

      if(!RecName){
        errors.RecName = "This field is required!";
        setIsRnameActive(true)
      }else{
        setIsRnameActive(false)
      }

      if(!RecContact){
        errors.RecContact = "This field is required!";      
        setIsRcontactActive(true)
      }else{
        setIsRcontactActive(false)
      }

      if(!RecAddress){
        errors.RecAddress = "This field is required!";
        setIsRaddressactActive(true)
      }else{
        setIsRaddressactActive(false)
      }

      if(!city){
        errors.city = "This field is required!";      
        setIsRcityactActive(true)
      }else{
        setIsRcityactActive(false)
      }

      if(!payMethod){
        errors.payMethod = "This field is required!";      
        setIspayMethodActive(true)
      }else{
        setIspayMethodActive(false)
      }

      if(payMethod === "COD"){
        if(!CODamount){
          errors.CODamount = "This field is required!";
          setIscodActive(true)
        }else{
          setIscodActive(false)
        }
      }else{
        setIscodActive(false)
      }
      


      return errors;
    }



    const submitParcelHandler = (event) => {
      event.preventDefault()
      setIsSubmit(true); 

      var logUser = sessionStorage.getItem("UserID");
            
      setformErrors(validate(parceldes,hiddenParcelType,RecName,RecContact,RecAddress,city,payMethod,CODamount));        

      var errorLength =  (Object.keys(validate(parceldes,hiddenParcelType,RecName,RecContact,RecAddress,city,payMethod,CODamount)).length)
                    
         if(errorLength === 0 ){
          
          async function parcelAddRequest() {
            try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/addNewParcel`, {
                method: 'POST',
                body: JSON.stringify({
                  hiddenParcelType: hiddenParcelType,
                  parceldes: parceldes,
                  orderId: orderId,
                  RecName: RecName,
                  RecContact: RecContact,
                  RecAddress: RecAddress,
                  city: city,
                  exchange: exchange,
                  payMethod: payMethod,
                  CODamount: CODamount,
                  logUser:logUser,
                }),
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              } )
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                  console.log(data.Clstatus);               
  
                  const statusbr =  data.Clstatus;
                  var nameArr    = statusbr.split(',');
                  
                  var dataWord        =  (nameArr[0]); 
                  var WaybillID       =  (nameArr[1]);
  
                  if(dataWord === "success"){
                    setisAnonymous(false);
                    // swal("Good job!", "Parcel Successfully Added!", "success");
                    Swal.fire({
                      title: "Good job!",
                      text: "Parcel Successfully Added!",
                      icon: "success",
                      confirmButtonText: "OK",
                      timer: 1500,
                    });
                    const loggedNUser = sessionStorage.getItem('UserID'); 
                    // window.open('print_label_parcel.php?ID='+WaybillID+ "&loggedUser=" + loggedNUser);
                    window.open(`/newParcelPrint/${loggedNUser}/${WaybillID}`);

                    // navigate({
                    //   pathname: '/newParcelPrint',
                    //   search: `/${loggedNUser}/${WaybillID}`,
                    // });

                    // <Link to={`/newParcelPrint/${loggedNUser}/${WaybillID}`} class="btn btn-success btn-sm" target="_blank">Print</Link>
  
                    setPayMethod('');
                    setparceldes('');
                    setorderId('');
                    setRecName('');
                    setRecContact('');
                    setRecAddress('');
                    setcity('');
                    
                    setExchange(false);
                    // setDefaultChecked(false);
                      // exchangeHandler();
                      
                      // setExchange(exchange.checked ? true : false)
                      // setExchange(true);
                      // setExchange(!current);
                      
                    // console.log(exchange);
                    setCODamount('');
                    setparcelType('');
                    sethiddenParcelType('');
                    
                    parcelTypeRequest();
                  
                  }else if(dataWord === "invalidCity"){
  
                    setisAnonymous(false);
                    // swal("Warning!", "Invalid City!", "error");
  
                    Swal.fire({
                      title: "Warning",
                      text: "Invalid City!",
                      icon: "error",
                      confirmButtonText: "OK",
                      // timer: 1500,
                    });
                    // $("#existingBtn").html('Submit');
                    // $("#existingBtn").prop('disabled', false);
  
                  }else{
  
                    setisAnonymous(false);
                    
                    Swal.fire({
                      title: "Warning",
                      text: "Parcel Add Unsuccessful!",
                      icon: "error",
                      confirmButtonText: "OK",
                      // timer: 1500,
                    });
  
                  }
                                 
                })
            } catch (error) {
              console.log(error.message)
            }
          }
      
          setisAnonymous(true);
          parcelAddRequest();
          
        }
      }
        
    // useEffect(() => {
    //   if(Object.keys(formErrors).length === 0 && isSubmit){
    
    //     }else{
   
    //     }
    // }, [formErrors]);  

    document.title = "Logistic Client Portal | New Parcel";
    return (
      <div>
  <div id="layout-wrapper">

  <TopBar/>

  <SideBar/>
    
    <div className="main-content">

      <div className="page-content">
        <div className="container-fluid">
               
               <div className="row mt-3" style={{backgroundColor: '#ebebf3',margin: '10px' }} >
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <div className="page-title-box">
                      <h4 className="font-size-18" >Add New Parcel</h4>
                     </div>
                    </div>
                    <div className="col-lg-12">
                     <div className="card">
                        <div className="card-body">
                            <div className="card-title">
                                <h4 className="card-title">Parcel Detail</h4>
                                <hr/>
                            </div>
                                    <form id="ParcelAddForm" name="ParcelAddForm" onSubmit={submitParcelHandler} method="post">
                                        <div className="beforeDiv">
                                            <div className="row">
                                                
                                                <div className="col-lg-3">
                                                    <input type="hidden" id="hiddenParcelType" name="hiddenParcelType" value={hiddenParcelType}  />
                                                     <div className="form-group">
                                                        <label htmlFor="forname">Parcel Type :</label>
                                                        <select className="form-control" id="parcelType" name="parcelType" onChange={handleTypeChange} >
                                                        <option value="">Select...</option>

                                                        {
                                                            parcelType && parcelType.map((option, index) => (
                                                                <option key={index} value={option.id}>
                                                                    {option.pName}-{option.pSize}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                    
                                                    <p style={{display: isPTypeActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.hiddenParcelType}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="formname">Parcel Description :</label>
                                                        <input type="text" id="parceldes" name="parceldes" placeholder="Description" value={parceldes} onChange={parceldesHandler} className="form-control" />
                                                         {/* {formErrors.parceldes && */}
                                                         <p style={{display: isPdesActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}} >{formErrors.parceldes}</p>
                                                         {/* } */}
                                                         
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="formname">Order Id :</label>
                                                        <input type="text" id="orderId" name="orderId" placeholder="Order Id" value={orderId} onChange={orderIdHandler} className="form-control"  />
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="formname">Recipient Name :</label>
                                                        <input type="text" id="RecName" name="RecName" placeholder="Name" value={RecName} onChange={RecNameHandler} className="form-control" />
                                                        <p style={{display: isRnameActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.RecName}</p>
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="formname">Recipient Contact No :</label>
                                                        <input type="text" id="RecContact" name="RecContact"  placeholder="xxxxxxxxxx/xxxxxxxxxx" value={RecContact} onChange={RecContactHandler}  className="form-control" />
                                                        {/* <small id="validNo" name="validNo" style={{color: 'red',display: 'none' }} >Please Enter a valid number</small> */}
                                                        <p style={{display: isRcontactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.RecContact}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="formname">Recipient Address :</label>
                                                        <input type="text" id="RecAddress" name="RecAddress" placeholder="No/Address" value={RecAddress} onChange={RecAddressHandler}  className="form-control" />
                                                        <p style={{display: isRaddressactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.RecAddress}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="formname">Recipient City :</label>
                                                        <input type="text" id="city" name="city" className="form-control" value={city} onChange={cityHandler} placeholder="city" />
                                                        {/* <small id="validCity" name="validCity" style={{color: 'red',display: 'none' }}>Please Enter a valid city</small> */}
                                                        <p style={{display: isRcityactActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.city}</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label htmlFor="formname">Exchange :</label>{exchange}
                                                        <input type="checkbox" id="exchange" name="exchange" value={exchange} checked={exchange} onChange={exchangeHandler}  className="form-control" style={{width: 'unset' }} />
                                                    </div>
                                                    {/* defaultChecked={exchange === true ? 'selected': ''} */}
                                                </div>
                                                <div className="col-lg-3">
                                                      <div className="form-group">
                                                        <label htmlFor="forname">Payement Method :</label>
                                                        <select className="form-control" id="payementMeth" value={payMethod} name="payementMeth" onChange={handleChange}  >
                                                        <option value="" >Select...</option>
                                                        <option value="AlreadyPaid">Already Paid</option>
                                                        <option value="COD">COD</option>
                                                        {/* {payMethod === "default" ? selected: ''} */}
                                                    </select>
                                                    <p style={{display: ispayMethodActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.payMethod}</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                {payMethod === "COD" && 
                                                  
                                                    <div className="form-group" id="showAmountCOD" >
                                                        <label htmlFor="formname">COD Amount :</label>
                                                        <input type="text" id="CODamount" name="CODamount" value={CODamount} onChange={CODamountHandler} className="form-control" />
                                                        <p style={{display: iscodActive ? 'block' : 'none', marginBottom: '0px', color: 'red'}}>{formErrors.CODamount}</p>
                                                    </div>
                                                  
                                                }
                                                </div>
                                                
                                                <div className="col-lg-3">
                                                    
                                                </div>

                                                <div className="col-lg-3">
                                                    <div style={{float: 'right',marginTop: '30px' }}>
                                                        <button className="btn btn-success inner" disabled={isAnonymous ? true : false} type="submit" id="exitButton" name="exitButton">Submit</button>
                                                    </div> 
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                        
                                    </form>
                        </div>
                    </div>
                 </div>    
               </div> 
          
     
        </div>
   
      </div>
    
      <footer className="footer">
        <Footer/>
      </footer>

    </div>
 

  </div>
     
</div>

 

    )
}
