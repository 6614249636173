import {useState, useEffect } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from "../Components/Footer";

import img1 from "../assets/images/dashboard/waiting_parcels.png";
import img2 from '../assets/images/dashboard/pickup_parcels.png';
import img3 from '../assets/images/dashboard/processing_parcels.png';
import img4 from '../assets/images/dashboard/dispatched _parcels.png';
import img5 from '../assets/images/dashboard/delivered_parcels.png';
import img6 from '../assets/images/dashboard/removed_parcels.png';
import img7 from '../assets/images/dashboard/return_parcels.png';
import img8 from '../assets/images/dashboard/reschedule_parcels.png';
import img9 from '../assets/images/dashboard/exchange_parcel.png';
import img10 from '../assets/images/dashboard/all_parcel.png';
import { Link } from "react-router-dom";





export default function Dashboard(){

    const ClientIdD = sessionStorage.getItem('UserID');
    const[dashData,setDashData] = useState('');

    const [isLoadData, setIsLoadData]   = useState(false);

    
    useEffect(() => {
        async function dashboardDetails() {
          try {
              await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelCountByDeliveryStatus`, {
              method: 'POST', 
              body: JSON.stringify({             
                  clientID: ClientIdD,
                 }),         
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            } )
              .then((respose) => {
                if (respose.ok) {
                  return respose.json()
                }
                throw new Error('error')
              })
              .then((data) => {
                // console.log(data.Clstatus);
                setDashData(data.Clstatus);
                setIsLoadData(true);
               
              })
          } catch (error) {
            console.log(error.message)
          }
        }
          dashboardDetails();
          sessionStorage.setItem("UserID", ClientIdD); 
      }, []);
      
    const [alertSetting, setAlertSetting] = useState('');
  
    async function settingAlertRequest() {
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
            method: 'POST',          
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          } )
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
              console.log(data.Clstatus);
              setAlertSetting(data.Clstatus);
             
            })
        } catch (error) {
          console.log(error.message)
        }
      }
       
    useEffect(() => {
        settingAlertRequest()
    }, []);
  
      
      
      

   console.log(dashData);
    return(
        <>
        <div id="layout-wrapper">
            
         <TopBar/>
         <SideBar/>
         
      <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
            <div className="row" style={{padding: '20px'}}>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <h4 className="font-size-18">Welcome To Dashboard</h4>
                </div>
              </div>
              
                {alertSetting.client_announce_publish === "Active" && alertSetting.client_announce_header !== null && alertSetting.client_announce_header !== "" && alertSetting.client_announce_body !==null && alertSetting.client_announce_body !=="" &&
                  <div className="row">
                        <div className="col-xl-12 col-md-3 col-sm-12 mb-4" style={{marginTop: '20px'}}>

                          <div className="alert alert-warning" role="alert">
                            <h4 className="alert-heading">{alertSetting.client_announce_header}</h4> 
                            <hr/>
                            <p className="mb-0">{alertSetting.client_announce_body}</p>
                          </div>

                        </div>
                  </div>
                }
              
              <div className="row">
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
                <Link to="/waitingparcel">
                 {/* <a className="a-tag-dash" href=""> */}
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>WAITING PARCLES</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img1} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}} />
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.Waiting}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                 
                                 
                                 
                                 </span></h2>
                            </div>
                    </div>
                 {/* </a> */}
                 </Link>
            </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
            <Link to="/pickup_order">
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>PICKUP PARCELS</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img2} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}} />
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                 {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.Pickup}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                </span></h2>
                            </div>
                    </div>
                    </Link>
            </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
            <Link to="/pending_order">
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>PROCESSING PARCELS</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img3} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}} />
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                 {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.Pending}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                </span></h2>
                            </div>
                    </div>
                    </Link>
            </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
            <Link to="/dispatched_order">
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>DISPATCHED PARCELS</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img4} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}}/>
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                 {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.Dispatched}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                </span></h2>
                            </div>
                    </div>
                    </Link>
            </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
            <Link to="/complete_order">
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>DELIVERED PARCELS</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img5} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}}/>
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                 {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.Complete}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                </span></h2>
                            </div>
                    </div>
                    </Link>
            </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
            <Link to="/remove_order">
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>REMOVED PARCELS</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img6} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}} />
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                 {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.Delete}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                 </span></h2>
                            </div>
                    </div>
                    </Link>
            </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
            <Link to="/cancel_order">
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>RETURN PARCELS</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img7} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}} />
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                 {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.Cancel}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                 </span></h2>
                            </div>
                    </div>
                    </Link>
            </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
            <Link to="/rescheduled_order">
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>RESHEDULE PARCELS</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img8} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}} />
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                 {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.Reschedule}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                 </span></h2>
                            </div>
                    </div>
                    </Link>
            </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
            <Link to="/exchange_order">
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>EXCHANGE PARCELS</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img9} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}} />
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                 {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.exchange}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                 </span></h2>
                            </div>
                    </div>
                    </Link>
            </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="card bg-c-card order-card" style={{boxShadow: "1px 2px 9px #9E9E9E"}}>
            <Link to="/all_order">
                    <div className="card-block">
                            <h5 className="mb-3" style={{marginLeft:"20px",marginTop:"10px",color:"#5b626b"}}>ALL PARCELS</h5>
                            <div className="d-flex justify-content-between mb-3">
                                <div className="img-div bg-c-img">
                                    <img src={img10} alt="img" className="img-fluid" style={{width:"54px",height:"50px",marginLeft:"20px"}} />
                                </div>
                                 <h2 className="text-right mx-3" style={{marginRight:"30px",color:"#5b626b"}}><span>
                                 {
                                    (isLoadData) ? (
                                        <>
                                        {dashData.all}
                                        </>   
                                    ) : (
                                        <>
                                         
                                            <i className="fas fa-spinner fa-spin fa-1x" style={{ color: '#1088e2'}}></i> 
                                           
                                        </>
                                    )
                                }
                                 </span></h2>
                            </div>
                    </div>
                    </Link>
            </div>
        </div>
        </div>

                </div>
            </div>
        </div>
        <footer className="footer">
            <Footer/>
          </footer>
        </div> 
  </>
    )
}