import { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import AccountConfirm from './view/confirm'
import Home from './view/home'
import Login from './view/login'
import Logout from './view/logout'
import ClientProfile from './view/client_profile'
import Register from './view/register'
import ResetPassword from './view/reset'
import Index from './view/index';
import RemoveParcelPrintLabel from './view/RemoveParcelPrintLabel';
import AddParcel from './view/add_new_parcel';
import AllParcel from './view/AllParcel';
import CityWiseParcel from './view/CityWiseParcel';
import PickupParcel from './view/PickupParcel';
import DeliveredParcel from './view/DeliveredParcel';
import DispatchedParcel from './view/DispatchedParcel';
import ExchangeParcel from './view/ExchangeParcel';
import ProcessingParcel from './view/ProcessingParcel';
import RemoveParcel from './view/RemoveParcel';
import RescheduledParcel from './view/RescheduledParcel'
import ReturnParcel from './view/ReturnParcel'
import ClientTrainingPDF from './view/ClientTrainingPDF';
import WaitingParcel from './view/WaitingParcel';
import PrintSearch from './view/PrintSearch'
import BulkPrinTenByTen from './view/bulk_print_ten_by_ten';
import NewParcelPrint from './view/newParcelPrint';
import CsvBulkPrint from './view/csv_waybill_upload_bulk_print';
import ExistingWaybill from './view/with_existing_waybill';
import SiteMaintenaneClient from './view/site_maintenane_client';
import WaybillGenerator from './view/waybill_generator';
import WaybillUploadCsv from './view/waybill_upload_csv';
import WaybillUploadHistory from './view/waybill_upload_history';
import CsvWaybillHistryTble from './view/csv_waybill_histry_tble';
import UnusedOrder from './view/unused_order';
import LabelPrint from './view/label_print';
import LabelBulkPrint from './view/label_bulk_print_copy';
import DeliveredReport from './view/delivered_report';
import DeliveredReportLoad from './view/delivered_report_load';
import PendingReturnList from './view/pending_return_list';
import PendingReturnListLoad from './view/pending_return_list_load';
import CompleteReturnList from './view/complete_return_list';
import CompleteReturnListLoad from './view/complete_return_list_load';
import ReturnHandoverConfirmSheet from './view/return_parcel_handover_confirm_sheet';
import ReturnClientConfirmPrint from './view/returnClientConfirmPrint';
import PickupOption from './view/pickup_option';
import AllArchiveParcel from './view/AllArchiveParcel'
// import axios from 'axios';
import Dashboard from './view/dashboard';
import ChangesRequest from './view/changes_request';
import TroubleTicket from './view/trouble_ticket'; 
import ClientApi from './view/client_api'; 
import OngoingInvoice from './view/ongoing_invoice'; 
import PendingPaidInvoice from './view/pending_paid_invoice';
import AllSearch from './view/all_search'
import VendorPayListPrint from './view/vendor_pay_list_print'; 
import PaidInvoice from './view/paid_invoice';
import PaidArchiveInvoice from './view/paid_archive_invoice';
import ClientTwoWayApi from './view/client_two_way_api'; 
import WaitingParcelPrint from './view/WaitinParcelPrint'
import Wa from './view/wp'
import VendorPayListaAchivePrint from './view/vendor_pay_list_archive_print'; 

function App() {

  const navigate = useNavigate()
  const [cityNames, setCityName]          = useState([]);
  const [zoneNames, setZoneName]          = useState([]);
  const [parcelTypeA, setparcelTypeA]     = useState([]); 
  const [cancelReason, setCancelReason]   = useState([]); 
  const [adminInfo, setAdminInfo]         = useState([]);
  // const [allParcelD, setAllParcelD]       = useState([]); 
  const [bankD, setBankD]                 = useState([]); 
  const [branchD, setBranchD]             = useState([]); 
  const [hubMName, setHubMName]           = useState([]); 
  const [clientInfo, setClientInfo]       = useState([]);
  
  function logUserRequest(){
                                  
      fetch(`${process.env.REACT_APP_URL}/index.php/getAllUsers`, {
      method: 'POST',                
      headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      },
      } )
      .then((respose) => {
      if (respose.ok) {
          return respose.json()

      }
      throw new Error('error')
      })
      .then((data) => {
      // console.log(data.Clstatus);
      setAdminInfo(data.Clstatus);
                            
      })

  }
  
  function logClientRequest(){
                                  
      fetch(`${process.env.REACT_APP_URL}/index.php/getAllclients`, {
      method: 'POST',                
      headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      },
      } )
      .then((respose) => {
      if (respose.ok) {
          return respose.json()

      }
      throw new Error('error')
      })
      .then((data) => {
       
      setClientInfo(data.Clstatus);
     
      })

  }

  const clIDN = sessionStorage.getItem('UserID'); 

    useEffect(() => {
      if((sessionStorage.getItem('UserID')) === null || localStorage.getItem('token') === null ) {
      navigate('/');      
      }
      },[true])

    useEffect(() => {
        getCityName();
        getZoneName();
        parcelTypeGet();
        cancelReasonGet();
        logUserRequest();
        logClientRequest();
        // allParcelsRequest();
        BankRequest();
        BranchRequest();
        getHubName();
    },[])
    
    // ####### get city name #########
    async function getCityName() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/geCityName`, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            // console.log(data.Clstatus);
            setCityName(data.Clstatus);
            
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }

    
    
    // function getCityName() {
    //     axios.post(`${process.env.REACT_APP_URL}/index.php/geCityName`)
    //         .then((res) => {
    //             console.log(res.data);
    //             setCityName(res.data.Clstatus);
    //         })
    //         .catch(err => console.log(err));
    // }
    
      // ####### get zone name ##########
    // function getZoneName() {
    //     axios.get(`${process.env.REACT_APP_URL}/index.php/getZoneName`)
    //         .then((res) => {
    //             // console.log(res.data.Clstatus)
    //             setZoneName(res.data.Clstatus);
    //         })
    //         .catch(err => console.log(err));
    // }
    
    async function getZoneName() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getZoneName`, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            // console.log(data.Clstatus);
            setZoneName(data.Clstatus);
            
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    
    async function getHubName() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getHubNameData`, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
          
            setHubMName(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    
    async function parcelTypeGet() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getParcelType`, {
          method: 'POST',          
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {          
            setparcelTypeA(data.Clstatus);
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    
    async function cancelReasonGet() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getCancelReason`, {
          method: 'POST',          
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {          
            setCancelReason(data.Clstatus);
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    
    // async function allParcelsRequest() {
    //   try {
    //       await fetch(`${process.env.REACT_APP_URL}/index.php/getAllParcels`, {
    //       method: 'POST', 
    //       body: JSON.stringify({             
    //         clientID: clIDN,               
    //          }),         
    //       headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //       },
    //     } )
    //       .then((respose) => {
    //         if (respose.ok) {
    //           return respose.json()
    //         }
    //         throw new Error('error')
    //       })
    //       .then((data) => {
    //         setAllParcelD(data.Clstatus);
           
    //       })
    //   } catch (error) {
    //     console.log(error.message)
    //   }
    // }
     
    async function BankRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getBank`, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setBankD(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    
    async function BranchRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getBranches`, {
          method: 'POST', 
          body: JSON.stringify({             
            clientID: clIDN,               
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            // console.log(data.Clstatus)
            setBranchD(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    
  return ( 
    <div className="App">  
    
    {/* {(localStorage.getItem('token')) !== null?
      <TopBar/>
      :
      <></>
    } */}
    {/* {authenticate === true && 
        <TopBar/>
       }   */}
       
      {/* {authenticate === true && 
        <SideBar /> 
      }  */}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/index" element={<Index />} />        
          <Route  path="/register" element={<Register />} />
          <Route  path="/confirm" element={<AccountConfirm />} />
          <Route  path="/reset" element={<ResetPassword />} />
          <Route  path="/client_profile" element={<ClientProfile cityNames={cityNames} bankD={bankD} branchD={branchD} />} />
          <Route  path="/logout" element={<Logout />} />
          <Route  path="/add_new_parcel" element={<AddParcel />} />
          <Route  path="/all_order" element={<AllParcel cityNames={cityNames} zoneNames={zoneNames} parcelTypeA={parcelTypeA} adminInfo={adminInfo} hubMName={hubMName} clientInfo={clientInfo} />} />
          <Route  path="/all_archive_order" element={<AllArchiveParcel cityNames={cityNames} zoneNames={zoneNames} parcelTypeA={parcelTypeA} adminInfo={adminInfo} hubMName={hubMName} clientInfo={clientInfo} />} />
          <Route  path="/all_city_order" element={<CityWiseParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} clientInfo={clientInfo} />} />
          <Route  path="/pickup_order" element={<PickupParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} clientInfo={clientInfo} />} />
          <Route  path="/complete_order" element={<DeliveredParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} clientInfo={clientInfo} />} />
          <Route  path="/dispatched_order" element={<DispatchedParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} clientInfo={clientInfo} />} />
          <Route  path="/exchange_order" element={<ExchangeParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} />} clientInfo={clientInfo} />
          <Route  path="/remove_order" element={<RemoveParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} />} clientInfo={clientInfo} />
          <Route  path="/pending_order" element={<ProcessingParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} clientInfo={clientInfo} />} />
          <Route  path="/rescheduled_order" element={<RescheduledParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} clientInfo={clientInfo} />} />
          <Route  path="/cancel_order" element={<ReturnParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} clientInfo={clientInfo} />} />
          <Route  path="/ClientTrainingPDF/:logId" element={<ClientTrainingPDF />} />         
          <Route  path="/waitingparcel" element={<WaitingParcel cityNames={cityNames} zoneNames={zoneNames} adminInfo={adminInfo} clientInfo={clientInfo} />} />
          <Route  path="/waybill_generator" element={<WaybillGenerator />} />
          <Route  path="/newParcelPrint/:loggedUser/:waybill" element={<NewParcelPrint />} />
          <Route  path="/with_existing_waybill" element={<ExistingWaybill />} />
          <Route  path="/site_maintenane_client" element={<SiteMaintenaneClient />} />
          <Route  path="/waybill_upload_csv" element={<WaybillUploadCsv />} />
          <Route  path="/csvWaybillUploadBulkPrint/:uploadId/:loggedNUser" element={<CsvBulkPrint />} />
          <Route  path="/bulkPrinTenByTen/:updId/:loggedUser" element={<BulkPrinTenByTen />} />
          <Route  path="/waybill_upload_history" element={<WaybillUploadHistory />} />
          <Route  path="/csvWaybillHistryTble/:upId" element={<CsvWaybillHistryTble parcelTypeA={parcelTypeA} zoneNames={zoneNames} />} />
          <Route  path="/unused_order" element={<UnusedOrder parcelTypeA={parcelTypeA} zoneNames={zoneNames} />} />
          <Route  path="/label_print" element={<LabelPrint />} />
          <Route  path="/label_bulk_print_copy/:loggedUser/:pdate/:pFrom/:pTo/:pStatus" element={<LabelBulkPrint />} />
          <Route  path="/delivered_report" element={<DeliveredReport />} />
          <Route  path="/delivered_report_load/:loggedUser/:type/:pFrom/:pTo" element={<DeliveredReportLoad parcelTypeA={parcelTypeA} zoneNames={zoneNames} />} />
          <Route  path="/pending_return_list" element={<PendingReturnList />} />
          <Route  path="/pending_return_list_load/:clientUser/:type" element={<PendingReturnListLoad parcelTypeA={parcelTypeA} zoneNames={zoneNames} cancelReason={cancelReason} />} />
          <Route  path="/complete_return_list" element={<CompleteReturnList />} />
          <Route  path="/complete_return_list_load/:clientid/:type" element={<CompleteReturnListLoad parcelTypeA={parcelTypeA} zoneNames={zoneNames} cancelReason={cancelReason} />} />
          <Route  path="/return_parcel_handover_confirm_sheet" element={<ReturnHandoverConfirmSheet parcelTypeA={parcelTypeA} zoneNames={zoneNames} cancelReason={cancelReason} adminInfo={adminInfo} />} />
          <Route  path="/returnClientConfirmPrint/:rHeaderId/:loggedNUser" element={<ReturnClientConfirmPrint cancelReason={cancelReason} adminInfo={adminInfo} />} />
          <Route  path="/pickup_option" element={<PickupOption />} />
          <Route  path="/dashboard" element={<Dashboard />}/>
          <Route  path="/changes_request" element={<ChangesRequest />}/>
          <Route  path="/trouble_ticket" element={<TroubleTicket />}/>
          <Route  path="/client_api" element={<ClientApi />}/>
          <Route  path="/ongoing_invoice" element={<OngoingInvoice />}/> 
          <Route  path="/pending_paid_invoice" element={<PendingPaidInvoice />}/> 
          <Route  path="/all_search" element={<AllSearch />}/> 
          <Route  path="/parcel-print/:loggeduser" element={<PrintSearch cityNames={cityNames} zoneNames={zoneNames} />} />
          <Route  path="/vendor_pay_list_print/:batchNum/:Client" element={<VendorPayListPrint />} />
          <Route  path="/paid_invoice" element={<PaidInvoice />}/> 
          <Route  path="/paid_archive_invoice" element={<PaidArchiveInvoice />}/> 
          <Route  path="/remove-parcel-print-label/:waybill/:loggedUser" element={<RemoveParcelPrintLabel />} />
          <Route  path="/client_two_way_api" element={<ClientTwoWayApi />}/>
          <Route  path="/wa" element={<Wa cityNames={cityNames} zoneNames={zoneNames} />} />
          <Route  path="/vendor_pay_list_archive_print/:batchNum/:Client" element={<VendorPayListaAchivePrint adminInfo={adminInfo} />} />
         
        </Routes>
        
        {/* <TopBar/> */}
{/* {(localStorage.getItem('loggedUser')) === "userLogged"? */}
      {/* <SideBar/> */}
      {/* :
      <></>
    } */}

    </div>
   
  )
}

export default App
