import { useEffect, useState } from "react";
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import Footer from '../Components/Footer';

export default function OngoingInvoice() {
  document.title = "Logistic Client Portal | Pending Payout";

  const [pendingPyout, setPendingPyout]   = useState('');
  const [spinner, setSpinner] = useState(true);
  const [payClient, setPayClient]         = useState('');
  const [setting, setsetting]             = useState('');
  const [invCount, setInvCount]           = useState(0);
  
  // const [tCodCount, setTCodCount]         = useState(0);

  var totalCOD = 0;
  var totalDelivery = 0;
  var totalcodafterDelivery = 0;
  var codCommisstionParcelTotal = 0;
  var totalCancel = 0;
  var totalDiscount = 0;
  var newPayAmount_before_tax = 0;
  var newPayAmount0 = 0;
  var tax_amount = 0;
  var newPayAmount = 0;


  var countn  = 0;
  var countn2 = 0;
  var countn3 = 0;

  const ClientId = sessionStorage.getItem('UserID')
  
  async function PendingPayoutRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/PendingPayout`, {
        method: 'POST', 
        body: JSON.stringify({             
            ClientId: ClientId           
           }),         
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
            setPendingPyout(data.Clstatus);
            setSpinner(false);           
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {   
    PendingPayoutRequest();    
  }, [])
  
  useEffect(() => {
    async function clRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getClient`, {
          method: 'POST', 
          body: JSON.stringify({             
              clientID: ClientId,
             }),         
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        } )
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {          
            setPayClient(data.Clstatus);
           
          })
      } catch (error) {
        console.log(error.message)
      }
    }
    clRequest();
  }, []);
  
  useEffect(() => {
    async function settingpRequest() {
      try {
          await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
          method: 'POST',          
          headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          },
      } )
          .then((respose) => {
          if (respose.ok) {
              return respose.json()
          }
          throw new Error('error')
          })
          .then((data) => {
          // console.log(data.Clstatus);
          setsetting(data.Clstatus);
          
          })
      } catch (error) {
      console.log(error.message)
      }
    }
    settingpRequest()
    
  }, []);


  return (
    <>
    <div id="layout-wrapper">
      <TopBar/>
      <SideBar/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="page-title-box">
                      <h4 className="font-size-18">Pending Payout</h4>
                    </div>
                </div>                              
              </div>
                                  
              <div className="row">
                <div className="col-xl-12">
                  <div className="card  mt-3">
                    <div className="card-body">                                           
                      <div id="pending_oder_load"> 
                      {
                          (pendingPyout.length !== 0) ? (
                            <>
                            <div className="table-responsive">
                              <table className="table  table-bordered">
                                <thead className="thead-dark">
                                  <tr>
                                      <th>Parcel Info</th>
                                      <th>Delivered Date</th>
                                      <th style={{textAlign: 'right'}}>COD Amount</th> 
                                      <th style={{textAlign: 'right'}}>Delivery Fee</th>
                                      <th style={{textAlign: 'right'}}>COD After Delivery Fee</th>
                                      <th style={{textAlign: 'right'}}>COD Commission
                                      {payClient.cod_commission_limit !== null ? (
                                                <span> ({payClient.cod_commission_limit} LKR Limit)</span>
                                              ) : (
                                                <></>
                                              )
                                      }
                                      </th>
                                      <th  style={{textAlign: 'right'}}>Return Fee                                   
                                      {payClient.returnFee ? (
                                                <span> ({payClient.returnFee}%)</span>
                                              ) : (
                                                <></>
                                              )
                                      }
                                      </th>
                                      <th  style={{textAlign: 'right'}}>Discount
                                      {payClient.discount ? (
                                                <span> ({payClient.discount}%)</span>
                                              ) : (
                                                <></>
                                              )
                                      }                                   
                                      </th>
                                  </tr>
                                </thead>
                                
                                <tbody>
                                {                                                                                         
                                    pendingPyout.map(item=>{
                                    return( 
                                    <>
                                    {
                                    (() => {
                                      if(((item.deliveryStatus == 'Complete') || (item.deliveryStatus == 'Cancel')) && ((item.hand_over_vendor == '2') || (item.hand_over_vendor == '1'))){
                                        if((item.paymentType == 'COD') && (item.deliveryStatus == 'Complete')){
                                          return (
                                            <tr key={item.id}>
                                              <td>{item.id}                              
                                                <br/>{item.paymentType}
                                                <br/>{item.orderID ? (
                                                      <span>Order ID :  {item.orderID}</span>
                                                    ) : (
                                                      <></>
                                                    )
                                                    }
                                              </td>
                                 
                                              <td>{item.deliveredDate}</td>
                                 
                                              <td style={{textAlign: 'right'}}>
                                              {
                                                (() => {
                                                    if(item.deliveryStatus == 'Complete'){
                                                      if(item.CODAmount){
                                                        var newCodAmount = item.CODAmount
                                                      }else{
                                                        var newCodAmount = 0
                                                      }
                                                      // totalCOD = totalCOD+newCodAmount
                                                      
                                                      totalCOD = (parseFloat(newCodAmount) + parseFloat(totalCOD)).toFixed(2);
                                                        return (
                                                            <>
                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmount).toFixed(2))}
                                                            </>                                                                                                                
                                                        )
                                                                                                           
                                                    }else if(item.deliveryStatus == 'Cancel'){
                                                        return (
                                                            <>0.00</>
                                                        )  
                                                    }else{
                                                        return (
                                                          <>0.00</>
                                                        )
                                                    }
                                                })()  
                                              }
                                            </td> 
                                 
                                            <td style={{textAlign: 'right'}}>
                                              {
                                                (() => {
                                                    if(item.deliveryStatus == 'Complete'){
                                                    
                                                    if(item.deliveryFee){
                                                        var newDelFAmount = item.deliveryFee
                                                    }else{
                                                        var newDelFAmount = 0
                                                    }
                                                                                                    
                                                      totalDelivery = (parseFloat(newDelFAmount) + parseFloat(totalDelivery)).toFixed(2);
                                                      //  totalDelivery = item.deliveryFee+totalDelivery; 
                                                        
                                                        return (
                                                            <>
                                                             {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelFAmount).toFixed(2))}
                                                            </>                                                                                                                
                                                        )
                                                                                                           
                                                    }else{
                                                      return (
                                                        <> - </>
                                                    )
                                                    }
                                                })()  
                                              }                                                                               
                                            </td> 
                                 
                                            <td style={{textAlign: 'right'}}>
                                              {
                                                (() => {
                                                    if(item.deliveryStatus == 'Complete'){

                                                      if(item.CODAmount){
                                                        var newCodAmounto = item.CODAmount
                                                      }else{
                                                        var newCodAmounto = 0
                                                      }
                                                      
                                                      if(item.deliveryFee){
                                                        var newDelFAmounto = item.deliveryFee
                                                      }else{
                                                        var newDelFAmounto = 0
                                                      }
                                                      
                                                      var codafterDelivery  = (parseFloat(newCodAmounto) - parseFloat(newDelFAmounto)).toFixed(2);
                                                      totalcodafterDelivery = (parseFloat(codafterDelivery) + parseFloat(totalcodafterDelivery)).toFixed(2);
                                                    
                                                        return (
                                                            <>
                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codafterDelivery).toFixed(2))}
                                                            </>                                                                                                                
                                                        )
                                                                                                           
                                                    }else{
                                                      return (
                                                        <> - </>
                                                    )
                                                    }
                                                })()  
                                              } 
                                            </td> 
         
                                 
                                            <td style={{textAlign: 'right'}}> 

                                              {
                                                (() => {
                                                        if(item.CODAmount){
                                                          var newCodAmountS = item.CODAmount
                                                        }else{
                                                          var newCodAmountS = 0
                                                        }
                                                        
                                                        if(item.deliveryFee){
                                                            var newDelFAmountS = item.deliveryFee
                                                        }else{
                                                            var newDelFAmountS = 0
                                                        }
                                                                                                    
                                                        if(payClient.cod_commission_limit){
                                                            var newcomLimitF = payClient.cod_commission_limit
                                                        }else{
                                                            var newcomLimitF = 0
                                                        }                                                        


                                                      var codafterDeliveryCOD = (parseFloat(newCodAmountS) - parseFloat(newDelFAmountS)).toFixed(2);
                                                      
                                                      if(codafterDeliveryCOD<=payClient.cod_commission_limit){
                                                      
                                                          if(payClient.cod_commission_limit_min){
                                                                var newcomLimitminF = payClient.cod_commission_limit_min
                                                          }else{
                                                                var newcomLimitminF = 0
                                                          }
                                                                                                    
                                                        // var codCommisstionParcel = codafterDeliveryCOD*newcomLimitminF/100;
                                                        var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitminF))/100).toFixed(2);
                                                        codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                        
                                                        // codCommisstionParcelTotal = codCommisstionParcel+codCommisstionParcelTotal
                                                        return (
                                                            <>
                                                            {payClient.cod_commission_limit_min !== null ? (
                                                                <span>{parseFloat(codCommisstionParcel).toFixed(2)}<br/>{payClient.cod_commission_limit_min} % Below</span>
                                                              ) : (
                                                                <> - </>
                                                              )
                                                            }
                                                            </>                                                                                                                
                                                        )
                                                      }else{
                                                      
                                                        if(payClient.cod_commission_limit_max){
                                                          var newcomLimitmaxF = payClient.cod_commission_limit_max
                                                        }else{
                                                          var newcomLimitmaxF = 0
                                                        }
                                                        
                                                        var codCommisstionParcel  = ((parseFloat(codafterDeliveryCOD) * parseFloat(newcomLimitmaxF))/100).toFixed(2);  
                                                        codCommisstionParcelTotal = (parseFloat(codCommisstionParcel) + parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                       
                                                        // var codCommisstionParcel = codafterDeliveryCOD*newcomLimitmaxF/100;
                                                        // codCommisstionParcelTotal = codCommisstionParcel+codCommisstionParcelTotal
                                                        return (
                                                          <>
                                                          {payClient.cod_commission_limit_max !== null ? (
                                                              <span>{parseFloat(codCommisstionParcel).toFixed(2)}<br/>{payClient.cod_commission_limit_max} % Above</span>
                                                            ) : (
                                                              <> - </>
                                                            )
                                                          }
                                                          </>                                                                                                                
                                                        )

                                                      }
                                                      
                                                                                                           
                                                   
                                                    
                                                })()  
                                              }                                               
                                            </td>
                                 
                                 
                                            <td style={{textAlign: 'right'}}>
                                            {
                                                (() => {
                                                      if(item.deliveryStatus == 'Cancel'){
                                                      
                                                      if(item.deliveryFee){
                                                          var newDelAmountCaF = item.deliveryFee
                                                      }else{
                                                          var newDelAmountCaF = 0
                                                      }

                                                      if(payClient.returnFee){
                                                          var newCancelFeeF = payClient.returnFee
                                                      }else{
                                                          var newCancelFeeF = 0
                                                      }
                                                        
                                                      var cancelFeeNew  = ((parseFloat(newDelAmountCaF) * parseFloat(newCancelFeeF))/100).toFixed(2);  
                                                      totalCancel       = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
                                                      // var cancelFeeNew  = newDelAmountCaF*newCancelFeeF/100 
                                                      
                                                      // totalCancel = cancelFeeNew+totalCancel
                                                        return (
                                                            <>
                                                            {parseFloat(cancelFeeNew).toFixed(2)}
                                                            </>                                                                                                                
                                                        )
                                                                                                           
                                                    }else{
                                                      return (
                                                        <> - </>
                                                    )
                                                    }
                                                })()  
                                          }                                     
                                          </td>
                        
                                          <td style={{textAlign: 'right'}}>
                                          {/* {() => setInvCount(invCount + 1)} */}
                                          {
                                            
                                                (() => {
                                                  // setInvCount(invCount+1)
                                                      // var count1 = count1+1
                                                      // var count1 = invCount+1
                                                      // setInvCount(count1)
                                                      // setInvCount(invCount + 1)
                                                      countn = countn+1
                                                      // countn2 = countn
                                                      // setInvCount(countn)
                                                      if(item.deliveryStatus == 'Complete'){
                                                      
                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountDisF = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountDisF = 0
                                                                                                        }

                                                                                                        if(payClient.discount){
                                                                                                            var newdiscFeeF = payClient.discount
                                                                                                        }else{
                                                                                                            var newdiscFeeF = 0
                                                                                                        }
                                                      
                                                      var discountedAmount  = ((parseFloat(newDelAmountDisF)/100) * parseFloat(newdiscFeeF)).toFixed(2); 
                                                      totalDiscount       = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);                                                  
                                                      // var discountedAmount = newDelAmountDisF/100*newdiscFeeF 
                                                      // totalDiscount = discountedAmount+totalDiscount
                                            
                                                      return (
                                                            <>
                                                            {parseFloat(discountedAmount).toFixed(2)}
                                                            </>                                                                                                                
                                                        )
                                                                                                           
                                                    }else if(item.deliveryStatus == 'Cancel'){
                                                    
                                                                                                        if(item.deliveryFee){
                                                                                                            var newDelAmountDisOF = item.deliveryFee
                                                                                                        }else{
                                                                                                            var newDelAmountDisOF = 0
                                                                                                        }

                                                                                                        if(payClient.discount){
                                                                                                            var newdiscFeeOF = payClient.discount
                                                                                                        }else{
                                                                                                            var newdiscFeeOF = 0
                                                                                                        }

                                                                                                        if(payClient.returnFee){
                                                                                                            var newCancelFeeOF = payClient.returnFee
                                                                                                        }else{
                                                                                                            var newCancelFeeOF = 0
                                                                                                        }

                                                      var cancelFeeNew      = ((parseFloat(newDelAmountDisOF) * parseFloat(newCancelFeeOF))/100).toFixed(2);  
                                                      // var cancelFeeNew = newDelAmountDisOF*newCancelFeeOF/100 
                                                      var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOF)).toFixed(2); 
                                                      // var discountedAmount = cancelFeeNew/100*newdiscFeeOF 
                                                      totalDiscount         = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2); 
                                                      // totalDiscount = discountedAmount+totalDiscount
                                                      return (
                                                        <>{parseFloat(discountedAmount).toFixed(2)}</>
                                                    )
                                                    }else{
                                                      
                                                      return (
                                                      <>0.00</>
                                                      )
                                                    }
                                                })()  
                                          }
                                          
                                          </td>                                                               
                                        </tr>                                                                                                               
                                          )
                                        }else if((item.deliveryStatus == 'Cancel') && (item.paymentType == 'COD')){
                                          return (
                                            <tr key={item.id}>
                                              <td>{item.id}<br/>{item.paymentType}</td>
                                 
                                              <td>{item.deliveredDate}</td> 
                                
                                              <td style={{textAlign: 'right'}}>
                                              {
                                                (() => {
                                                    if(item.deliveryStatus == 'Complete'){
                                                      if(item.CODAmount){
                                                        var newCodAmountTh = item.CODAmount
                                                      }else{
                                                        var newCodAmountTh = 0
                                                      }
                                                      
                                                      totalCOD = (parseFloat(newCodAmountTh) + parseFloat(totalCOD)).toFixed(2);
                                                      // totalCOD = newCodAmountTh+totalCOD; 
                                                      
                                                        return (
                                                            <>
                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountTh).toFixed(2))}
                                                            </>                                                                                                                
                                                        )
                                                                                                           
                                                    }else if(item.deliveryStatus == 'Cancel'){
                                                        return (
                                                            <>0.00</>
                                                        )  
                                                    }else{
                                                      return (
                                                        <>0.00</>
                                                    )
                                                    }
                                                })()  
                                              }  
                                                
                                              </td> 
                                
                                              <td style={{textAlign: 'right'}}>
                                              {
                                                (() => {
                                                    if(item.deliveryStatus == 'Complete'){
                                                    
                                                                                                    if(item.deliveryFee){
                                                                                                        var newFDelAmountTh = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newFDelAmountTh = 0
                                                                                                    }
                                                                                                    
                                                      totalDelivery = (parseFloat(newFDelAmountTh) + parseFloat(totalDelivery)).toFixed(2);
                                                      // totalDelivery = item.deliveryFee+totalDelivery; 
                                                        return (
                                                            <>
                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newFDelAmountTh).toFixed(2))}
                                                            </>                                                                                                                
                                                        )
                                                                                                           
                                                    }else{
                                                      return (
                                                        <> - </>
                                                    )
                                                    }
                                                })()  
                                              }  
                                                
                                              </td> 
         
                                              <td style={{textAlign: 'right'}}>
                                              -
                                              </td>
                                
                                              <td style={{textAlign: 'right'}}>
                                                -  
                                              </td>
                                 
                                              <td style={{textAlign: 'right'}}>
                                              {
                                                (() => {
                                                      if(item.deliveryStatus == 'Cancel'){
                                                      
                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountCaThF = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountCaThF = 0
                                                                                                    }

                                                                                                    if(payClient.returnFee){
                                                                                                        var newCancelFeeThF = payClient.returnFee
                                                                                                    }else{
                                                                                                        var newCancelFeeThF = 0
                                                                                                    }
                                                      
                                                      var cancelFeeNew  = ((parseFloat(newDelAmountCaThF) * parseFloat(newCancelFeeThF))/100).toFixed(2);  
                                                      totalCancel       = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
                                                      // var cancelFeeNew = newDelAmountCaThF*newCancelFeeThF/100 
                                                      // totalCancel = cancelFeeNew+totalCancel
                                                        return (
                                                            <>
                                                            {parseFloat(cancelFeeNew).toFixed(2)}
                                                            </>                                                                                                                
                                                        )
                                                                                                           
                                                    }else{
                                                      return (
                                                        <> - </>
                                                    )
                                                    }
                                                })()  
                                              }
                                                    
                                              </td>
                                 
                          
                                              <td style={{textAlign: 'right'}}>
                                              {
                                                //  setInvCount(invCount+1)
                                                (() => {
                                                  // setInvCount(invCount+1)
                                                  countn = countn+1
                                                  //  countn2 = countn2+1
                                                  //  countn3 = countn2
                                                      if(item.deliveryStatus == 'Complete'){
                                                      
                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisThF = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisThF = 0
                                                                                                    }

                                                                                                    if(payClient.discount){
                                                                                                        var newdiscFeeThF = payClient.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeThF = 0
                                                                                                    }
                                                                                                    
                                                      var discountedAmount  = ((parseFloat(newDelAmountDisThF)/100) * parseFloat(newdiscFeeThF)).toFixed(2);  
                                                      totalDiscount       = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2);    
                                                      // var discountedAmount = newDelAmountDisThF/100*newdiscFeeThF 
                                                      // totalDiscount = discountedAmount+totalDiscount
                                                      return (
                                                            <>
                                                            {parseFloat(discountedAmount).toFixed(2)}
                                                            </>                                                                                                                
                                                        )
                                                                                                           
                                                    }else if(item.deliveryStatus == 'Cancel'){
                                                    
                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisOSF = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisOSF = 0
                                                                                                    }

                                                                                                    if(payClient.discount){
                                                                                                        var newdiscFeeOSF = payClient.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeOSF = 0
                                                                                                    }

                                                                                                    if(payClient.returnFee){
                                                                                                        var newCancelFeeOSF = payClient.returnFee
                                                                                                    }else{
                                                                                                        var newCancelFeeOSF = 0
                                                                                                    }
                                                                                                    
                                                      var cancelFeeNew      = ((parseFloat(newDelAmountDisOSF) * parseFloat(newCancelFeeOSF))/100).toFixed(2); 
                                                      var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOSF)).toFixed(2); 
                                                      totalDiscount         = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2); 
                                                      // var cancelFeeNew     = newDelAmountDisOSF*newCancelFeeOSF/100 
                                                      // var discountedAmount = cancelFeeNew/100*newdiscFeeOSF 
                                                      
                                                      // totalDiscount = discountedAmount+totalDiscount
                                                      return (
                                                        <>{parseFloat(discountedAmount).toFixed(2)}</>
                                                    )
                                                    }else{
                                                      return (
                                                      <>0.00</>
                                                      )
                                                    }
                                                })()  
                                              }
                                            
                                          </td>
                                        </tr>                                                                                                                
                                          )
                                        }else if(item.paymentType != 'COD'){
                                          return (
                                            <tr key={item.id}>
                                              <td>{item.id}<br/>{item.paymentType}</td>
                                 
                                              <td>{item.deliveredDate}</td> 
                   
                                              <td style={{textAlign: 'right'}}>
                                                {
                                                  (() => {
                                                      if(item.deliveryStatus == 'Complete'){
                                                        if(item.CODAmount){
                                                          var newCodAmountF = item.CODAmount
                                                        }else{
                                                          var newCodAmountF = 0
                                                        }
                                                        
                                                        totalCOD = (parseFloat(newCodAmountF) + parseFloat(totalCOD)).toFixed(2);
                                                       
                                                          return (
                                                              <>
                                                              {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newCodAmountF).toFixed(2))}
                                                              </>                                                                                                                
                                                          )
                                                                                                              
                                                      }else if(item.deliveryStatus == 'Cancel'){
                                                          return (
                                                              <>0.00</>
                                                          )  
                                                      }else{
                                                        return (
                                                          <>0.00</>
                                                      )
                                                      }
                                                  })()  
                                                }  
                                              </td> 
                   
                                              <td style={{textAlign: 'right'}}>
                                              {
                                                (() => {
                                                    if(item.deliveryStatus == 'Complete'){
                                                    
                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountFiF = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountFiF = 0
                                                                                                    }
                                                      
                                                      totalDelivery = (parseFloat(newDelAmountFiF) + parseFloat(totalDelivery)).toFixed(2);
                                                      
                                                        return (
                                                            <>
                                                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newDelAmountFiF).toFixed(2))}
                                                            </>                                                                                                                
                                                        )
                                                                                                            
                                                    }else{
                                                      return (
                                                        <> - </>
                                                    )
                                                    }
                                                })()  
                                              }  
                                                
                                              </td> 

                                              <td style={{textAlign: 'right'}}>
                                              -
                                              </td>
                                
                                              <td style={{textAlign: 'right'}}>
                                                -  
                                              </td>
                    
                                              <td style={{textAlign: 'right'}}>
                                              {
                                                (() => {
                                                      if(item.deliveryStatus == 'Cancel'){
                                                      
                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountCaSixF = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountCaSixF = 0
                                                                                                    }

                                                                                                    if(payClient.returnFee){
                                                                                                        var newCancelFeeSixF = payClient.returnFee
                                                                                                    }else{
                                                                                                        var newCancelFeeSixF = 0
                                                                                                    }
                                                      
                                                      var cancelFeeNew  = ((parseFloat(newDelAmountCaSixF) * parseFloat(newCancelFeeSixF))/100).toFixed(2); 
                                                      totalCancel       = (parseFloat(cancelFeeNew) + parseFloat(totalCancel)).toFixed(2);
                                                      // var cancelFeeNew = newDelAmountCaSixF*newCancelFeeSixF/100 
                                                      // totalCancel = cancelFeeNew+totalCancel
                                                        return (
                                                            <>
                                                            {parseFloat(cancelFeeNew).toFixed(2)}
                                                            </>                                                                                                                
                                                        )
                                                                                                            
                                                    }else{
                                                      return (
                                                        <> - </>
                                                    )
                                                    }
                                                })()  
                                              }
                                                    
                                              </td>
                    
             
                                              <td style={{textAlign: 'right'}}>
                                              {
                                                //  setInvCount(invCount+1)
                                                (() => {
                                                  countn = countn+1
                                                  //  countn3 = countn3+1
                                                  
                                                      if(item.deliveryStatus == 'Complete'){
                                                      
                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisEF = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisEF = 0
                                                                                                    }

                                                                                                    if(payClient.discount){
                                                                                                        var newdiscFeeEF = payClient.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeEF = 0
                                                                                                    }
                                                      
                                                      var discountedAmount  = ((parseFloat(newDelAmountDisEF)/100) * parseFloat(newdiscFeeEF)).toFixed(2);  
                                                      totalDiscount         = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2); 
                                                      // var discountedAmount = newDelAmountDisEF/100*newdiscFeeEF 
                                                      // totalDiscount = discountedAmount+totalDiscount
                                                      return (
                                                            <>
                                                            {parseFloat(discountedAmount).toFixed(2)}
                                                            </>                                                                                                                
                                                        )
                                                                                                            
                                                    }else if(item.deliveryStatus == 'Cancel'){
                                                    
                                                                                                    if(item.deliveryFee){
                                                                                                        var newDelAmountDisOEF = item.deliveryFee
                                                                                                    }else{
                                                                                                        var newDelAmountDisOEF = 0
                                                                                                    }

                                                                                                    if(payClient.discount){
                                                                                                        var newdiscFeeOEF = payClient.discount
                                                                                                    }else{
                                                                                                        var newdiscFeeOEF = 0
                                                                                                    }

                                                                                                    if(payClient.returnFee){
                                                                                                        var newCancelFeeOEF = payClient.returnFee
                                                                                                    }else{
                                                                                                        var newCancelFeeOEF = 0
                                                                                                    }
                                                                                                    
                                                      var cancelFeeNew      = ((parseFloat(newDelAmountDisOEF) * parseFloat(newCancelFeeOEF))/100).toFixed(2); 
                                                      var discountedAmount  = ((parseFloat(cancelFeeNew)/100) * parseFloat(newdiscFeeOEF)).toFixed(2); 
                                                      totalDiscount         = (parseFloat(discountedAmount) + parseFloat(totalDiscount)).toFixed(2); 
                                                      // var cancelFeeNew     = newDelAmountDisOEF*newCancelFeeOEF/100 
                                                      // var discountedAmount = cancelFeeNew/100*newdiscFeeOEF 
                                                      
                                                      // totalDiscount = discountedAmount+totalDiscount
                                                      return (
                                                        <>{parseFloat(discountedAmount).toFixed(2)}</>
                                                    )
                                                    }else{
                                                      return (
                                                      <>0.00</>
                                                      )
                                                    }
                                                })()  
                                              }
                                          {
                                            
                                           
                                          }  
                                          </td>
                                            </tr>                                                                                                               
                                          )
                                        }
                                                                                                                                                    
                                      }

                                    })()  
                                    }
                                    {/* {
                                    var count = count1+count2+count3
                                    }   */}
                                    </>
                                    )
                                    })
                                } 

                                <tr style={{fontWeight: '500'}}>
                                    <td style={{border: '1px solid #ffffff'}}></td>
                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>Total</td>
                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCOD).toFixed(2))}</td> 
                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalDelivery).toFixed(2))}</td>
                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalcodafterDelivery).toFixed(2))}</td>
                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(codCommisstionParcelTotal).toFixed(2))} </td>
                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalCancel).toFixed(2))}
                                    {
                                                (() => {
                                               
  
                                                
                                                  var sumReturnNDelivFee      = (parseFloat(totalDelivery) + parseFloat(totalCancel)).toFixed(2);
                                                  var finalsumReturnNDelivFee = (parseFloat(sumReturnNDelivFee) - parseFloat(totalDiscount)).toFixed(2);
                                                
                                                  // var sumReturnNDelivFee      = totalDelivery+totalCancel;
                                                  // var finalsumReturnNDelivFee = sumReturnNDelivFee-totalDiscount;
                                                  
                                                  // newPayAmount_before_tax = totalCOD-finalsumReturnNDelivFee-codCommisstionParcelTotal;  
                                                  newPayAmount_before_tax     = (parseFloat(totalCOD) - parseFloat(finalsumReturnNDelivFee)-parseFloat(codCommisstionParcelTotal)).toFixed(2);
                                                 
                                                  
                                                  if(payClient.tax === 1){
                                                    
                                                  var tax_based_amount     = (parseFloat(totalDelivery) + parseFloat(totalCancel) + parseFloat(codCommisstionParcelTotal) - parseFloat(totalDiscount)).toFixed(2);  
                                                    
                                                  // var tax_based_amount = (totalDelivery+totalCancel+codCommisstionParcelTotal)-totalDiscount;
                                                  
                                                   tax_amount       = ((parseFloat(tax_based_amount)*parseFloat(setting.tax_value))/100).toFixed(2);
                                                    
                                                   newPayAmount0    = (parseFloat(newPayAmount_before_tax) - parseFloat(tax_amount)).toFixed(2);
                                                   
                                                        
                                                  }else{
                                                   newPayAmount0    = newPayAmount_before_tax; 
                                                  }
                                                  
                                                  if(payClient.creaditBalance != '0'){
                                                  
                                                       newPayAmount      = (parseFloat(newPayAmount0) + parseFloat(payClient.creaditBalance)).toFixed(2);
                                                       
                                                  }else{
                                                       newPayAmount = newPayAmount0;
                                                  }
                                                    
                                                })()  
                                              }
                                    
                                    </td>
                                    <td style={{textAlign: 'right'}}> {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(totalDiscount).toFixed(2))}</td>
                                </tr>
                                <tr style={{fontWeight: '800'}}>
                                  <td style={{border: '1px solid #ffffff'}}></td>
                                  <td style={{border: '1px solid #ffffff'}}></td>
                                  <td style={{border: '1px solid #ffffff'}}></td>
                                  <td style={{border: '1px solid #ffffff'}}></td>
                                  <td style={{border: '1px solid #ffffff'}}></td>
                                  <td style={{border: '1px solid #ffffff'}}></td>
                                  <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                  {
                                                (() => {
                                                
                                                  if(newPayAmount0< 0){
                                                    return(<>Sub Due Amount</>)
                                                      
                                                  }else{
                                                    return(<>Sub Pay Amount</>)
                                                  }
                                                    
                                                })()  
                                              }
                                   
                                  </td>
                                  
                                    <td style={{textAlign: 'right'}}> {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newPayAmount_before_tax).toFixed(2))}</td>

                                </tr> 

                                {
                                                (() => {
                                                
                                                  if(payClient.creaditBalance == '0'){
                                                    var TotalCredit =  payClient.creaditBalance
                                                    return(
                                                    <tr style={{fontWeight: '800'}}>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                      Credit Balance
                                                      </td>
                                                      <td style={{textAlign: 'right'}}>
                                                      {
                                                        (() => {
                                                          if(TotalCredit == '0'){
                                                            return(
                                                            <>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(TotalCredit).toFixed(2))}</>
                                                            
                                                            )
                                                        }else{
                                                          return(<>- {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(TotalCredit).toFixed(2))}</>)
                                                        }
                                                        })()  
                                                      }

                                                      
                                                      </td>
                                                    </tr>
                                                 )
                                                      
                                                  }else{
                                                    return(
                                                    <tr style={{fontWeight: '800'}}>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                      Credit Balance
                                                      </td>
                                                    <td style={{textAlign: 'right'}}>
                                                    {Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(payClient.creaditBalance).toFixed(2))}
                                                     
                                                      </td>
                    
                                                  </tr>
                                                  
                                                  )
                                                  }
                                                    
                                                })()  
                                              }

                                              {
                                                (() => {
                                                
                                                  if(payClient.tax == 1){
                                                    
                                                    return(
                                                    <tr style={{fontWeight: '800'}}>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                      Tax Amount
                                                      </td>
                                                      <td style={{textAlign: 'right'}}>
                                                      {parseFloat(tax_amount).toFixed(2)}                                                     
                                                      </td>
                                                    </tr>
                                                 )
                                                      
                                                  }                                                  
                                                })()  
                                              }

                                              {
                                                (() => {
                                                
                                                  if(payClient.creaditBalance == '0'){
                                                    
                                                    return(
                                                    <tr style={{fontWeight: '800'}}>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{border: '1px solid #ffffff'}}></td>
                                                      <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                      {
                                                        (() => {
                                                          if(newPayAmount < 0){
                                                            return(
                                                              <>Due Amount</>
                                                            )
                                                          }else{
                                                            return(
                                                              <>Pay Amount</>
                                                            )
                                                          }
                                                        })()  
                                                      }
                                                      </td>
                                                      <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newPayAmount).toFixed(2))}</td>
                                                    </tr>
                                                 )
                                                      
                                                  }else{
                                                    return(
                                                    <tr style={{fontWeight: '800'}}>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{border: '1px solid #ffffff'}}></td>
                                                    <td style={{textAlign: 'right',border: '1px solid #ffffff',borderRight: '1px solid #e9ecef !important'}}>
                                                    {
                                                        (() => {
                                                          if(newPayAmount < 0){
                                                            return(
                                                              <>Due Amount</>
                                                            )
                                                          }else{
                                                            return(
                                                              <>Pay Amount</>
                                                            )
                                                          }
                                                        })()  
                                                    }
                                                    </td>
                                                    <td style={{textAlign: 'right'}}>{Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(parseFloat(newPayAmount).toFixed(2))}</td>
                    
                                                  </tr>
                                                  
                                                  )
                                                  }
                                                    
                                                })()  
                                              }
                              
                                </tbody>
                              </table> 
                             
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <p>Invoice Count : {countn}</p>
                              </div>
                              <div className="col-lg-6"></div>
                  
                              <div className="col-lg-12">
                              <hr/>
                     
                              <p>Figures : <p style={{fontWeight: '500'}}>Sub Pay Amount = Total COD Amount - (Total Return Fee + Total Delivery Fee + Total COD Commission - Total Discount)</p></p>
  
                              <p style={{fontWeight: '500'}}>COD Commission = (COD Amount - Delivery Fee) * COD Commission Fee(%)</p>
                                              {
                                                (() => {
                                                
                                                  if(payClient.tax == 1){
                                                    
                                                    return(
                                                      <p style={{fontWeight: '500'}}>Tax Amount = ((Total Return Fee + Total Delivery Fee + Total COD Commission)-Total Discount) * Tax Value (%)</p>
                                                    )
                                                      
                                                  }                                                  
                                                })()  
                                              }

                                              {
                                                (() => {
                                                
                                                  if(payClient.tax == 1){
                                                    
                                                    return(
                                                      <p style={{fontWeight: '500'}}>Pay Amount = Sub Pay Amount - Credit Balance - Tax Amount</p>
                                                    )
                                                      
                                                  }else{
                                                    return(
                                                      <p style={{fontWeight: '500'}}>Pay Amount = Sub Pay Amount - Credit Balance</p>
                                                    ) 
                                                  }                                                  
                                                })()  
                                              }                                
                      </div>
                    </div>

                    </>



                          ): (
                            spinner ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                              <div className="card bg-danger">
                                  <div className="card-body">
                                      <h5 className="text-center text-white">No Records</h5> 
                                  </div> 
                              </div>
                          )
                      }       
                      </div>                      
                    </div>
                  </div>
                </div> 
              </div>

            </div>
          </div>
          <footer className="footer">
            <Footer/>
          </footer>
        </div>
    </div>
    </>
  )
}
