import React, { useState, useEffect}  from 'react';
import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';
import index_dashboard from "../images/index_dashboard.png";
import Footer from '../Components/Footer';

export default function Index() {

  const [wSetting, setWSetting] = useState('');
  
  async function settingWRequest() {
    try {
        await fetch(`${process.env.REACT_APP_URL}/index.php/getSetting`, {
        method: 'POST',          
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      } )
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then((data) => {
          console.log(data.Clstatus);
          setWSetting(data.Clstatus);
         
        })
    } catch (error) {
      console.log(error.message)
    }
  }
   
  useEffect(() => {
    settingWRequest()
  }, []);
  
  

    document.title = "Logistic Client Portal | Welcome";
    return (

      <div>
        <div id="layout-wrapper">
          <TopBar/>
          <SideBar/>
            <div className="main-content">
              <div className="page-content">
                <div className="container-fluid">
                  {wSetting.client_announce_publish === "Active" && wSetting.client_announce_header !== null && wSetting.client_announce_header !== "" && wSetting.client_announce_body !==null && wSetting.client_announce_body !=="" &&
                  <div className="row">
                        <div className="col-xl-12 col-md-3 col-sm-12 mb-4" style={{marginTop: '20px'}}>

                          <div className="alert alert-warning" role="alert">
                            <h4 className="alert-heading">{wSetting.client_announce_header}
                            
                           {
                            
                           }
                           
                            </h4> 
                            <hr/>
                            <p className="mb-0">{wSetting.client_announce_body}</p>
                          </div>

                        </div>
                  </div>
                  }
                  
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 mt-5 text-center">  
                      <img src={index_dashboard} alt="" style={{width:'75%'}} /> 
                    </div> 
                  </div>
                </div>
              </div>
              <footer className="footer">
                    <Footer/>
              </footer>
            </div>
        </div>
      </div>
    )

// }else{
    
//     (localStorage.setItem('loggedUser', "userNotLogged"));
//     <></> 
// }




 
}
