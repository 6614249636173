import { useEffect, useState } from "react";
import axios from "axios";

function CityZone(props) {

    //const [parcelType, setParcelType] = useState([]);

    // useEffect(() => {
    //     getParcelType();
    // },[])

    // function getParcelType() {
    //     axios.get('http://localhost/hs-login-system-master/parcel_type.php')
    //         .then(res => {
    //             setParcelType(res.data);
    //         })
    //         .catch(err => console.log(err))
    // }

    return(
        <div className="col-3 col-md-3 col-lg-3 form-group">
            <label Htmlfor="cityzone">City Zone :</label>
            <select onChange={props.func} name="cityzone" id="cityzone" className="form-control">
                <option value="">Select City Zone</option>
                {
                    props.cityzone.map((item) => {
                        return(
                            <option value={item.cid}>{item.cname}</option>
                        )
                    })
                }
            </select>
        </div>
    )
}

export default CityZone;