 

 import Multiselect from 'multiselect-react-dropdown';
 
 
 
 function Status({setStatusArray}) {
    
    return(
        <>
        <div className="form-group col-6 col-md-6 col-lg-6">
            <label Htmlfor="type">Status :</label>


            <Multiselect
                displayValue="key"
                onKeyPressFn={function noRefCheck(){}}
                onRemove={(event) => {
                    const status = [];
                    event.forEach(item => {
                        status.push(item.val);
                    });
                    setStatusArray(status);
                    
                }}
                onSearch={function noRefCheck(){}}
                onSelect={(event) => {
                    const status = [];
                    event.forEach(item => {
                        status.push(item.val);
                    });
                    setStatusArray(status);
                    
                }}
                options={[
                      {
                        val: 'Waiting',
                        key: 'Waiting'
                      },
                      {
                        val: 'Pickup',
                        key: 'Pickup'
                      },
                      {
                        val: 'Pending',
                        key: 'Processing'
                      },
                      {
                        val: 'Dispatched',
                        key: 'Dispatched'
                      },
                      {
                        val: 'Complete',
                        key: 'Delivered'
                      },
                      {
                        val: 'Cancel',
                        key: 'Return'
                      },
                      {
                        val: 'Delete',
                        key: 'Remove'
                      },
                      {
                        val: 'Reschedule',
                        key: 'Reschedule'
                      }
                ]}
            />

        </div>
        
        </>
    )
 }

 export default Status;