import { useState, useEffect, useContext } from "react";
import {Link} from "react-router-dom";
import axios from "axios";

import TopBar from '../Components/TopBar';
import SideBar from '../Components/SideBar';

import Waybill from '../Components/search-fields/Waybill';
import Search from '../Components/search-fields/Search';
import ParcelType from '../Components/search-fields/ParcelType';
import FromDate from '../Components/search-fields/FromDate';
import ToDate from '../Components/search-fields/ToDate';
import PaymentType from '../Components/search-fields/PaymentType';
import Status from "../Components/search-fields/Status";
import OrderId from "../Components/search-fields/OrderId";
import TableBody from "../Components/TableBody";
//import Pagination from "../Components/Pagination";
import Pagination from "./Pagination";

import ModalView from "./ModalView";
import ModalA from "./ModalA";
import { handleExport } from "./printExportAllParcel";
import TableHead from "../Components/TableHead";

import ModB from "./ModB";





if((sessionStorage.getItem('UserID')) !== null){   
    
    (localStorage.setItem('loggedUser', "userLogged"))

}else{
    
    (localStorage.setItem('loggedUser', "userNotLogged")) 
}

const user = sessionStorage.getItem('UserID');

function AllParcel({cityNames, zoneNames, parcelTypeA, adminInfo, hubMName, clientInfo}) {

    if((sessionStorage.getItem('UserID')) !== null){   
    
        (localStorage.setItem('loggedUser', "userLogged"))
    
    }else{
        
        (localStorage.setItem('loggedUser', "userNotLogged")) 
    }

    const ClientId = sessionStorage.getItem('UserID');
    const [modalIsOpen, setModalIsOpen]       = useState(false);

    const [Data, setData] = useState([]);
    const [count, setCount] = useState([]);
    const [spinner, setSpinner] = useState(true);
    
    const [isSBtnInactive, setIsSBtnInactive]    = useState(false);
    const [isExBtnInactive, setIsExBtnInactive]  = useState(false);
    
    // const [isExAllow, setIsExAllow]  = useState(false);
    
    // const [exDivRes, setExDivRes]	             = useState('Search & Export');

    useEffect(() => {
        //handleSearch();
        getData();
        
    }, [])
    
    
    
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = Data.slice(firstIndex, lastIndex);
    

    const[display, setDisplay] = useState('none');
    const[waybillID, setwaybill] = useState('');
    const[margin, setMargin] = useState(0);

    function changeDisplay(val) {
        setDisplay(val);
    }

    
    const [val, setVal] = useState({
        waybill_id:"",
        search:"",
        parcelType:"",
        fdate:"",
        tdate:"",
        paymentType:"",
        order_id : "",
        status:[]
    });

    function setStatusArray(value) {
        const key = 'status'
        setVal({...val, [key]:value})
    }

    function handleChange(e) {
        const inputValue = e.target.value;
        const inputName = e.target.name;
        

        setVal({...val, [inputName]:inputValue});
        
    }
    

    

    async function handleSearch() {
        // axios.post(`${process.env.REACT_APP_URL}/allparcel.php`, {...val, clientID : user})
        //     .then((res) => {
                
        //         setData(res.data);
        //         setVal({
        //             waybill_id:"",
        //             search:"",
        //             parcelType:val.parcelType,
        //             fdate:val.fdate,
        //             tdate:val.tdate,
        //             paymentType:val.paymentType,
        //             orderID:"",
        //             status:val.status
        //         })
        //         setCurrentPage(1);
        //     })
        //     .catch(err => console.log(err));


        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/getAllParcels`, {
            method: 'POST', 
            body: JSON.stringify({
                clientId: ClientId,  
                waybill_id: val.waybill_id,
                search: val.search,
                parcelType: val.parcelType,
                fdate: val.fdate,
                tdate: val.tdate,
                paymentType: val.paymentType, 
                order_id: val.order_id,                    
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {            
            setData(data.Clstatus);
            setVal({waybill_id: '', search: ''});
            setCurrentPage(1);
            
            })
        } catch (error) {
        console.log(error.message)
        }
    }


    async function getData() {
        setCount({});
        
        setIsSBtnInactive(true);
        
        try {
            await fetch(`${process.env.REACT_APP_URL}/index.php/allParcels`, {
            method: 'POST', 
            body: JSON.stringify({
                clientID: ClientId, 
                waybill_id: val.waybill_id,
                search: val.search,
                paymentType: val.paymentType,
                parcelType: val.parcelType,
                order_id: val.order_id,
                fdate: val.fdate,
                tdate: val.tdate,
                status: val.status                                
            }),         
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then((respose) => {
            if (respose.ok) {
                return respose.json()
            }
            throw new Error('error')
            })
            .then((data) => {
             
            setData(data.Clstatus);
            setSpinner(false);
            setCount({
                waitingCount: data.count1,
                pickupCount: data.count2,
                pendingCount: data.count3,
                completeCount: data.count4,
                rescheduleCount: data.count5,
                cancelCount: data.count6,
                dispatchedCount: data.count7,
                deleteCount: data.count8,
            });
            setCurrentPage(1);
            setVal({waybill_id: "",
            search: "",
            parcelType:val.parcelType,
            fdate:val.fdate,
            tdate:val.tdate,
            paymentType:val.paymentType,
            order_id:"",
            status: val.status });
            })
        } catch (error) {
        console.log(error.message)
        }
        
        setIsSBtnInactive(false);
    }

    document.title = "Logistic Client Portal | All Order List";
    //console.log(Data)


    const ExportandSearchb = (e) => {
         
        e.preventDefault(); 
        setIsExBtnInactive(true);
        // setExDivRes("Waiting")
       
       function download_csv_file(datan) {

            var today = new Date();
        
            var todaytime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        
            var todaydate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        
            var datetime = todaydate+" "+todaytime;
    
            // const datetime =  `${new Date().toDateString()} ${new Date().toLocaleTimeString()}`;
            let csv = `"WAYBILL ID","ORDER DATE","PICKUP DATE","PROCESSING DATE","DELIVERED DATE","RESCHEDULE DATE","RETURN DATE","DISPATCHED DATE","REMOVE DATE","DELIVERY STATUS",RIDER,HUB,"ORDER ID","PARCEL TYPE","PARCEL DESCRIPTION","CLIENT NOTE","RECIPIENT NAME","RECIPIENT CONTACT NUMBER","RECIPIENT ADDRESS","RECIPIENT CITY","RECIPIENT CITY ZONE","PICKUP FROM","PICKUP ADDRESS","PICKUP CITY","PAY METHOD",AMOUNT,"DELIVERY FEE","ADMIN NOTE","REQUEST TYPE"\n`;
                    
            //merge the data with CSV
            datan.map( (row) => {
                    const values = [];
        
                    if(row.rider_id){
                        // var riderIdval    = parseInt(row.rider_id);
                        // var newRiderIdval = parseInt(riderIdval-1);
                   
                        // var riderFname = riderArrayn[newRiderIdval].fname;
                        // var riderLname = riderArrayn[newRiderIdval].lname;
                        
                        // var riderFullName = riderFname+' '+riderLname+' ('+row.rider_id+')';
                        
                        var riderFname = {...adminInfo.find(element => element.id == row.rider_id)}.fname
                        var riderLname = {...adminInfo.find(element => element.id == row.rider_id)}.lname
                        
                        var riderFullName = riderFname+' '+riderLname+' ('+row.rider_id+')';
                   
                    }else{
                      var riderFullName = '';
                    }
        
                    if(row.recipientCity){
                   
                        // var ridercityval    = parseInt(row.recipientCity);
                        // var newRiderCityval = parseInt(ridercityval-1);
                        var riderRecipientCity =    {...zoneNames.find(element => element.cid == row.recipientCity)}.cname
                        
                        // var riderRecipientCity = riderZoneCityArr[newRiderCityval].cname; 
                     }else{
                        var riderRecipientCity = '';
                     }
                     
                   
                      
                     if(row.zoneCity){
                        
                        // var riderZoneyval    = parseInt(row.zoneCity);
                        // var newRiderZoneyval = parseInt(riderZoneyval-1);
                        
                        var riderZoneCity =    {...cityNames.find(element => element.cid == row.zoneCity)}.cname
                        
                        // var riderZoneCity = riderCityArr[newRiderZoneyval].cname; 
                     }else{
                        var riderZoneCity = '';
                     }
        
                     
                     
                     if(row.orderDate){
                         var neworderDate = ' '+row.orderDate+' ';
                     }else{
                         var neworderDate = '';
                     }
             
                     if(row.hub_id) {
                         
                         var hubName =    {...hubMName.find(element => element.id == row.hub_id)}.name
                         
                        //  var hubID = parseInt(row.hub_id);
                        //  var hubName = hubNameData.find(item => {
                        //      if(item.id === hubID) {
                        //          return item.name;
                        //      } 
                        //  })
                     }
             
                     if(row.pType) {
                       var pTpeN =   {...parcelTypeA.find(element => element.id == row.pType)}.pName
                        //  var pid = parseInt(row.pType);
                        //  var pArr = parcelType.find(item => {
                        //      return item.id === pid;
                        //  })
                     }
                     
                     if(row.pickupCity){
                   
                           var riderPickupcityval    = parseInt(row.pickupCity);
                           var newRiderPickupCityval = parseInt(riderPickupcityval-1);
                           
                             var riderPickCity =    {...zoneNames.find(element => element.cid == row.pickupCity)}.cname
                        //   var riderPickCity = riderZoneCityArr[newRiderPickupCityval].cname; 
                     }else{
                           var riderPickCity = '';
                     }
        
                     if(row.id){
                        values.push(row.id);
                     } else {
                        values.push('');
                     }
        
                     if(row.orderDate){
                        values.push(neworderDate);
                     }else{
                        values.push('');
                     }
        
                     if(row.collectedDate){
                        values.push(row.collectedDate);
                     } else {
                        values.push('');
                     }
        
                     if(row.processig_date){
                        values.push(row.processig_date);
                     } else {
                        values.push('');
                     }
        
                     if(row.deliveredDate){
                        values.push(row.deliveredDate);
                     } else {
                        values.push('');
                     }
                    
                     if(row.reschedule_date){
                        values.push(row.reschedule_date);
                     } else {
                        values.push('');
                     }
                    
                     if(row.cancellation_date){
                        values.push(row.cancellation_date);
                     } else {
                        values.push('');
                     }
        
                     if(row.dispatchedDate){
                        values.push(row.dispatchedDate);
                     } else {
                        values.push('');
                     }
                    
                     if(row.removedDate){
                        values.push(row.removedDate);
                     } else {
                        values.push('');
                     }
        
                     if(row.deliveryStatus){
                        values.push(row.deliveryStatus);
                     } else {
                        values.push('');
                     }
                    
                    if(row.rider_id){
                      values.push(riderFullName); 
                        // values.push(row.rider_id);
                    }else{
                       values.push(''); 
                    }
                    
                    if(row.hub_id){
                        //  values.push(row.hub_id);
                        values.push(hubName);
                    } else {
                        values.push('');
                    }
        
                    if(row.orderID){
                        values.push(row.orderID);
                    } else {
                        values.push('');
                    }
        
                    if(row.pType) {
                        // values.push(row.pType);
                        values.push(pTpeN);
                    } else {
                        values.push('');
                    }
        
                    if(row.pDescription) {
                         values.push(row.pDescription.split(',').join(' '));
                    } else {
                        values.push('');
                    }
        
                    if(row.clientNote) {
                        values.push(row.clientNote);
                    } else {
                        values.push('');
                    }
        
                    if(row.recipientName) {
                        values.push(row.recipientName);
                    } else {
                        values.push('');
                    }
        
                    if(row.recipientMobile) {
                        values.push(row.recipientMobile);
                    } else {
                        values.push('');
                    }
                   
                    if(row.recipientAddress) {
                        var newrecAddress = row.recipientAddress.split(',').join(' ');
                        values.push(newrecAddress.split('#').join(' '));
                    } else {
                        values.push('');
                    }
        
                    if(row.zoneCity){
                        //  values.push(row.zoneCity);
                      values.push(riderZoneCity);
                    }else{
                       values.push(''); 
                    }
                    
                    if(row.recipientCity) {
                        // values.push(row.recipientCity);
                        values.push(riderRecipientCity);
                    } else {
                        values.push('');
                    }
                    
                    if(row.pickupFrom) {
                        values.push(row.pickupFrom);
                    } else {
                        values.push('');
                    }
                    
                    if(row.pickupAddress) {
                        values.push(row.pickupAddress.split(',').join(' '));
                    } else {
                        values.push('');
                    }
                    
                    if(row.pickupCity) {
                        values.push(riderPickCity);
                    } else {
                        values.push('');
                    }
                    
                    if(row.paymentType) {
                        values.push(row.paymentType);
                    } else {
                        values.push('');
                    }
                    
                    if(row.paymentType == 'COD'){
                        if(row.CODAmount) {
                            values.push(row.CODAmount);
                        }else {
                            values.push('');
                        }
                    
                    }else{
                      values.push('');
                    } 
                    
                    if(row.deliveryFee !="" && row.deliveryFee !=0){
                       values.push(row.deliveryFee);
                    }else{
                        values.push('');
                    } 
                    
                    if(row.adminNote) {
                        values.push(row.adminNote);
                    } else {
                        values.push('');
                    }
                    
                    if(row.interface) {
                        values.push(row.interface);
                    } else {
                        values.push('');
                    }
                    
                    
                    
                    csv += values.join(',');
                    csv += "\n";
            });
         
       
            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
            hiddenElement.target = '_blank';
            
            //provide the name for the CSV file to be downloaded
            hiddenElement.download = `${datetime}.csv`;
            hiddenElement.click();
        }
       
        async function handleExport1() {

            try {
                await fetch(`${process.env.REACT_APP_URL}/index.php/allParcels`, {
                method: 'POST', 
                body: JSON.stringify({
                    clientID: ClientId, 
                    waybill_id: val.waybill_id,
                    search: val.search,
                    paymentType: val.paymentType,
                    parcelType: val.parcelType,
                    order_id: val.order_id,
                    fdate: val.fdate,
                    tdate: val.tdate,
                    status: val.status                              
                }),         
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
                .then((respose) => {
                if (respose.ok) {
                    return respose.json()
                }
                throw new Error('error')
                })
                .then((data) => {
                    // download_csv_file(data.Clstatus);
                    setData(data.Clstatus);
                    setCurrentPage(1);
                    // setIsExAllow(true);
                    download_csv_file(data.Clstatus);
                })
            } catch (error) {
                console.log(error.message)
            }
            
            setIsExBtnInactive(false);
        }
        handleExport1();
        
        
    //   handleExport1(`${process.env.REACT_APP_URL}/index.php/allArchiveParcels`, setData, {...val, clientID : user})
    
        // setIsExBtnInactive(false);
        // setExDivRes('Search & Export')
    }

    return(
        <>
            {(localStorage.getItem('loggedUser')) === "userLogged"? <TopBar/> : <></> }

            {(localStorage.getItem('loggedUser')) === "userLogged"? <SideBar/> : <></> }

            <div className='main-content'>
                <div className='page-content'>
                    <div className='container-fluid'>
                        <div className="row mt-3 mb-3">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <h4 className="font-size-18">All Parcel List</h4>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                                <div className='row'>
                                    
                                    <Waybill func={handleChange} value={val.waybill_id}/>
                                    <Search func={handleChange} value = {val.search}/>
                                    <ParcelType func={handleChange} value = {val.parcelType}/>
                                    <FromDate func={handleChange} label="Date From" value = {val.fdate}/>
                                    <ToDate func={handleChange} label="Date To" value = {val.tdate}/>  
                                    <PaymentType func={handleChange} value = {val.paymentType}/>
                                    <Status setStatusArray={setStatusArray}  value = {val.status}/>
                                    <OrderId func={handleChange} value = {val.order_id}/>

                                </div>

                                <div className="row">
                                    <div className="col-8 col-md-6 col-lg-6"> </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-block" id="searchBtn" onClick={getData} disabled={isSBtnInactive ? true : false}> Search</button>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            {/* <button className="btn btn-info btn-block" id="btnSeartchExport" onClick={() => {handleExport(`${process.env.REACT_APP_URL}/index.php/allParcels`, setData, {...val, clientID : user})} }> Search &amp; Export</button> */}
                                            <button className="btn btn-info btn-block" id="btnSeartchExport" onClick={ExportandSearchb} disabled={isExBtnInactive ? true : false}> Search &amp; Export</button>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2">
                                        <div className="form-group">
                                            
                                            <Link to={`/parcel-print/${ClientId}?waybill_id=${val.waybill_id}&search=${val.search}&parcelType=${val.parcelType}&fdate=${val.fdate}&tdate=${val.tdate}&paymentType=${val.paymentType}&orderID=${val.order_id}&status=${val.status}&type=allparcel`} className="btn btn-success btn-block" target="_blank" onClick={getData}>Print</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-xl-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div id="aa">
                                                    <p><span className="badge" style={{color: '#fff', backgroundColor: '#0054a6'}}>Total Parcel Count - {Data.length}</span> {count.waitingCount != null && (<span className="badge" style={{color: '#fff', backgroundColor: 'green'}}> Waiting - {count.waitingCount}</span>)} {count.pickupCount != null && (<span className="badge badge-warning"> Pickup - {count.pickupCount}</span>)} {count.pendingCount != null && (<span className="badge badge-info"> Processing - {count.pendingCount}</span>)} {count.completeCount != null && (<span className="badge badge-success"> Delivered - {count.completeCount}</span>)} {count.rescheduleCount != null && (<span className="badge badge-primary"> Reschedule - {count.rescheduleCount}</span>)} {count.cancelCount != null && (<span className="badge badge-danger"> Return - {count.cancelCount}</span>)} {count.dispatchedCount != null && (<span className="badge" style={{color: '#fff', backgroundColor: '#f838f6'}}> Dispatched - {count.dispatchedCount}</span>)} {count.deleteCount != null && (<span className="badge" style={{color: '#fff', backgroundColor: 'red'}}> Remove - {count.deleteCount}</span>)}</p>
                                                    <div id='target-content'>
                                                        {
                                                            (Data.length !== 0) ? (
                                                                <div className='table-responsive'>
                                                                    <table className='table'>
                                                                        <thead className="thead-dark">
                                                                            
                                                                            <TableHead/>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                records.map( (item) => {
                                                                                    return (
                                                                            
                                                                                        <TableBody key={item.id} item = {item} cityNames = {cityNames} zoneNames = {zoneNames} setwaybill = {setwaybill} changeDisplay={changeDisplay} type = "allparcel" setModalIsOpen={setModalIsOpen} dataIsChoose={getData} />
                                                                                    )
                                                                                }
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <nav>
                                                                        
                                                                        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} data={Data} itemPerPage={recordsPerPage}/>
                                                                    </nav>
                                                                </div>
                                                            ) : (
                                                                spinner ? <div className="card" style={{  textAlign: 'center', display: 'block' }}> <i className="fas fa-spinner fa-spin fa-4x" style={{ color: '#1088e2', marginTop: '100px'}}></i> </div> :
                                                                <div className="card bg-danger">
                                                                    <div className="card-body">
                                                                        <h5 className="text-center text-white"> No parcel</h5> 
                                                                    </div> 
                                                                </div>
                                                                
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        
            {modalIsOpen === true &&(<ModalA waybill = {waybillID} title = "" adminNUserInfo={adminInfo} clientNInfo={clientInfo} modalIsClose={setModalIsOpen}/>)}
            {/* {modalIsOpen === true && (<ModB/>)} */}
            <div className={(modalIsOpen) ? "modal-backdrop show" : ""}></div>
        </>
    )
}

export default AllParcel;